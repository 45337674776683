import { z } from 'zod'

export const fileItemSchema = z.object({
  id: z.string(),
  file_type: z.string(),
  file_name: z.string(),
  url: z.string(),
  content_length: z.number(),
})
export type FileItem = z.infer<typeof fileItemSchema>

export const updateFileItemSchema = fileItemSchema.pick({ id: true })
export type UpdateFileItem = z.infer<typeof updateFileItemSchema>

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { noop } from 'lodash-es'
import { Recipient } from '@/api/user-api'
import {
  failureAPIStatus,
  initialAPIStatus,
  requestingAPIStatus,
  successAPIStatus,
} from '@/constants/api-status'
import GeneralTagCode from '@/constants/general-tag-code'
import { PostType } from '@/constants/post-type'
import { APIStatus } from '@/types/api-status'
import {
  BrandRecognitionSnap,
  CampaignKol,
  ModelMinorContractTemplate,
  Picture,
  UpdateCampaignKolContractRequest,
  UpdateCampaignKolPaymentRequest,
  UpdateCampaignKolRequest,
} from '@/types/mock-api-types'
import { ListResource } from '@/types/resource-type'
import { PlatformShortcode } from '@/utils/convert-platform'

export enum CampaignTab {
  kolList = 'kol-list',
  chatroom = 'chatroom',
  content = 'content',
  reporting = 'reporting',
  setting = 'setting',
}

export enum CampaignSettingsTab {
  campaign = 'campaign',
  contract = 'contract',
}

export enum CampaignKolListTab {
  Preparing = 'preparing',
  Confirming = 'confirming',
  Running = 'running',
  Done = 'done',
  Close = 'close',
}

export enum QueryParams {
  CampaignId = 'campaignId',
  ConversationID = 'conversationID',
  From = 'from',
  KolListTab = 'kolListTab',
  SettingsTab = 'settingsTab',
  Tab = 'tab',
  ConfirmingListTab = 'confirmingListTab',
}

interface UpdateCampaignKolsPayload {
  campaignKolIds: number[]
  campaignKol: UpdateCampaignKolRequest
  campaignId: number
}

interface UpdateCampaignKolContractsPayload {
  campaignKolContractID: string
  campaignKolId: number
  campaignKolContract: UpdateCampaignKolContractRequest
  campaignId: number
}

interface CreateCampaignKolContractsPayload {
  campaignKolIds: number[]
  campaignKolContract: UpdateCampaignKolContractRequest
  campaignId: number
}

export interface CooperationItemBrand {
  /**
   * 後端說先開 Array
   */
  generalTagCodes: GeneralTagCode[]
  name: string
  link: string
  description: string
  pictures: Picture[]
}

export interface CooperationItemProduct extends CooperationItemBrand {
  value: number
}

export interface CampaignPicture extends Picture {
  description: string
}

export enum CampaignPurpose {
  PromotingBrand = 'promoting_brand',
  PromotingProduct = 'promoting_product',
}

export interface FollowerCountRange {
  followerCountLessThan?: number
  followerCountGreaterThan?: number
}

interface Contract {
  kolUUID: string
  accept: boolean
}

export interface Campaign {
  id: number
  name: string

  /**
   * Confirming List Count = applied + inviting
   * Closed List Count = rejected + canceled
   */
  campaignKolStatus: {
    applied: number
    preparing: number
    inviting: number
    running: number
    rejected: number
    done: number
    canceled: number
  }
  remark?: string

  /**
   * 甲方名稱
   */
  partyACompany: string

  /**
   * 甲方統一編號
   */
  partyATaxId: string

  /**
   * 甲方負責人
   */
  partyAOwner: string

  /**
   * 甲方負責人聯絡電話
   */
  partyAOwnerPhone: string

  /**
   * 甲方負責人 Email
   */
  partyAOwnerEmail: string
  brandRecognitionSnap: BrandRecognitionSnap
  description: string
  endAt: string
  platformInvolved: PlatformShortcode[]
  startAt: string
  campaignPurpose: CampaignPurpose
  postTypes: PostType[]
  /**
   * 後端說先開 Array
   */
  generalTagCodes: GeneralTagCode[]
  applyEndTime?: string
  isPublic: boolean
  cooperation: {
    numberOfCooperators: number
    budgetPerPerson: number
    specifiedContentValue?: string
    postWrittenScenarioValue?: string
    specifiedHashtagsValue: string[]
    specifiedMentionsValue: string[]
    specifiedLinkValue: string[]
    ig: FollowerCountRange
    yt: FollowerCountRange
    fb: FollowerCountRange
  }
  brands: CooperationItemBrand[]
  products: CooperationItemProduct[]
  qualifiedSamplePicture?: CampaignPicture
  unqualifiedSamplePicture?: CampaignPicture
  contracts: Contract[]
  havePaid: boolean
  snapIsInternal: boolean
  campaignKolAmount: number
  owner: {
    name: string
  } | null
}

interface TemporaryCampaign
  extends Omit<
    Campaign,
    'qualifiedSamplePicture' | 'unqualifiedSamplePicture'
  > {
  qualifiedSamplePicture?: Pick<CampaignPicture, 'description'>
  unqualifiedSamplePicture?: Pick<CampaignPicture, 'description'>
}

interface CampaignContractTemplates {
  data?: ModelMinorContractTemplate[]
  total?: number
  page?: number
  totalPage?: number
}

interface CampaignState {
  apiStatus: {
    updateCampaignKols: APIStatus
    inviteCampaignKols: APIStatus
    cancelInviteCampaignKols: APIStatus
    fetchCampaignContractTemplates: APIStatus
    updateCampaignKolContracts: APIStatus
    createCampaignKolContracts: APIStatus
    updateCampaignKolPayment: APIStatus
    removeCampaignKols: APIStatus
  }
  loading: boolean
  selectedKolListTab: string
  selectedSettingsTab: string
  selectedTab: string
  campaign?: Campaign
  temporaryCampaign?: TemporaryCampaign
  templateList: ModelMinorContractTemplate[]
  campaignKols?: ListResource<CampaignKol>
  campaignContractTemplates?: CampaignContractTemplates
  recipientInfo?: Recipient
}

const initialState: CampaignState = {
  apiStatus: {
    updateCampaignKols: initialAPIStatus(),
    inviteCampaignKols: initialAPIStatus(),
    cancelInviteCampaignKols: initialAPIStatus(),
    fetchCampaignContractTemplates: initialAPIStatus(),
    updateCampaignKolContracts: initialAPIStatus(),
    createCampaignKolContracts: initialAPIStatus(),
    updateCampaignKolPayment: initialAPIStatus(),
    removeCampaignKols: initialAPIStatus(),
  },
  loading: false,
  selectedKolListTab: CampaignKolListTab.Preparing,
  selectedSettingsTab: CampaignSettingsTab.campaign,
  selectedTab: CampaignTab.kolList,
  campaign: undefined,
  templateList: [],
  campaignKols: undefined,
  campaignContractTemplates: {},
  recipientInfo: undefined,
}

const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    removeCampaignKols: (
      state,
      action: PayloadAction<{
        campaignId: number
        campaignKolIds: number[]
      }>,
    ) => {
      state.apiStatus.removeCampaignKols = requestingAPIStatus()
      noop(action)
    },
    removeCampaignKolsFailure: (state) => {
      state.apiStatus.removeCampaignKols = failureAPIStatus()
    },
    removeCampaignKolsSuccess: (state, action: PayloadAction<number[]>) => {
      state.apiStatus.removeCampaignKols = successAPIStatus()
      noop(action)
    },
    getCampaignKolsSuccess: (
      state,
      action: PayloadAction<ListResource<CampaignKol>>,
    ) => {
      state.campaignKols = action.payload
    },
    fetchCampaignSuccess: (state, action: PayloadAction<Campaign>) => {
      state.campaign = action.payload
    },
    updateCampaignKols: (
      state,
      action: PayloadAction<UpdateCampaignKolsPayload>,
    ) => {
      state.apiStatus.updateCampaignKols = requestingAPIStatus()
      noop(action)
    },
    updateCampaignKolsSuccess: (
      state,
      action: PayloadAction<UpdateCampaignKolsPayload>,
    ) => {
      state.apiStatus.updateCampaignKols = successAPIStatus()

      if (state.campaignKols?.data) {
        state.campaignKols.data = state.campaignKols.data.map((kol) => {
          if (action.payload.campaignKolIds.includes(kol.id)) {
            return { ...kol, ...(action?.payload.campaignKol || {}) }
          }

          return kol
        })
      }
    },
    updateCampaignKolsFailure: (state) => {
      state.apiStatus.updateCampaignKols = failureAPIStatus()
    },
    fetchCampaignContractTemplates: (
      state,
      action: PayloadAction<{ page: number; campaignId: number }>,
    ) => {
      state.apiStatus.fetchCampaignContractTemplates = requestingAPIStatus()
      noop(action)
    },
    fetchCampaignContractTemplatesSuccess: (
      state,
      action: PayloadAction<CampaignContractTemplates>,
    ) => {
      state.apiStatus.fetchCampaignContractTemplates = successAPIStatus()
      state.campaignContractTemplates = action.payload
    },
    fetchCampaignContractTemplatesFailure: (state) => {
      state.apiStatus.fetchCampaignContractTemplates = failureAPIStatus()
    },
    updateCampaignKolContracts: (
      state,
      action: PayloadAction<UpdateCampaignKolContractsPayload>,
    ) => {
      state.apiStatus.updateCampaignKolContracts = requestingAPIStatus()
      noop(action)
    },
    updateCampaignKolContractsSuccess: (state) => {
      state.apiStatus.updateCampaignKolContracts = successAPIStatus()
    },
    updateCampaignKolContractsFailure: (state) => {
      state.apiStatus.updateCampaignKolContracts = failureAPIStatus()
    },
    createCampaignKolContracts: (
      state,
      action: PayloadAction<CreateCampaignKolContractsPayload>,
    ) => {
      state.apiStatus.createCampaignKolContracts = requestingAPIStatus()
      noop(action)
    },
    createCampaignKolContractsSuccess: (state) => {
      state.apiStatus.createCampaignKolContracts = successAPIStatus()
    },
    createCampaignKolContractsFailure: (state) => {
      state.apiStatus.createCampaignKolContracts = failureAPIStatus()
    },
    switchCampaignKolListTab: (state, action: PayloadAction<string>) => {
      state.selectedKolListTab = action.payload
    },
    switchCampaignSettingsTab: (state, action: PayloadAction<string>) => {
      state.selectedSettingsTab = action.payload
    },
    switchCampaignTab: (state, action: PayloadAction<string>) => {
      state.selectedTab = action.payload
    },
    updateCampaignKolPayment: (
      state,
      action: PayloadAction<{
        campaignId: number
        campaignKolIds: number[]
        campaignKol: UpdateCampaignKolPaymentRequest
        requestPaymentDate?: string
      }>,
    ) => {
      state.apiStatus.updateCampaignKolPayment = requestingAPIStatus()
      noop(action)
    },
    updateCampaignKolPaymentSuccess: (state) => {
      state.apiStatus.updateCampaignKolPayment = successAPIStatus()
    },
    updateCampaignKolPaymentFailure: (state) => {
      state.apiStatus.updateCampaignKolPayment = failureAPIStatus()
    },
    setRecipientInfo: (state, action: PayloadAction<Recipient | undefined>) => {
      state.recipientInfo = action.payload
    },
    setTemporaryCampaign: (
      state,
      action: PayloadAction<TemporaryCampaign | undefined>,
    ): void => {
      if (!action.payload) {
        state.temporaryCampaign = undefined
        return
      }

      const now = dayjs()

      state.temporaryCampaign = {
        ...action.payload,
        name: action.payload.name + 'copy',
        startAt: now.toISOString(),
        endAt: now.add(30, 'day').toISOString(),
        applyEndTime: now.add(15, 'day').toISOString(),
      }
    },
  },
})

export const {
  fetchCampaignSuccess,
  fetchCampaignContractTemplates,
  fetchCampaignContractTemplatesFailure,
  fetchCampaignContractTemplatesSuccess,
  getCampaignKolsSuccess,
  switchCampaignKolListTab,
  switchCampaignSettingsTab,
  switchCampaignTab,
  updateCampaignKolContracts,
  updateCampaignKolContractsFailure,
  updateCampaignKolContractsSuccess,
  createCampaignKolContracts,
  createCampaignKolContractsFailure,
  createCampaignKolContractsSuccess,
  updateCampaignKols,
  updateCampaignKolsFailure,
  updateCampaignKolsSuccess,
  updateCampaignKolPayment,
  updateCampaignKolPaymentFailure,
  updateCampaignKolPaymentSuccess,
  removeCampaignKols,
  removeCampaignKolsFailure,
  removeCampaignKolsSuccess,
  setRecipientInfo,
  setTemporaryCampaign,
} = campaignSlice.actions

export default campaignSlice.reducer

import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import {
  APIErrorData,
  ERROR_FAILED_AUTHENTICATION,
  ERROR_USER_ALREADY_EXISTS,
} from '@/constants/error'

export const isRTKFetchBaseQueryError = (
  error: unknown,
): error is FetchBaseQueryError => {
  if (typeof error !== 'object' || error === null) {
    return false
  }

  return (
    'status' in error &&
    (typeof error.status === 'number' ||
      [
        'FETCH_ERROR',
        'PARSING_ERROR',
        'TIMEOUT_ERROR',
        'CUSTOM_ERROR',
      ].includes(error.status as string))
  )
}

const isErrorDataFromAPI = (
  errorData: Record<string, any>,
): errorData is APIErrorData => {
  return 'Errors' in errorData
}

const isRTKErrorFromSomeAPI = (
  error: unknown,
  predicator: (errors: APIErrorData['Errors']) => boolean,
): boolean => {
  if (!isRTKFetchBaseQueryError(error)) {
    return false
  }

  if (
    typeof error.data !== 'object' ||
    typeof error.data === 'undefined' ||
    error.data === null
  ) {
    return false
  }

  if (isErrorDataFromAPI(error.data)) {
    return predicator(error.data.Errors)
  }

  return false
}

export const hasFailedAuthenticationError = (error: unknown): boolean => {
  return isRTKErrorFromSomeAPI(error, (errors) =>
    errors.some((error) => error === ERROR_FAILED_AUTHENTICATION),
  )
}

export const hasUserAlreadyExistsError = (error: unknown): boolean => {
  return isRTKErrorFromSomeAPI(error, (errors) =>
    errors.some((error) => error === ERROR_USER_ALREADY_EXISTS),
  )
}

const isLocalhost = (host: string): boolean => {
  return host.startsWith('localhost')
}

export const getCookieDomain = (host: string): string => {
  if (isLocalhost(host)) {
    return 'localhost'
  }

  return host
}

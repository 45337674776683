import { UserType } from '@/hooks/use-authorization/constants'
import { UserSchema } from '@/types/schema/user-schema'

export enum RegistrationStatus {
  UntypedKol,
  Incomplete,
  EmailNotConfirmed,
  Unverified,
  VerifiedAd,
  UnverifiedKol,
  Done,
}

export const getRegistrationStatus = (user: UserSchema): RegistrationStatus => {
  if (user.type === UserType.Untyped) {
    return RegistrationStatus.UntypedKol
  }

  if (user.incompleteRegistration) {
    return RegistrationStatus.Incomplete
  }

  if (!user.emailConfirmed) {
    return RegistrationStatus.EmailNotConfirmed
  }

  if (!user.verified) {
    return RegistrationStatus.Unverified
  }

  if (user.type === UserType.NormalAd) {
    return RegistrationStatus.VerifiedAd
  }

  if (user.type === UserType.NormalKol || user.type === UserType.Agency) {
    return RegistrationStatus.UnverifiedKol
  }

  return RegistrationStatus.Done
}

import { z } from 'zod'

const tokenBasedPaginationSchema = z.object({
  per_page: z.number().nullish(),
  total: z.number(),
  total_page: z.number(),
  next_page: z.string().nullish(),
  previous_page: z.string().nullish(),
})
export type TokenBasedPagination = z.infer<typeof tokenBasedPaginationSchema>

export default tokenBasedPaginationSchema

import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { useFetchUserStatusQuery } from '@/api/user-api'
import Footer from '@/components/common/footer'
import { CountryCode } from '@/constants/country-code'

interface IrmFooterProps {
  isCollapsed?: boolean
}

const IrmFooter: FunctionComponent<IrmFooterProps> = ({
  isCollapsed = false,
}) => {
  const { data: userStatus } = useFetchUserStatusQuery()
  return (
    <FooterWrapper isCollapsed={isCollapsed}>
      <Footer
        displayTaxId={
          userStatus?.currentWorkspace?.countryCode === CountryCode.TW
        }
      />
    </FooterWrapper>
  )
}

export const FooterWrapper = styled.div<{ isCollapsed: boolean }>`
  .ant-layout-footer {
    background-color: transparent;
  }

  margin: 0 0 0 ${(props): number => (props.isCollapsed ? 80 : 200)}px;
`

export default IrmFooter

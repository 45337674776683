import { useMemo } from 'react'
import { useFetchUserMetadataQuery } from '@/api/user-api'
import migrateData from '@/constants/user-metadata'
import { UserMetadata, userMetadataSchema } from '@/types/schema/user-metadata'

interface UseUserMetadata {
  userMetadata?: UserMetadata
  isFetchingUserMetadata: boolean
}

const useUserMetadata = (): UseUserMetadata => {
  const { data: response, isFetching: isFetchingUserMetadata } =
    useFetchUserMetadataQuery()

  const userMetadata = useMemo<UserMetadata | undefined>(() => {
    if (!response?.data) {
      return undefined
    }
    const parseData = userMetadataSchema.safeParse(response.data)
    return parseData.success ? parseData.data : migrateData(response.data)
  }, [response?.data])

  return {
    userMetadata,
    isFetchingUserMetadata,
  }
}

export default useUserMetadata

import { Icon } from '@buggy/shared'
import { Select, SelectProps } from 'antd'
import { FunctionComponent } from 'react'
import { useTheme } from 'styled-components'

/**
 * antd Select Component with override suffixIcon
 */
const BasicSelect: FunctionComponent<
  Omit<SelectProps, 'suffixIcon' | 'removeIcon'>
> = ({ children, ...otherProps }) => {
  const theme = useTheme()
  return (
    <Select
      {...otherProps}
      menuItemSelectedIcon={<Icon fontSize={16} name='check' />}
      removeIcon={
        <Icon color={theme.colors.text.tooltip} fontSize={10} name='x' />
      }
      suffixIcon={
        <Icon
          color={theme.colors.text.tooltip}
          fontSize={16}
          name='chevron-down'
        />
      }
    >
      {children}
    </Select>
  )
}

export default BasicSelect

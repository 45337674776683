import { isEqual, isUndefined, omit } from 'lodash-es'
import { api, WorkspacesAPITagType } from '@/api/api'
import getWorkspaceApiUrl from '@/api/utils/get-workspace-api-url'
import {
  KolRadarSearchRequest,
  KolRadarSearchResponse,
  KOLResponse,
  QuickSearchRequest,
  QuickSearchResponse,
  QuickSearchWithExploreResponse,
  SearchSimilarKolResponse,
  SearchSimilarKolsRequest,
  PostRadarSearchRequest,
} from '@/types/api/search'
import {
  postRadarSearchResponseSchema,
  PostRadarSearchResponse,
} from '@/types/schema/api/radar-search-post'
import { applyKolSchemaModel } from '@/types/schema/kol-schema'
import { camelizeKeys, decamelizeKeys } from '@/utils/humps'
import removeNil from '@/utils/remove-nil'

export const searchApi = api.injectEndpoints({
  endpoints: (builder) => ({
    searchSimilarKols: builder.query<
      SearchSimilarKolResponse,
      SearchSimilarKolsRequest
    >({
      query: ({ workspaceId, ...params }) => {
        return {
          url: `/v3/workspaces/${workspaceId}/kol/search-similar-kols`,
          params: removeNil(params),
        }
      },
      transformResponse: (response: object) => {
        const camelizeResponse = camelizeKeys(
          response,
        ) as unknown as SearchSimilarKolResponse

        return {
          ...camelizeResponse,
          list: camelizeResponse.list.map(applyKolSchemaModel),
        }
      },
      providesTags: [
        WorkspacesAPITagType.SearchSimilarKols,
        WorkspacesAPITagType.KolCollectionKol,
      ],
    }),
    kolRadarSearch: builder.query<
      KolRadarSearchResponse,
      KolRadarSearchRequest
    >({
      query: ({ workspaceId, params, beforeQueryStarts, onQueryFulfilled }) => {
        return {
          url: `/v3/workspaces/${workspaceId}/kol/radar-search`,
          params,
          beforeQueryStarts,
          onQueryFulfilled,
        }
      },
      transformResponse: (response: object) => {
        const camelizeResponse = camelizeKeys(
          response,
        ) as unknown as KOLResponse

        return {
          ...camelizeResponse,
          list: camelizeResponse.list.map(applyKolSchemaModel),
        }
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
      merge: (currentCache, newResponse, { arg }): void => {
        const updateCache = {
          ...newResponse,
          list: isUndefined(arg.params.anchor)
            ? newResponse.list
            : [...currentCache.list, ...newResponse.list],
        }

        Object.assign(currentCache, updateCache)
      },
      forceRefetch({ currentArg, previousArg }): boolean {
        const omitEventKeys = ['beforeQueryStarts', 'onQueryFulfilled']

        return !isEqual(
          omit(currentArg, omitEventKeys),
          omit(previousArg, omitEventKeys),
        )
      },
      providesTags: [
        WorkspacesAPITagType.KolRadarSearch,
        WorkspacesAPITagType.KolCollectionKol,
      ],
    }),
    quickSearch: builder.query<QuickSearchResponse, QuickSearchRequest>({
      query: ({ limit = 60, ...params }) => {
        return {
          url: getWorkspaceApiUrl('/kol/quick-search'),
          params: removeNil(decamelizeKeys({ ...params, limit })),
        }
      },
      transformResponse: (response: object) => {
        return camelizeKeys(response) as unknown as QuickSearchResponse
      },
    }),
    quickSearchWithExplore: builder.query<
      QuickSearchWithExploreResponse,
      QuickSearchRequest
    >({
      query: ({ limit = 60, ...params }) => {
        return {
          url: getWorkspaceApiUrl('/kol/quick-search'),
          params: removeNil(
            decamelizeKeys({ ...params, limit, with_explore: true }),
          ),
        }
      },
      transformResponse: (response: object) => {
        return camelizeKeys(
          response,
        ) as unknown as QuickSearchWithExploreResponse
      },
    }),
    postRadarSearch: builder.query<
      PostRadarSearchResponse,
      PostRadarSearchRequest
    >({
      query: ({ workspaceId, params, beforeQueryStarts, onQueryFulfilled }) => {
        return {
          url: `/workspaces/${workspaceId}/kol/radar-search-post`,
          params,
          beforeQueryStarts,
          onQueryFulfilled,
        }
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
      merge: (currentCache, newResponse, { arg }): void => {
        const updateCache = {
          ...newResponse,
          data: arg.params.after_page
            ? [...currentCache.data, ...newResponse.data]
            : newResponse.data,
        }

        Object.assign(currentCache, updateCache)
      },
      forceRefetch({ currentArg, previousArg }): boolean {
        const omitEventKeys = ['beforeQueryStarts', 'onQueryFulfilled']

        return !isEqual(
          omit(currentArg, omitEventKeys),
          omit(previousArg, omitEventKeys),
        )
      },
      extraOptions: {
        dataSchema: postRadarSearchResponseSchema,
      },
      providesTags: [WorkspacesAPITagType.PostRadarSearch],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled
        } catch (error) {
          console.error(error)
        }
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useSearchSimilarKolsQuery,
  useKolRadarSearchQuery,
  useQuickSearchQuery,
  useLazyQuickSearchQuery,
  useQuickSearchWithExploreQuery,
  useLazyQuickSearchWithExploreQuery,
  usePostRadarSearchQuery,
} = searchApi

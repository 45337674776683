import { NextRouter, useRouter } from 'next/router'
import { useLifecycles } from 'react-use'
import LocalStorageKeys from '@/constants/localstorage-keys'
import { Page } from '@/hooks/use-authorization/constants'
import { localStorageObserverMap } from '@/hooks/use-local-storage-safely'
import { getCurrentLocalStorageKey } from '@/hooks/use-search-id'
import env from '@/utils/env'
import SearchIdManager from '@/utils/search-id-manager'
import LocalStorageStore from '@/utils/search-id-manager/store'

const useSearchIdManagerInit = (): void => {
  const router = useRouter()

  useLifecycles(
    async () => {
      const searchIdManager = SearchIdManager.getInstance()
      await searchIdManager.init({
        store: new LocalStorageStore(LocalStorageKeys.SearchIdMap),
        searchIdValidationRuleList: [
          kdToKdRule(router),
          searchToKdRule(router),
        ],
        searchIdObserverList: [notifyReactComponentRender],
      })
    },
    () => {
      SearchIdManager.getInstance().destroy()
    },
  )
}

const kdToKdRule = (router: NextRouter) => (): boolean => {
  const { referrer } = document
  const fromAdKolPage = referrer.includes(Page.KolId.replace('[kolId]', ''))
  const fromSimilarKolOrRelatedKol =
    router.asPath.includes('similarInfluencer') ||
    router.asPath.includes('relatedInfluencer')
  return fromSimilarKolOrRelatedKol && fromAdKolPage
}

const searchToKdRule = (router: NextRouter) => (): boolean => {
  const { referrer } = document
  // 來自其他網站 or social share 的流量，其 search id 一率視為過期
  const fromSameSite = referrer.includes(
    env.DEVELOPMENT_MODE ? 'localhost' : env.APP_URL,
  )

  // 僅 search 和 ai-search 會產新鮮的 search id，來自其他頁的 search id 一率視為過期
  const fromSearchPage =
    referrer.includes(Page.Search) || referrer.includes(Page.AISearch)

  // 僅 ad kol 頁的事件會需要 search-id，若身處其他頁，則將現在的 search id 視為過期
  const inAdKolPage = [Page.KolId].includes(router.route as Page)

  return fromSameSite && fromSearchPage && inAdKolPage
}

// 不預期 UI 會拿 search id 去做事情，也不預期更改 search id 會導致任何元件 re-render
// 但目前有 effect 依賴 search id，故改完 localStorage 之後，要通知 subscribed 的元件
const notifyReactComponentRender = (newSearchIdMap?: string): void => {
  const currentLocalStorageKey = getCurrentLocalStorageKey()
  const observerList = localStorageObserverMap[currentLocalStorageKey] ?? []

  observerList.forEach((observer) => observer(newSearchIdMap))
}

export default useSearchIdManagerInit

import { Button, Layout, notification } from 'antd'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import React, { FunctionComponent, useEffect } from 'react'
import { useMount } from 'react-use'
import styled from 'styled-components'
import { useLogoutMutation } from '@/api/user-api'
import errorImgSrc from '@/assets/images/cosmos.png'
import { H3, H6 } from '@/components/common/typography'
import { Header } from '@/components/layout/header'
import { HeaderWithWorkspace } from '@/components/layout/header-with-workspace'
import { CookieName } from '@/constants/cookie-name'
import { useAuth } from '@/hooks/use-auth'
import routes from '@/hooks/use-authorization/routes'
import { useDispatch } from '@/hooks/use-dispatch'
import { useI18n } from '@/hooks/use-i18n'
import { useIntl } from '@/i18n/hooks/use-intl'
import { logout as logoutAction } from '@/store/me'
import { ampli } from '@/utils/ampli'
import logger from '@/utils/logger'

interface ErrorPageProps {
  onReset?: () => void
}

const ErrorPage: FunctionComponent<ErrorPageProps> = ({ onReset }) => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const [logout] = useLogoutMutation()
  const { locale } = useI18n()
  const router = useRouter()
  const { isAd } = useAuth()

  useMount(() => {
    ampli.somethingWentWrong({ path: router.asPath })
  })

  useEffect(() => {
    if (!onReset) {
      return
    }
    router.events.on('routeChangeComplete', onReset)
    return () => {
      router.events.off('routeChangeComplete', onReset)
    }
  }, [router.events, onReset])

  const onLogout: VoidFunction = () => {
    logout()
      .then(() => {
        ampli.logoutSuccess({
          isSingleLogin: Boolean(Cookies.get(CookieName.IsSingleLogin)),
        })
        Cookies.remove(CookieName.IsSingleLogin)

        notification.success({
          message: formatMessage({ id: 'general:logout_successfully' }),
          placement: 'bottomRight',
        })

        dispatch(logoutAction())
        routes.login.goto({}, { lang: locale })
      })
      .catch((error) => logger.error(error))
  }

  const onReload: VoidFunction = () => {
    ampli.refreshPage({ path: router.asPath })
    location.reload()
  }

  return (
    <Layout style={{ height: '100%' }}>
      {isAd ? <HeaderWithWorkspace /> : <Header />}
      <Wrapper>
        <ErrorContainer>
          <StyledImage src={errorImgSrc} />
          <TextWrapper>
            <H3>{formatMessage({ id: 'error:something_wrong_title' })}</H3>
            <H6>{formatMessage({ id: 'error:refresh_again_try_again' })}</H6>
            <ButtonWrapper>
              <Button type='primary' onClick={onReload}>
                {formatMessage({ id: 'error:error_page_refresh_button' })}
              </Button>
              <Button ghost type='primary' onClick={onLogout}>
                {formatMessage({ id: 'error:error_page_login_button' })}
              </Button>
            </ButtonWrapper>
          </TextWrapper>
        </ErrorContainer>
      </Wrapper>
    </Layout>
  )
}

export default ErrorPage

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  //todo: add brand background-color
`

const ErrorContainer = styled.div`
  display: flex;
  width: fit-content;
  height: fit-content;
  > * {
    width: 50%;
    margin: 25px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
`

const StyledImage = styled.img`
  width: 475px;
  height: auto;

  @media (max-width: 767px) {
    margin: 0;
    width: 100%;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 767px) {
    text-align: center;
    align-items: center;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  margin: 30px 0 0 0;
  button {
    margin: 0 15px 0 0;
  }
`

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { KolSchemaModel } from '@/types/schema/kol-schema'
import type { AddToProjectProperties } from '@/utils/ampli'

type Kol = Pick<
  KolSchemaModel,
  'id' | 'name' | 'potential' | 'verified' | 'isRadarStar' | 'follower'
>

type AddKolsToReportModalState =
  | {
      open: true
      from: AddToProjectProperties['from']
      isClickedFromDrawer?: boolean
      kols: Kol[]
    }
  | {
      open: false
      from?: never
      isClickedFromDrawer?: never
      kols?: never
    }

const initialState: AddKolsToReportModalState = {
  open: false,
}

const addKolsToReportModalSlice = createSlice({
  name: 'addKolsToReportModal',
  initialState: (): AddKolsToReportModalState => initialState,
  reducers: {
    setOpen: (_state, action: PayloadAction<AddKolsToReportModalState>) => {
      return action.payload
    },
  },
})

export const { setOpen } = addKolsToReportModalSlice.actions
export const addKolsToReportModalReducer = addKolsToReportModalSlice.reducer

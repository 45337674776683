import { DatePicker, Radio } from 'antd'
import dayjs from 'dayjs'
import toPairs from 'lodash-es/toPairs'
import values from 'lodash-es/values'
import { FunctionComponent } from 'react'
import { createEnumParam } from 'use-query-params'
import {
  ButtonRadioGroupWrapper,
  FilterGroupWrapper,
  Label,
} from '@/components/search/filters/advanced-filters'
import ExclusionFilterContent from '@/components/search/filters/common/exclusion-filter-content'
import WithOfficialFilterContent from '@/components/search/filters/common/with-official-filter-content'
import { DEFAULT_DATE_FORMAT } from '@/constants/date-format'
import { I18nId } from '@/i18n/config'
import { useIntl } from '@/i18n/hooks/use-intl'
import { Condition } from '@/types/api/search'

enum VerifiedKol {
  Any = 'any',
  Verified = 'verified',
  Unverified = 'unverified',
}

export const VerifiedKolParam = createEnumParam(values(VerifiedKol))
const SearchTagI18nKeyMap: Record<VerifiedKol, I18nId> = {
  [VerifiedKol.Any]: 'general:auto_tag_all',
  [VerifiedKol.Verified]: 'general:influencer_verification',
  [VerifiedKol.Unverified]: 'general:unverified_influencer',
}

interface OthersFilterGroupProps {
  filterValues: Condition
  onValuesChange: (changedValues: Condition) => void
}

const OthersFilterGroup: FunctionComponent<OthersFilterGroupProps> = ({
  filterValues,
  onValuesChange,
}) => {
  const { formatMessage } = useIntl()

  return (
    <FilterGroupWrapper>
      <h4>{formatMessage({ id: 'general:kol_radar_exclusive' })}</h4>
      <div>
        <Label>{formatMessage({ id: 'platform_verification' })}</Label>
        <ButtonRadioGroupWrapper>
          {/* @todo Use components/common/selectors/kol-verification-radio-group.tsx instead.*/}
          <Radio.Group
            optionType='button'
            options={toPairs(SearchTagI18nKeyMap).map(([value, id]) => ({
              label: formatMessage({ id }),
              value,
            }))}
            value={filterValues?.verifiedKol ?? VerifiedKol.Any}
            onChange={(v): void =>
              onValuesChange({
                verifiedKol:
                  v.target.value === VerifiedKol.Any
                    ? undefined
                    : v.target.value,
              })
            }
          />
        </ButtonRadioGroupWrapper>
      </div>
      <div>
        <Label>
          {formatMessage({ id: 'search:sorting_option_publish_time' })}
        </Label>
        <DatePicker.RangePicker
          allowClear
          disabledDate={(current): boolean =>
            current &&
            (current < dayjs().subtract(1, 'years').endOf('day') ||
              current > dayjs())
          }
          format={DEFAULT_DATE_FORMAT}
          placeholder={[
            formatMessage({ id: 'general:startdate_placeholder' }),
            formatMessage({ id: 'general:enddate_placeholder' }),
          ]}
          size='small'
          style={{ width: '100%' }}
          suffixIcon={null}
          value={
            filterValues.post_start_time && filterValues.post_end_time
              ? [
                  dayjs(filterValues.post_start_time),
                  dayjs(filterValues.post_end_time),
                ]
              : null
          }
          onChange={(rangeValue): void => {
            const [post_start_time, post_end_time] = rangeValue ?? []
            onValuesChange({
              post_start_time: post_start_time?.startOf('day').toISOString(),
              post_end_time: post_end_time?.endOf('day').toISOString(),
            })
          }}
        />
      </div>
      <div>
        <Label>{formatMessage({ id: 'general:exclude_btn' })}</Label>
        <ExclusionFilterContent
          filterValues={filterValues}
          onValuesChange={onValuesChange}
        />
      </div>
      <div>
        <Label>{formatMessage({ id: 'search:filter_with_official' })}</Label>
        <WithOfficialFilterContent
          filterValues={filterValues}
          onValuesChange={onValuesChange}
        />
      </div>
    </FilterGroupWrapper>
  )
}

export default OthersFilterGroup

import { Radio } from 'antd'
import dayjs from 'dayjs'
import isNil from 'lodash-es/isNil'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { FacebookPost, InstagramPost } from '@/api/meta-api'
import AsyncImage from '@/components/common/image/async-image'
import { Icon } from '@/components/custom-icon'
import SafeNewTabLink from '@/components/safe-new-tab-link'
import { DATE_TIME_SHORT_FORMAT } from '@/constants/date-format'
import { useAuth } from '@/hooks/use-auth'
import { useIntl } from '@/i18n/hooks/use-intl'
import { getThumbnail } from '@/utils/get-thumbnails'

interface Post {
  id: string
  url: string
  image: string
  createdTime: string
  caption: string
  likeCount: number
  commentCount: number
  shareCount?: number
  playCount?: number
}

interface PostProps {
  selectedPostID?: string
  post: Post
  onCheck: (checked: boolean, id: string) => void
  defaultImage?: string
}

type TransferFacebookPost = (
  fbPost: FacebookPost,
  fbPagePicture: string,
) => Post

export const transferFacebookPost: TransferFacebookPost = (
  fbPost,
  fbPagePicture,
) => {
  const { id, permalinkUrl, createdTime, message } = fbPost
  const image = fbPost.attachments?.data[0].media
    ? fbPost.attachments.data[0].media.image.src
    : fbPagePicture

  return {
    id: id,
    url: permalinkUrl,
    image,
    createdTime,
    caption: message,
    likeCount: fbPost.like.summary.totalCount,
    commentCount: fbPost.comments.summary.totalCount,
    shareCount: fbPost.shares?.count,
  }
}

type TransferInstagramPost = (igPost: InstagramPost) => Post

export const transferInstagramPost: TransferInstagramPost = (igPost) => {
  const {
    shortcode,
    permalink,
    timestamp,
    caption,
    likeCount,
    commentsCount,
    mediaType,
    thumbnailUrl,
    mediaUrl,
    insights,
  } = igPost

  const image = mediaType === 'VIDEO' ? thumbnailUrl : mediaUrl

  return {
    id: shortcode,
    url: permalink,
    image,
    createdTime: timestamp,
    caption,
    likeCount,
    commentCount: commentsCount,
    shareCount: insights?.data.find((insight) => insight.name === 'shares')
      ?.values[0]?.value,
    playCount: insights?.data.find((insight) => insight.name === 'plays')
      ?.values[0]?.value,
  }
}

const Post: FunctionComponent<PostProps> = ({
  post,
  onCheck,
  selectedPostID,
  defaultImage,
}) => {
  const { userInfo } = useAuth()
  const { formatNumber } = useIntl()

  const {
    id,
    url,
    createdTime,
    caption,
    likeCount,
    commentCount,
    shareCount,
    playCount,
  } = post

  return (
    <PostWrapper key={id} onClick={(): void => onCheck(true, id)}>
      <RadioWrapper>
        <Radio.Group value={selectedPostID}>
          <Radio value={post.id} />
        </Radio.Group>
      </RadioWrapper>
      <SafeNewTabLink href={url}>
        <AsyncImage
          alt={caption}
          height={100}
          imageUrls={[post.image, getThumbnail(userInfo?.kolId), defaultImage]}
          width={100}
        />
      </SafeNewTabLink>

      <InfoWrapper>
        <CreatedTime>
          {dayjs(createdTime).format(DATE_TIME_SHORT_FORMAT)}
        </CreatedTime>
        <Message>{caption}</Message>
        <Reactions>
          <span>
            <Icon type='like' />
            {formatNumber(likeCount)}
          </span>
          <span>
            <Icon type='message' />
            {formatNumber(commentCount)}
          </span>
          {!isNil(playCount) && (
            <span>
              <Icon type='play' />
              {formatNumber(playCount)}
            </span>
          )}
          {!isNil(shareCount) && (
            <span>
              <Icon type='elIconArrowForwardOutline' />
              {formatNumber(shareCount)}
            </span>
          )}
        </Reactions>
      </InfoWrapper>
    </PostWrapper>
  )
}

export default Post

export const PostWrapper = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-radius: 4px;
  width: 100%;
  margin: 0 0 10px 0;
  cursor: pointer;

  img {
    object-fit: cover;
  }
`

export const InfoWrapper = styled.div`
  padding: 12px 22px 12px 16px;
  max-width: calc(100% - 140px);
  width: 100%;
  height: 100px;
`

export const CreatedTime = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }): string => theme.colors.text.secondary};
`

export const Message = styled.div`
  font-size: 14px;
  line-height: 1.4;
  color: ${({ theme }): string => theme.colors.text.primary};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 8px 0 12px 0;
`

export const Reactions = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }): string => theme.colors.text.secondary};

  > span {
    margin: 0 10px 0 0;

    > .anticon {
      margin: 0 2px 0 0;
    }
  }
`

export const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;

  .ant-radio-wrapper {
    margin: 0;
  }
`

import { Badge } from 'antd'
import React, { FunctionComponent, ReactNode } from 'react'
import { useTheme } from 'styled-components'
import { useSelector } from '@/hooks/use-selector'

type UnreadMessageCountBadgeProps = {
  channelId: string
  fontSize?: string | number
  marginLeft?: string | number
  children?: ReactNode
}

const UnreadMessageCountBadge: FunctionComponent<
  UnreadMessageCountBadgeProps
> = (props) => {
  const theme = useTheme()
  const { conversationList } = useSelector((state) => state.chatroom)
  const conversation = conversationList[props.channelId]

  const unreadCount =
    conversation && conversation.unreadMessageCount >= 25
      ? '25+'
      : conversation?.unreadMessageCount || 0

  return (
    <Badge
      count={unreadCount}
      style={{
        backgroundColor: theme.colors.tagStatus.error,
        marginLeft: props.marginLeft,
        fontSize: props.fontSize,
      }}
    >
      {props.children}
    </Badge>
  )
}

export default UnreadMessageCountBadge

import styled from 'styled-components'

export const ActionsWrapper = styled.div`
  visibility: hidden;
  display: flex;

  /* @deprecated */
  .anticon {
    font-size: 16px;
    height: 32px;
    color: ${({ theme }): string => theme.colors.brand.primary};
    border-radius: 20px;
    cursor: pointer;
    margin: 0 0 0 6px;
    padding: 8px;

    &:hover {
      background: ${({ theme }): string => theme.colors.brand.primaryHover};
    }
  }

  > .ant-btn {
    color: ${({ theme }): string => theme.colors.brand.secondary};
  }
  > .ant-btn:hover {
    color: ${({ theme }): string =>
      theme.colors.brand.secondaryHover} !important;
  }
`

import type { FunctionComponent } from 'react'

const FacebookDomainVerificationMeta: FunctionComponent = () => {
  return (
    <meta
      content='l3m7gt8d3iam8yoba1l552egtd6uop'
      name='facebook-domain-verification'
    />
  )
}

export default FacebookDomainVerificationMeta

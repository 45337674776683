import { z } from 'zod'
import { ExecutableRequest } from '@/types/api'
import { PlatformShortcode } from '@/utils/convert-platform'

export const kolPostListSorterSchema = z.enum([
  'publishTime',
  'engagement',
  'pageView',
  'likeCount',
  'commentCount',
])
export type KolPostListSorter = z.infer<typeof kolPostListSorterSchema>

export interface RecommendedKolsRequest
  extends ExecutableRequest<RecommendedKolsRequest, RecommendedKolsResponse> {
  uuids: string[]
  platform?: PlatformShortcode
  limit?: number
}
export const recommendedKolsResponseSchema = z.object({
  limit: z.number(),
  total: z.number(),
  data: z
    .object({
      uuid: z.string().uuid(),
      name: z.string(),
      follower: z.number().nullish(),
      dc_id_hashed: z.string(),
    })
    .array(),
})
export type RecommendedKolsResponse = z.infer<
  typeof recommendedKolsResponseSchema
>

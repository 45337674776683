import { InputProps } from 'antd/lib/input'
import { DOMAttributes, KeyboardEventHandler, useState } from 'react'

type CompositionEvents<T> = Pick<
  DOMAttributes<T>,
  'onCompositionStart' | 'onCompositionEnd'
>

interface HandleInputComposition {
  handleInputComposition: CompositionEvents<HTMLInputElement> &
    Pick<InputProps, 'onPressEnter'>
  compositionEnd: boolean
}

type UseHandleInputComposition = (
  onPressEnter?: KeyboardEventHandler<HTMLInputElement>,
) => HandleInputComposition

const useHandleInputComposition: UseHandleInputComposition = (onPressEnter) => {
  const [compositionEnd, setCompositionEnd] = useState(true)

  return {
    compositionEnd,
    handleInputComposition: {
      onCompositionStart: (): void => setCompositionEnd(false),
      onCompositionEnd: (): void => setCompositionEnd(true),
      onPressEnter: (event): void => {
        if (!compositionEnd) {
          return
        }

        onPressEnter?.(event)
      },
    },
  }
}

export default useHandleInputComposition

import {
  SerializeQueryArgs,
  createApi,
  defaultSerializeQueryArgs,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react'
import { flowRight, isEmpty, isEqual, omit } from 'lodash-es'
import { StringifyOptions } from 'query-string'
import { z } from 'zod'
import { defaultApiVersionBaseQueryWrapper } from '@/api/utils/default-api-version-base-query-wrapper'
import lifecycle from '@/api/utils/lifecycle'
import { paramsSerializer } from '@/api/utils/params-serializer'
import workspaceIdInjector from '@/api/utils/workspace-id-injector'
import zodBaseQueryWrapper from '@/api/zod-base-query-wrapper'
import {
  AnchorTypeRequest,
  AnchorTypeResource,
  ListResource,
} from '@/types/resource-type'
import { listResourceSchema } from '@/types/schema/api/list-resource-schema'
import { prepareHeaders } from '@/utils/api-utils'
import env from '@/utils/env'

export enum CampaignsAPITagType {
  CampaignList = 'CampaignList',
  ApplyCampaignKolList = 'ApplyCampaignKolList',
}

export enum OAuthAPITagType {
  AuthPages = 'AuthPages',
  GoogleAccessToken = 'GoogleAccessToken',
}

export enum UserAPITagType {
  ContractStatus = 'ContractStatus',
  UserStatus = 'UserStatus',
  UserInfo = 'UserInfo',
  KolBasicInfo = 'KolBasicInfo',
  KolCooperateInfo = 'KolCooperateInfo',
  AgentKolList = 'AgentKolList',
  PubNubAccessToken = 'PubNubAccessToken',
  RecipientInfo = 'RecipientInfo',
  UserMetadata = 'UserMetadata',
  UserPassword = 'UserPassword',
}

export enum WorkspacesAPITagType {
  BrandRecognition = 'BrandRecognitionSnap',
  Campaign = 'Campaign',
  CampaignList = 'CampaignList',
  CampaignKolList = 'CampaignKolList',
  CampaignPaymentSetting = 'CampaignPaymentSetting',
  CreditCards = 'CreditCards',
  CollectionSettings = 'CollectionSettings',
  CommentBoard = 'CommentBoard',
  GoogleAnalyticsSetting = 'GoogleAnalyticsSetting',
  SubscriptionCases = 'SubscriptionCases',
  RecommendKolList = 'recommendKolList',
  RecommendKol = 'recommendKol',
  Report = 'Report',
  ReportCrawlerStatus = 'ReportCrawlerStatus',
  ReportPermission = 'ReportPermission',
  ReportList = 'ReportList',
  ReportStatistic = 'ReportStatistic',
  ReportTerms = 'ReportTerms',
  Subscription = 'Subscription',
  HashtagWatchlist = 'HashtagWatchlist',
  HashtagAnalyzeDetail = 'HashtagAnalyzeDetail',
  KolCollectionKol = 'KolCollectionKol',
  KolCollectionKols = 'KolCollectionKols',
  KolCollectionSearchFields = 'KolCollectionSearchFields',
  Collection = 'Collection',
  Collections = 'Collections',
  KolInfo = 'KolInfo',
  KolPlatforms = 'KolPlatforms',
  QuotaWalletUsage = 'QuotaWalletUsage',
  Workspace = 'Workspace',
  ProductReceipts = 'ProductReceipts',
  InfluencerRanking = 'InfluencerRanking',
  PostCollectionPostList = 'PostCollectionPostList',
  PostCollectionList = 'PostCollectionList',
  PostCollection = 'PostCollection',
  PostCollectionPost = 'PostCollectionPost',
  FeedbackAnalyze = 'FeedbackAnalyze',
  KolContent = 'KolContent',
  CompetitiveBrandAnalysesHashTagList = 'CompetitiveBrandAnalysesHashTagList',
  CompetitiveBrandKolStatisticList = 'CompetitiveBrandKolStatisticList',
  CompetitiveBrandKolStatisticListCharts = 'CompetitiveKolStatisticListCharts',
  CompetitiveAnalysisCrawlerStatus = 'CompetitiveAnalysisCrawlerStatus',
  CompetitiveMentionAnalysis = 'CompetitiveMentionAnalysis',
  CompetitiveMentionAnalysisList = 'CompetitiveMentionAnalysisList',
  CompetitiveMentionAnalysisKolList = 'CompetitiveMentionAnalysisKolList',
  CompetitiveMentionAnalysesCreatorList = 'CompetitiveMentionAnalysesCreatorList',
  CompetitiveMentionKolPropertyChartData = 'CompetitiveMentionKolPropertyChartData',
  CompetitiveMentionAnalysesHashTagList = 'CompetitiveMentionAnalysesHashTagList',
  CompetitiveKeywordAnalysis = 'CompetitiveKeywordAnalysis',
  CompetitiveKeywordAnalysisList = 'CompetitiveKeywordAnalysisList',
  CompetitiveKeywordAnalysisHashtagList = 'CompetitiveKeywordAnalysesHashtagList',
  CompetitiveKeywordAnalysisStatisticList = 'CompetitiveKeywordAnalysisStatisticList',
  CompetitiveKeywordAnalysesCreatorList = 'CompetitiveKeywordAnalysesCreatorList',
  CompetitiveKeywordAnalysisKeywordList = 'CompetitiveKeywordAnalysisKeywordList',
  CompetitiveKeywordStatisticListCharts = 'CompetitiveKeywordStatisticListCharts',
  CompetitiveKeywordKolCustomizedTagsNameChartData = 'CompetitiveKeywordKolCustomizedTagsNameChartData',
  TrialList = 'TrialList',
  TutorialList = 'TutorialList',
  SearchSimilarKols = 'SearchSimilarKols',
  BehaviorRecommendation = 'BehaviorRecommendation',
  KolRadarSearch = 'KolRadarSearch',
  PostRadarSearch = 'PostRadarSearch',
  KolManagement = 'KolManagement',
  KolManagementStatus = 'KolManagementStatus',
  KolMetricPerformanceSummary = 'KolMetricPerformanceSummary',
  DirectoryQuotation = 'DirectoryQuotation',
  DirectoryCollaborationRecordScoring = 'DirectoryCollaborationRecordScoring',
  DirectoryNote = 'DirectoryIRMNote',
  DirectoryKolContactInfo = 'DirectoryKolContactInfo',
  DirectoryKolCustomizedFields = 'DirectoryKolCustomizedFields',
  DirectorySearchFields = 'DirectorySearchFields',
  DirectoryKolFootprint = 'DirectoryKolFootprint',
  FilterResources = 'FilterResources',
  DirectoryKolBasicInfo = 'DirectoryKolBasicInfo',
  CustomizedLabels = 'CustomizedLabels',
  CampaignContractAcceptation = 'CampaignContractAcceptation',
  DependPredictiveReport = 'DependPredictiveReport',
  DependPredictiveReportCandidate = 'DependPredictiveReportCandidate',
  DependPredictiveReportAiSummary = 'DependPredictiveReportAiSummary',
  PredictiveReportQuotation = 'PredictiveReportQuotation',
  PredictiveReportKolInvolves = 'PredictiveReportKolInvolves',
}

export enum PartnerAPITagType {
  CampaignList = 'CampaignList',
}

export enum InfluencerAPITagType {
  InfluencerInfo = 'InfluencerInfo',
  AgentInfo = 'AgentInfo',
  Accounting = 'Accounting',
}

export interface StringifyRequest
  extends Pick<StringifyOptions, 'arrayFormat'> {
  stringify: boolean
}

export interface CampaignInvitationResponse {
  succeeded: number
  failed: number
}

export interface BasicRequest {
  workspaceId: number
}

export interface PaginatedBasicRequest extends BasicRequest {
  page: number
  perPage?: number
}

const zodUnknownListResource = listResourceSchema.extend({
  data: z.array(z.unknown()),
})

type InfiniteConfigSetting = <
  RequestType,
  ResultType extends
    | ListResource<unknown>
    | z.infer<typeof zodUnknownListResource>
    | AnchorTypeResource<unknown>,
>(
  cacheKeyFormatter?: (args: RequestType) => unknown,
) => {
  serializeQueryArgs: SerializeQueryArgs<RequestType>
  merge: (
    currentCache: ResultType,
    newResponse: ResultType,
    args: { arg: RequestType & AnchorTypeRequest },
  ) => void
  forceRefetch: (params: {
    currentArg: RequestType | undefined
    previousArg: RequestType | undefined
  }) => boolean
}

/** @description : general setting, 適合用在不需要控制 data 狀態的 infinite scroll, 需要控狀態建議另外客制 merge 邏輯  */
export const infiniteConfigSetting: InfiniteConfigSetting = (
  cacheKeyFormatter,
) => {
  return {
    serializeQueryArgs: ({
      endpointName,
      queryArgs,
      endpointDefinition,
    }): string => {
      const argWithoutPagination = ((): unknown => {
        if (cacheKeyFormatter) {
          return cacheKeyFormatter(queryArgs)
        }
        return queryArgs
          ? omit(queryArgs, ['page', 'next_paging_token'])
          : undefined
      })()

      const cacheKey = defaultSerializeQueryArgs({
        endpointName,
        queryArgs: argWithoutPagination,
        endpointDefinition,
      })
      return cacheKey //infinite setting need to use the same endpointName
    },
    merge: (currentCache, newResponse, args): void => {
      let needReset = false
      if ('page' in newResponse) {
        needReset = newResponse.page === 1
      }
      if ('next_paging_token' in args.arg) {
        needReset = isEmpty(args.arg.next_paging_token)
      }
      const updateCache = {
        ...omit(newResponse, 'data'),
        data: needReset
          ? newResponse.data
          : [...currentCache.data, ...newResponse.data],
      }
      Object.assign(currentCache, updateCache)
    },
    // Refetch when the page arg changes
    forceRefetch({ currentArg, previousArg }): boolean {
      return !isEqual(currentArg, previousArg)
    },
  }
}

export const api = createApi({
  reducerPath: 'api',
  baseQuery: flowRight(
    defaultApiVersionBaseQueryWrapper,
    workspaceIdInjector,
    lifecycle,
    zodBaseQueryWrapper,
  )(
    fetchBaseQuery({
      baseUrl: env.API_BASE_URL,
      prepareHeaders,
      paramsSerializer,
    }),
  ),

  tagTypes: [
    ...Object.values(CampaignsAPITagType),
    ...Object.values(OAuthAPITagType),
    ...Object.values(PartnerAPITagType),
    ...Object.values(InfluencerAPITagType),
    ...Object.values(UserAPITagType),
    ...Object.values(WorkspacesAPITagType),
  ],
  endpoints: () => ({}),
})

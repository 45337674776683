import { isError } from 'lodash-es'
import { isRTKFetchBaseQueryError } from '@/utils/error'

type ResolveError = (error: unknown) => Error

const resolveError: ResolveError = (error) => {
  if (isError(error)) {
    return error
  }
  if (typeof error === 'string') {
    return new CustomError('SpecialError', error)
  }
  if (isRTKFetchBaseQueryError(error)) {
    switch (error.status) {
      case 'PARSING_ERROR':
        return new CustomError('RtkParsingError', error.error)
      case 'TIMEOUT_ERROR':
        return new CustomError('RtkTimeoutError', error.error)
      case 'FETCH_ERROR':
        return new CustomError('RtkFetchError', error.error)
      case 'CUSTOM_ERROR':
        return new CustomError('RtkCustomError', error.error)
      default:
        return new CustomError(
          'RtkQueryError',
          `${error.status}-${JSON.stringify(error.data) ?? 'undefined'}`,
        )
    }
  }
  return new CustomError('UnknownError', JSON.stringify(error) ?? 'undefined')
}

class CustomError extends Error {
  constructor(name: string, message: string) {
    super(message)
    this.name = name

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CustomError)
    } else {
      this.stack = new Error(message).stack
    }
  }
}

export default resolveError

import React, { FunctionComponent } from 'react'
import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components'
import { useQueryParam } from 'use-query-params'
import LogoHorizontal from '@/components/common/logo-horizontal'
import LangDropdown from '@/components/header/lang-dropdown'
import { WidthBoundary } from '@/components/layout/irm/width-boundary'
import UnsafeNewTabLink from '@/components/public-page/footer/components/unsafe-new-tab-link'
import MegaMenu from '@/components/public-page/header/components/mega-menu'
import useKolRadarJpMenuItems from '@/components/public-page/header/components/use-kol-radar-jp-menu-items'
import useKolRadarMenuItems from '@/components/public-page/header/components/use-kol-radar-menu-items'
import UserDropdown from '@/components/public-page/header/components/user-dropdown'
import useUrlControl from '@/components/public-page/hook/use-url-control'
import { CountryCode } from '@/constants/country-code'
import { useAuth } from '@/hooks/use-auth'
import { Page } from '@/hooks/use-authorization/constants'
import { getLocaleRegion, useI18n } from '@/hooks/use-i18n'
import { useIntl } from '@/i18n/hooks/use-intl'
import { NonNullStringParam } from '@/utils/use-query-params'

export const PUBLIC_HEADER_HEIGHT = 80
export const PUBLIC_MOBILE_HEADER_HEIGHT = 63

const AppHeaderPc: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const [from] = useQueryParam('from', NonNullStringParam)

  const { userInfo } = useAuth()
  const { language, locale } = useI18n()
  const othersMenuItems = useKolRadarMenuItems()
  const jpMenuItems = useKolRadarJpMenuItems()
  const { getRootUrl, getAppUrl } = useUrlControl()
  const country = getLocaleRegion(locale) || CountryCode.TW
  const menuItems = country === CountryCode.JP ? jpMenuItems : othersMenuItems

  return (
    <Wrapper>
      <WidthBoundary>
        <ItemsWrapper>
          <LeftItemsWrapper>
            <HomePageLink href={getRootUrl()}>
              <LogoHorizontal />
            </HomePageLink>
            <MegaMenu items={menuItems} />
          </LeftItemsWrapper>
          <RightItemsWrapper>
            <LangDropdown language={language} />
            {!userInfo ? (
              <>
                <ItemLink href={getAppUrl(Page.Login, { from })}>
                  {formatMessage({ id: 'general:navbar_text_login' })}
                </ItemLink>
                <RegisterLink
                  as={UnsafeNewTabLink}
                  country={country}
                  href={getAppUrl(Page.Register, { from })}
                >
                  {formatMessage({ id: 'general:navbar_text_signup' })}
                </RegisterLink>
              </>
            ) : (
              <UserDropdown />
            )}
          </RightItemsWrapper>
        </ItemsWrapper>
      </WidthBoundary>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: ${({ theme }): string => theme.colors.background.white};
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.102);
`

const ItemsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 10px 0;
`

const HomePageLink = styled.a`
  height: 60px;
  display: flex;
  align-items: center;
`

const ItemLink = styled.a`
  color: ${({ theme }): string => theme.colors.text.primary};
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  :hover {
    color: ${({ theme }): string => theme.colors.text.primary};
  }
`

const RegisterLink = styled.a<{ country: string }>`
  ${({ country, theme }): FlattenInterpolation<ThemeProps<DefaultTheme>> =>
    country === CountryCode.JP
      ? css`
          display: inline-block;
          padding: 12px 20px !important;
          cursor: pointer;
          border-radius: 4px;
          border: 1px solid ${theme.colors.brand.primary};
          color: ${theme.colors.brand.primary} !important;
          background-color: transparent;
          transition: 0.3s;

          &:hover {
            color: ${({ theme }): string => theme.colors.text.white} !important;
            background-color: ${({ theme }): string =>
              theme.colors.brand.primary};
          }

          @media (max-width: 768px) {
            margin: 32px 0 60px 0;
          }
        `
      : css`
          color: ${({ theme }): string => theme.colors.text.white};
          border-radius: 4px;
          background-color: ${({ theme }): string =>
            theme.colors.brand.primary};
          font-size: 16px;
          line-height: 19px;
          padding: 12px 20px;

          :hover {
            color: ${({ theme }): string => theme.colors.text.white};
          }
        `}
`

const LeftItemsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  @media (min-width: 1024px) {
    gap: 30px;
  }
`

const RightItemsWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 30px;
`

export default AppHeaderPc

import { Avatar } from 'antd'
import { adjustHue, lighten, saturate } from 'polished'
import { CSSProperties, FunctionComponent, useMemo } from 'react'
import { useTheme } from 'styled-components'
import { useFetchUserStatusQuery } from '@/api/user-api'

interface AvatarImageProps {
  imgSrc?: string
  name?: string
  deactivate?: boolean
  size?: number | 'small' | 'default' | 'large'
  style?: CSSProperties | undefined
}

export const AvatarImage: FunctionComponent<AvatarImageProps> = ({
  name = '',
  deactivate = false,
  imgSrc,
  size = 'default',
  style,
}) => {
  const theme = useTheme()
  const { data: userStatus } = useFetchUserStatusQuery()
  const enterpriseIdentity = userStatus?.currentWorkspace?.enterpriseIdentity
  const backgroundColor = useMemo<string>(() => {
    if (imgSrc) {
      return 'transparent'
    }
    const activeColor = enterpriseIdentity
      ? saturate(0.21, lighten(0.26, adjustHue(-8, theme.colors.brand.primary)))
      : theme.colors.base.blue.blue5
    return deactivate ? theme.colors.border.border : activeColor
  }, [deactivate, enterpriseIdentity, imgSrc, theme])

  return (
    <Avatar
      size={size}
      src={imgSrc}
      style={{
        backgroundColor,
        verticalAlign: 'middle',
        ...style,
      }}
    >
      {name[0]?.toUpperCase()}
    </Avatar>
  )
}

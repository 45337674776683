import { Tooltip, Skeleton } from 'antd'
import { isNumber } from 'lodash-es'
import Image, { ImageProps } from 'next/image'
import {
  FunctionComponent,
  ReactNode,
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from 'styled-components'
import defaultAvatar from '@/assets/images/default-avatar.png'
import Space, { SpaceProps } from '@/components/common/antd-v4-backport/space'
import FallbackLogoImage from '@/components/common/image/fallback-logo-image'
import { Icon, OurIconType } from '@/components/custom-icon'
import { getThumbnail } from '@/utils/get-thumbnails'
import useThumbnailAndNameBadgeInfo from '@/utils/hooks/use-thumbnail-and-name-badge-info'

type ThumbnailStyle = Omit<SpaceProps, 'size'> & {
  imageSize?: 'auto' | 'small' | 'default' | 'medium' | 'large' | number
  loading?: ImageProps['loading']
  badgeSize?: number
  isRadarStar?: boolean
  isVerified?: boolean
  /**
   * @description If provided, the radar star or verified badge will not be displayed.
   */
  customBadgeType?: OurIconType
  children?: ReactNode
} & (
    | { shape?: undefined; borderWidth?: undefined }
    | { shape: 'square'; borderWidth?: undefined }
    | { shape: 'round'; borderWidth?: number }
  )

type KolUuidThumbnailProps = ThumbnailStyle & {
  kolUuid: string
  isLoading?: boolean
}

type SrcThumbnailProps = ThumbnailStyle & {
  src?: string
  isLoading?: boolean
}

export type KolThumbnailProps = KolUuidThumbnailProps | SrcThumbnailProps

/**
 * @todo: display verified, merge all other thumbnail component
 */
const Wrapper: FunctionComponent<KolThumbnailProps> = (props) => {
  const {
    className,
    children,
    isLoading = false,
    loading,
    imageSize = 'default',
    badgeSize = 14,
    shape = 'round',
    borderWidth = 4,
    isRadarStar,
    isVerified,
    align,
    direction,
    customBadgeType,
  } = props

  const { hasBadge, toolTipTitle, badgeType, isInternalRadarStar } =
    useThumbnailAndNameBadgeInfo(!!isVerified, !!isRadarStar)
  const [preferredSize, badgeSizeByImgSize] = useMemo((): [
    number | undefined,
    number,
  ] => {
    if (isNumber(imageSize)) {
      return [imageSize, badgeSize]
    }

    switch (imageSize) {
      case 'auto':
        return [undefined, badgeSize]
      case 'small':
        return [24, 8]
      case 'default':
        return [40, 14]
      case 'medium':
        return [80, 20]
      case 'large':
        return [150, 40]
    }
  }, [imageSize, badgeSize])

  const [isError, setIsError] = useState(false)

  const imgSrc = useMemo((): string => {
    if ('kolUuid' in props) {
      return getThumbnail(props.kolUuid)
    }

    return props.src ?? defaultAvatar
  }, [props])

  useEffect(() => {
    setIsError(false)
  }, [imgSrc])

  if (isLoading) {
    return (
      <StyledSkeleton
        avatar={{ size: isNumber(imageSize) ? imageSize : undefined }}
        paragraph={false}
        title={false}
      />
    )
  }

  return (
    <StyleWrapper
      align={align}
      badgeSize={badgeSizeByImgSize}
      className={className}
      direction={direction}
      preferredSize={preferredSize}
      size={0}
      style={{
        position:
          imageSize === 'auto' || hasBadge || customBadgeType
            ? 'relative'
            : undefined,
      }}
    >
      {isError && shape === 'square' ? (
        <FallbackLogoImage height={preferredSize} width={preferredSize} />
      ) : (
        <StyledImage
          $borderWidth={borderWidth}
          $isRadarStar={isInternalRadarStar}
          $isVerified={isVerified}
          $shape={shape}
          alt='KOL Avatar'
          fill={imageSize === 'auto'}
          height={preferredSize}
          loading={loading}
          src={isError ? defaultAvatar : imgSrc}
          unoptimized={['kolr.ai', 'kolradar.com'].some((domain) =>
            imgSrc.includes(domain),
          )}
          width={preferredSize}
          onError={(): void => {
            setIsError(true)
          }}
        />
      )}
      {children}
      {customBadgeType && <Icon className='badgeIcon' type={customBadgeType} />}
      {hasBadge && !customBadgeType && (
        <Tooltip
          overlayStyle={{ width: 134, height: 44 }}
          placement='bottom'
          title={toolTipTitle}
        >
          {!!badgeType && (
            <BuiltinBadge badgeSize={badgeSizeByImgSize} type={badgeType} />
          )}
        </Tooltip>
      )}
    </StyleWrapper>
  )
}

const BuiltinBadge = styled(Icon)<{
  badgeSize: number
}>`
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: ${({ badgeSize }): string => `${badgeSize}px`};
`

const StyleWrapper = styled(Space)<{
  badgeSize: number
  preferredSize: number | undefined
}>`
  .badgeIcon {
    position: absolute;
    top: ${(props): string =>
      props.preferredSize !== undefined
        ? `${props.preferredSize - props.badgeSize / 2}px`
        : '100%'};

    left: ${(props): string =>
      props.preferredSize !== undefined
        ? `${props.preferredSize / 2 - props.badgeSize / 2}px`
        : '50%'};

    transform: ${(props): string =>
      props.preferredSize === undefined ? 'translate(-50%, -50%)' : ''};

    font-size: ${({ badgeSize }): string => `${badgeSize}px`};
  }
`

const StyledImage = styled(Image)<{
  $shape: KolThumbnailProps['shape']
  $isRadarStar?: KolThumbnailProps['isRadarStar']
  $isVerified?: KolThumbnailProps['isVerified']
  $borderWidth?: KolThumbnailProps['borderWidth']
}>`
  object-fit: cover;
  object-position: center;
  border: ${({
    $isVerified,
    $isRadarStar,
    $shape,
    $borderWidth,
    theme,
  }): string =>
    $shape === 'round' && $isVerified && $isRadarStar
      ? `${$borderWidth}px solid ${theme.colors.base.sns.radarStar}`
      : 'unset'} !important;
  border-radius: ${(props): string =>
    props.$shape === 'round' ? '100%' : 'unset'};
`

const StyledSkeleton = styled(Skeleton)`
  width: auto;
`

export const KolThumbnail = styled(memo(Wrapper))``

import { isNumber } from 'lodash-es'
import Image from 'next/image'
import { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { useFetchUserStatusQuery } from '@/api/user-api'
import kolrLogoIcon from '@/public/assets/kolr-logo/kolr-logo-icon.png'

interface FallbackLogoImageProps {
  width?: string | number
  height?: string | number
}

const FallbackLogoImage: FunctionComponent<FallbackLogoImageProps> = ({
  width,
  height,
}) => {
  const { data: userStatus } = useFetchUserStatusQuery()
  const [isError, setIsError] = useState(false)
  const logoUrl =
    userStatus?.currentWorkspace.enterpriseIdentity?.appLogoUrl ?? kolrLogoIcon

  return (
    <BackgroundWrapper $height={height} $width={width}>
      <ResponseImageSizeBox>
        <Image
          fill
          alt='logo'
          src={isError ? kolrLogoIcon : logoUrl}
          style={{ objectFit: 'contain' }}
          onError={(): void => setIsError(true)}
        />
      </ResponseImageSizeBox>
    </BackgroundWrapper>
  )
}

const BackgroundWrapper = styled.div<{
  $width?: string | number
  $height?: string | number
}>`
  flex-shrink: 0;

  width: ${({ $width }): string =>
    isNumber($width) ? `${$width}px` : ($width ?? '100%')};
  height: ${({ $height }): string =>
    isNumber($height) ? `${$height}px` : ($height ?? '100%')};

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ theme }): string => theme.colors.background.grey};

  filter: grayscale(1);
`

const ResponseImageSizeBox = styled.div`
  position: relative;

  width: 50%;
  height: 50%;
`

export default FallbackLogoImage

import { Icon, mapHexHoverColor } from '@buggy/shared'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { FunctionComponent } from 'react'
import styled, {
  css,
  FlattenSimpleInterpolation,
  useTheme,
} from 'styled-components'
import { useFetchUserStatusQuery } from '@/api/user-api'
import LogoHorizontal from '@/components/common/logo-horizontal'
import { H6 } from '@/components/common/typography'
import LangDropdown from '@/components/header/lang-dropdown'
import MessageDropdown from '@/components/header/message-dropdown'
import {
  SearchInputContainer,
  SearchKeyword,
} from '@/components/header/search-keyword'
import { WorkspaceDropdown } from '@/components/header/workspace-dropdown'
import TrialCounter from '@/components/layout/header/trial-counter'
import { LayoutProps } from '@/components/layout/irm/irm-layout'
import { WidthBoundary } from '@/components/layout/irm/width-boundary'
import { SafeNewTabLink } from '@/components/safe-new-tab-link'
import { CountryCode } from '@/constants/country-code'
import { REQUEST_DEMO_LINK } from '@/constants/links'
import routes from '@/hooks/use-authorization/routes'
import { useBreakpoint } from '@/hooks/use-breakpoint'
import { usePermissions } from '@/hooks/use-permissions'
import useTrial from '@/hooks/use-trial'
import { useIntl } from '@/i18n/hooks/use-intl'
import { StyledHeader } from '@/types/antd/styled-antd'
import { ampli, PromiseResult, Result } from '@/utils/ampli'

interface HeaderProps extends LayoutProps {
  isCollapsed: boolean
  isSticky?: boolean
}

export const IRM_HEADER_HEIGHT = 64

const IrmHeader: FunctionComponent<HeaderProps> = ({
  layout,
  isCollapsed,
  isSticky = false,
}) => {
  const { formatMessage } = useIntl()
  const { asPath } = useRouter()
  const { canTrial } = useTrial()
  const { data: userStatus } = useFetchUserStatusQuery()
  const theme = useTheme()

  const { withFreePermission, withTrialPermission } = usePermissions()

  const breakpoint = useBreakpoint()
  const isMobile = breakpoint === 'mobile'

  const isTrial = userStatus?.currentWorkspace.isTrial
  const showRequestDemoLink =
    (withFreePermission || withTrialPermission) &&
    userStatus?.currentWorkspace.countryCode === CountryCode.TW

  const contentEl = (
    <Wrapper $isMobile={isMobile}>
      <LogoWrapper>
        <LogoHorizontal height={36} width={98} />
      </LogoWrapper>
      <SearchKeyword />
      <ActionWrapper>
        {showRequestDemoLink && (
          <RequestDemoLink
            href={REQUEST_DEMO_LINK}
            onClick={(): void => {
              ampli.clickDemo({ clickDemoSource: 'Header' })
            }}
          >
            <ItemWrapper>
              <Icon fill={theme.colors.plan.premium} name='edit-outlined' />
              {formatMessage({ id: 'irm:header_request_demo_act' })}
            </ItemWrapper>
          </RequestDemoLink>
        )}
        {layout === 'setting' && (
          <StyledLink href={routes.search.stringifyUrl({})}>
            <ItemWrapper>
              <Icon
                color={theme.colors.base.grey.grey1}
                fontSize={16}
                name='home'
              />
              <H6>
                {formatMessage({
                  id: 'irm:header_button_backtoservice',
                })}
              </H6>
            </ItemWrapper>
          </StyledLink>
        )}
        {canTrial && (
          <FreePremiumTrial
            href={routes.workspacePlan.stringifyUrl({}, { openTrialModal: 1 })}
            onClick={(): PromiseResult<Result> =>
              ampli.clickWorkspacePlan({
                path: asPath,
                clickPlanSource: 'Header',
              })
            }
          >
            <ItemWrapper>
              <Icon
                fill={theme.colors.base.green.green3}
                fontSize={16}
                name='crown-outlined'
              />
              {formatMessage({ id: 'account:free_premium_trial' })}
            </ItemWrapper>
          </FreePremiumTrial>
        )}
        <LangDropdown iconOnly={isMobile} />
        <MessageDropdown />
        <WorkspaceDropdown />
      </ActionWrapper>
    </Wrapper>
  )

  const renderContent = (): JSX.Element => {
    if (isMobile) {
      return contentEl
    }

    return <WidthBoundary>{contentEl}</WidthBoundary>
  }

  return (
    <HeaderWrapper isCollapsed={isCollapsed} isSticky={isSticky}>
      <StyleHeader>{renderContent()}</StyleHeader>
      {isTrial && layout === 'feature' && <TrialCounter />}
    </HeaderWrapper>
  )
}

// 目前只有警告頁面是特例，需要顯示 Logo
export const LogoWrapper = styled.div`
  display: none;
`

export const HeaderWrapper = styled.div<{
  isCollapsed: boolean
  isSticky: boolean
}>`
  position: ${({ isSticky }): string => (isSticky ? 'sticky' : 'fixed')};
  z-index: 100;
  left: ${({ isCollapsed }): string => (isCollapsed ? '80px' : '200px')};
  width: calc(
    100% - ${({ isCollapsed }): number => (isCollapsed ? 80 : 200)}px
  );
`

const StyleHeader = styled(StyledHeader)`
  border-bottom: 1px solid
    ${({ theme }): string => theme.colors.base.grey.grey6};
  height: fit-content;
`

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 40px;

  @media (max-width: 1280px) {
    gap: 16px;
  }

  ${LangDropdown} {
    flex: 0 0 auto;
    max-width: 120px;
  }

  ${MessageDropdown} {
    flex: 0 0 auto;
    max-width: 120px;
  }
`

const desktopWrapperStyle = css`
  padding: 12px 0px;
`

const mobileWrapperStyle = css`
  padding: 12px 24px;
`

const Wrapper = styled.div<{ $isMobile: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ $isMobile }): FlattenSimpleInterpolation =>
    $isMobile ? mobileWrapperStyle : desktopWrapperStyle}

  ${SearchInputContainer} {
    flex: 0 1 240px;
  }
`

const StyledLink = styled(Link)`
  line-height: 20px;
`

const FreePremiumTrial = styled(StyledLink)`
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }): string => theme.colors.plan.premium};

  &:hover {
    color: ${({ theme }): string => theme.colors.plan.premium};
  }

  > .anticon {
    margin-right: 10px;
  }
`

const RequestDemoLink = styled(SafeNewTabLink)`
  display: flex;
  padding: 3px 15px;
  align-items: center;
  border-radius: 16px;
  border: 1px solid ${({ theme }): string => theme.colors.plan.premium};
  background: ${({ theme }): string => theme.colors.background.green};
  color: ${({ theme }): string => theme.colors.plan.premium};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  :hover {
    color: ${({ theme }): string =>
      mapHexHoverColor(theme.colors.plan.premium)};
  }
`

const ItemWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

export default IrmHeader

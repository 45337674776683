import { Button, Flex, ModalProps } from 'antd'
import { get } from 'lodash-es'
import Link from 'next/link'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { BasicModal } from '@/components/common/antd/basic-modal'
import VipButton from '@/components/vip-button'
import { CountryCode } from '@/constants/country-code'
import {
  totalHongKongKOLs,
  totalJapanKOLs,
  totalKoreaKols,
  totalMalaysiaKOLs,
  totalSingaporeKols,
  totalTaiwanKOLs,
  totalThaiKols,
  totalVietnamKols,
} from '@/constants/kol-sin-database'
import routes from '@/hooks/use-authorization/routes'
import { usePermissions } from '@/hooks/use-permissions'
import { useSelector } from '@/hooks/use-selector'
import { I18nId } from '@/i18n/config'
import { useIntl } from '@/i18n/hooks/use-intl'
import { reduxAPI } from '@/store/redux-api'

interface DbPurchaseModalProps extends Pick<ModalProps, 'open'> {
  onClose?: VoidFunction
}

const DbPurchaseModal: FunctionComponent<DbPurchaseModalProps> = ({
  open,
  onClose,
}) => {
  const { formatMessage, formatNumber } = useIntl()
  const { dbPlan } = useSelector((state) => state.userPlan)
  const modal = reduxAPI.state.modal.hooks.useVisible('dbPurchase')
  const { withEnterprisePermission } = usePermissions()

  const toggleVisible: VoidFunction = () => modal.toggleVisible()

  const dbMap = {
    [CountryCode.TW]: totalTaiwanKOLs,
    [CountryCode.JP]: totalJapanKOLs,
    [CountryCode.MY]: totalMalaysiaKOLs,
    [CountryCode.HK]: totalHongKongKOLs,
    [CountryCode.KR]: totalKoreaKols,
    [CountryCode.TH]: totalThaiKols,
    [CountryCode.VN]: totalVietnamKols,
    [CountryCode.SG]: totalSingaporeKols,
  }

  const handleClose = (): void => {
    if (onClose) {
      onClose()
      return
    }

    toggleVisible()
  }

  return (
    <Modal
      centered
      closable={false}
      footer={null}
      open={open ?? modal.visible}
      title={formatMessage(
        { id: 'vip:dialog_upgrade_db_title' },
        {
          region: formatMessage({
            id: ('account:register_region_' + (dbPlan ?? 'tw')) as I18nId,
          }),
        },
      )}
    >
      <Desc>
        {formatMessage(
          { id: 'vip:dialog_upgrade_db_dsp' },
          {
            n: formatNumber(get(dbMap, dbPlan ?? 'tw')),
            region: formatMessage({
              id: ('account:register_region_' + (dbPlan ?? 'tw')) as I18nId,
            }),
          },
        )}
      </Desc>
      <Flex gap={24} justify='center'>
        <Button onClick={handleClose}>
          {formatMessage({ id: 'search:free_popop_vip_only_read_btn' })}
        </Button>
        <Link
          href={routes.workspacePlan.stringifyUrl({})}
          onClick={(): void => {
            handleClose()
          }}
        >
          <VipButton>
            {withEnterprisePermission
              ? formatMessage({ id: 'vip:upgrade_activate_btn' })
              : formatMessage({ id: 'vip:dialog_upgrade_db_btn' })}
          </VipButton>
        </Link>
      </Flex>
    </Modal>
  )
}

const Modal = styled(BasicModal)`
  .ant-modal-title {
    text-align: center;
  }
`

const Desc = styled.div`
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: ${({ theme }): string => theme.colors.text.secondary};
  margin-bottom: 24px;
`

export default DbPurchaseModal

export const META_KOL_AUTH_SCOPES = [
  'pages_show_list',
  'catalog_management',
  'pages_read_engagement',
  'pages_read_user_content',
  'pages_manage_metadata',
  'read_insights',
  'leads_retrieval',
  'public_profile',
  'instagram_manage_insights',
  'instagram_basic',
  'email',
  'business_management',
]

export const META_KOL_AUTH_SCOPE = META_KOL_AUTH_SCOPES.join(',')

export const META_AD_AUTH_SCOPES = ['public_profile', 'email']

export const META_AD_AUTH_SCOPE = META_AD_AUTH_SCOPES.join(',')

export const KOL_AUTH_PARAMS = {
  scope: META_KOL_AUTH_SCOPE,
  return_scopes: true,
}

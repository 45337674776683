import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ModelContractAcception } from '@/types/mock-api-types'

export interface CampaignInfo {
  id?: number
  name?: string
}

interface ContractState {
  //TODO - Use zod schema instead of ModelContractAcception
  data?: ModelContractAcception
  campaignInfo?: CampaignInfo
}

interface SetContractPayload {
  contract?: ModelContractAcception
  campaignInfo?: CampaignInfo
}

const initialState: ContractState = {}

const contractSlice = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    setContract: (state, { payload }: PayloadAction<SetContractPayload>) => {
      state.data = payload.contract
      state.campaignInfo = payload.campaignInfo
    },
  },
})

export const { setContract } = contractSlice.actions

export default contractSlice.reducer

import { FetchArgs } from '@reduxjs/toolkit/query/react'
import {
  api,
  infiniteConfigSetting,
  InfluencerAPITagType,
  UserAPITagType,
} from '@/api/api'
import { FetchKolContentPayload } from '@/api/workspaces-api'
import {
  AudienceAnalyze,
  FollowerAnalyze,
  SimilarKols,
} from '@/store/kol-detail'
import { Platform } from '@/tests/mock-data/mock-content-distribution'
import { FetchInfluencerRelatedBrandsRequest } from '@/types/api/influencer'
import {
  FetchHashTagAnalyzeResponse,
  FetchKolAnalyzeResponse,
  FetchKolContentResponse,
  RelatedKol,
} from '@/types/api/kols'
import { ListResource } from '@/types/resource-type'
import { AgentSchema } from '@/types/schema/agent-schema'
import {
  accountingInfoSchema,
  type AccountingInfo,
  type UpdateAccountingInfo,
} from '@/types/schema/influencer/accounting-info-schema'
import {
  applyKolSchemaModel,
  KolSchemaModel,
  KolSchemaRemote,
} from '@/types/schema/kol-schema'
import { camelizeKeys, decamelizeKeys } from '@/utils/humps'
import removeNil from '@/utils/remove-nil'

interface FetchSimilarKolsRequest {
  uuid: string
  platform: string
  page?: number
  per_page?: number
}

interface FetchInfluencerRequest extends FetchKolContentPayload {
  uuid: string
}

const influencerApi = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchAgentInfo: builder.query<AgentSchema, void>({
      query: () => {
        return {
          url: '/influencer/user/agent-info',
        }
      },
      transformResponse: (response: object) => {
        return camelizeKeys(response) as unknown as AgentSchema
      },
      providesTags: [InfluencerAPITagType.AgentInfo],
    }),
    updateAgentInfo: builder.mutation<void, AgentSchema>({
      query: (payload) => {
        return {
          url: '/influencer/user/agent-info',
          method: 'PUT',
          body: decamelizeKeys(payload),
        }
      },
      invalidatesTags: [InfluencerAPITagType.AgentInfo],
    }),
    fetchInfluencerInfo: builder.query<KolSchemaModel, string>({
      query: (uuid) => {
        return {
          url: `/influencer/kols/${uuid}/info`,
        }
      },
      transformResponse: (response: object) => {
        const camelizeResponse = camelizeKeys(
          response,
        ) as unknown as KolSchemaRemote
        return applyKolSchemaModel(camelizeResponse)
      },
      providesTags: [InfluencerAPITagType.InfluencerInfo],
    }),
    fetchInfluencerFollowerAnalysis: builder.query<FollowerAnalyze, string>({
      query: (uuid) => {
        return {
          url: `/influencer/kols/${uuid}/follower/analyze`,
        }
      },
      transformResponse: (response: { data: object }) => {
        return camelizeKeys(response.data) as unknown as FollowerAnalyze
      },
    }),
    fetchInfluencerAudienceAnalysis: builder.query<AudienceAnalyze, string>({
      query: (uuid) => {
        return {
          url: `/influencer/kols/${uuid}/audience/analyze`,
        }
      },
      transformResponse: (response: { data: object }) => {
        return camelizeKeys(response.data) as unknown as AudienceAnalyze
      },
    }),
    fetchInfluencerLinkAnalysis: builder.query<Platform, string>({
      query: (uuid) => {
        return {
          url: `/influencer/kols/${uuid}/link/analyze`,
        }
      },
      transformResponse: (response: object) => {
        return camelizeKeys(response) as unknown as Platform
      },
    }),
    fetchInfluencerAnalyze: builder.query<FetchKolAnalyzeResponse, string>({
      query: (uuid) => {
        return {
          url: `/influencer/kols/${uuid}/post/analyze`,
        }
      },
      transformResponse: (response: object) => {
        return camelizeKeys(response) as unknown as FetchKolAnalyzeResponse
      },
    }),
    fetchInfluencerHashTagAnalysis: builder.query<
      FetchHashTagAnalyzeResponse,
      string
    >({
      query: (uuid) => {
        return {
          url: `/influencer/kols/${uuid}/statistic-hashtags`,
        }
      },
      transformResponse: (response: object) => {
        return camelizeKeys(response) as unknown as FetchHashTagAnalyzeResponse
      },
    }),
    fetchInfluencerSimilarKols: builder.query<
      ListResource<SimilarKols>,
      FetchSimilarKolsRequest
    >({
      query: ({ uuid, platform, per_page = 10, page = 1 }) => {
        return {
          url: `/influencer/kols/${uuid}/similar-kols`,
          params: { platform, per_page, page },
        }
      },
      transformResponse: (response: object) => {
        return camelizeKeys(response) as unknown as ListResource<SimilarKols>
      },
    }),
    fetchInfiniteInfluencerContent: builder.query<
      FetchKolContentResponse,
      FetchInfluencerRequest
    >({
      query: ({ uuid, ...payload }) => {
        const params = removeNil(payload)

        return {
          url: `/influencer/kols/${uuid}/content`,
          params,
        }
      },
      ...infiniteConfigSetting<
        FetchKolContentPayload,
        FetchKolContentResponse
      >(),
      transformResponse: (response: object) => {
        return camelizeKeys(response) as unknown as FetchKolContentResponse
      },
    }),
    fetchInfluencerRelatedBrands: builder.query<
      ListResource<RelatedKol>,
      FetchInfluencerRelatedBrandsRequest
    >({
      query: ({ radar_uuid, ...payload }): FetchArgs => {
        const params = decamelizeKeys(removeNil(payload))

        return {
          url: `/influencer/kols/${radar_uuid}/related-kols`,
          params,
        }
      },
      ...infiniteConfigSetting<
        FetchInfluencerRelatedBrandsRequest,
        ListResource<RelatedKol>
      >(),
      transformResponse: (response: object): ListResource<RelatedKol> => {
        return camelizeKeys(response) as unknown as ListResource<RelatedKol>
      },
    }),
    fetchAccountingInfo: builder.query<AccountingInfo, void>({
      query: () => {
        return {
          url: '/influencer/user/accountingInfo',
        }
      },
      extraOptions: {
        schema: {
          response: accountingInfoSchema,
        },
      },
      providesTags: [InfluencerAPITagType.Accounting],
    }),
    updateAccountingInfo: builder.mutation<void, UpdateAccountingInfo>({
      query: (recipient) => {
        return {
          url: '/influencer/user/accountingInfo',
          body: recipient,
          method: 'PATCH',
        }
      },
      invalidatesTags: [
        InfluencerAPITagType.Accounting,
        UserAPITagType.ContractStatus,
      ],
    }),
  }),
})

export const useLazyFetchAgentInfoQuery =
  influencerApi.endpoints.fetchAgentInfo.useLazyQuery
export const useFetchAccountingInfoQuery =
  influencerApi.endpoints.fetchAccountingInfo.useQuery
export const useUpdateAccountingInfoMutation =
  influencerApi.endpoints.updateAccountingInfo.useMutation
export const useUpdateAgentInfoMutation =
  influencerApi.endpoints.updateAgentInfo.useMutation
export const useFetchAgentInfoQuery =
  influencerApi.endpoints.fetchAgentInfo.useQuery
export const useFetchInfluencerInfoQuery =
  influencerApi.endpoints.fetchInfluencerInfo.useQuery
export const useFetchInfluencerFollowerAnalysisQuery =
  influencerApi.endpoints.fetchInfluencerFollowerAnalysis.useQuery
export const useFetchInfluencerAudienceAnalysisQuery =
  influencerApi.endpoints.fetchInfluencerAudienceAnalysis.useQuery
export const useFetchInfluencerLinkAnalysisQuery =
  influencerApi.endpoints.fetchInfluencerLinkAnalysis.useQuery
export const useFetchInfluencerAnalyzeQuery =
  influencerApi.endpoints.fetchInfluencerAnalyze.useQuery
export const useFetchInfluencerHashTagAnalysisQuery =
  influencerApi.endpoints.fetchInfluencerHashTagAnalysis.useQuery
export const useFetchInfluencerSimilarKolsQuery =
  influencerApi.endpoints.fetchInfluencerSimilarKols.useQuery
export const useFetchInfiniteInfluencerContentQuery =
  influencerApi.endpoints.fetchInfiniteInfluencerContent.useQuery

export const { useFetchInfluencerRelatedBrandsQuery } = influencerApi

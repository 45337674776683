import { createBreakpoint } from 'react-use'

export type Breakpoint = 'mobile' | 'PC'

export const PC_BREAKPOINT = 768

export const useBreakpoint = createBreakpoint({
  PC: PC_BREAKPOINT,
  mobile: 1,
}) as () => Breakpoint

import { Drawer, Menu } from 'antd'
import { stringifyUrl } from 'query-string'
import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { useQueryParam } from 'use-query-params'
import { useUpdateUserInfoMutation } from '@/api/user-api'
import LogoHorizontal from '@/components/common/logo-horizontal'
import { Icon } from '@/components/custom-icon'
import IconCSSVG from '@/components/icon/own-svg/el-icon-cs.svg'
import UnsafeNewTabLink from '@/components/public-page/footer/components/unsafe-new-tab-link'
import {
  Group,
  GroupTitle,
  Items,
} from '@/components/public-page/header/components/mega-menu/item/dropdown/content'
import useKolRadarMenuItems from '@/components/public-page/header/components/use-kol-radar-menu-items'
import useUrlControl from '@/components/public-page/hook/use-url-control'
import { useAuth } from '@/hooks/use-auth'
import { Page } from '@/hooks/use-authorization/constants'
import { useI18n } from '@/hooks/use-i18n'
import { useIntl } from '@/i18n/hooks/use-intl'
import { LanguageDict, languages } from '@/i18n/languages'
import { logout } from '@/store/me'
import { ampli } from '@/utils/ampli'
import { goToPage } from '@/utils/routes/go-to-page'
import { NonNullStringParam } from '@/utils/use-query-params'

const AppHeaderOtherCountryMobile: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const [from] = useQueryParam('from', NonNullStringParam)

  const [isMenuVisible, setMenuVisible] = useState(false)
  const drawerContainerRef = useRef<HTMLDivElement>(null)
  const menuItems = useKolRadarMenuItems()
  const { userInfo } = useAuth()
  const dispatch = useDispatch()
  const { getRootUrl, getAppUrl } = useUrlControl()
  const { language } = useI18n()
  const [currentLang, setCurrentLang] = useState(language)
  const [updateUser] = useUpdateUserInfoMutation()
  const handleSelectLanguage = async (lang: LanguageDict): Promise<void> => {
    setMenuVisible(false)

    if (userInfo) {
      try {
        await updateUser({ language: lang.i18nCode })
        setCurrentLang(lang)
        ampli.changeLanguage({ language: lang.i18nCode })
      } catch (e) {
        console.warn(e)
      }
    } else {
      await goToPage(
        stringifyUrl({
          url: `${location.origin}${location.pathname}`,
          query: {
            lang: lang.i18nCode,
            from,
          },
        }),
      )
    }
  }

  useEffect(() => {
    setCurrentLang(language)
  }, [language])

  const menu = (
    <Menu
      mode='inline'
      selectable={false}
      onClick={(): void => setMenuVisible(false)}
    >
      {menuItems.map((item, i) => {
        switch (item.type) {
          case 'link': {
            const { url, onClick, title, isBlank } = item
            return (
              <Menu.Item key={i}>
                <MenuLinkItem
                  as={isBlank ? UnsafeNewTabLink : undefined}
                  href={url}
                  onClick={onClick}
                >
                  {title}
                </MenuLinkItem>
              </Menu.Item>
            )
          }
          case 'dropdown': {
            const { columns, title } = item
            return (
              <Menu.SubMenu
                key={i}
                title={<SubMenuTitle>{title}</SubMenuTitle>}
              >
                {columns.map((column) => {
                  return column.map((group) => {
                    const {
                      title: groupTitle,
                      visible: groupVisible = true,
                      linkItems,
                      type,
                    } = group

                    if (!groupVisible) {
                      return null
                    }

                    const isAllItemHidden = linkItems.every(
                      (item) => item.visible === false,
                    )
                    if (isAllItemHidden) {
                      return null
                    }

                    return (
                      <Group key={groupTitle}>
                        <GroupTitle groupType={type}>{groupTitle}</GroupTitle>
                        <Items>
                          {linkItems.map((item) => {
                            const {
                              title,
                              visible = true,
                              url,
                              onClick = (): void => {},
                              isBlank = false,
                            } = item

                            if (!visible) {
                              return null
                            }

                            return (
                              <Menu.Item key={title}>
                                {isBlank ? (
                                  <UnsafeNewTabLink
                                    href={url}
                                    onClick={onClick}
                                  >
                                    <MenuLinkItem child>{title}</MenuLinkItem>
                                  </UnsafeNewTabLink>
                                ) : (
                                  <MenuLinkItem
                                    child
                                    href={url}
                                    onClick={onClick}
                                  >
                                    {title}
                                  </MenuLinkItem>
                                )}
                              </Menu.Item>
                            )
                          })}
                        </Items>
                      </Group>
                    )
                  })
                })}
              </Menu.SubMenu>
            )
          }
          default:
            const never: never = item
            return never
        }
      })}
      {!userInfo ? (
        <Menu.Item>
          <MenuLinkItem href={getAppUrl(Page.Login, { from })}>
            {formatMessage({ id: 'general:navbar_text_login' })}
          </MenuLinkItem>
        </Menu.Item>
      ) : (
        <Menu.Item>
          <MenuLinkItem href={getAppUrl(Page.Default)}>
            {formatMessage({ id: 'general:go_to_app' })}
          </MenuLinkItem>
        </Menu.Item>
      )}
      {!userInfo ? (
        <Menu.Item>
          <MenuLinkItem
            as={UnsafeNewTabLink}
            href={getAppUrl(Page.Register, { from })}
          >
            {formatMessage({ id: 'general:navbar_text_signup' })}
          </MenuLinkItem>
        </Menu.Item>
      ) : (
        <Menu.Item>
          <MenuLinkItem
            onClick={(): void => {
              dispatch(logout())
            }}
          >
            {formatMessage({ id: 'general:logout' })}
          </MenuLinkItem>
        </Menu.Item>
      )}
      <Menu.SubMenu title={<SubMenuTitle>{currentLang.label}</SubMenuTitle>}>
        {languages
          .filter(({ i18nCode }) => i18nCode !== currentLang.i18nCode)
          .map((lang) => (
            <Menu.Item
              key={lang.i18nCode}
              onClick={(): Promise<void> => handleSelectLanguage(lang)}
            >
              <LangLinkItem>{lang.label}</LangLinkItem>
            </Menu.Item>
          ))}
      </Menu.SubMenu>
    </Menu>
  )

  return (
    <>
      <Wrapper>
        <HomePageLink href={getRootUrl()}>
          <LogoHorizontal />
        </HomePageLink>
        <RightItems>
          <RegisterButton
            as={UnsafeNewTabLink}
            href={getAppUrl(Page.Register, { from })}
          >
            <IconCSSVG />
            {formatMessage({ id: 'product:main_register_btn' })}
          </RegisterButton>
          <a
            onClick={(event): void => {
              event.preventDefault()
              setMenuVisible(!isMenuVisible)
            }}
          >
            <ListIcon type='elIconList' />
          </a>
          <Drawer
            closable={false}
            getContainer={drawerContainerRef.current ?? 'body'}
            height='auto'
            open={isMenuVisible}
            placement='top'
            title={null}
            onClose={(): void => setMenuVisible(false)}
          >
            {menu}
          </Drawer>
        </RightItems>
      </Wrapper>
      <DrawerContainer ref={drawerContainerRef}></DrawerContainer>
    </>
  )
}

const Wrapper = styled.div`
  align-items: center;
  background: ${({ theme }): string => theme.colors.background.white};
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.102);
  display: flex;
  height: 62px;
  justify-content: space-between;
  padding: 0 16px 0 20px;

  /* 為了 transition 的體驗，壓過 ant-drawer 的 z-index */
  position: relative;
  z-index: 1001;
`

const DrawerContainer = styled.div`
  position: relative;

  .ant-drawer-body {
    padding: 0;
    overflow-x: hidden;
  }

  .ant-drawer-mask {
    opacity: 0 !important;
  }

  .ant-drawer-content-wrapper {
    box-shadow: none !important;
  }

  .ant-drawer-top.ant-drawer-open {
    position: absolute;
  }

  .ant-drawer-wrapper-body {
    overflow: hidden;
  }

  .ant-menu {
    color: ${({ theme }): string => theme.colors.text.primary};
    border-right: 0 !important;
  }

  .ant-menu-submenu-inline {
    > .ant-menu-submenu-title {
      :hover {
        .ant-menu-submenu-arrow {
          ::before,
          ::after {
            transition:
              transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
              -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            background: ${({ theme }): string => theme.colors.text.primary};
          }
        }
      }
    }
  }

  .ant-menu-submenu > .ant-menu {
    background: #fafafa;
  }

  .ant-menu-inline {
    .ant-menu-item:not(:last-child),
    .ant-menu-submenu-title,
    .ant-menu-item {
      margin: 0;
    }
  }

  .ant-menu-submenu-title,
  .ant-menu-item {
    display: flex;
    align-items: center;
    padding: 12px 0 12px 24px;

    :hover {
      color: ${({ theme }): string => theme.colors.text.primary};
      background: none;
    }
  }

  .ant-menu-item > a {
    align-items: center;
    color: ${({ theme }): string => theme.colors.text.primary};
    display: flex;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;

    :hover {
      color: ${({ theme }): string => theme.colors.text.primary};
    }
  }

  ${Group} {
    padding-top: 20px;
  }

  ${GroupTitle} {
    margin-bottom: 12px;
    padding-left: 24px;
  }

  ${Items} {
    padding-left: 24px;
  }
`

const HomePageLink = styled.a`
  svg {
    margin: 5px 0 0;
  }
`

const RightItems = styled.div`
  display: flex;
  align-items: center;
`

const ListIcon = styled(Icon)`
  svg {
    height: 30px;
    width: 30px;
  }
`

const RegisterButton = styled.button`
  align-items: center;
  display: flex;
  margin: 0 16px 0 0;
  background: ${({ theme }): string => theme.colors.background.white};
  border-radius: 4px;
  border: 1px solid ${({ theme }): string => theme.colors.brand.primary};
  color: ${({ theme }): string => theme.colors.brand.primary};
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 114%;
  padding: 12px 20px;
  text-align: center;

  svg {
    fill: ${({ theme }): string => theme.colors.brand.primary};
    height: 16px;
    margin: 0 4px 0 0;
    width: 16px;
  }

  :hover {
    color: ${({ theme }): string => theme.colors.brand.primaryHover};
    border-color: ${({ theme }): string => theme.colors.brand.primaryHover};
  }

  :focus,
  :active {
    border: 1px solid ${({ theme }): string => theme.colors.brand.primary};
    box-shadow: 0 0 0 4px rgba(255, 181, 181, 0.25);
    color: ${({ theme }): string => theme.colors.brand.primaryHover};
  }

  :disabled {
    background: #f5f5f5;
    border: 1px solid #d9d9d9;
  }
`

const SubMenuTitle = styled.p`
  align-items: center;
  color: ${({ theme }): string => theme.colors.text.primary};
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin: 0;

  svg {
    height: 16px;
    margin: 0 16px 0 0;
    width: 24px;
  }
`

const MenuLinkItem = styled.a<{ child?: boolean }>`
  display: block;
  color: ${({ theme }): string => theme.colors.text.primary};
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  :hover {
    color: ${({ theme }): string => theme.colors.text.primary};
  }

  svg {
    width: 20px;
    height: 20px;
    margin: 0 4px 2px 0;
  }

  ${({ child }): FlattenSimpleInterpolation =>
    child
      ? css`
          font-size: 14px;
        `
      : css`
          font-size: 16px;
        `}
`

const LangLinkItem = styled(MenuLinkItem)`
  display: flex;
  align-items: center;

  svg {
    width: 24px;
    height: 16px;
    margin: 0 12px 0 0;
  }
`

export default AppHeaderOtherCountryMobile

import { z as P } from "zod";
var ee = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function jt(e) {
  return e && e.__esModule && Object.prototype.hasOwnProperty.call(e, "default") ? e.default : e;
}
function Zd(e) {
  if (e.__esModule) return e;
  var t = e.default;
  if (typeof t == "function") {
    var r = function n() {
      return this instanceof n ? Reflect.construct(t, arguments, this.constructor) : t.apply(this, arguments);
    };
    r.prototype = t.prototype;
  } else r = {};
  return Object.defineProperty(r, "__esModule", { value: !0 }), Object.keys(e).forEach(function(n) {
    var a = Object.getOwnPropertyDescriptor(e, n);
    Object.defineProperty(r, n, a.get ? a : {
      enumerable: !0,
      get: function() {
        return e[n];
      }
    });
  }), r;
}
const Ve = P.enum([
  "payment-outlined",
  "ai-badge-outlined",
  "ai-badge-purple-gradient-outlined",
  "arrow-forward-outlined",
  "product-outlined",
  "sort-outlined",
  "bullhorn-outlined",
  "book-mark-outlined",
  "circle-check-filled",
  "star-box-outlined",
  "badge-radar-star-filled",
  "badge-radar-verified-filled",
  "crown-outlined",
  "crown-filled",
  "edit-outlined",
  "edit-2-outlined",
  "brand-blogger-filled",
  "brand-sina-filled",
  "brand-plurk-filled",
  "brand-instagram-filled",
  "brand-instagram-inactive-filled",
  "brand-facebook-filled",
  "brand-facebook-inactive-filled",
  "brand-facebook-circle-filled",
  "brand-youtube-filled",
  "brand-youtube-button-filled",
  "brand-youtube-inactive-filled",
  "brand-twitch-filled",
  "brand-twitter-filled",
  "brand-thread-filled",
  "brand-tiktok-filled",
  "brand-google-filled",
  "brand-tiktok-inactive-filled",
  "brand-twitter-inactive-filled",
  "radar-chart-outlined",
  "film-outlined",
  "key-outlined",
  "user-outlined",
  "home-outlined",
  "hand-shake-outlined",
  "user-square-outlined",
  "target-outlined",
  "bulb-on-outlined",
  "conversation-outlined",
  "rocket-outlined",
  "analytics-outlined",
  "face-open-mouth-outlined",
  "tags-filled",
  "no-data-outlined",
  "cooperation-post-outlined",
  "cooperation-video-outlined",
  "cooperation-live-outlined",
  "cooperation-event-outlined",
  "dollar-circle-outlined",
  "resume-outlined",
  "save-to-collection-outlined",
  "save-to-collection-filled",
  "table-outlined",
  "shopping-bag-outlined",
  "flag-outlined",
  "checks-outlined",
  "trending-plateau",
  "top-1-filled",
  "top-2-filled",
  "top-3-filled",
  "brand-facebook-corner-filled",
  "brand-instagram-corner-filled",
  "brand-youtube-corner-filled",
  "flag-jp-filled",
  "flag-th-filled",
  "flag-tw-filled",
  "flag-us-filled",
  "search-kol-filled",
  "social-performance-filled",
  "marketing-trend-filled",
  "report-performance-filled",
  "predict-competitor-filled",
  "predictive-report-filled"
]);
function xr() {
  this.__data__ = [], this.size = 0;
}
var Ir = xr;
function Nr(e, t) {
  return e === t || e !== e && t !== t;
}
var Bt = Nr, Dr = Bt;
function Fr(e, t) {
  for (var r = e.length; r--; )
    if (Dr(e[r][0], t))
      return r;
  return -1;
}
var oe = Fr, Lr = oe, Mr = Array.prototype, jr = Mr.splice;
function Br(e) {
  var t = this.__data__, r = Lr(t, e);
  if (r < 0)
    return !1;
  var n = t.length - 1;
  return r == n ? t.pop() : jr.call(t, r, 1), --this.size, !0;
}
var Ur = Br, kr = oe;
function Hr(e) {
  var t = this.__data__, r = kr(t, e);
  return r < 0 ? void 0 : t[r][1];
}
var Kr = Hr, qr = oe;
function Gr(e) {
  return qr(this.__data__, e) > -1;
}
var zr = Gr, Jr = oe;
function Vr(e, t) {
  var r = this.__data__, n = Jr(r, e);
  return n < 0 ? (++this.size, r.push([e, t])) : r[n][1] = t, this;
}
var Wr = Vr, Xr = Ir, Yr = Ur, Qr = Kr, Zr = zr, en = Wr;
function j(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.clear(); ++t < r; ) {
    var n = e[t];
    this.set(n[0], n[1]);
  }
}
j.prototype.clear = Xr;
j.prototype.delete = Yr;
j.prototype.get = Qr;
j.prototype.has = Zr;
j.prototype.set = en;
var ce = j, tn = ce;
function rn() {
  this.__data__ = new tn(), this.size = 0;
}
var nn = rn;
function an(e) {
  var t = this.__data__, r = t.delete(e);
  return this.size = t.size, r;
}
var sn = an;
function on(e) {
  return this.__data__.get(e);
}
var cn = on;
function un(e) {
  return this.__data__.has(e);
}
var ln = un, fn = typeof ee == "object" && ee && ee.Object === Object && ee, Ut = fn, dn = Ut, pn = typeof self == "object" && self && self.Object === Object && self, hn = dn || pn || Function("return this")(), x = hn, yn = x, mn = yn.Symbol, ue = mn, We = ue, kt = Object.prototype, bn = kt.hasOwnProperty, gn = kt.toString, G = We ? We.toStringTag : void 0;
function _n(e) {
  var t = bn.call(e, G), r = e[G];
  try {
    e[G] = void 0;
    var n = !0;
  } catch {
  }
  var a = gn.call(e);
  return n && (t ? e[G] = r : delete e[G]), a;
}
var vn = _n, Sn = Object.prototype, $n = Sn.toString;
function wn(e) {
  return $n.call(e);
}
var An = wn, Xe = ue, En = vn, Tn = An, On = "[object Null]", Cn = "[object Undefined]", Ye = Xe ? Xe.toStringTag : void 0;
function Rn(e) {
  return e == null ? e === void 0 ? Cn : On : Ye && Ye in Object(e) ? En(e) : Tn(e);
}
var V = Rn;
function Pn(e) {
  var t = typeof e;
  return e != null && (t == "object" || t == "function");
}
var le = Pn, xn = V, In = le, Nn = "[object AsyncFunction]", Dn = "[object Function]", Fn = "[object GeneratorFunction]", Ln = "[object Proxy]";
function Mn(e) {
  if (!In(e))
    return !1;
  var t = xn(e);
  return t == Dn || t == Fn || t == Nn || t == Ln;
}
var Ht = Mn, jn = x, Bn = jn["__core-js_shared__"], Un = Bn, _e = Un, Qe = function() {
  var e = /[^.]+$/.exec(_e && _e.keys && _e.keys.IE_PROTO || "");
  return e ? "Symbol(src)_1." + e : "";
}();
function kn(e) {
  return !!Qe && Qe in e;
}
var Hn = kn, Kn = Function.prototype, qn = Kn.toString;
function Gn(e) {
  if (e != null) {
    try {
      return qn.call(e);
    } catch {
    }
    try {
      return e + "";
    } catch {
    }
  }
  return "";
}
var Kt = Gn, zn = Ht, Jn = Hn, Vn = le, Wn = Kt, Xn = /[\\^$.*+?()[\]{}|]/g, Yn = /^\[object .+?Constructor\]$/, Qn = Function.prototype, Zn = Object.prototype, ea = Qn.toString, ta = Zn.hasOwnProperty, ra = RegExp(
  "^" + ea.call(ta).replace(Xn, "\\$&").replace(/hasOwnProperty|(function).*?(?=\\\()| for .+?(?=\\\])/g, "$1.*?") + "$"
);
function na(e) {
  if (!Vn(e) || Jn(e))
    return !1;
  var t = zn(e) ? ra : Yn;
  return t.test(Wn(e));
}
var aa = na;
function ia(e, t) {
  return e == null ? void 0 : e[t];
}
var sa = ia, oa = aa, ca = sa;
function ua(e, t) {
  var r = ca(e, t);
  return oa(r) ? r : void 0;
}
var B = ua, la = B, fa = x, da = la(fa, "Map"), Le = da, pa = B, ha = pa(Object, "create"), fe = ha, Ze = fe;
function ya() {
  this.__data__ = Ze ? Ze(null) : {}, this.size = 0;
}
var ma = ya;
function ba(e) {
  var t = this.has(e) && delete this.__data__[e];
  return this.size -= t ? 1 : 0, t;
}
var ga = ba, _a = fe, va = "__lodash_hash_undefined__", Sa = Object.prototype, $a = Sa.hasOwnProperty;
function wa(e) {
  var t = this.__data__;
  if (_a) {
    var r = t[e];
    return r === va ? void 0 : r;
  }
  return $a.call(t, e) ? t[e] : void 0;
}
var Aa = wa, Ea = fe, Ta = Object.prototype, Oa = Ta.hasOwnProperty;
function Ca(e) {
  var t = this.__data__;
  return Ea ? t[e] !== void 0 : Oa.call(t, e);
}
var Ra = Ca, Pa = fe, xa = "__lodash_hash_undefined__";
function Ia(e, t) {
  var r = this.__data__;
  return this.size += this.has(e) ? 0 : 1, r[e] = Pa && t === void 0 ? xa : t, this;
}
var Na = Ia, Da = ma, Fa = ga, La = Aa, Ma = Ra, ja = Na;
function U(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.clear(); ++t < r; ) {
    var n = e[t];
    this.set(n[0], n[1]);
  }
}
U.prototype.clear = Da;
U.prototype.delete = Fa;
U.prototype.get = La;
U.prototype.has = Ma;
U.prototype.set = ja;
var Ba = U, et = Ba, Ua = ce, ka = Le;
function Ha() {
  this.size = 0, this.__data__ = {
    hash: new et(),
    map: new (ka || Ua)(),
    string: new et()
  };
}
var Ka = Ha;
function qa(e) {
  var t = typeof e;
  return t == "string" || t == "number" || t == "symbol" || t == "boolean" ? e !== "__proto__" : e === null;
}
var Ga = qa, za = Ga;
function Ja(e, t) {
  var r = e.__data__;
  return za(t) ? r[typeof t == "string" ? "string" : "hash"] : r.map;
}
var de = Ja, Va = de;
function Wa(e) {
  var t = Va(this, e).delete(e);
  return this.size -= t ? 1 : 0, t;
}
var Xa = Wa, Ya = de;
function Qa(e) {
  return Ya(this, e).get(e);
}
var Za = Qa, ei = de;
function ti(e) {
  return ei(this, e).has(e);
}
var ri = ti, ni = de;
function ai(e, t) {
  var r = ni(this, e), n = r.size;
  return r.set(e, t), this.size += r.size == n ? 0 : 1, this;
}
var ii = ai, si = Ka, oi = Xa, ci = Za, ui = ri, li = ii;
function k(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.clear(); ++t < r; ) {
    var n = e[t];
    this.set(n[0], n[1]);
  }
}
k.prototype.clear = si;
k.prototype.delete = oi;
k.prototype.get = ci;
k.prototype.has = ui;
k.prototype.set = li;
var Me = k, fi = ce, di = Le, pi = Me, hi = 200;
function yi(e, t) {
  var r = this.__data__;
  if (r instanceof fi) {
    var n = r.__data__;
    if (!di || n.length < hi - 1)
      return n.push([e, t]), this.size = ++r.size, this;
    r = this.__data__ = new pi(n);
  }
  return r.set(e, t), this.size = r.size, this;
}
var mi = yi, bi = ce, gi = nn, _i = sn, vi = cn, Si = ln, $i = mi;
function H(e) {
  var t = this.__data__ = new bi(e);
  this.size = t.size;
}
H.prototype.clear = gi;
H.prototype.delete = _i;
H.prototype.get = vi;
H.prototype.has = Si;
H.prototype.set = $i;
var qt = H, wi = "__lodash_hash_undefined__";
function Ai(e) {
  return this.__data__.set(e, wi), this;
}
var Ei = Ai;
function Ti(e) {
  return this.__data__.has(e);
}
var Oi = Ti, Ci = Me, Ri = Ei, Pi = Oi;
function ae(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.__data__ = new Ci(); ++t < r; )
    this.add(e[t]);
}
ae.prototype.add = ae.prototype.push = Ri;
ae.prototype.has = Pi;
var xi = ae;
function Ii(e, t) {
  for (var r = -1, n = e == null ? 0 : e.length; ++r < n; )
    if (t(e[r], r, e))
      return !0;
  return !1;
}
var Ni = Ii;
function Di(e, t) {
  return e.has(t);
}
var Fi = Di, Li = xi, Mi = Ni, ji = Fi, Bi = 1, Ui = 2;
function ki(e, t, r, n, a, i) {
  var s = r & Bi, o = e.length, f = t.length;
  if (o != f && !(s && f > o))
    return !1;
  var l = i.get(e), d = i.get(t);
  if (l && d)
    return l == t && d == e;
  var u = -1, y = !0, v = r & Ui ? new Li() : void 0;
  for (i.set(e, t), i.set(t, e); ++u < o; ) {
    var p = e[u], h = t[u];
    if (n)
      var b = s ? n(h, p, u, t, e, i) : n(p, h, u, e, t, i);
    if (b !== void 0) {
      if (b)
        continue;
      y = !1;
      break;
    }
    if (v) {
      if (!Mi(t, function(g, $) {
        if (!ji(v, $) && (p === g || a(p, g, r, n, i)))
          return v.push($);
      })) {
        y = !1;
        break;
      }
    } else if (!(p === h || a(p, h, r, n, i))) {
      y = !1;
      break;
    }
  }
  return i.delete(e), i.delete(t), y;
}
var Gt = ki, Hi = x, Ki = Hi.Uint8Array, qi = Ki;
function Gi(e) {
  var t = -1, r = Array(e.size);
  return e.forEach(function(n, a) {
    r[++t] = [a, n];
  }), r;
}
var zi = Gi;
function Ji(e) {
  var t = -1, r = Array(e.size);
  return e.forEach(function(n) {
    r[++t] = n;
  }), r;
}
var Vi = Ji, tt = ue, rt = qi, Wi = Bt, Xi = Gt, Yi = zi, Qi = Vi, Zi = 1, es = 2, ts = "[object Boolean]", rs = "[object Date]", ns = "[object Error]", as = "[object Map]", is = "[object Number]", ss = "[object RegExp]", os = "[object Set]", cs = "[object String]", us = "[object Symbol]", ls = "[object ArrayBuffer]", fs = "[object DataView]", nt = tt ? tt.prototype : void 0, ve = nt ? nt.valueOf : void 0;
function ds(e, t, r, n, a, i, s) {
  switch (r) {
    case fs:
      if (e.byteLength != t.byteLength || e.byteOffset != t.byteOffset)
        return !1;
      e = e.buffer, t = t.buffer;
    case ls:
      return !(e.byteLength != t.byteLength || !i(new rt(e), new rt(t)));
    case ts:
    case rs:
    case is:
      return Wi(+e, +t);
    case ns:
      return e.name == t.name && e.message == t.message;
    case ss:
    case cs:
      return e == t + "";
    case as:
      var o = Yi;
    case os:
      var f = n & Zi;
      if (o || (o = Qi), e.size != t.size && !f)
        return !1;
      var l = s.get(e);
      if (l)
        return l == t;
      n |= es, s.set(e, t);
      var d = Xi(o(e), o(t), n, a, i, s);
      return s.delete(e), d;
    case us:
      if (ve)
        return ve.call(e) == ve.call(t);
  }
  return !1;
}
var ps = ds;
function hs(e, t) {
  for (var r = -1, n = t.length, a = e.length; ++r < n; )
    e[a + r] = t[r];
  return e;
}
var ys = hs, ms = Array.isArray, D = ms, bs = ys, gs = D;
function _s(e, t, r) {
  var n = t(e);
  return gs(e) ? n : bs(n, r(e));
}
var vs = _s;
function Ss(e, t) {
  for (var r = -1, n = e == null ? 0 : e.length, a = 0, i = []; ++r < n; ) {
    var s = e[r];
    t(s, r, e) && (i[a++] = s);
  }
  return i;
}
var $s = Ss;
function ws() {
  return [];
}
var As = ws, Es = $s, Ts = As, Os = Object.prototype, Cs = Os.propertyIsEnumerable, at = Object.getOwnPropertySymbols, Rs = at ? function(e) {
  return e == null ? [] : (e = Object(e), Es(at(e), function(t) {
    return Cs.call(e, t);
  }));
} : Ts, Ps = Rs;
function xs(e, t) {
  for (var r = -1, n = Array(e); ++r < e; )
    n[r] = t(r);
  return n;
}
var Is = xs;
function Ns(e) {
  return e != null && typeof e == "object";
}
var W = Ns, Ds = V, Fs = W, Ls = "[object Arguments]";
function Ms(e) {
  return Fs(e) && Ds(e) == Ls;
}
var js = Ms, it = js, Bs = W, zt = Object.prototype, Us = zt.hasOwnProperty, ks = zt.propertyIsEnumerable, Hs = it(/* @__PURE__ */ function() {
  return arguments;
}()) ? it : function(e) {
  return Bs(e) && Us.call(e, "callee") && !ks.call(e, "callee");
}, Jt = Hs, ie = { exports: {} };
function Ks() {
  return !1;
}
var qs = Ks;
ie.exports;
(function(e, t) {
  var r = x, n = qs, a = t && !t.nodeType && t, i = a && !0 && e && !e.nodeType && e, s = i && i.exports === a, o = s ? r.Buffer : void 0, f = o ? o.isBuffer : void 0, l = f || n;
  e.exports = l;
})(ie, ie.exports);
var Vt = ie.exports, Gs = 9007199254740991, zs = /^(?:0|[1-9]\d*)$/;
function Js(e, t) {
  var r = typeof e;
  return t = t ?? Gs, !!t && (r == "number" || r != "symbol" && zs.test(e)) && e > -1 && e % 1 == 0 && e < t;
}
var Wt = Js, Vs = 9007199254740991;
function Ws(e) {
  return typeof e == "number" && e > -1 && e % 1 == 0 && e <= Vs;
}
var je = Ws, Xs = V, Ys = je, Qs = W, Zs = "[object Arguments]", eo = "[object Array]", to = "[object Boolean]", ro = "[object Date]", no = "[object Error]", ao = "[object Function]", io = "[object Map]", so = "[object Number]", oo = "[object Object]", co = "[object RegExp]", uo = "[object Set]", lo = "[object String]", fo = "[object WeakMap]", po = "[object ArrayBuffer]", ho = "[object DataView]", yo = "[object Float32Array]", mo = "[object Float64Array]", bo = "[object Int8Array]", go = "[object Int16Array]", _o = "[object Int32Array]", vo = "[object Uint8Array]", So = "[object Uint8ClampedArray]", $o = "[object Uint16Array]", wo = "[object Uint32Array]", _ = {};
_[yo] = _[mo] = _[bo] = _[go] = _[_o] = _[vo] = _[So] = _[$o] = _[wo] = !0;
_[Zs] = _[eo] = _[po] = _[to] = _[ho] = _[ro] = _[no] = _[ao] = _[io] = _[so] = _[oo] = _[co] = _[uo] = _[lo] = _[fo] = !1;
function Ao(e) {
  return Qs(e) && Ys(e.length) && !!_[Xs(e)];
}
var Eo = Ao;
function To(e) {
  return function(t) {
    return e(t);
  };
}
var Oo = To, se = { exports: {} };
se.exports;
(function(e, t) {
  var r = Ut, n = t && !t.nodeType && t, a = n && !0 && e && !e.nodeType && e, i = a && a.exports === n, s = i && r.process, o = function() {
    try {
      var f = a && a.require && a.require("util").types;
      return f || s && s.binding && s.binding("util");
    } catch {
    }
  }();
  e.exports = o;
})(se, se.exports);
var Co = se.exports, Ro = Eo, Po = Oo, st = Co, ot = st && st.isTypedArray, xo = ot ? Po(ot) : Ro, Xt = xo, Io = Is, No = Jt, Do = D, Fo = Vt, Lo = Wt, Mo = Xt, jo = Object.prototype, Bo = jo.hasOwnProperty;
function Uo(e, t) {
  var r = Do(e), n = !r && No(e), a = !r && !n && Fo(e), i = !r && !n && !a && Mo(e), s = r || n || a || i, o = s ? Io(e.length, String) : [], f = o.length;
  for (var l in e)
    (t || Bo.call(e, l)) && !(s && // Safari 9 has enumerable `arguments.length` in strict mode.
    (l == "length" || // Node.js 0.10 has enumerable non-index properties on buffers.
    a && (l == "offset" || l == "parent") || // PhantomJS 2 has enumerable non-index properties on typed arrays.
    i && (l == "buffer" || l == "byteLength" || l == "byteOffset") || // Skip index properties.
    Lo(l, f))) && o.push(l);
  return o;
}
var ko = Uo, Ho = Object.prototype;
function Ko(e) {
  var t = e && e.constructor, r = typeof t == "function" && t.prototype || Ho;
  return e === r;
}
var qo = Ko;
function Go(e, t) {
  return function(r) {
    return e(t(r));
  };
}
var zo = Go, Jo = zo, Vo = Jo(Object.keys, Object), Wo = Vo, Xo = qo, Yo = Wo, Qo = Object.prototype, Zo = Qo.hasOwnProperty;
function ec(e) {
  if (!Xo(e))
    return Yo(e);
  var t = [];
  for (var r in Object(e))
    Zo.call(e, r) && r != "constructor" && t.push(r);
  return t;
}
var tc = ec, rc = Ht, nc = je;
function ac(e) {
  return e != null && nc(e.length) && !rc(e);
}
var Yt = ac, ic = ko, sc = tc, oc = Yt;
function cc(e) {
  return oc(e) ? ic(e) : sc(e);
}
var Be = cc, uc = vs, lc = Ps, fc = Be;
function dc(e) {
  return uc(e, fc, lc);
}
var pc = dc, ct = pc, hc = 1, yc = Object.prototype, mc = yc.hasOwnProperty;
function bc(e, t, r, n, a, i) {
  var s = r & hc, o = ct(e), f = o.length, l = ct(t), d = l.length;
  if (f != d && !s)
    return !1;
  for (var u = f; u--; ) {
    var y = o[u];
    if (!(s ? y in t : mc.call(t, y)))
      return !1;
  }
  var v = i.get(e), p = i.get(t);
  if (v && p)
    return v == t && p == e;
  var h = !0;
  i.set(e, t), i.set(t, e);
  for (var b = s; ++u < f; ) {
    y = o[u];
    var g = e[y], $ = t[y];
    if (n)
      var w = s ? n($, g, y, t, e, i) : n(g, $, y, e, t, i);
    if (!(w === void 0 ? g === $ || a(g, $, r, n, i) : w)) {
      h = !1;
      break;
    }
    b || (b = y == "constructor");
  }
  if (h && !b) {
    var I = e.constructor, Z = t.constructor;
    I != Z && "constructor" in e && "constructor" in t && !(typeof I == "function" && I instanceof I && typeof Z == "function" && Z instanceof Z) && (h = !1);
  }
  return i.delete(e), i.delete(t), h;
}
var gc = bc, _c = B, vc = x, Sc = _c(vc, "DataView"), $c = Sc, wc = B, Ac = x, Ec = wc(Ac, "Promise"), Tc = Ec, Oc = B, Cc = x, Rc = Oc(Cc, "Set"), Pc = Rc, xc = B, Ic = x, Nc = xc(Ic, "WeakMap"), Dc = Nc, Te = $c, Oe = Le, Ce = Tc, Re = Pc, Pe = Dc, Qt = V, K = Kt, ut = "[object Map]", Fc = "[object Object]", lt = "[object Promise]", ft = "[object Set]", dt = "[object WeakMap]", pt = "[object DataView]", Lc = K(Te), Mc = K(Oe), jc = K(Ce), Bc = K(Re), Uc = K(Pe), F = Qt;
(Te && F(new Te(new ArrayBuffer(1))) != pt || Oe && F(new Oe()) != ut || Ce && F(Ce.resolve()) != lt || Re && F(new Re()) != ft || Pe && F(new Pe()) != dt) && (F = function(e) {
  var t = Qt(e), r = t == Fc ? e.constructor : void 0, n = r ? K(r) : "";
  if (n)
    switch (n) {
      case Lc:
        return pt;
      case Mc:
        return ut;
      case jc:
        return lt;
      case Bc:
        return ft;
      case Uc:
        return dt;
    }
  return t;
});
var kc = F, Se = qt, Hc = Gt, Kc = ps, qc = gc, ht = kc, yt = D, mt = Vt, Gc = Xt, zc = 1, bt = "[object Arguments]", gt = "[object Array]", te = "[object Object]", Jc = Object.prototype, _t = Jc.hasOwnProperty;
function Vc(e, t, r, n, a, i) {
  var s = yt(e), o = yt(t), f = s ? gt : ht(e), l = o ? gt : ht(t);
  f = f == bt ? te : f, l = l == bt ? te : l;
  var d = f == te, u = l == te, y = f == l;
  if (y && mt(e)) {
    if (!mt(t))
      return !1;
    s = !0, d = !1;
  }
  if (y && !d)
    return i || (i = new Se()), s || Gc(e) ? Hc(e, t, r, n, a, i) : Kc(e, t, f, r, n, a, i);
  if (!(r & zc)) {
    var v = d && _t.call(e, "__wrapped__"), p = u && _t.call(t, "__wrapped__");
    if (v || p) {
      var h = v ? e.value() : e, b = p ? t.value() : t;
      return i || (i = new Se()), a(h, b, r, n, i);
    }
  }
  return y ? (i || (i = new Se()), qc(e, t, r, n, a, i)) : !1;
}
var Wc = Vc, Xc = Wc, vt = W;
function Zt(e, t, r, n, a) {
  return e === t ? !0 : e == null || t == null || !vt(e) && !vt(t) ? e !== e && t !== t : Xc(e, t, r, n, Zt, a);
}
var er = Zt, Yc = qt, Qc = er, Zc = 1, eu = 2;
function tu(e, t, r, n) {
  var a = r.length, i = a, s = !n;
  if (e == null)
    return !i;
  for (e = Object(e); a--; ) {
    var o = r[a];
    if (s && o[2] ? o[1] !== e[o[0]] : !(o[0] in e))
      return !1;
  }
  for (; ++a < i; ) {
    o = r[a];
    var f = o[0], l = e[f], d = o[1];
    if (s && o[2]) {
      if (l === void 0 && !(f in e))
        return !1;
    } else {
      var u = new Yc();
      if (n)
        var y = n(l, d, f, e, t, u);
      if (!(y === void 0 ? Qc(d, l, Zc | eu, n, u) : y))
        return !1;
    }
  }
  return !0;
}
var ru = tu, nu = le;
function au(e) {
  return e === e && !nu(e);
}
var tr = au, iu = tr, su = Be;
function ou(e) {
  for (var t = su(e), r = t.length; r--; ) {
    var n = t[r], a = e[n];
    t[r] = [n, a, iu(a)];
  }
  return t;
}
var cu = ou;
function uu(e, t) {
  return function(r) {
    return r == null ? !1 : r[e] === t && (t !== void 0 || e in Object(r));
  };
}
var rr = uu, lu = ru, fu = cu, du = rr;
function pu(e) {
  var t = fu(e);
  return t.length == 1 && t[0][2] ? du(t[0][0], t[0][1]) : function(r) {
    return r === e || lu(r, e, t);
  };
}
var hu = pu, yu = V, mu = W, bu = "[object Symbol]";
function gu(e) {
  return typeof e == "symbol" || mu(e) && yu(e) == bu;
}
var pe = gu, _u = D, vu = pe, Su = /\.|\[(?:[^[\]]*|(["'])(?:(?!\1)[^\\]|\\.)*?\1)\]/, $u = /^\w*$/;
function wu(e, t) {
  if (_u(e))
    return !1;
  var r = typeof e;
  return r == "number" || r == "symbol" || r == "boolean" || e == null || vu(e) ? !0 : $u.test(e) || !Su.test(e) || t != null && e in Object(t);
}
var Ue = wu, nr = Me, Au = "Expected a function";
function ke(e, t) {
  if (typeof e != "function" || t != null && typeof t != "function")
    throw new TypeError(Au);
  var r = function() {
    var n = arguments, a = t ? t.apply(this, n) : n[0], i = r.cache;
    if (i.has(a))
      return i.get(a);
    var s = e.apply(this, n);
    return r.cache = i.set(a, s) || i, s;
  };
  return r.cache = new (ke.Cache || nr)(), r;
}
ke.Cache = nr;
var ar = ke;
const ep = /* @__PURE__ */ jt(ar);
var Eu = ar, Tu = 500;
function Ou(e) {
  var t = Eu(e, function(n) {
    return r.size === Tu && r.clear(), n;
  }), r = t.cache;
  return t;
}
var Cu = Ou, Ru = Cu, Pu = /[^.[\]]+|\[(?:(-?\d+(?:\.\d+)?)|(["'])((?:(?!\2)[^\\]|\\.)*?)\2)\]|(?=(?:\.|\[\])(?:\.|\[\]|$))/g, xu = /\\(\\)?/g, Iu = Ru(function(e) {
  var t = [];
  return e.charCodeAt(0) === 46 && t.push(""), e.replace(Pu, function(r, n, a, i) {
    t.push(a ? i.replace(xu, "$1") : n || r);
  }), t;
}), Nu = Iu;
function Du(e, t) {
  for (var r = -1, n = e == null ? 0 : e.length, a = Array(n); ++r < n; )
    a[r] = t(e[r], r, e);
  return a;
}
var Fu = Du, St = ue, Lu = Fu, Mu = D, ju = pe, Bu = 1 / 0, $t = St ? St.prototype : void 0, wt = $t ? $t.toString : void 0;
function ir(e) {
  if (typeof e == "string")
    return e;
  if (Mu(e))
    return Lu(e, ir) + "";
  if (ju(e))
    return wt ? wt.call(e) : "";
  var t = e + "";
  return t == "0" && 1 / e == -Bu ? "-0" : t;
}
var Uu = ir, ku = Uu;
function Hu(e) {
  return e == null ? "" : ku(e);
}
var Ku = Hu, qu = D, Gu = Ue, zu = Nu, Ju = Ku;
function Vu(e, t) {
  return qu(e) ? e : Gu(e, t) ? [e] : zu(Ju(e));
}
var sr = Vu, Wu = pe, Xu = 1 / 0;
function Yu(e) {
  if (typeof e == "string" || Wu(e))
    return e;
  var t = e + "";
  return t == "0" && 1 / e == -Xu ? "-0" : t;
}
var he = Yu, Qu = sr, Zu = he;
function el(e, t) {
  t = Qu(t, e);
  for (var r = 0, n = t.length; e != null && r < n; )
    e = e[Zu(t[r++])];
  return r && r == n ? e : void 0;
}
var or = el, tl = or;
function rl(e, t, r) {
  var n = e == null ? void 0 : tl(e, t);
  return n === void 0 ? r : n;
}
var nl = rl;
function al(e, t) {
  return e != null && t in Object(e);
}
var il = al, sl = sr, ol = Jt, cl = D, ul = Wt, ll = je, fl = he;
function dl(e, t, r) {
  t = sl(t, e);
  for (var n = -1, a = t.length, i = !1; ++n < a; ) {
    var s = fl(t[n]);
    if (!(i = e != null && r(e, s)))
      break;
    e = e[s];
  }
  return i || ++n != a ? i : (a = e == null ? 0 : e.length, !!a && ll(a) && ul(s, a) && (cl(e) || ol(e)));
}
var pl = dl, hl = il, yl = pl;
function ml(e, t) {
  return e != null && yl(e, t, hl);
}
var bl = ml, gl = er, _l = nl, vl = bl, Sl = Ue, $l = tr, wl = rr, Al = he, El = 1, Tl = 2;
function Ol(e, t) {
  return Sl(e) && $l(t) ? wl(Al(e), t) : function(r) {
    var n = _l(r, e);
    return n === void 0 && n === t ? vl(r, e) : gl(t, n, El | Tl);
  };
}
var Cl = Ol;
function Rl(e) {
  return e;
}
var Pl = Rl;
function xl(e) {
  return function(t) {
    return t == null ? void 0 : t[e];
  };
}
var Il = xl, Nl = or;
function Dl(e) {
  return function(t) {
    return Nl(t, e);
  };
}
var Fl = Dl, Ll = Il, Ml = Fl, jl = Ue, Bl = he;
function Ul(e) {
  return jl(e) ? Ll(Bl(e)) : Ml(e);
}
var kl = Ul, Hl = hu, Kl = Cl, ql = Pl, Gl = D, zl = kl;
function Jl(e) {
  return typeof e == "function" ? e : e == null ? ql : typeof e == "object" ? Gl(e) ? Kl(e[0], e[1]) : Hl(e) : zl(e);
}
var cr = Jl, Vl = cr, Wl = Yt, Xl = Be;
function Yl(e) {
  return function(t, r, n) {
    var a = Object(t);
    if (!Wl(t)) {
      var i = Vl(r);
      t = Xl(t), r = function(o) {
        return i(a[o], o, a);
      };
    }
    var s = e(t, r, n);
    return s > -1 ? a[i ? t[s] : s] : void 0;
  };
}
var Ql = Yl;
function Zl(e, t, r, n) {
  for (var a = e.length, i = r + (n ? 1 : -1); n ? i-- : ++i < a; )
    if (t(e[i], i, e))
      return i;
  return -1;
}
var ef = Zl, tf = /\s/;
function rf(e) {
  for (var t = e.length; t-- && tf.test(e.charAt(t)); )
    ;
  return t;
}
var nf = rf, af = nf, sf = /^\s+/;
function of(e) {
  return e && e.slice(0, af(e) + 1).replace(sf, "");
}
var cf = of, uf = cf, At = le, lf = pe, Et = NaN, ff = /^[-+]0x[0-9a-f]+$/i, df = /^0b[01]+$/i, pf = /^0o[0-7]+$/i, hf = parseInt;
function yf(e) {
  if (typeof e == "number")
    return e;
  if (lf(e))
    return Et;
  if (At(e)) {
    var t = typeof e.valueOf == "function" ? e.valueOf() : e;
    e = At(t) ? t + "" : t;
  }
  if (typeof e != "string")
    return e === 0 ? e : +e;
  e = uf(e);
  var r = df.test(e);
  return r || pf.test(e) ? hf(e.slice(2), r ? 2 : 8) : ff.test(e) ? Et : +e;
}
var mf = yf, bf = mf, Tt = 1 / 0, gf = 17976931348623157e292;
function _f(e) {
  if (!e)
    return e === 0 ? e : 0;
  if (e = bf(e), e === Tt || e === -Tt) {
    var t = e < 0 ? -1 : 1;
    return t * gf;
  }
  return e === e ? e : 0;
}
var vf = _f, Sf = vf;
function $f(e) {
  var t = Sf(e), r = t % 1;
  return t === t ? r ? t - r : t : 0;
}
var wf = $f, Af = ef, Ef = cr, Tf = wf, Of = Math.max;
function Cf(e, t, r) {
  var n = e == null ? 0 : e.length;
  if (!n)
    return -1;
  var a = r == null ? 0 : Tf(r);
  return a < 0 && (a = Of(n + a, 0)), Af(e, Ef(t), a);
}
var Rf = Cf, Pf = Ql, xf = Rf, If = Pf(xf), Nf = If;
const He = /* @__PURE__ */ jt(Nf), T = P.enum([
  "all",
  "blog",
  "fb",
  "fb_person",
  "ig",
  "other",
  "tiktok",
  "yt",
  "twitter"
]), R = P.enum([
  "all",
  "blog",
  "facebook",
  "instagram",
  "other",
  "tiktok",
  "youtube",
  "twitter"
]);
var ur = /* @__PURE__ */ ((e) => (e[e.All = -1] = "All", e[e.YouTube = 0] = "YouTube", e[e.Facebook = 1] = "Facebook", e[e.Instagram = 3] = "Instagram", e[e.TikTok = 4] = "TikTok", e[e.Twitter = 5] = "Twitter", e[e.Other = 999] = "Other", e))(ur || {});
const Df = P.nativeEnum(ur);
P.object({
  code: R,
  shortcode: T,
  title: P.string(),
  type: P.optional(Df),
  icon: P.optional(Ve),
  inactiveIcon: P.optional(Ve)
});
const Ke = [
  {
    code: R.enum.all,
    shortcode: T.enum.all,
    title: "All"
  },
  {
    type: 999,
    code: R.enum.other,
    shortcode: T.enum.other,
    title: "Other"
  },
  {
    code: R.enum.blog,
    shortcode: T.enum.blog,
    title: "Blog"
  },
  {
    code: R.enum.tiktok,
    shortcode: T.enum.tiktok,
    title: "TikTok",
    type: 4
    /* TikTok */
  },
  {
    code: R.enum.facebook,
    shortcode: T.enum.fb,
    title: "Facebook",
    type: 1,
    icon: "brand-facebook-filled",
    inactiveIcon: "brand-facebook-inactive-filled"
  },
  {
    code: R.enum.instagram,
    shortcode: T.enum.ig,
    title: "Instagram",
    type: 3,
    icon: "brand-instagram-filled",
    inactiveIcon: "brand-instagram-inactive-filled"
  },
  {
    code: R.enum.youtube,
    shortcode: T.enum.yt,
    title: "YouTube",
    type: 0,
    icon: "brand-youtube-filled",
    inactiveIcon: "brand-youtube-inactive-filled"
  },
  {
    code: R.enum.twitter,
    shortcode: T.enum.twitter,
    title: "Twitter",
    type: 5
    /* Twitter */
  }
], tp = (e) => He(Ke, { code: e }), rp = (e = "") => {
  const t = T.safeParse(e);
  return t.success ? He(Ke, { shortcode: t.data }) : void 0;
}, np = (e) => He(Ke, { type: e }), Ff = {
  beauty: {
    id: "beauty",
    netSuiteValue: "BT 美容業",
    i18nKey: "general:ad_industry_bt",
    isDeprecated: !1
  },
  medicalHealthcare: {
    id: "medicalHealthcare",
    netSuiteValue: "MH 醫療生技及保健業",
    i18nKey: "general:ad_industry_mh",
    isDeprecated: !1
  },
  foodBeverage: {
    id: "foodBeverage",
    netSuiteValue: "FB 食品飲料業",
    i18nKey: "general:ad_industry_fb",
    isDeprecated: !1
  },
  retailWholesale: {
    id: "retailWholesale",
    netSuiteValue: "RW 批發及零售業",
    i18nKey: "general:ad_industry_rw",
    isDeprecated: !1
  },
  eCommerce: {
    id: "eCommerce",
    netSuiteValue: "EC 電子商務業",
    i18nKey: "general:ad_industry_ec",
    isDeprecated: !1
  },
  financial: {
    id: "financial",
    netSuiteValue: "FN 金融業",
    i18nKey: "general:ad_industry_fn",
    isDeprecated: !1
  },
  computersElectronics: {
    id: "computersElectronics",
    netSuiteValue: "CE 資訊電子工業",
    i18nKey: "general:ad_industry_ce",
    isDeprecated: !1
  },
  travelLeisure: {
    id: "travelLeisure",
    netSuiteValue: "TL 觀光餐旅業",
    i18nKey: "general:ad_industry_tl",
    isDeprecated: !1
  },
  gaming: {
    id: "gaming",
    netSuiteValue: "GM 遊戲業",
    i18nKey: "general:ad_industry_gm",
    isDeprecated: !1
  },
  telecommunications: {
    id: "telecommunications",
    netSuiteValue: "TC 通訊業",
    i18nKey: "general:ad_industry_tc",
    isDeprecated: !1
  },
  entertainment: {
    id: "entertainment",
    netSuiteValue: "EN 娛樂媒體業",
    i18nKey: "general:ad_industry_en",
    isDeprecated: !1
  },
  education: {
    id: "education",
    netSuiteValue: "ED 教育業",
    i18nKey: "general:ad_industry_ed",
    isDeprecated: !1
  },
  govNonProfit: {
    id: "govNonProfit",
    netSuiteValue: "GN 政府及社服業",
    i18nKey: "general:ad_industry_gn",
    isDeprecated: !1
  },
  advertisingMarketing: {
    id: "advertisingMarketing",
    netSuiteValue: "AM 廣告行銷業",
    i18nKey: "general:ad_industry_am",
    isDeprecated: !1
  },
  realEstate: {
    id: "realEstate",
    netSuiteValue: "RE 營造及不動產業",
    i18nKey: "general:ad_industry_re",
    isDeprecated: !1
  },
  others: {
    id: "others",
    netSuiteValue: "OT 其他",
    i18nKey: "general:ad_industry_ot",
    isDeprecated: !1
  },
  // 這些只有舊的使用者才會有，新註冊的人不能讓它選
  softwareInternet: {
    id: "softwareInternet",
    netSuiteValue: "SI 資訊軟體業",
    i18nKey: "general:ad_industry_si",
    isDeprecated: !0
  },
  otherManufacturing: {
    id: "otherManufacturing",
    netSuiteValue: "OM 其他製造業",
    i18nKey: "general:ad_industry_om",
    isDeprecated: !0
  }
}, Lf = {
  beautyCare: "beauty",
  healthFood: "medicalHealthcare",
  food: "foodBeverage",
  retail: "retailWholesale",
  "e-commerce": "eCommerce",
  babyCare: "retailWholesale",
  finance: "financial",
  technology: "computersElectronics",
  tourism: "travelLeisure",
  gaming: "gaming",
  sport: "travelLeisure",
  telecom: "telecommunications",
  media: "entertainment",
  education: "education",
  npo: "govNonProfit",
  marketing: "advertisingMarketing",
  realEstate: "realEstate",
  freelancer: "others",
  others: "others"
};
function lr(e, t) {
  return function() {
    return e.apply(t, arguments);
  };
}
const { toString: Mf } = Object.prototype, { getPrototypeOf: qe } = Object, ye = /* @__PURE__ */ ((e) => (t) => {
  const r = Mf.call(t);
  return e[r] || (e[r] = r.slice(8, -1).toLowerCase());
})(/* @__PURE__ */ Object.create(null)), C = (e) => (e = e.toLowerCase(), (t) => ye(t) === e), me = (e) => (t) => typeof t === e, { isArray: q } = Array, J = me("undefined");
function jf(e) {
  return e !== null && !J(e) && e.constructor !== null && !J(e.constructor) && A(e.constructor.isBuffer) && e.constructor.isBuffer(e);
}
const fr = C("ArrayBuffer");
function Bf(e) {
  let t;
  return typeof ArrayBuffer < "u" && ArrayBuffer.isView ? t = ArrayBuffer.isView(e) : t = e && e.buffer && fr(e.buffer), t;
}
const Uf = me("string"), A = me("function"), dr = me("number"), be = (e) => e !== null && typeof e == "object", kf = (e) => e === !0 || e === !1, re = (e) => {
  if (ye(e) !== "object")
    return !1;
  const t = qe(e);
  return (t === null || t === Object.prototype || Object.getPrototypeOf(t) === null) && !(Symbol.toStringTag in e) && !(Symbol.iterator in e);
}, Hf = C("Date"), Kf = C("File"), qf = C("Blob"), Gf = C("FileList"), zf = (e) => be(e) && A(e.pipe), Jf = (e) => {
  let t;
  return e && (typeof FormData == "function" && e instanceof FormData || A(e.append) && ((t = ye(e)) === "formdata" || // detect form-data instance
  t === "object" && A(e.toString) && e.toString() === "[object FormData]"));
}, Vf = C("URLSearchParams"), Wf = (e) => e.trim ? e.trim() : e.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, "");
function X(e, t, { allOwnKeys: r = !1 } = {}) {
  if (e === null || typeof e > "u")
    return;
  let n, a;
  if (typeof e != "object" && (e = [e]), q(e))
    for (n = 0, a = e.length; n < a; n++)
      t.call(null, e[n], n, e);
  else {
    const i = r ? Object.getOwnPropertyNames(e) : Object.keys(e), s = i.length;
    let o;
    for (n = 0; n < s; n++)
      o = i[n], t.call(null, e[o], o, e);
  }
}
function pr(e, t) {
  t = t.toLowerCase();
  const r = Object.keys(e);
  let n = r.length, a;
  for (; n-- > 0; )
    if (a = r[n], t === a.toLowerCase())
      return a;
  return null;
}
const hr = typeof globalThis < "u" ? globalThis : typeof self < "u" ? self : typeof window < "u" ? window : global, yr = (e) => !J(e) && e !== hr;
function xe() {
  const { caseless: e } = yr(this) && this || {}, t = {}, r = (n, a) => {
    const i = e && pr(t, a) || a;
    re(t[i]) && re(n) ? t[i] = xe(t[i], n) : re(n) ? t[i] = xe({}, n) : q(n) ? t[i] = n.slice() : t[i] = n;
  };
  for (let n = 0, a = arguments.length; n < a; n++)
    arguments[n] && X(arguments[n], r);
  return t;
}
const Xf = (e, t, r, { allOwnKeys: n } = {}) => (X(t, (a, i) => {
  r && A(a) ? e[i] = lr(a, r) : e[i] = a;
}, { allOwnKeys: n }), e), Yf = (e) => (e.charCodeAt(0) === 65279 && (e = e.slice(1)), e), Qf = (e, t, r, n) => {
  e.prototype = Object.create(t.prototype, n), e.prototype.constructor = e, Object.defineProperty(e, "super", {
    value: t.prototype
  }), r && Object.assign(e.prototype, r);
}, Zf = (e, t, r, n) => {
  let a, i, s;
  const o = {};
  if (t = t || {}, e == null) return t;
  do {
    for (a = Object.getOwnPropertyNames(e), i = a.length; i-- > 0; )
      s = a[i], (!n || n(s, e, t)) && !o[s] && (t[s] = e[s], o[s] = !0);
    e = r !== !1 && qe(e);
  } while (e && (!r || r(e, t)) && e !== Object.prototype);
  return t;
}, ed = (e, t, r) => {
  e = String(e), (r === void 0 || r > e.length) && (r = e.length), r -= t.length;
  const n = e.indexOf(t, r);
  return n !== -1 && n === r;
}, td = (e) => {
  if (!e) return null;
  if (q(e)) return e;
  let t = e.length;
  if (!dr(t)) return null;
  const r = new Array(t);
  for (; t-- > 0; )
    r[t] = e[t];
  return r;
}, rd = /* @__PURE__ */ ((e) => (t) => e && t instanceof e)(typeof Uint8Array < "u" && qe(Uint8Array)), nd = (e, t) => {
  const n = (e && e[Symbol.iterator]).call(e);
  let a;
  for (; (a = n.next()) && !a.done; ) {
    const i = a.value;
    t.call(e, i[0], i[1]);
  }
}, ad = (e, t) => {
  let r;
  const n = [];
  for (; (r = e.exec(t)) !== null; )
    n.push(r);
  return n;
}, id = C("HTMLFormElement"), sd = (e) => e.toLowerCase().replace(
  /[-_\s]([a-z\d])(\w*)/g,
  function(r, n, a) {
    return n.toUpperCase() + a;
  }
), Ot = (({ hasOwnProperty: e }) => (t, r) => e.call(t, r))(Object.prototype), od = C("RegExp"), mr = (e, t) => {
  const r = Object.getOwnPropertyDescriptors(e), n = {};
  X(r, (a, i) => {
    let s;
    (s = t(a, i, e)) !== !1 && (n[i] = s || a);
  }), Object.defineProperties(e, n);
}, cd = (e) => {
  mr(e, (t, r) => {
    if (A(e) && ["arguments", "caller", "callee"].indexOf(r) !== -1)
      return !1;
    const n = e[r];
    if (A(n)) {
      if (t.enumerable = !1, "writable" in t) {
        t.writable = !1;
        return;
      }
      t.set || (t.set = () => {
        throw Error("Can not rewrite read-only method '" + r + "'");
      });
    }
  });
}, ud = (e, t) => {
  const r = {}, n = (a) => {
    a.forEach((i) => {
      r[i] = !0;
    });
  };
  return q(e) ? n(e) : n(String(e).split(t)), r;
}, ld = () => {
}, fd = (e, t) => (e = +e, Number.isFinite(e) ? e : t), $e = "abcdefghijklmnopqrstuvwxyz", Ct = "0123456789", br = {
  DIGIT: Ct,
  ALPHA: $e,
  ALPHA_DIGIT: $e + $e.toUpperCase() + Ct
}, dd = (e = 16, t = br.ALPHA_DIGIT) => {
  let r = "";
  const { length: n } = t;
  for (; e--; )
    r += t[Math.random() * n | 0];
  return r;
};
function pd(e) {
  return !!(e && A(e.append) && e[Symbol.toStringTag] === "FormData" && e[Symbol.iterator]);
}
const hd = (e) => {
  const t = new Array(10), r = (n, a) => {
    if (be(n)) {
      if (t.indexOf(n) >= 0)
        return;
      if (!("toJSON" in n)) {
        t[a] = n;
        const i = q(n) ? [] : {};
        return X(n, (s, o) => {
          const f = r(s, a + 1);
          !J(f) && (i[o] = f);
        }), t[a] = void 0, i;
      }
    }
    return n;
  };
  return r(e, 0);
}, yd = C("AsyncFunction"), md = (e) => e && (be(e) || A(e)) && A(e.then) && A(e.catch), c = {
  isArray: q,
  isArrayBuffer: fr,
  isBuffer: jf,
  isFormData: Jf,
  isArrayBufferView: Bf,
  isString: Uf,
  isNumber: dr,
  isBoolean: kf,
  isObject: be,
  isPlainObject: re,
  isUndefined: J,
  isDate: Hf,
  isFile: Kf,
  isBlob: qf,
  isRegExp: od,
  isFunction: A,
  isStream: zf,
  isURLSearchParams: Vf,
  isTypedArray: rd,
  isFileList: Gf,
  forEach: X,
  merge: xe,
  extend: Xf,
  trim: Wf,
  stripBOM: Yf,
  inherits: Qf,
  toFlatObject: Zf,
  kindOf: ye,
  kindOfTest: C,
  endsWith: ed,
  toArray: td,
  forEachEntry: nd,
  matchAll: ad,
  isHTMLForm: id,
  hasOwnProperty: Ot,
  hasOwnProp: Ot,
  // an alias to avoid ESLint no-prototype-builtins detection
  reduceDescriptors: mr,
  freezeMethods: cd,
  toObjectSet: ud,
  toCamelCase: sd,
  noop: ld,
  toFiniteNumber: fd,
  findKey: pr,
  global: hr,
  isContextDefined: yr,
  ALPHABET: br,
  generateString: dd,
  isSpecCompliantForm: pd,
  toJSONObject: hd,
  isAsyncFn: yd,
  isThenable: md
};
function m(e, t, r, n, a) {
  Error.call(this), Error.captureStackTrace ? Error.captureStackTrace(this, this.constructor) : this.stack = new Error().stack, this.message = e, this.name = "AxiosError", t && (this.code = t), r && (this.config = r), n && (this.request = n), a && (this.response = a);
}
c.inherits(m, Error, {
  toJSON: function() {
    return {
      // Standard
      message: this.message,
      name: this.name,
      // Microsoft
      description: this.description,
      number: this.number,
      // Mozilla
      fileName: this.fileName,
      lineNumber: this.lineNumber,
      columnNumber: this.columnNumber,
      stack: this.stack,
      // Axios
      config: c.toJSONObject(this.config),
      code: this.code,
      status: this.response && this.response.status ? this.response.status : null
    };
  }
});
const gr = m.prototype, _r = {};
[
  "ERR_BAD_OPTION_VALUE",
  "ERR_BAD_OPTION",
  "ECONNABORTED",
  "ETIMEDOUT",
  "ERR_NETWORK",
  "ERR_FR_TOO_MANY_REDIRECTS",
  "ERR_DEPRECATED",
  "ERR_BAD_RESPONSE",
  "ERR_BAD_REQUEST",
  "ERR_CANCELED",
  "ERR_NOT_SUPPORT",
  "ERR_INVALID_URL"
  // eslint-disable-next-line func-names
].forEach((e) => {
  _r[e] = { value: e };
});
Object.defineProperties(m, _r);
Object.defineProperty(gr, "isAxiosError", { value: !0 });
m.from = (e, t, r, n, a, i) => {
  const s = Object.create(gr);
  return c.toFlatObject(e, s, function(f) {
    return f !== Error.prototype;
  }, (o) => o !== "isAxiosError"), m.call(s, e.message, t, r, n, a), s.cause = e, s.name = e.name, i && Object.assign(s, i), s;
};
const bd = null;
function Ie(e) {
  return c.isPlainObject(e) || c.isArray(e);
}
function vr(e) {
  return c.endsWith(e, "[]") ? e.slice(0, -2) : e;
}
function Rt(e, t, r) {
  return e ? e.concat(t).map(function(a, i) {
    return a = vr(a), !r && i ? "[" + a + "]" : a;
  }).join(r ? "." : "") : t;
}
function gd(e) {
  return c.isArray(e) && !e.some(Ie);
}
const _d = c.toFlatObject(c, {}, null, function(t) {
  return /^is[A-Z]/.test(t);
});
function ge(e, t, r) {
  if (!c.isObject(e))
    throw new TypeError("target must be an object");
  t = t || new FormData(), r = c.toFlatObject(r, {
    metaTokens: !0,
    dots: !1,
    indexes: !1
  }, !1, function(h, b) {
    return !c.isUndefined(b[h]);
  });
  const n = r.metaTokens, a = r.visitor || d, i = r.dots, s = r.indexes, f = (r.Blob || typeof Blob < "u" && Blob) && c.isSpecCompliantForm(t);
  if (!c.isFunction(a))
    throw new TypeError("visitor must be a function");
  function l(p) {
    if (p === null) return "";
    if (c.isDate(p))
      return p.toISOString();
    if (!f && c.isBlob(p))
      throw new m("Blob is not supported. Use a Buffer instead.");
    return c.isArrayBuffer(p) || c.isTypedArray(p) ? f && typeof Blob == "function" ? new Blob([p]) : Buffer.from(p) : p;
  }
  function d(p, h, b) {
    let g = p;
    if (p && !b && typeof p == "object") {
      if (c.endsWith(h, "{}"))
        h = n ? h : h.slice(0, -2), p = JSON.stringify(p);
      else if (c.isArray(p) && gd(p) || (c.isFileList(p) || c.endsWith(h, "[]")) && (g = c.toArray(p)))
        return h = vr(h), g.forEach(function(w, I) {
          !(c.isUndefined(w) || w === null) && t.append(
            // eslint-disable-next-line no-nested-ternary
            s === !0 ? Rt([h], I, i) : s === null ? h : h + "[]",
            l(w)
          );
        }), !1;
    }
    return Ie(p) ? !0 : (t.append(Rt(b, h, i), l(p)), !1);
  }
  const u = [], y = Object.assign(_d, {
    defaultVisitor: d,
    convertValue: l,
    isVisitable: Ie
  });
  function v(p, h) {
    if (!c.isUndefined(p)) {
      if (u.indexOf(p) !== -1)
        throw Error("Circular reference detected in " + h.join("."));
      u.push(p), c.forEach(p, function(g, $) {
        (!(c.isUndefined(g) || g === null) && a.call(
          t,
          g,
          c.isString($) ? $.trim() : $,
          h,
          y
        )) === !0 && v(g, h ? h.concat($) : [$]);
      }), u.pop();
    }
  }
  if (!c.isObject(e))
    throw new TypeError("data must be an object");
  return v(e), t;
}
function Pt(e) {
  const t = {
    "!": "%21",
    "'": "%27",
    "(": "%28",
    ")": "%29",
    "~": "%7E",
    "%20": "+",
    "%00": "\0"
  };
  return encodeURIComponent(e).replace(/[!'()~]|%20|%00/g, function(n) {
    return t[n];
  });
}
function Ge(e, t) {
  this._pairs = [], e && ge(e, this, t);
}
const Sr = Ge.prototype;
Sr.append = function(t, r) {
  this._pairs.push([t, r]);
};
Sr.toString = function(t) {
  const r = t ? function(n) {
    return t.call(this, n, Pt);
  } : Pt;
  return this._pairs.map(function(a) {
    return r(a[0]) + "=" + r(a[1]);
  }, "").join("&");
};
function vd(e) {
  return encodeURIComponent(e).replace(/%3A/gi, ":").replace(/%24/g, "$").replace(/%2C/gi, ",").replace(/%20/g, "+").replace(/%5B/gi, "[").replace(/%5D/gi, "]");
}
function $r(e, t, r) {
  if (!t)
    return e;
  const n = r && r.encode || vd, a = r && r.serialize;
  let i;
  if (a ? i = a(t, r) : i = c.isURLSearchParams(t) ? t.toString() : new Ge(t, r).toString(n), i) {
    const s = e.indexOf("#");
    s !== -1 && (e = e.slice(0, s)), e += (e.indexOf("?") === -1 ? "?" : "&") + i;
  }
  return e;
}
class xt {
  constructor() {
    this.handlers = [];
  }
  /**
   * Add a new interceptor to the stack
   *
   * @param {Function} fulfilled The function to handle `then` for a `Promise`
   * @param {Function} rejected The function to handle `reject` for a `Promise`
   *
   * @return {Number} An ID used to remove interceptor later
   */
  use(t, r, n) {
    return this.handlers.push({
      fulfilled: t,
      rejected: r,
      synchronous: n ? n.synchronous : !1,
      runWhen: n ? n.runWhen : null
    }), this.handlers.length - 1;
  }
  /**
   * Remove an interceptor from the stack
   *
   * @param {Number} id The ID that was returned by `use`
   *
   * @returns {Boolean} `true` if the interceptor was removed, `false` otherwise
   */
  eject(t) {
    this.handlers[t] && (this.handlers[t] = null);
  }
  /**
   * Clear all interceptors from the stack
   *
   * @returns {void}
   */
  clear() {
    this.handlers && (this.handlers = []);
  }
  /**
   * Iterate over all the registered interceptors
   *
   * This method is particularly useful for skipping over any
   * interceptors that may have become `null` calling `eject`.
   *
   * @param {Function} fn The function to call for each interceptor
   *
   * @returns {void}
   */
  forEach(t) {
    c.forEach(this.handlers, function(n) {
      n !== null && t(n);
    });
  }
}
const wr = {
  silentJSONParsing: !0,
  forcedJSONParsing: !0,
  clarifyTimeoutError: !1
}, Sd = typeof URLSearchParams < "u" ? URLSearchParams : Ge, $d = typeof FormData < "u" ? FormData : null, wd = typeof Blob < "u" ? Blob : null, Ad = {
  isBrowser: !0,
  classes: {
    URLSearchParams: Sd,
    FormData: $d,
    Blob: wd
  },
  protocols: ["http", "https", "file", "blob", "url", "data"]
}, Ar = typeof window < "u" && typeof document < "u", Ed = ((e) => Ar && ["ReactNative", "NativeScript", "NS"].indexOf(e) < 0)(typeof navigator < "u" && navigator.product), Td = typeof WorkerGlobalScope < "u" && // eslint-disable-next-line no-undef
self instanceof WorkerGlobalScope && typeof self.importScripts == "function", Od = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  hasBrowserEnv: Ar,
  hasStandardBrowserEnv: Ed,
  hasStandardBrowserWebWorkerEnv: Td
}, Symbol.toStringTag, { value: "Module" })), O = {
  ...Od,
  ...Ad
};
function Cd(e, t) {
  return ge(e, new O.classes.URLSearchParams(), Object.assign({
    visitor: function(r, n, a, i) {
      return O.isNode && c.isBuffer(r) ? (this.append(n, r.toString("base64")), !1) : i.defaultVisitor.apply(this, arguments);
    }
  }, t));
}
function Rd(e) {
  return c.matchAll(/\w+|\[(\w*)]/g, e).map((t) => t[0] === "[]" ? "" : t[1] || t[0]);
}
function Pd(e) {
  const t = {}, r = Object.keys(e);
  let n;
  const a = r.length;
  let i;
  for (n = 0; n < a; n++)
    i = r[n], t[i] = e[i];
  return t;
}
function Er(e) {
  function t(r, n, a, i) {
    let s = r[i++];
    const o = Number.isFinite(+s), f = i >= r.length;
    return s = !s && c.isArray(a) ? a.length : s, f ? (c.hasOwnProp(a, s) ? a[s] = [a[s], n] : a[s] = n, !o) : ((!a[s] || !c.isObject(a[s])) && (a[s] = []), t(r, n, a[s], i) && c.isArray(a[s]) && (a[s] = Pd(a[s])), !o);
  }
  if (c.isFormData(e) && c.isFunction(e.entries)) {
    const r = {};
    return c.forEachEntry(e, (n, a) => {
      t(Rd(n), a, r, 0);
    }), r;
  }
  return null;
}
function xd(e, t, r) {
  if (c.isString(e))
    try {
      return (t || JSON.parse)(e), c.trim(e);
    } catch (n) {
      if (n.name !== "SyntaxError")
        throw n;
    }
  return (0, JSON.stringify)(e);
}
const Y = {
  transitional: wr,
  adapter: ["xhr", "http"],
  transformRequest: [function(t, r) {
    const n = r.getContentType() || "", a = n.indexOf("application/json") > -1, i = c.isObject(t);
    if (i && c.isHTMLForm(t) && (t = new FormData(t)), c.isFormData(t))
      return a && a ? JSON.stringify(Er(t)) : t;
    if (c.isArrayBuffer(t) || c.isBuffer(t) || c.isStream(t) || c.isFile(t) || c.isBlob(t))
      return t;
    if (c.isArrayBufferView(t))
      return t.buffer;
    if (c.isURLSearchParams(t))
      return r.setContentType("application/x-www-form-urlencoded;charset=utf-8", !1), t.toString();
    let o;
    if (i) {
      if (n.indexOf("application/x-www-form-urlencoded") > -1)
        return Cd(t, this.formSerializer).toString();
      if ((o = c.isFileList(t)) || n.indexOf("multipart/form-data") > -1) {
        const f = this.env && this.env.FormData;
        return ge(
          o ? { "files[]": t } : t,
          f && new f(),
          this.formSerializer
        );
      }
    }
    return i || a ? (r.setContentType("application/json", !1), xd(t)) : t;
  }],
  transformResponse: [function(t) {
    const r = this.transitional || Y.transitional, n = r && r.forcedJSONParsing, a = this.responseType === "json";
    if (t && c.isString(t) && (n && !this.responseType || a)) {
      const s = !(r && r.silentJSONParsing) && a;
      try {
        return JSON.parse(t);
      } catch (o) {
        if (s)
          throw o.name === "SyntaxError" ? m.from(o, m.ERR_BAD_RESPONSE, this, null, this.response) : o;
      }
    }
    return t;
  }],
  /**
   * A timeout in milliseconds to abort a request. If set to 0 (default) a
   * timeout is not created.
   */
  timeout: 0,
  xsrfCookieName: "XSRF-TOKEN",
  xsrfHeaderName: "X-XSRF-TOKEN",
  maxContentLength: -1,
  maxBodyLength: -1,
  env: {
    FormData: O.classes.FormData,
    Blob: O.classes.Blob
  },
  validateStatus: function(t) {
    return t >= 200 && t < 300;
  },
  headers: {
    common: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": void 0
    }
  }
};
c.forEach(["delete", "get", "head", "post", "put", "patch"], (e) => {
  Y.headers[e] = {};
});
const Id = c.toObjectSet([
  "age",
  "authorization",
  "content-length",
  "content-type",
  "etag",
  "expires",
  "from",
  "host",
  "if-modified-since",
  "if-unmodified-since",
  "last-modified",
  "location",
  "max-forwards",
  "proxy-authorization",
  "referer",
  "retry-after",
  "user-agent"
]), Nd = (e) => {
  const t = {};
  let r, n, a;
  return e && e.split(`
`).forEach(function(s) {
    a = s.indexOf(":"), r = s.substring(0, a).trim().toLowerCase(), n = s.substring(a + 1).trim(), !(!r || t[r] && Id[r]) && (r === "set-cookie" ? t[r] ? t[r].push(n) : t[r] = [n] : t[r] = t[r] ? t[r] + ", " + n : n);
  }), t;
}, It = Symbol("internals");
function z(e) {
  return e && String(e).trim().toLowerCase();
}
function ne(e) {
  return e === !1 || e == null ? e : c.isArray(e) ? e.map(ne) : String(e);
}
function Dd(e) {
  const t = /* @__PURE__ */ Object.create(null), r = /([^\s,;=]+)\s*(?:=\s*([^,;]+))?/g;
  let n;
  for (; n = r.exec(e); )
    t[n[1]] = n[2];
  return t;
}
const Fd = (e) => /^[-_a-zA-Z0-9^`|~,!#$%&'*+.]+$/.test(e.trim());
function we(e, t, r, n, a) {
  if (c.isFunction(n))
    return n.call(this, t, r);
  if (a && (t = r), !!c.isString(t)) {
    if (c.isString(n))
      return t.indexOf(n) !== -1;
    if (c.isRegExp(n))
      return n.test(t);
  }
}
function Ld(e) {
  return e.trim().toLowerCase().replace(/([a-z\d])(\w*)/g, (t, r, n) => r.toUpperCase() + n);
}
function Md(e, t) {
  const r = c.toCamelCase(" " + t);
  ["get", "set", "has"].forEach((n) => {
    Object.defineProperty(e, n + r, {
      value: function(a, i, s) {
        return this[n].call(this, t, a, i, s);
      },
      configurable: !0
    });
  });
}
class E {
  constructor(t) {
    t && this.set(t);
  }
  set(t, r, n) {
    const a = this;
    function i(o, f, l) {
      const d = z(f);
      if (!d)
        throw new Error("header name must be a non-empty string");
      const u = c.findKey(a, d);
      (!u || a[u] === void 0 || l === !0 || l === void 0 && a[u] !== !1) && (a[u || f] = ne(o));
    }
    const s = (o, f) => c.forEach(o, (l, d) => i(l, d, f));
    return c.isPlainObject(t) || t instanceof this.constructor ? s(t, r) : c.isString(t) && (t = t.trim()) && !Fd(t) ? s(Nd(t), r) : t != null && i(r, t, n), this;
  }
  get(t, r) {
    if (t = z(t), t) {
      const n = c.findKey(this, t);
      if (n) {
        const a = this[n];
        if (!r)
          return a;
        if (r === !0)
          return Dd(a);
        if (c.isFunction(r))
          return r.call(this, a, n);
        if (c.isRegExp(r))
          return r.exec(a);
        throw new TypeError("parser must be boolean|regexp|function");
      }
    }
  }
  has(t, r) {
    if (t = z(t), t) {
      const n = c.findKey(this, t);
      return !!(n && this[n] !== void 0 && (!r || we(this, this[n], n, r)));
    }
    return !1;
  }
  delete(t, r) {
    const n = this;
    let a = !1;
    function i(s) {
      if (s = z(s), s) {
        const o = c.findKey(n, s);
        o && (!r || we(n, n[o], o, r)) && (delete n[o], a = !0);
      }
    }
    return c.isArray(t) ? t.forEach(i) : i(t), a;
  }
  clear(t) {
    const r = Object.keys(this);
    let n = r.length, a = !1;
    for (; n--; ) {
      const i = r[n];
      (!t || we(this, this[i], i, t, !0)) && (delete this[i], a = !0);
    }
    return a;
  }
  normalize(t) {
    const r = this, n = {};
    return c.forEach(this, (a, i) => {
      const s = c.findKey(n, i);
      if (s) {
        r[s] = ne(a), delete r[i];
        return;
      }
      const o = t ? Ld(i) : String(i).trim();
      o !== i && delete r[i], r[o] = ne(a), n[o] = !0;
    }), this;
  }
  concat(...t) {
    return this.constructor.concat(this, ...t);
  }
  toJSON(t) {
    const r = /* @__PURE__ */ Object.create(null);
    return c.forEach(this, (n, a) => {
      n != null && n !== !1 && (r[a] = t && c.isArray(n) ? n.join(", ") : n);
    }), r;
  }
  [Symbol.iterator]() {
    return Object.entries(this.toJSON())[Symbol.iterator]();
  }
  toString() {
    return Object.entries(this.toJSON()).map(([t, r]) => t + ": " + r).join(`
`);
  }
  get [Symbol.toStringTag]() {
    return "AxiosHeaders";
  }
  static from(t) {
    return t instanceof this ? t : new this(t);
  }
  static concat(t, ...r) {
    const n = new this(t);
    return r.forEach((a) => n.set(a)), n;
  }
  static accessor(t) {
    const n = (this[It] = this[It] = {
      accessors: {}
    }).accessors, a = this.prototype;
    function i(s) {
      const o = z(s);
      n[o] || (Md(a, s), n[o] = !0);
    }
    return c.isArray(t) ? t.forEach(i) : i(t), this;
  }
}
E.accessor(["Content-Type", "Content-Length", "Accept", "Accept-Encoding", "User-Agent", "Authorization"]);
c.reduceDescriptors(E.prototype, ({ value: e }, t) => {
  let r = t[0].toUpperCase() + t.slice(1);
  return {
    get: () => e,
    set(n) {
      this[r] = n;
    }
  };
});
c.freezeMethods(E);
function Ae(e, t) {
  const r = this || Y, n = t || r, a = E.from(n.headers);
  let i = n.data;
  return c.forEach(e, function(o) {
    i = o.call(r, i, a.normalize(), t ? t.status : void 0);
  }), a.normalize(), i;
}
function Tr(e) {
  return !!(e && e.__CANCEL__);
}
function Q(e, t, r) {
  m.call(this, e ?? "canceled", m.ERR_CANCELED, t, r), this.name = "CanceledError";
}
c.inherits(Q, m, {
  __CANCEL__: !0
});
function jd(e, t, r) {
  const n = r.config.validateStatus;
  !r.status || !n || n(r.status) ? e(r) : t(new m(
    "Request failed with status code " + r.status,
    [m.ERR_BAD_REQUEST, m.ERR_BAD_RESPONSE][Math.floor(r.status / 100) - 4],
    r.config,
    r.request,
    r
  ));
}
const Bd = O.hasStandardBrowserEnv ? (
  // Standard browser envs support document.cookie
  {
    write(e, t, r, n, a, i) {
      const s = [e + "=" + encodeURIComponent(t)];
      c.isNumber(r) && s.push("expires=" + new Date(r).toGMTString()), c.isString(n) && s.push("path=" + n), c.isString(a) && s.push("domain=" + a), i === !0 && s.push("secure"), document.cookie = s.join("; ");
    },
    read(e) {
      const t = document.cookie.match(new RegExp("(^|;\\s*)(" + e + ")=([^;]*)"));
      return t ? decodeURIComponent(t[3]) : null;
    },
    remove(e) {
      this.write(e, "", Date.now() - 864e5);
    }
  }
) : (
  // Non-standard browser env (web workers, react-native) lack needed support.
  {
    write() {
    },
    read() {
      return null;
    },
    remove() {
    }
  }
);
function Ud(e) {
  return /^([a-z][a-z\d+\-.]*:)?\/\//i.test(e);
}
function kd(e, t) {
  return t ? e.replace(/\/+$/, "") + "/" + t.replace(/^\/+/, "") : e;
}
function Or(e, t) {
  return e && !Ud(t) ? kd(e, t) : t;
}
const Hd = O.hasStandardBrowserEnv ? (
  // Standard browser envs have full support of the APIs needed to test
  // whether the request URL is of the same origin as current location.
  function() {
    const t = /(msie|trident)/i.test(navigator.userAgent), r = document.createElement("a");
    let n;
    function a(i) {
      let s = i;
      return t && (r.setAttribute("href", s), s = r.href), r.setAttribute("href", s), {
        href: r.href,
        protocol: r.protocol ? r.protocol.replace(/:$/, "") : "",
        host: r.host,
        search: r.search ? r.search.replace(/^\?/, "") : "",
        hash: r.hash ? r.hash.replace(/^#/, "") : "",
        hostname: r.hostname,
        port: r.port,
        pathname: r.pathname.charAt(0) === "/" ? r.pathname : "/" + r.pathname
      };
    }
    return n = a(window.location.href), function(s) {
      const o = c.isString(s) ? a(s) : s;
      return o.protocol === n.protocol && o.host === n.host;
    };
  }()
) : (
  // Non standard browser envs (web workers, react-native) lack needed support.
  /* @__PURE__ */ function() {
    return function() {
      return !0;
    };
  }()
);
function Kd(e) {
  const t = /^([-+\w]{1,25})(:?\/\/|:)/.exec(e);
  return t && t[1] || "";
}
function qd(e, t) {
  e = e || 10;
  const r = new Array(e), n = new Array(e);
  let a = 0, i = 0, s;
  return t = t !== void 0 ? t : 1e3, function(f) {
    const l = Date.now(), d = n[i];
    s || (s = l), r[a] = f, n[a] = l;
    let u = i, y = 0;
    for (; u !== a; )
      y += r[u++], u = u % e;
    if (a = (a + 1) % e, a === i && (i = (i + 1) % e), l - s < t)
      return;
    const v = d && l - d;
    return v ? Math.round(y * 1e3 / v) : void 0;
  };
}
function Nt(e, t) {
  let r = 0;
  const n = qd(50, 250);
  return (a) => {
    const i = a.loaded, s = a.lengthComputable ? a.total : void 0, o = i - r, f = n(o), l = i <= s;
    r = i;
    const d = {
      loaded: i,
      total: s,
      progress: s ? i / s : void 0,
      bytes: o,
      rate: f || void 0,
      estimated: f && s && l ? (s - i) / f : void 0,
      event: a
    };
    d[t ? "download" : "upload"] = !0, e(d);
  };
}
const Gd = typeof XMLHttpRequest < "u", zd = Gd && function(e) {
  return new Promise(function(r, n) {
    let a = e.data;
    const i = E.from(e.headers).normalize();
    let { responseType: s, withXSRFToken: o } = e, f;
    function l() {
      e.cancelToken && e.cancelToken.unsubscribe(f), e.signal && e.signal.removeEventListener("abort", f);
    }
    let d;
    if (c.isFormData(a)) {
      if (O.hasStandardBrowserEnv || O.hasStandardBrowserWebWorkerEnv)
        i.setContentType(!1);
      else if ((d = i.getContentType()) !== !1) {
        const [h, ...b] = d ? d.split(";").map((g) => g.trim()).filter(Boolean) : [];
        i.setContentType([h || "multipart/form-data", ...b].join("; "));
      }
    }
    let u = new XMLHttpRequest();
    if (e.auth) {
      const h = e.auth.username || "", b = e.auth.password ? unescape(encodeURIComponent(e.auth.password)) : "";
      i.set("Authorization", "Basic " + btoa(h + ":" + b));
    }
    const y = Or(e.baseURL, e.url);
    u.open(e.method.toUpperCase(), $r(y, e.params, e.paramsSerializer), !0), u.timeout = e.timeout;
    function v() {
      if (!u)
        return;
      const h = E.from(
        "getAllResponseHeaders" in u && u.getAllResponseHeaders()
      ), g = {
        data: !s || s === "text" || s === "json" ? u.responseText : u.response,
        status: u.status,
        statusText: u.statusText,
        headers: h,
        config: e,
        request: u
      };
      jd(function(w) {
        r(w), l();
      }, function(w) {
        n(w), l();
      }, g), u = null;
    }
    if ("onloadend" in u ? u.onloadend = v : u.onreadystatechange = function() {
      !u || u.readyState !== 4 || u.status === 0 && !(u.responseURL && u.responseURL.indexOf("file:") === 0) || setTimeout(v);
    }, u.onabort = function() {
      u && (n(new m("Request aborted", m.ECONNABORTED, e, u)), u = null);
    }, u.onerror = function() {
      n(new m("Network Error", m.ERR_NETWORK, e, u)), u = null;
    }, u.ontimeout = function() {
      let b = e.timeout ? "timeout of " + e.timeout + "ms exceeded" : "timeout exceeded";
      const g = e.transitional || wr;
      e.timeoutErrorMessage && (b = e.timeoutErrorMessage), n(new m(
        b,
        g.clarifyTimeoutError ? m.ETIMEDOUT : m.ECONNABORTED,
        e,
        u
      )), u = null;
    }, O.hasStandardBrowserEnv && (o && c.isFunction(o) && (o = o(e)), o || o !== !1 && Hd(y))) {
      const h = e.xsrfHeaderName && e.xsrfCookieName && Bd.read(e.xsrfCookieName);
      h && i.set(e.xsrfHeaderName, h);
    }
    a === void 0 && i.setContentType(null), "setRequestHeader" in u && c.forEach(i.toJSON(), function(b, g) {
      u.setRequestHeader(g, b);
    }), c.isUndefined(e.withCredentials) || (u.withCredentials = !!e.withCredentials), s && s !== "json" && (u.responseType = e.responseType), typeof e.onDownloadProgress == "function" && u.addEventListener("progress", Nt(e.onDownloadProgress, !0)), typeof e.onUploadProgress == "function" && u.upload && u.upload.addEventListener("progress", Nt(e.onUploadProgress)), (e.cancelToken || e.signal) && (f = (h) => {
      u && (n(!h || h.type ? new Q(null, e, u) : h), u.abort(), u = null);
    }, e.cancelToken && e.cancelToken.subscribe(f), e.signal && (e.signal.aborted ? f() : e.signal.addEventListener("abort", f)));
    const p = Kd(y);
    if (p && O.protocols.indexOf(p) === -1) {
      n(new m("Unsupported protocol " + p + ":", m.ERR_BAD_REQUEST, e));
      return;
    }
    u.send(a || null);
  });
}, Ne = {
  http: bd,
  xhr: zd
};
c.forEach(Ne, (e, t) => {
  if (e) {
    try {
      Object.defineProperty(e, "name", { value: t });
    } catch {
    }
    Object.defineProperty(e, "adapterName", { value: t });
  }
});
const Dt = (e) => `- ${e}`, Jd = (e) => c.isFunction(e) || e === null || e === !1, Cr = {
  getAdapter: (e) => {
    e = c.isArray(e) ? e : [e];
    const { length: t } = e;
    let r, n;
    const a = {};
    for (let i = 0; i < t; i++) {
      r = e[i];
      let s;
      if (n = r, !Jd(r) && (n = Ne[(s = String(r)).toLowerCase()], n === void 0))
        throw new m(`Unknown adapter '${s}'`);
      if (n)
        break;
      a[s || "#" + i] = n;
    }
    if (!n) {
      const i = Object.entries(a).map(
        ([o, f]) => `adapter ${o} ` + (f === !1 ? "is not supported by the environment" : "is not available in the build")
      );
      let s = t ? i.length > 1 ? `since :
` + i.map(Dt).join(`
`) : " " + Dt(i[0]) : "as no adapter specified";
      throw new m(
        "There is no suitable adapter to dispatch the request " + s,
        "ERR_NOT_SUPPORT"
      );
    }
    return n;
  },
  adapters: Ne
};
function Ee(e) {
  if (e.cancelToken && e.cancelToken.throwIfRequested(), e.signal && e.signal.aborted)
    throw new Q(null, e);
}
function Ft(e) {
  return Ee(e), e.headers = E.from(e.headers), e.data = Ae.call(
    e,
    e.transformRequest
  ), ["post", "put", "patch"].indexOf(e.method) !== -1 && e.headers.setContentType("application/x-www-form-urlencoded", !1), Cr.getAdapter(e.adapter || Y.adapter)(e).then(function(n) {
    return Ee(e), n.data = Ae.call(
      e,
      e.transformResponse,
      n
    ), n.headers = E.from(n.headers), n;
  }, function(n) {
    return Tr(n) || (Ee(e), n && n.response && (n.response.data = Ae.call(
      e,
      e.transformResponse,
      n.response
    ), n.response.headers = E.from(n.response.headers))), Promise.reject(n);
  });
}
const Lt = (e) => e instanceof E ? e.toJSON() : e;
function M(e, t) {
  t = t || {};
  const r = {};
  function n(l, d, u) {
    return c.isPlainObject(l) && c.isPlainObject(d) ? c.merge.call({ caseless: u }, l, d) : c.isPlainObject(d) ? c.merge({}, d) : c.isArray(d) ? d.slice() : d;
  }
  function a(l, d, u) {
    if (c.isUndefined(d)) {
      if (!c.isUndefined(l))
        return n(void 0, l, u);
    } else return n(l, d, u);
  }
  function i(l, d) {
    if (!c.isUndefined(d))
      return n(void 0, d);
  }
  function s(l, d) {
    if (c.isUndefined(d)) {
      if (!c.isUndefined(l))
        return n(void 0, l);
    } else return n(void 0, d);
  }
  function o(l, d, u) {
    if (u in t)
      return n(l, d);
    if (u in e)
      return n(void 0, l);
  }
  const f = {
    url: i,
    method: i,
    data: i,
    baseURL: s,
    transformRequest: s,
    transformResponse: s,
    paramsSerializer: s,
    timeout: s,
    timeoutMessage: s,
    withCredentials: s,
    withXSRFToken: s,
    adapter: s,
    responseType: s,
    xsrfCookieName: s,
    xsrfHeaderName: s,
    onUploadProgress: s,
    onDownloadProgress: s,
    decompress: s,
    maxContentLength: s,
    maxBodyLength: s,
    beforeRedirect: s,
    transport: s,
    httpAgent: s,
    httpsAgent: s,
    cancelToken: s,
    socketPath: s,
    responseEncoding: s,
    validateStatus: o,
    headers: (l, d) => a(Lt(l), Lt(d), !0)
  };
  return c.forEach(Object.keys(Object.assign({}, e, t)), function(d) {
    const u = f[d] || a, y = u(e[d], t[d], d);
    c.isUndefined(y) && u !== o || (r[d] = y);
  }), r;
}
const Rr = "1.6.2", ze = {};
["object", "boolean", "number", "function", "string", "symbol"].forEach((e, t) => {
  ze[e] = function(n) {
    return typeof n === e || "a" + (t < 1 ? "n " : " ") + e;
  };
});
const Mt = {};
ze.transitional = function(t, r, n) {
  function a(i, s) {
    return "[Axios v" + Rr + "] Transitional option '" + i + "'" + s + (n ? ". " + n : "");
  }
  return (i, s, o) => {
    if (t === !1)
      throw new m(
        a(s, " has been removed" + (r ? " in " + r : "")),
        m.ERR_DEPRECATED
      );
    return r && !Mt[s] && (Mt[s] = !0, console.warn(
      a(
        s,
        " has been deprecated since v" + r + " and will be removed in the near future"
      )
    )), t ? t(i, s, o) : !0;
  };
};
function Vd(e, t, r) {
  if (typeof e != "object")
    throw new m("options must be an object", m.ERR_BAD_OPTION_VALUE);
  const n = Object.keys(e);
  let a = n.length;
  for (; a-- > 0; ) {
    const i = n[a], s = t[i];
    if (s) {
      const o = e[i], f = o === void 0 || s(o, i, e);
      if (f !== !0)
        throw new m("option " + i + " must be " + f, m.ERR_BAD_OPTION_VALUE);
      continue;
    }
    if (r !== !0)
      throw new m("Unknown option " + i, m.ERR_BAD_OPTION);
  }
}
const De = {
  assertOptions: Vd,
  validators: ze
}, N = De.validators;
class L {
  constructor(t) {
    this.defaults = t, this.interceptors = {
      request: new xt(),
      response: new xt()
    };
  }
  /**
   * Dispatch a request
   *
   * @param {String|Object} configOrUrl The config specific for this request (merged with this.defaults)
   * @param {?Object} config
   *
   * @returns {Promise} The Promise to be fulfilled
   */
  request(t, r) {
    typeof t == "string" ? (r = r || {}, r.url = t) : r = t || {}, r = M(this.defaults, r);
    const { transitional: n, paramsSerializer: a, headers: i } = r;
    n !== void 0 && De.assertOptions(n, {
      silentJSONParsing: N.transitional(N.boolean),
      forcedJSONParsing: N.transitional(N.boolean),
      clarifyTimeoutError: N.transitional(N.boolean)
    }, !1), a != null && (c.isFunction(a) ? r.paramsSerializer = {
      serialize: a
    } : De.assertOptions(a, {
      encode: N.function,
      serialize: N.function
    }, !0)), r.method = (r.method || this.defaults.method || "get").toLowerCase();
    let s = i && c.merge(
      i.common,
      i[r.method]
    );
    i && c.forEach(
      ["delete", "get", "head", "post", "put", "patch", "common"],
      (p) => {
        delete i[p];
      }
    ), r.headers = E.concat(s, i);
    const o = [];
    let f = !0;
    this.interceptors.request.forEach(function(h) {
      typeof h.runWhen == "function" && h.runWhen(r) === !1 || (f = f && h.synchronous, o.unshift(h.fulfilled, h.rejected));
    });
    const l = [];
    this.interceptors.response.forEach(function(h) {
      l.push(h.fulfilled, h.rejected);
    });
    let d, u = 0, y;
    if (!f) {
      const p = [Ft.bind(this), void 0];
      for (p.unshift.apply(p, o), p.push.apply(p, l), y = p.length, d = Promise.resolve(r); u < y; )
        d = d.then(p[u++], p[u++]);
      return d;
    }
    y = o.length;
    let v = r;
    for (u = 0; u < y; ) {
      const p = o[u++], h = o[u++];
      try {
        v = p(v);
      } catch (b) {
        h.call(this, b);
        break;
      }
    }
    try {
      d = Ft.call(this, v);
    } catch (p) {
      return Promise.reject(p);
    }
    for (u = 0, y = l.length; u < y; )
      d = d.then(l[u++], l[u++]);
    return d;
  }
  getUri(t) {
    t = M(this.defaults, t);
    const r = Or(t.baseURL, t.url);
    return $r(r, t.params, t.paramsSerializer);
  }
}
c.forEach(["delete", "get", "head", "options"], function(t) {
  L.prototype[t] = function(r, n) {
    return this.request(M(n || {}, {
      method: t,
      url: r,
      data: (n || {}).data
    }));
  };
});
c.forEach(["post", "put", "patch"], function(t) {
  function r(n) {
    return function(i, s, o) {
      return this.request(M(o || {}, {
        method: t,
        headers: n ? {
          "Content-Type": "multipart/form-data"
        } : {},
        url: i,
        data: s
      }));
    };
  }
  L.prototype[t] = r(), L.prototype[t + "Form"] = r(!0);
});
class Je {
  constructor(t) {
    if (typeof t != "function")
      throw new TypeError("executor must be a function.");
    let r;
    this.promise = new Promise(function(i) {
      r = i;
    });
    const n = this;
    this.promise.then((a) => {
      if (!n._listeners) return;
      let i = n._listeners.length;
      for (; i-- > 0; )
        n._listeners[i](a);
      n._listeners = null;
    }), this.promise.then = (a) => {
      let i;
      const s = new Promise((o) => {
        n.subscribe(o), i = o;
      }).then(a);
      return s.cancel = function() {
        n.unsubscribe(i);
      }, s;
    }, t(function(i, s, o) {
      n.reason || (n.reason = new Q(i, s, o), r(n.reason));
    });
  }
  /**
   * Throws a `CanceledError` if cancellation has been requested.
   */
  throwIfRequested() {
    if (this.reason)
      throw this.reason;
  }
  /**
   * Subscribe to the cancel signal
   */
  subscribe(t) {
    if (this.reason) {
      t(this.reason);
      return;
    }
    this._listeners ? this._listeners.push(t) : this._listeners = [t];
  }
  /**
   * Unsubscribe from the cancel signal
   */
  unsubscribe(t) {
    if (!this._listeners)
      return;
    const r = this._listeners.indexOf(t);
    r !== -1 && this._listeners.splice(r, 1);
  }
  /**
   * Returns an object that contains a new `CancelToken` and a function that, when called,
   * cancels the `CancelToken`.
   */
  static source() {
    let t;
    return {
      token: new Je(function(a) {
        t = a;
      }),
      cancel: t
    };
  }
}
function Wd(e) {
  return function(r) {
    return e.apply(null, r);
  };
}
function Xd(e) {
  return c.isObject(e) && e.isAxiosError === !0;
}
const Fe = {
  Continue: 100,
  SwitchingProtocols: 101,
  Processing: 102,
  EarlyHints: 103,
  Ok: 200,
  Created: 201,
  Accepted: 202,
  NonAuthoritativeInformation: 203,
  NoContent: 204,
  ResetContent: 205,
  PartialContent: 206,
  MultiStatus: 207,
  AlreadyReported: 208,
  ImUsed: 226,
  MultipleChoices: 300,
  MovedPermanently: 301,
  Found: 302,
  SeeOther: 303,
  NotModified: 304,
  UseProxy: 305,
  Unused: 306,
  TemporaryRedirect: 307,
  PermanentRedirect: 308,
  BadRequest: 400,
  Unauthorized: 401,
  PaymentRequired: 402,
  Forbidden: 403,
  NotFound: 404,
  MethodNotAllowed: 405,
  NotAcceptable: 406,
  ProxyAuthenticationRequired: 407,
  RequestTimeout: 408,
  Conflict: 409,
  Gone: 410,
  LengthRequired: 411,
  PreconditionFailed: 412,
  PayloadTooLarge: 413,
  UriTooLong: 414,
  UnsupportedMediaType: 415,
  RangeNotSatisfiable: 416,
  ExpectationFailed: 417,
  ImATeapot: 418,
  MisdirectedRequest: 421,
  UnprocessableEntity: 422,
  Locked: 423,
  FailedDependency: 424,
  TooEarly: 425,
  UpgradeRequired: 426,
  PreconditionRequired: 428,
  TooManyRequests: 429,
  RequestHeaderFieldsTooLarge: 431,
  UnavailableForLegalReasons: 451,
  InternalServerError: 500,
  NotImplemented: 501,
  BadGateway: 502,
  ServiceUnavailable: 503,
  GatewayTimeout: 504,
  HttpVersionNotSupported: 505,
  VariantAlsoNegotiates: 506,
  InsufficientStorage: 507,
  LoopDetected: 508,
  NotExtended: 510,
  NetworkAuthenticationRequired: 511
};
Object.entries(Fe).forEach(([e, t]) => {
  Fe[t] = e;
});
function Pr(e) {
  const t = new L(e), r = lr(L.prototype.request, t);
  return c.extend(r, L.prototype, t, { allOwnKeys: !0 }), c.extend(r, t, null, { allOwnKeys: !0 }), r.create = function(a) {
    return Pr(M(e, a));
  }, r;
}
const S = Pr(Y);
S.Axios = L;
S.CanceledError = Q;
S.CancelToken = Je;
S.isCancel = Tr;
S.VERSION = Rr;
S.toFormData = ge;
S.AxiosError = m;
S.Cancel = S.CanceledError;
S.all = function(t) {
  return Promise.all(t);
};
S.spread = Wd;
S.isAxiosError = Xd;
S.mergeConfig = M;
S.AxiosHeaders = E;
S.formToJSON = (e) => Er(c.isHTMLForm(e) ? new FormData(e) : e);
S.getAdapter = Cr.getAdapter;
S.HttpStatusCode = Fe;
S.default = S;
const Yd = async (e, t) => {
  const r = t ? {
    script: "839",
    deploy: "1",
    compid: "6085217_SB2",
    "ns-at": "AAEJ7tMQPl7057sa1dN3kFpPT0a1p8RT6uv5M2MO0bxysKA80bQ"
  } : {
    script: "839",
    deploy: "1",
    compid: "6085217",
    "ns-at": "AAEJ7tMQ6oSsvIcKywdrZ3RWqCXRFhS7x5--jjWErXEXw_WiYfc"
  }, n = "s7M6k2g8Ca9Rf3gQpxUm", a = new URLSearchParams(r), i = S.create({
    baseURL: t ? "https://6085217-sb2.extforms.netsuite.com" : "https://6085217.extforms.netsuite.com"
  }), s = `/app/site/hosting/scriptlet.nl?${a.toString()}`, {
    companyName: o = "無",
    email: f = "",
    contactName: l = "無",
    contactJobTitle: d = "無",
    contactPhone: u = "無",
    category: y = "無",
    country: v = "無",
    currency: p = "無",
    budget: h = "無",
    note: b = "無",
    schedule: g = "無",
    source: $,
    otherInfo: w = "無"
  } = e, I = {
    apikey: n,
    IKALA_BU: "KOL Radar",
    ExternalID: `${(/* @__PURE__ */ new Date()).toISOString()}-${Math.ceil(
      Math.random() * 1e4
    )}`,
    // 外部資料庫的 id，因為我們用不到，但又是必填，所以亂數產一組無意義的數字，目的是不要重複，不然 net suite 會回傳錯誤
    Name: o,
    Company_Name: o,
    Contact_Email: f,
    Email: f,
    Contact_Name: l,
    Contact_Job_Title: d,
    Contact_Phone: u,
    Category: Ff[y].netSuiteValue,
    Country: v,
    Primary_Currency: p,
    LS_Estimated_Budget: h,
    LS_Require_Detail: b,
    LS_Time_Frame: g,
    LS_Lead_Source: $,
    LS_Name: w
  };
  return await i.post(s, I, {
    headers: {
      "content-type": "text/plain;charset=UTF-8",
      "User-Agent": "Mozilla/5.0"
      // API 規定的 User-Agent
    }
  });
}, ap = { create: Yd }, ip = (e) => {
  if (e !== void 0)
    return Lf[e] ?? e;
};
export {
  kc as A,
  W as B,
  Co as C,
  Oo as D,
  qt as E,
  Vt as F,
  pc as G,
  D as H,
  pe as I,
  cr as J,
  tp as K,
  R as L,
  Df as M,
  ur as P,
  B as _,
  Ff as a,
  ee as b,
  np as c,
  jt as d,
  Ve as e,
  rp as f,
  ip as g,
  Zd as h,
  Bt as i,
  le as j,
  Be as k,
  qo as l,
  ep as m,
  ap as n,
  Lf as o,
  T as p,
  ko as q,
  Yt as r,
  x as s,
  Ps as t,
  zo as u,
  ys as v,
  As as w,
  vs as x,
  qi as y,
  ue as z
};

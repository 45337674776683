import { Button, Col, Modal, Row } from 'antd'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FunctionComponent, ReactNode } from 'react'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'
import VipButton from '@/components/vip-button'
import routes from '@/hooks/use-authorization/routes'
import { useSelector } from '@/hooks/use-selector'
import useTrial from '@/hooks/use-trial'
import { I18nId } from '@/i18n/config'
import { useIntl } from '@/i18n/hooks/use-intl'
import {
  ampli,
  ClickUpgradePlanProperties,
  ClickWorkspacePlanProperties,
} from '@/utils/ampli'

/**
 * @todo extend ModalProps
 */
interface VipOnlyModalProps {
  /**
   * @todo change to required
   */
  section?: ClickUpgradePlanProperties['section']
  visible: boolean
  onClose: VoidFunction
  title?: ReactNode
  isEnterprise?: boolean
  onlyQuota?: boolean
  canContinueBrowsingFromProp?: boolean
  source: ClickWorkspacePlanProperties['clickPlanSource']
  upgradeButtonLabelI18nId?: I18nId
  upgradeButtonLink?: string
}

export const VipOnlyModal: FunctionComponent<VipOnlyModalProps> = ({
  section,
  visible,
  onClose,
  title,
  isEnterprise,
  onlyQuota = false,
  canContinueBrowsingFromProp,
  source,
  upgradeButtonLabelI18nId,
  upgradeButtonLink = routes.workspacePlan.stringifyUrl({}),
}) => {
  const router = useRouter()

  const { formatMessage } = useIntl()
  const { trial } = useTrial()
  const { canContinueBrowsing: canContinueBrowsingFromRedux } = useSelector(
    (state) => state.modal.vipOnly,
  )
  const canContinueBrowsing =
    canContinueBrowsingFromProp ?? canContinueBrowsingFromRedux

  const handleClose = (): void => {
    onClose()
  }

  useUpdateEffect(() => {
    if (visible) {
      ampli.blockedVipOnly()
    }
  }, [visible])

  return (
    <Modal
      closable={canContinueBrowsing}
      footer={null}
      open={visible}
      onCancel={handleClose}
    >
      <>
        <Row justify='center'>
          <Col>
            {title ? (
              <Title>{title}</Title>
            ) : (
              <Title>
                {formatMessage({
                  id: isEnterprise
                    ? 'vip:free_popup_enterprise_only_title'
                    : 'search:free_popup_vip_only_title',
                })}
              </Title>
            )}
          </Col>
        </Row>
        <Row gutter={24} justify='center'>
          <StyledCol>
            {trial.length === 0 && !canContinueBrowsing ? (
              <Link
                href={routes.workspacePlan.stringifyUrl({})}
                onClick={(): void => {
                  handleClose()
                  ampli.clickWorkspacePlan({
                    path: router.asPath,
                    clickPlanSource: source,
                  })
                }}
              >
                <Button>
                  {formatMessage({ id: 'account:apply_premium_trial' })}
                </Button>
              </Link>
            ) : (
              <Button onClick={handleClose}>
                {formatMessage({ id: 'search:free_popop_vip_only_read_btn' })}
              </Button>
            )}
          </StyledCol>
          <Col>
            <Link
              href={upgradeButtonLink}
              onClick={(): void => {
                handleClose()
                ampli.clickWorkspacePlan({
                  path: router.asPath,
                  clickPlanSource: source,
                })
              }}
            >
              <VipButton section={section}>
                {((): string => {
                  if (upgradeButtonLabelI18nId) {
                    return formatMessage({ id: upgradeButtonLabelI18nId })
                  }

                  return onlyQuota
                    ? formatMessage({ id: 'kol:btn_go_to_purchase' })
                    : formatMessage({ id: 'vip:upgrade_plan_btn' })
                })()}
              </VipButton>
            </Link>
          </Col>
        </Row>
      </>
    </Modal>
  )
}

const Title = styled.div`
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 30px;
  font-weight: bold;
  color: #606266;
  text-align: center;
`

const StyledCol = styled(Col)`
  margin-bottom: 8px;
`

import Image from 'next/image'
import React, { FunctionComponent } from 'react'
import { useFetchUserStatusQuery } from '@/api/user-api'
import kolrLogo from '@/public/assets/kolr-logo/kolr-logo-horizontal.png'

const DEFAULT_WIDTH = 107
const DEFAULT_HEIGHT = 40
const ASPECT_RATIO = DEFAULT_WIDTH / DEFAULT_HEIGHT

interface LogoProps {
  width?: number
  height?: number
}

const calculateDimension = ({
  width,
  height,
}: LogoProps): { width: number; height: number } => {
  if (width) {
    return {
      width,
      height: height ?? Math.round(width / ASPECT_RATIO),
    }
  }

  if (height) {
    return {
      width: Math.round(height * ASPECT_RATIO),
      height,
    }
  }

  return {
    width: DEFAULT_WIDTH,
    height: DEFAULT_HEIGHT,
  }
}

const LogoHorizontal: FunctionComponent<LogoProps> = ({ width, height }) => {
  const dimension = calculateDimension({ width, height })
  const { data: userStatus } = useFetchUserStatusQuery()

  return (
    <Image
      alt='logo'
      height={dimension.height}
      src={
        userStatus?.currentWorkspace.enterpriseIdentity?.brandLogoUrl ??
        kolrLogo
      }
      width={dimension.width}
    />
  )
}

export default LogoHorizontal

import { CheckOutlined } from '@ant-design/icons'
import { Select, Tag } from 'antd'
import flow from 'lodash-es/flow'
import React, {
  type FunctionComponent,
  type ReactElement,
  type ReactNode,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react'
import Highlighter from 'react-highlight-words'
import styled, { useTheme } from 'styled-components'
import { useFetchCustomizedTagListQuery } from '@/api/customized-tag-api'
import useCustomizedTag from '@/hooks/use-customized-tag'
import { useThemeConstants } from '@/hooks/use-theme-constants'
import { useIntl } from '@/i18n/hooks/use-intl'

interface CustomizedTagSearchProps {
  onValueChange?: (selectedTags: string[]) => void
  onSearchChange?: (value: string) => void
  value?: string[]
}

// 只處理兩層結構的標籤，未來如果有多層的話，可以參考 TreeSelect 的實作, ref: https://ant.design/components/tree-select
const CustomizedTagSearch: FunctionComponent<CustomizedTagSearchProps> = ({
  onValueChange,
  onSearchChange,
  value = [],
}): ReactNode => {
  const theme = useTheme()
  const { highlightStyle } = useThemeConstants()
  const [selectedTags, setSelectedTags] = useState<string[]>(value)
  const [searchWord, setSearchWord] = useState<string>('')
  const { searchCustomizedTagNames, getCustomizedTagLocalizedName } =
    useCustomizedTag()
  const { data: customizedTags = [], isFetching: isCustomizedTagsFetching } =
    useFetchCustomizedTagListQuery()
  const { formatMessage } = useIntl()
  const stringifyValues = JSON.stringify(value)

  const result = useMemo(
    () =>
      flow(searchCustomizedTagNames, (searchedTags) =>
        searchedTags.map((tag) => ({
          value: tag,
          label: getCustomizedTagLocalizedName(tag),
        })),
      )(searchWord),
    [searchWord, searchCustomizedTagNames, getCustomizedTagLocalizedName],
  )

  // 因為選單的部分完全客製化，所以需要額外處理鍵盤事件
  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent): void => {
      // 排除空白鍵行為避免 popover 被關閉
      if (e.key === ' ') {
        e.preventDefault()

        return
      }

      // backspace 需要刪除選取的標籤
      if (e.key === 'Backspace' && !searchWord) {
        const newTags = selectedTags.slice(0, -1)
        setSelectedTags(newTags)
        onValueChange?.(newTags)
      }
    },
    [onValueChange, searchWord, selectedTags],
  )

  const handleSearch = useCallback(
    (value: string): void => {
      setSearchWord(value)
      onSearchChange?.(value)
    },
    [onSearchChange],
  )

  const handleOptionClick = useCallback(
    (value: string): void => {
      const tagCategory = value.split('/')[0]

      // 已選中的標籤再選一次則取消選擇
      if (selectedTags.includes(value)) {
        const newTags = selectedTags.filter((tag) => tag !== value)
        setSelectedTags(newTags)
        onValueChange?.(newTags)
        return
      }

      // 選擇父標籤時，移除已選中的子標籤
      if (value.split('/').length === 1) {
        const newTags = selectedTags.filter((tag) => !tag.startsWith(value))
        setSelectedTags([...newTags, value])
        onValueChange?.([...newTags, value])
        return
      }

      // 選擇子標籤時，如果父標籤已選中則移除父標籤並選中所有其他子標籤
      if (value.split('/').length > 1 && selectedTags.includes(tagCategory)) {
        const newTags = selectedTags.filter((tag) => tag !== tagCategory)
        const children =
          customizedTags
            .find((tag) => tag.customized_tag_name === tagCategory)
            ?.sub_customized_tag_names.map(
              (subTag) => subTag.customized_tag_name,
            )
            .filter((subTag) => subTag !== value) ?? []

        setSelectedTags([...newTags, ...children])
        onValueChange?.([...newTags, ...children])
        return
      }

      // 選中所有子標籤後自動選中父標籤並移除子標籤
      const parent = value.split('/')[0]
      const children =
        customizedTags
          .find((tag) => tag.customized_tag_name === parent)
          ?.sub_customized_tag_names.map(
            (subTag) => subTag.customized_tag_name,
          ) ?? []

      if (children.every((child) => [...selectedTags, value].includes(child))) {
        const newTags = selectedTags.filter(
          (tag) => ![...children, value].includes(tag),
        )
        setSelectedTags([...newTags, parent])
        onValueChange?.([...newTags, parent])
        return
      }

      const newTags = [...selectedTags, value]
      setSelectedTags(newTags)
      onValueChange?.(newTags)
    },
    [selectedTags, customizedTags, onValueChange],
  )

  const handleDelete = useCallback(
    (value: string): void => {
      const newTags = selectedTags.filter((tag) => tag !== value)
      setSelectedTags(newTags)
      onValueChange?.(newTags)
    },
    [selectedTags, onValueChange],
  )

  const handleDropdownVisibleChange = useCallback((): void => {
    setSearchWord('')
    onSearchChange?.('')
  }, [onSearchChange])

  useEffect(() => {
    setSelectedTags(JSON.parse(stringifyValues))
  }, [stringifyValues])

  return (
    <Wrapper>
      <SearchTitle>
        {formatMessage({ id: 'general:search_and_select_type' })}
      </SearchTitle>
      <StyledSelect
        disabled={isCustomizedTagsFetching}
        dropdownRender={(): ReactElement => {
          return (
            <DropdownWrapper $isEmpty={!result.length}>
              {result.map(({ value }) => {
                const textToHighlight: string = flow(
                  (value: string) => value.split('/'),
                  ([parentName, subName]: [string, string]) => ({
                    parentLocalizedName:
                      getCustomizedTagLocalizedName(parentName),
                    subName,
                    fullValue: subName
                      ? `${parentName}/${subName}`
                      : parentName,
                  }),
                  ({ parentLocalizedName, subName, fullValue }) =>
                    subName
                      ? `${parentLocalizedName} / ${getCustomizedTagLocalizedName(fullValue)}`
                      : parentLocalizedName,
                )(value)

                const tagCategory = value.split('/')[0]

                // 是否以在列表中，或是屬於列表中某個父類別
                const isSelected =
                  selectedTags.includes(tagCategory) ||
                  selectedTags.includes(value)

                return (
                  <OptionWrapper
                    $isSelected={isSelected}
                    key={value}
                    onClick={(): void => handleOptionClick(value)}
                  >
                    <Highlighter
                      autoEscape
                      highlightStyle={{
                        ...highlightStyle,
                        fontSize: 14,
                      }}
                      searchWords={[searchWord]}
                      textToHighlight={textToHighlight}
                      unhighlightStyle={{
                        fontSize: 14,
                        color: theme.colors.text.secondary,
                      }}
                    />
                    {isSelected && (
                      <CheckOutlined
                        style={{ color: theme.colors.brand.primary }}
                      />
                    )}
                  </OptionWrapper>
                )
              })}
            </DropdownWrapper>
          )
        }}
        getPopupContainer={(triggerNode): HTMLElement =>
          triggerNode?.parentElement || document.body
        }
        mode='multiple'
        notFoundContent={null}
        optionFilterProp='label'
        options={result}
        placeholder={formatMessage({ id: 'search:filter_search' })}
        style={{ width: '100%', marginBottom: '2px' }}
        tagRender={({ value }): ReactElement => (
          <TagWrapper closable onClose={(): void => handleDelete(value)}>
            <TagText>{getCustomizedTagLocalizedName(value)}</TagText>
          </TagWrapper>
        )}
        value={selectedTags}
        onDropdownVisibleChange={handleDropdownVisibleChange}
        onInputKeyDown={handleKeyDown}
        onSearch={handleSearch}
      />
    </Wrapper>
  )
}

const SearchTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: ${({ theme }): string => theme.colors.text.secondary};
  margin-bottom: 10px;
`

const StyledSelect = styled(Select)`
  .ant-select-selection-overflow {
    gap: 4px;
  }
`

const OptionWrapper = styled.div<{ $isSelected: boolean }>`
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  font-size: 14px;
  font-weight: ${({ $isSelected }): number => ($isSelected ? 600 : 400)};
  height: 36px;
  background-color: ${({ $isSelected, theme }): string =>
    $isSelected ? theme.colors.brand.background : 'transparent'};

  &:hover {
    background-color: ${({ $isSelected, theme }): string =>
      $isSelected
        ? theme.colors.brand.background
        : theme.colors.background.grey};
  }
`

const Wrapper = styled.div`
  margin-bottom: 20px;
`

const TagText = styled.span`
  color: ${({ theme }): string => theme.colors.text.secondary};
  font-size: 14px;
`

const TagWrapper = styled(Tag)`
  display: flex;
  align-items: center;
  border: none;
  background-color: ${({ theme }): string => theme.colors.border.divider};
  margin-inline-end: 0;

  .ant-tag-close-icon {
    font-size: 8px;
  }
`

const DropdownWrapper = styled.div<{ $isEmpty: boolean }>`
  display: ${({ $isEmpty }): string => ($isEmpty ? 'none' : 'block')};
  width: 100%;
`

export default CustomizedTagSearch

import { WorkspaceMemberStatus } from '@/api/user-api'
import useAgentInfo from '@/hooks/use-agent-info'
import {
  MemberPermission,
  Permission,
  Permissions,
  usePermissions,
} from '@/hooks/use-permissions'

const permissionLevelMap: Record<MemberPermission, number> = {
  [WorkspaceMemberStatus.Viewer]: 1,
  [WorkspaceMemberStatus.Editor]: 2,
  [WorkspaceMemberStatus.Admin]: 3,
}

const covertPermission = (permission: Permission): keyof Permissions => {
  const permissionMap: Record<Permission, keyof Permissions> = {
    report: 'withReportPermission',
    internal: 'withInternalPermission',
    competitive: 'withCompetitivePermission',
    predictiveReport: 'withPredictiveReportPermission',
    viewer: 'withViewerPermission',
    editor: 'withEditorPermission',
    admin: 'withAdminPermission',
  }

  return permissionMap[permission]
}

const getMemberPermissionLevel = (permissions: Permissions): number => {
  if (permissions.withAdminPermission) {
    return permissionLevelMap[WorkspaceMemberStatus.Admin]
  }
  if (permissions.withEditorPermission) {
    return permissionLevelMap[WorkspaceMemberStatus.Editor]
  }
  if (permissions.withViewerPermission) {
    return permissionLevelMap[WorkspaceMemberStatus.Viewer]
  }

  return 0 // no permission
}

const checkIsMemberPermission = (
  permission: Permission,
): permission is MemberPermission => {
  const memberPermissions: Permission[] = [
    WorkspaceMemberStatus.Viewer,
    WorkspaceMemberStatus.Editor,
    WorkspaceMemberStatus.Admin,
  ]

  return memberPermissions.includes(permission)
}

const checkMemberPermission = (
  permission: MemberPermission,
  permissions: Permissions,
): boolean => {
  const permissionLevel = getMemberPermissionLevel(permissions)
  return permissionLevel >= permissionLevelMap[permission]
}

const checkPermission = (
  permission: Permission,
  permissions: Permissions,
): boolean => {
  return permissions[covertPermission(permission)] || false
}

const getPermission = (
  permission: Permission,
  permissions: Permissions,
): boolean => {
  if (checkIsMemberPermission(permission)) {
    return checkMemberPermission(permission, permissions)
  } else {
    return checkPermission(permission, permissions)
  }
}

export function usePermission(permission: Permission): boolean {
  const permissions = usePermissions()

  return getPermission(permission, permissions)
}

export function useUnlockKolDetailPermission(): boolean {
  return usePermission(WorkspaceMemberStatus.Editor)
}

export function usePurchasePermission(): boolean {
  return usePermission(WorkspaceMemberStatus.Admin)
}

export function useMemberManagementPermission(): boolean {
  return usePermission(WorkspaceMemberStatus.Admin)
}

export function useCollectionActionPermission(): boolean {
  return usePermission(WorkspaceMemberStatus.Editor)
}

export function useReportActionPermission(): boolean {
  return usePermission(WorkspaceMemberStatus.Editor)
}

export function useDirectoryActionPermission(): boolean {
  return usePermission(WorkspaceMemberStatus.Editor)
}

export function useHashtagActionPermission(): boolean {
  return usePermission(WorkspaceMemberStatus.Editor)
}

export function useCompetitiveActionPermission(): boolean {
  return usePermission(WorkspaceMemberStatus.Editor)
}

export function usePredictiveReportActionPermission(): boolean {
  const withPredictiveReportPermission = usePermission('predictiveReport')
  const withEditorPermission = usePermission(WorkspaceMemberStatus.Editor)
  return withPredictiveReportPermission && withEditorPermission
}

export function useCampaignActionPermission(): boolean {
  const { radarStarAgencyKOL } = useAgentInfo()
  const { withKolPermission } = usePermissions()
  const kolCampaignActionPermission = withKolPermission && !radarStarAgencyKOL
  const adCampaignActionPermission = usePermission(WorkspaceMemberStatus.Editor)
  return kolCampaignActionPermission || adCampaignActionPermission
}

import { skipToken } from '@reduxjs/toolkit/query'
import { useFetchUserStatusQuery } from '@/api/user-api'
import { useAuth } from '@/hooks/use-auth'

export const DEFAULT_BRAND_NAME = 'Kolr'

const useBrandName = (): string => {
  const { isAd } = useAuth()
  const { data: userStatus } = useFetchUserStatusQuery(
    isAd ? undefined : skipToken,
  )

  return (
    userStatus?.currentWorkspace.enterpriseIdentity?.name ?? DEFAULT_BRAND_NAME
  )
}

export default useBrandName

import { isUndefined } from 'lodash-es'
import { v4 as uuid4 } from 'uuid'
import { Store } from '@/utils/search-id-manager/store'
import {
  SearchIdMap,
  SearchIdObserver,
  SearchIdValidator,
  WindowMessageType,
  BeforeUpdateTask,
} from '@/utils/search-id-manager/type'

/**
 * SearchIdManager 用於管理 searchId
 *
 * 功能：
 * - framework-agnostic
 * - 避免 search id 顯式浮現在 query-string 上
 * - 支援多個 session (多個分頁) 同時使用
 * - 自動提供當前分頁的 search id，外部使用者無需關心具體分頁
 * - 可由外部設定 searchIdValidationRule，決定當前分頁是否要延續追蹤流程（繼承 opener 分頁的 search id）
 * - 可由外部設定 searchIdObserver，當 search id 有變動時，通知外部元件
 * - 自動清除已經關閉的 session searchId
 */
class SearchIdManager {
  static #instance?: SearchIdManager
  #currentSessionId?: string
  #openerSessionId?: string
  #searchIdValidationRuleList: SearchIdValidator[] = [] // 當任一 searchIdValidationRule 成立，則保留 searchId
  #searchIdObserverList: SearchIdObserver[] = []
  #store: Store
  #broadcastChannel?: BroadcastChannel
  #detectExpiredSessionIntervalTimerId?: number
  #sessionIdCheckCount: Record<string, number> = {}
  #beforeUpdateTasks: Array<BeforeUpdateTask> = []
  #skipBeforeUpdateTasks = false

  initialed: boolean

  private constructor() {}

  static readonly getInstance = (): SearchIdManager => {
    if (!SearchIdManager.#instance) {
      SearchIdManager.#instance = new SearchIdManager()
    }
    return SearchIdManager.#instance
  }

  init = async ({
    store,
    initialOpenerSessionId,
    searchIdValidationRuleList = [],
    searchIdObserverList = [],
    debug = false,
  }: {
    store: Store
    initialOpenerSessionId?: string
    searchIdValidationRuleList?: SearchIdValidator[]
    searchIdObserverList?: SearchIdObserver[]
    debug?: boolean
  }): Promise<SearchIdManager> => {
    if (this.initialed && SearchIdManager.#instance) {
      return SearchIdManager.#instance
    }
    this.#currentSessionId = uuid4()
    this.#store = store
    this.#openerSessionId =
      initialOpenerSessionId ?? (await this.#getOpenerSessionId())
    this.#searchIdValidationRuleList = searchIdValidationRuleList
    this.#searchIdObserverList = searchIdObserverList
    if (debug) {
      const updateDebugger = (searchId?: string): void => {
        let debugContainerDom = document.getElementById(debugContainerDomId)
        if (!debugContainerDom) {
          debugContainerDom = document.createElement('div')
          debugContainerDom.id = debugContainerDomId
          debugContainerDom.style.position = 'fixed'
          debugContainerDom.style.bottom = '0'
          debugContainerDom.style.left = '0'
          debugContainerDom.style.padding = '2px'
          debugContainerDom.style.backgroundColor = 'rgba(0,0,0,0.8)'
          debugContainerDom.style.color = 'white'
          debugContainerDom.style.fontSize = '12px'
          debugContainerDom.style.zIndex = '9999'
          document.body.appendChild(debugContainerDom)
        }
        debugContainerDom.innerText = `sessionId:\n ${this.#currentSessionId}\n\n searchId:\n ${searchId}\n\n openerSessionId:\n ${this.#openerSessionId}`
      }
      updateDebugger()
      this.addSearchIdObserver(updateDebugger)
    }
    this.extendOpenerSearchIdIfValid()

    // 用來判斷哪些 session 已經被關閉
    this.#broadcastChannel = new BroadcastChannel('search_id_channel')
    this.#broadcastChannel.addEventListener(
      'message',
      this.#handleBroadcastMessage,
    )
    this.#detectExpiredSessionIntervalTimerId = window.setInterval(
      this.#checkExpiredSessions,
      10000,
    )

    // 監聽子分頁的 getSessionId 請求，回傳當前分頁的 sessionId
    window.addEventListener('message', this.#handleWindowMessage)

    this.initialed = true
    return this
  }

  destroy = (): void => {
    SearchIdManager.#instance = undefined
    window.removeEventListener('message', this.#handleWindowMessage)

    if (this.#broadcastChannel) {
      this.#broadcastChannel.close()
    }
    if (this.#detectExpiredSessionIntervalTimerId) {
      window.clearInterval(this.#detectExpiredSessionIntervalTimerId)
      this.#detectExpiredSessionIntervalTimerId = undefined
    }

    const debugContainerDom = document.getElementById(debugContainerDomId)
    if (debugContainerDom) {
      debugContainerDom.remove()
    }
  }

  #handleWindowMessage = (e: MessageEvent<WindowMessageType>): void => {
    const originWindow = e.source
    if (e.data.type === 'getSessionId') {
      originWindow?.postMessage({
        type: 'getSessionIdResponse',
        payload: this.#currentSessionId,
      })
    }
  }

  #handleBroadcastMessage = (e: MessageEvent<WindowMessageType>): void => {
    if (e.data.type === 'getSessionId') {
      this.#broadcastChannel?.postMessage({
        type: 'getSessionIdResponse',
        payload: this.#currentSessionId,
      })
    }
  }

  #getConnectingSessionIdList = (): Promise<string[]> =>
    new Promise<string[]>((resolve) => {
      const connectingSessionIdList: string[] = []

      const getSessionIdResponseCallback = (
        e: MessageEvent<WindowMessageType>,
      ): void => {
        if (e.data.type === 'getSessionIdResponse') {
          connectingSessionIdList.push(e.data.payload)
        }
      }

      this.#broadcastChannel?.addEventListener(
        'message',
        getSessionIdResponseCallback,
      )

      this.#broadcastChannel?.postMessage({
        type: 'getSessionId',
      })

      setTimeout(() => {
        this.#broadcastChannel?.removeEventListener(
          'message',
          getSessionIdResponseCallback,
        )
        resolve(connectingSessionIdList)
      }, 3000)
    }).catch(() => [])

  #checkExpiredSessions = async (): Promise<void> => {
    const connectingSessionIdList = [
      this.#currentSessionId,
      ...(await this.#getConnectingSessionIdList()),
    ]
    const allSessionIdList = this.#store.getAllSessionId()

    // 超過三次未回應的 session 視為已經關閉
    allSessionIdList.forEach((sessionId) => {
      if (!connectingSessionIdList.includes(sessionId)) {
        this.#sessionIdCheckCount[sessionId] =
          (this.#sessionIdCheckCount[sessionId] || 0) + 1
      } else {
        this.#sessionIdCheckCount[sessionId] = 0
      }
    })
    const expiredSessionIdList = Object.keys(this.#sessionIdCheckCount).filter(
      (sessionId) => this.#sessionIdCheckCount[sessionId] >= 3,
    )

    if (expiredSessionIdList.length > 0) {
      expiredSessionIdList.forEach((sessionId) => {
        this.#sessionIdCheckCount[sessionId] = 0
      })
      this.remove(expiredSessionIdList)
    }
  }

  #getOpenerSessionId = (): Promise<string | undefined> =>
    new Promise<string | undefined>((resolve, reject) => {
      if (!window.opener) {
        resolve(undefined)
      }

      // 這個不放在 handleWindowMessage 是因為想知道回來的時機點，已方便後續判斷是否延續追蹤旅程
      const getSessionIdResponseCallback = (
        e: MessageEvent<WindowMessageType>,
      ): void => {
        if (e.data.type === 'getSessionIdResponse') {
          window.removeEventListener('message', getSessionIdResponseCallback)
          resolve(e.data.payload)
        }
      }
      window.addEventListener('message', getSessionIdResponseCallback)
      window.opener.postMessage({
        type: 'getSessionId',
      })

      setTimeout(() => reject(new Error('timeout')), 3000)
    }).catch(() => undefined)

  #notifyObserver = (newSearchId?: string): void => {
    this.#searchIdObserverList.forEach((observer) => {
      observer(newSearchId)
    })
  }
  #beforeUpdateSearchId = (): void => {
    if (this.#skipBeforeUpdateTasks) {
      this.#skipBeforeUpdateTasks = false
      return
    }

    const previousSearchId = this.get()

    this.#beforeUpdateTasks.map((task) => {
      if (!isUndefined(previousSearchId)) {
        task(previousSearchId)
      }
    })
  }

  #throwAlert = (): never => {
    throw new Error(
      'Before using SearchIdManager, you should call searchIdManager.init() after creating a new instance.',
    )
  }

  /**
   * 公開 API
   **/
  addSearchIdValidationRule = (validator: SearchIdValidator): void => {
    if (!this.#currentSessionId) {
      return this.#throwAlert()
    }

    this.#searchIdValidationRuleList.push(validator)
  }

  addSearchIdObserver = (observer: SearchIdObserver): void => {
    if (!this.#currentSessionId) {
      return this.#throwAlert()
    }

    this.#searchIdObserverList.push(observer)
  }

  // 若外部傳來的任一條件成立，則延續追蹤旅程（從 opener 複製一份 searchId 到當前 session）
  extendOpenerSearchIdIfValid = (): void => {
    if (!this.#openerSessionId) {
      return
    }

    const isSearchIdValid = this.#searchIdValidationRuleList.some((validator) =>
      validator(),
    )
    if (!isSearchIdValid) {
      return
    }

    const openerSessionSearchId = this.get(this.#openerSessionId)
    this.update(openerSessionSearchId)
  }

  get = (sessionId?: string): string | undefined => {
    if (!this.#currentSessionId) {
      return this.#throwAlert()
    }

    return this.#store.get(sessionId ?? this.#currentSessionId)
  }

  getMap = (): SearchIdMap => {
    if (!this.#currentSessionId) {
      return this.#throwAlert()
    }

    return this.#store.getAll()
  }

  update = (searchId?: string): string => {
    if (!this.#currentSessionId) {
      return this.#throwAlert()
    }

    this.#beforeUpdateSearchId()

    const newSearchId = searchId ?? uuid4()
    this.#store.update(this.#currentSessionId, newSearchId)
    this.#notifyObserver(newSearchId)

    return newSearchId
  }

  remove = (sessionIdList?: string[]): void => {
    if (!this.#currentSessionId) {
      return this.#throwAlert()
    }

    const targetSessionIdList = sessionIdList ?? [this.#currentSessionId]
    this.#store.remove(targetSessionIdList)

    if (targetSessionIdList.includes(this.#currentSessionId)) {
      this.#notifyObserver()
    }
  }

  getSessionId = (): string => {
    if (!this.#currentSessionId) {
      return this.#throwAlert()
    }

    return this.#currentSessionId
  }

  addRegisterBeforeUpdateTask = (task: BeforeUpdateTask): (() => void) => {
    if (!this.#beforeUpdateTasks.includes(task)) {
      this.#beforeUpdateTasks.push(task)
    }

    return (): void => this.removeRegisterBeforeUpdateTask(task)
  }

  removeRegisterBeforeUpdateTask = (task: BeforeUpdateTask): void => {
    const index = this.#beforeUpdateTasks.indexOf(task)
    if (index > -1) {
      this.#beforeUpdateTasks.splice(index, 1)
    }
  }

  skipNextBeforeUpdateTasks = (): void => {
    this.#skipBeforeUpdateTasks = true
  }

  clearCurrentSessionId = (): void => {
    // 不丟出錯誤，因為這個方法會在 search-id 還沒初始化時被呼叫.
    if (!this.#currentSessionId) {
      return
    }

    this.remove([this.#currentSessionId])
  }
}

const debugContainerDomId = 'search-id-debugger'

export default SearchIdManager

enum LocalStorageKeys {
  TrialCode = 'trial_code',
  Jwt = 'radar:jwt',
  CollectionView = 'collection-view',
  SearchLayout = 'search-layout',
  SiderCollapsed = 'isSiderCollapsed',
  LoginTime = 'loginTime',
  SearchIdMap = 'radar:search-id',
  DefaultSearch = 'default-search',
  FreeTrialOnboardingSeen = 'free-trial-onboarding-seen',
}

export default LocalStorageKeys

import { z } from 'zod'
import { ReportTab } from '@/constants/report-tab'

const reportModeSchema = z.enum(['preview', 'edit']).optional()
const reportTabSchema = z.nativeEnum(ReportTab).optional()

export const reportParamsSchema = z.object({
  workspaceId: z.coerce.number(),
  reportId: z.union([z.literal('demo'), z.coerce.number()]),
})

export const reportSearchParamsSchema = z.object({
  mode: reportModeSchema,
  tab: reportTabSchema,
})

import { z } from 'zod'

const idSchema = z.string().uuid()
const searchIdMapSchema = z.record(idSchema, idSchema)
type SearchIdMap = z.infer<typeof searchIdMapSchema>

const windowMessageEventTypeSchema = z.discriminatedUnion('type', [
  z.object({ type: z.literal('getSessionId') }),
  z.object({
    type: z.literal('getSessionIdResponse'),
    payload: z.string(),
  }),
])
type WindowMessageType = z.infer<typeof windowMessageEventTypeSchema>

type SearchIdValidator = () => boolean
type SearchIdObserver = (newSearchId?: string) => void

type BeforeUpdateTask = (previousSearchId: string) => void

export type {
  BeforeUpdateTask,
  SearchIdMap,
  SearchIdObserver,
  SearchIdValidator,
  WindowMessageType,
}

export { idSchema, searchIdMapSchema }

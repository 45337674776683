import * as Sentry from '@sentry/react'
import { ScopeContext } from '@sentry/types/types/scope'
import env from '@/utils/env'
import resolveError from '@/utils/logger/resolve-error'

const info = (
  info: unknown,
  extra?: ScopeContext['extra'],
  tags?: ScopeContext['tags'],
): void => {
  if (env.DEVELOPMENT_MODE) {
    console.info({ info, extra, tags })
  } else {
    Sentry.captureException(info, {
      level: 'info',
      extra,
      tags,
    })
  }
}

const error = (
  error: unknown,
  extra?: ScopeContext['extra'],
  tags?: ScopeContext['tags'],
): void => {
  if (env.DEVELOPMENT_MODE) {
    console.info({ error, extra, tags })
  } else {
    const resolvedError = resolveError(error)
    Sentry.captureException(resolvedError, {
      level: 'error',
      extra: {
        ...extra,
        originalError: error,
      },
      tags,
    })
  }
}

const logger = {
  info,
  error,
}

export default logger

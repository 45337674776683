import { CheckOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { Button, notification } from 'antd'
import { isEmpty, isUndefined, times } from 'lodash-es'
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'
import {
  useAddCampaignPostMutation,
  useDeleteCampaignPostMutation,
} from '@/api/campaigns-api'
import { AuthPage, useFetchAuthPagesQuery } from '@/api/oauth-api'
import AddYouTubeVideo from '@/components/campaign/kol/add-post-modal/add-youtube-video'
import AuthPages from '@/components/campaign/kol/add-post-modal/auth-pages'
import InstagramStories from '@/components/campaign/kol/add-post-modal/instagram-stories'
import LoadingMask from '@/components/campaign/kol/add-post-modal/loading-mask'
import PageSelector from '@/components/campaign/kol/add-post-modal/page-selector'
import Post, {
  transferFacebookPost,
  transferInstagramPost,
} from '@/components/campaign/kol/add-post-modal/post'
import { disablePostUpdateStatus } from '@/components/campaign/kol/running/running-case-table'
import { BasicModal } from '@/components/common/antd/basic-modal'
import LoadingElementWithRef from '@/components/common/loading-element-with-ref'
import InstagramBusinessAccountAuthorizationModal from '@/components/social/instagram-business-account-authorization-modal'
import { KOL_AUTH_PARAMS } from '@/constants/meta-auth-request'
import { useSelector } from '@/hooks/use-selector'
import { useIntl } from '@/i18n/hooks/use-intl'
import { reduxAPI } from '@/store/redux-api'
import { setFacebookAuth } from '@/store/state.socialAuth'
import { StyledAntdDivider } from '@/types/antd/styled-antd'
import {
  CampaignCooperationPosts,
  CampaignCooperationType,
} from '@/types/mock-api-types'
import { PlatformType } from '@/types/schema/report-schema'
import { ampli } from '@/utils/ampli'
import { PlatformCode } from '@/utils/convert-platform'
import {
  getCampaignCooperationTypeConfig,
  getSubmitPostLinkI18nIdByCampaignCooperationType,
} from '@/utils/get-campaign-cooperation'
import getYouTubeVideoLink from '@/utils/get-youtube-video-link'
import useFacebookPosts from '@/utils/hooks/use-facebook-posts'
import useInstagramPosts from '@/utils/hooks/use-instagram-posts'

enum PostType {
  Text = 'text',
  Image = 'image',
  Video = 'video',
  Live = 'live',
  Story = 'story',
  Blog = 'blog',
  Short = 'short',
  Other = 'other',
}

export interface PostModel {
  useHandfilled: boolean
  pageId?: string
  postId?: string
  instagramBusinessAccount?: string
  shortcode?: string
  postType: PostType
  platformType: number
  url?: string
}

const convertCampaignCooperationTypeToPostType = (
  type: CampaignCooperationType,
): PostType => {
  switch (type) {
    case CampaignCooperationType.FacebookPost:
    case CampaignCooperationType.InstagramPost:
      return PostType.Image
    case CampaignCooperationType.YouTubeVideo:
    case CampaignCooperationType.FacebookVideo:
      return PostType.Video
    case CampaignCooperationType.FacebookLive:
    case CampaignCooperationType.InstagramLive:
      return PostType.Live
    case CampaignCooperationType.InstagramReels:
      return PostType.Short
    case CampaignCooperationType.InstagramStory:
      return PostType.Story
    case CampaignCooperationType.AdvertisingLicense7Days:
    case CampaignCooperationType.AdvertisingLicense14Days:
    case CampaignCooperationType.AdvertisingLicense30Days:
    case CampaignCooperationType.AdvertisingLicense90Days:
      return PostType.Other
  }
}

type UseAddPostModal = () => {
  toggleModal: () => void
  addPostModalVisible: boolean
}

export const useAddPostModal: UseAddPostModal = () => {
  const addPostModalModal = reduxAPI.state.modal.hooks.useVisible(
    'addCampaignPostModalByKOL',
  )
  const toggleModal = useCallback(
    () => addPostModalModal.toggleVisible(),
    [addPostModalModal],
  )

  return { toggleModal, addPostModalVisible: addPostModalModal.visible }
}

const AddPostModal: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const [selectedFacebookPage, setSelectedFacebookPage] = useState<AuthPage>()
  const [selectedInstagramPage, setSelectedInstagramPage] = useState<AuthPage>()
  const instagram = useInstagramPosts(
    selectedInstagramPage?.pageAccessToken,
    selectedInstagramPage?.instagramBusinessAccount,
  )
  const facebook = useFacebookPosts(
    selectedFacebookPage?.pageAccessToken,
    selectedFacebookPage?.pageId,
  )
  const { loading: socialAuthLoading } = useSelector(
    (state) => state.socialAuth,
  )
  const { selectedCampaignCase } = useSelector((state) => state.campaignCase)
  const { addPostModalVisible, toggleModal } = useAddPostModal()
  const [post, setPost] = useState<PostModel>()
  const [type, setType] = useState<CampaignCooperationType>()
  const [selectedPost, setSelectedPost] = useState<CampaignCooperationPosts>()
  const [
    instagramBusinessAccountIntegrationModalVisible,
    setInstagramBusinessAccountIntegrationModalVisible,
  ] = useState(false)
  const { data, refetch: refetchAuthPages } = useFetchAuthPagesQuery()
  const [addCampaignPost] = useAddCampaignPostMutation()
  const [deleteCampaignPost] = useDeleteCampaignPostMutation()

  useEffect(() => {
    if (!addPostModalVisible) {
      setType(undefined)
      setPost(undefined)
      setSelectedPost(undefined)
      setSelectedFacebookPage(undefined)
      setSelectedInstagramPage(undefined)
    } else {
      refetchAuthPages()
    }
  }, [addPostModalVisible, refetchAuthPages])

  const facebookPages = useMemo<AuthPage[]>(() => {
    return data?.pageIds.filter((page) => !!page.pageId) ?? []
  }, [data])

  const instagramPages = useMemo<AuthPage[]>(() => {
    return data?.pageIds.filter((page) => !!page.instagramBusinessAccount) ?? []
  }, [data])

  useUpdateEffect(() => {
    if (!socialAuthLoading) {
      refetchAuthPages()
    }
  }, [socialAuthLoading])

  useUpdateEffect(() => {
    setSelectedFacebookPage(facebookPages[0])
  }, [facebookPages, addPostModalVisible])

  useUpdateEffect(() => {
    if (selectedInstagramPage?.pageId === instagramPages[0]?.pageId) {
      return
    }

    setSelectedInstagramPage(instagramPages[0])
  }, [instagramPages, addPostModalVisible])

  const onCheckFacebookLink = (
    checked: boolean,
    id: string,
    postType: PostType,
  ): void => {
    const foundPost = facebook.posts.find((fbPost) => fbPost.id === id)
    if (checked && foundPost) {
      setPost({
        useHandfilled: false,
        pageId: selectedFacebookPage?.pageId,
        postId: foundPost.id,
        postType,
        platformType: PlatformType.Facebook,
        url: foundPost.permalinkUrl,
      })
    } else {
      setPost(undefined)
    }
  }

  const handleAuthClick = (): void => {
    ampli.tryGrantSnsPermission({ platform: 'Facebook' })

    window.FB.login(({ authResponse }) => {
      const { accessToken, userID, grantedScopes } = authResponse

      if (accessToken) {
        ampli.authorizeSuccessful({
          platform: 'Facebook',
          permission: grantedScopes?.split(','),
        })

        dispatch(setFacebookAuth({ accessToken, userID }))
      } else {
        ampli.authorizeFail({ platform: 'Facebook' })
      }
    }, KOL_AUTH_PARAMS)
  }

  const onCheckInstagramLink = (
    checked: boolean,
    shortcode: string,
    postType: PostType,
  ): void => {
    const foundPost = instagram.posts.find(
      (igPost) => igPost.shortcode === shortcode,
    )
    if (checked && foundPost) {
      setPost({
        useHandfilled: false,
        instagramBusinessAccount:
          selectedInstagramPage?.instagramBusinessAccount,
        pageId: selectedInstagramPage?.pageId,
        postId: foundPost.id,
        shortcode: foundPost.shortcode,
        postType,
        platformType: PlatformType.Instagram,
        url: foundPost.permalink,
      })
    } else {
      setPost(undefined)
    }
  }

  const handleClickYouTubeVideo = useCallback(
    (checked: boolean, videoId: string): void => {
      if (checked) {
        setPost({
          useHandfilled: false,
          postId: videoId,
          postType: PostType.Video,
          platformType: PlatformType.YouTube,
          url: getYouTubeVideoLink(videoId),
        })
      } else {
        setPost(undefined)
      }
    },
    [],
  )

  const handleSubmit = async (): Promise<void> => {
    if (selectedCampaignCase) {
      const { id, campaign } = selectedCampaignCase
      const posts: PostModel[] = [post].filter(
        (campaignPost): campaignPost is PostModel => !!campaignPost,
      )

      if (selectedPost) {
        try {
          await deleteCampaignPost({
            campaignId: campaign.id,
            campaignKolId: id,
            postId: selectedPost.campaignKolPostId,
          }).unwrap()

          await addCampaignPost({
            campaignId: campaign.id,
            campaignKolId: id,
            posts,
          }).unwrap()

          notification.success({
            message: formatMessage({
              id: 'submit_campaign_case_post:edit_post_successfully',
            }),
            placement: 'bottomRight',
          })

          toggleModal()
        } catch (error) {
          notification.error({
            message: formatMessage({
              id: 'submit_campaign_case_post:edit_post_failed',
            }),
            placement: 'bottomRight',
          })
        }
      } else {
        try {
          await addCampaignPost({
            campaignId: campaign.id,
            campaignKolId: id,
            posts,
          }).unwrap()

          notification.success({
            message: formatMessage({
              id: 'submit_campaign_case_post:submit_post_successfully',
            }),
            placement: 'bottomRight',
          })

          toggleModal()
        } catch (error) {
          notification.error({
            message: formatMessage({
              id: 'submit_campaign_case_post:submit_post_failed',
            }),
            placement: 'bottomRight',
          })
        }
      }
    }
  }

  const handleEdit = (
    postType: CampaignCooperationType,
    kolPost: CampaignCooperationPosts,
  ): void => {
    setType(postType)
    setSelectedPost(kolPost)
  }

  const modalTitle = (
    <h2>{formatMessage({ id: 'submit_campaign_case_post:title' })}</h2>
  )

  useEffect(() => {
    setPost(undefined)
  }, [type])

  if (!selectedCampaignCase) {
    return null
  }

  const { cooperationDetail, orderStatus } = selectedCampaignCase

  return (
    <StyledModal
      destroyOnClose
      footer={null}
      open={addPostModalVisible}
      onCancel={toggleModal}
    >
      {!type ? (
        <>
          {modalTitle}
          <div>
            {formatMessage({ id: 'submit_campaign_case_post:content' })}
          </div>
          <div>
            {cooperationDetail
              ?.filter(
                (cooperationDetailItem) =>
                  !getCampaignCooperationTypeConfig(cooperationDetailItem.type)
                    .otherPlatformType,
              )
              .map((item) => {
                return times(item.count).map((index) => {
                  const uploadedPost = item.posts?.[index]
                  const isUploaded = !!uploadedPost
                  const canEditPost =
                    isUploaded &&
                    orderStatus &&
                    !disablePostUpdateStatus.includes(orderStatus)
                  const linkI18nId =
                    getSubmitPostLinkI18nIdByCampaignCooperationType(item.type)

                  return (
                    <Item isUploaded={isUploaded} key={`${item.type}_${index}`}>
                      <div
                        onClick={(): void => {
                          if (!isUploaded) {
                            setType(item.type)
                          }
                        }}
                      >
                        {isUploaded ? <CheckOutlined /> : <PlusOutlined />}
                        {linkI18nId && formatMessage({ id: linkI18nId })}
                      </div>
                      {canEditPost && (
                        <div>
                          <EditOutlined
                            onClick={(): void =>
                              handleEdit(item.type, uploadedPost)
                            }
                          />
                        </div>
                      )}
                    </Item>
                  )
                })
              })}
          </div>
        </>
      ) : (
        <>
          {type.includes('facebook') && (
            <LoadingMask loading={facebook.loading}>
              {isEmpty(facebookPages) ? (
                <AuthPages
                  handleAuthClick={handleAuthClick}
                  platformCode={PlatformCode.Facebook}
                />
              ) : (
                <>
                  {modalTitle}
                  <Title>
                    {formatMessage(
                      {
                        id: 'submit_campaign_case_post:select_post_source',
                      },
                      {
                        platformTitle: 'Facebook',
                        sourceName: formatMessage({
                          id: 'general:facebook_page',
                        }),
                      },
                    )}
                  </Title>
                  <PageSelector
                    handleAuthClick={handleAuthClick}
                    loading={socialAuthLoading}
                    pages={facebookPages}
                    platform='facebook'
                    value={selectedFacebookPage?.pageId}
                    onSelect={setSelectedFacebookPage}
                  />
                  <StyledAntdDivider />
                  <Title>
                    {formatMessage(
                      {
                        id: 'submit_campaign_case_post:select_post_source',
                      },
                      {
                        platformTitle: 'Facebook',
                        sourceName: formatMessage({
                          id: getCampaignCooperationTypeConfig(type).i18nId,
                        }),
                      },
                    )}
                  </Title>
                  <AutoLinkWrapper>
                    {facebook.posts
                      .filter((fb) => {
                        const isVideo =
                          fb.attachments?.data[0].type?.includes('video') ||
                          fb.attachments?.data[0].mediaType?.includes('video')

                        if (type.includes('post')) {
                          return !isVideo
                        }
                        return isVideo
                      })
                      .map((fbPost) => (
                        <Post
                          defaultImage={facebook.pagePicture}
                          key={fbPost.id}
                          post={transferFacebookPost(
                            fbPost,
                            facebook.pagePicture,
                          )}
                          selectedPostID={
                            post?.postId ??
                            `${selectedFacebookPage?.pageId}_${selectedPost?.platformPostId}`
                          }
                          onCheck={(checked, id): void =>
                            onCheckFacebookLink(
                              checked,
                              id,
                              convertCampaignCooperationTypeToPostType(type),
                            )
                          }
                        />
                      ))}
                    {(facebook.hasNextPage || facebook.loading) && (
                      <LoadingElementWithRef loadingRef={facebook.loadingRef} />
                    )}
                  </AutoLinkWrapper>
                </>
              )}
            </LoadingMask>
          )}
          {type.includes('instagram') && (
            <LoadingMask loading={instagram.loading}>
              {isEmpty(instagramPages) ? (
                <AuthPages
                  handleAuthClick={(): void =>
                    setInstagramBusinessAccountIntegrationModalVisible(true)
                  }
                  platformCode={PlatformCode.Instagram}
                />
              ) : (
                <>
                  {modalTitle}
                  <Title>
                    {formatMessage(
                      {
                        id: 'submit_campaign_case_post:select_post_source',
                      },
                      {
                        platformTitle: 'Instagram',
                        sourceName: formatMessage({
                          id: 'general:instagram_business_account',
                        }),
                      },
                    )}
                  </Title>
                  <PageSelector
                    handleAuthClick={(): void =>
                      setInstagramBusinessAccountIntegrationModalVisible(true)
                    }
                    loading={socialAuthLoading}
                    pages={instagramPages}
                    platform='instagram'
                    value={selectedInstagramPage?.pageId}
                    onSelect={setSelectedInstagramPage}
                  />
                  <StyledAntdDivider />
                  <Title>
                    {formatMessage(
                      {
                        id: 'submit_campaign_case_post:select_post_source',
                      },
                      {
                        platformTitle: 'Instagram',
                        sourceName: formatMessage({
                          id: getCampaignCooperationTypeConfig(type).i18nId,
                        }),
                      },
                    )}
                  </Title>
                  {type === CampaignCooperationType.InstagramStory ? (
                    <InstagramStories
                      key={selectedInstagramPage?.instagramBusinessAccount}
                      selectedPage={selectedInstagramPage}
                      selectedStoryId={
                        post?.shortcode ?? selectedPost?.platformPostId
                      }
                      onAuthClick={(): void =>
                        setInstagramBusinessAccountIntegrationModalVisible(true)
                      }
                      onSelect={(story): void =>
                        setPost({
                          useHandfilled: false,
                          pageId: selectedInstagramPage?.pageId,
                          instagramBusinessAccount:
                            selectedInstagramPage?.instagramBusinessAccount,
                          postId: story.id,
                          shortcode: story.shortcode,
                          postType: PostType.Story,
                          platformType: PlatformType.Instagram,
                          url: story.permalink,
                        })
                      }
                    />
                  ) : (
                    <AutoLinkWrapper>
                      {instagram.posts
                        .filter((ig) => {
                          const isVideo = ig.mediaType === 'VIDEO'
                          if (type.includes('post')) {
                            return !isVideo
                          }
                          return isVideo
                        })
                        .map((igPost) => (
                          <Post
                            key={igPost.id}
                            post={transferInstagramPost(igPost)}
                            selectedPostID={
                              post?.shortcode ?? selectedPost?.platformPostId
                            }
                            onCheck={(checked, id): void =>
                              onCheckInstagramLink(
                                checked,
                                id,
                                convertCampaignCooperationTypeToPostType(type),
                              )
                            }
                          />
                        ))}
                      {(instagram.hasNextPage || instagram.loading) && (
                        <LoadingElementWithRef
                          loadingRef={instagram.loadingRef}
                        />
                      )}
                    </AutoLinkWrapper>
                  )}
                </>
              )}
            </LoadingMask>
          )}
          {type.includes('youtube') && (
            <GoogleOAuthProvider
              clientId={`${process.env.NEXT_PUBLIC_GOOGLE_APP_ID}.apps.googleusercontent.com`}
            >
              <AddYouTubeVideo
                selectedVideoId={post?.postId ?? selectedPost?.platformPostId}
                onCancel={toggleModal}
                onSelectVideo={handleClickYouTubeVideo}
              />
            </GoogleOAuthProvider>
          )}
          {!isUndefined(post) && (
            <Footer>
              <Button type='primary' onClick={handleSubmit}>
                {formatMessage({ id: 'general:submit_btn' })}
              </Button>
            </Footer>
          )}
        </>
      )}
      <InstagramBusinessAccountAuthorizationModal
        isVisible={instagramBusinessAccountIntegrationModalVisible}
        onCancel={(): void =>
          setInstagramBusinessAccountIntegrationModalVisible(false)
        }
      />
    </StyledModal>
  )
}

export default AddPostModal

interface ItemProps {
  readonly isUploaded: boolean
}

const StyledModal = styled(BasicModal)`
  ${StyledAntdDivider} {
    margin: 20px 0 12px;
  }
`

const Item = styled.div.attrs<ItemProps>((props) => ({
  isUploaded: props.isUploaded,
}))<ItemProps>`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: ${(props): string =>
    props.isUploaded
      ? props.theme.colors.unclassified.grey.grey1
      : props.theme.colors.brand.primary};
  padding: 12px 0;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  justify-content: space-between;

  > div:nth-child(1) {
    cursor: ${(props): string => (props.isUploaded ? 'initial' : 'pointer')};

    .anticon {
      margin: 0 6px 0 0;
      color: ${(props): string =>
        props.isUploaded ? '#67C23A' : props.theme.colors.brand.primary};
    }
  }

  > div:nth-child(2) {
    .anticon {
      color: ${({ theme }): string => theme.colors.brand.primary};
      cursor: pointer;
    }
  }
`

const Title = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 40px;

  &::before {
    content: '*';
    color: ${({ theme }): string => theme.colors.brand.primary};
    margin: 0 4px 0 0;
  }
`

const AutoLinkWrapper = styled.div`
  height: 100%;
  max-height: 315px;
  overflow: scroll;
`

const Footer = styled.div`
  margin: 26px 0 0 0;
  display: flex;
  justify-content: flex-end;
`

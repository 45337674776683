import { createEnumParam } from 'use-query-params'
import { z } from 'zod'
import { Item } from '@/components/common/utils'
import { type I18nId } from '@/i18n/config'

export enum CountryCode {
  TW = 'tw',
  JP = 'jp',
  HK = 'hk',
  MY = 'my',
  TH = 'th',
  VN = 'vn',
  SG = 'sg',
  KR = 'kr',
  AF = 'af',
  AX = 'ax',
  AL = 'al',
  DZ = 'dz',
  AS = 'as',
  AD = 'ad',
  AO = 'ao',
  AI = 'ai',
  AQ = 'aq',
  AG = 'ag',
  AR = 'ar',
  AM = 'am',
  AW = 'aw',
  AU = 'au',
  AT = 'at',
  AZ = 'az',
  BS = 'bs',
  BH = 'bh',
  BD = 'bd',
  BB = 'bb',
  BY = 'by',
  BE = 'be',
  BZ = 'bz',
  BJ = 'bj',
  BM = 'bm',
  BT = 'bt',
  BO = 'bo',
  BQ = 'bq',
  BA = 'ba',
  BW = 'bw',
  BV = 'bv',
  BR = 'br',
  IO = 'io',
  BN = 'bn',
  BG = 'bg',
  BF = 'bf',
  BI = 'bi',
  CV = 'cv',
  KH = 'kh',
  CM = 'cm',
  CA = 'ca',
  KY = 'ky',
  CF = 'cf',
  TD = 'td',
  CL = 'cl',
  CN = 'cn',
  CX = 'cx',
  CC = 'cc',
  CO = 'co',
  KM = 'km',
  CG = 'cg',
  CD = 'cd',
  CK = 'ck',
  CR = 'cr',
  CI = 'ci',
  HR = 'hr',
  CU = 'cu',
  CW = 'cw',
  CY = 'cy',
  CZ = 'cz',
  DK = 'dk',
  DJ = 'dj',
  DM = 'dm',
  DO = 'do',
  EC = 'ec',
  EG = 'eg',
  SV = 'sv',
  GQ = 'gq',
  ER = 'er',
  EE = 'ee',
  SZ = 'sz',
  ET = 'et',
  FK = 'fk',
  FO = 'fo',
  FJ = 'fj',
  FI = 'fi',
  FR = 'fr',
  GF = 'gf',
  PF = 'pf',
  TF = 'tf',
  GA = 'ga',
  GM = 'gm',
  GE = 'ge',
  DE = 'de',
  GH = 'gh',
  GI = 'gi',
  GR = 'gr',
  GL = 'gl',
  GD = 'gd',
  GP = 'gp',
  GU = 'gu',
  GT = 'gt',
  GG = 'gg',
  GN = 'gn',
  GW = 'gw',
  GY = 'gy',
  HT = 'ht',
  HM = 'hm',
  VA = 'va',
  HN = 'hn',
  HU = 'hu',
  IS = 'is',
  IN = 'in',
  ID = 'id',
  IR = 'ir',
  IQ = 'iq',
  IE = 'ie',
  IM = 'im',
  IL = 'il',
  IT = 'it',
  JM = 'jm',
  JE = 'je',
  JO = 'jo',
  KZ = 'kz',
  KE = 'ke',
  KI = 'ki',
  KP = 'kp',
  KW = 'kw',
  KG = 'kg',
  LA = 'la',
  LV = 'lv',
  LB = 'lb',
  LS = 'ls',
  LR = 'lr',
  LY = 'ly',
  LI = 'li',
  LT = 'lt',
  LU = 'lu',
  MO = 'mo',
  MG = 'mg',
  MW = 'mw',
  MV = 'mv',
  ML = 'ml',
  MT = 'mt',
  MH = 'mh',
  MQ = 'mq',
  MR = 'mr',
  MU = 'mu',
  YT = 'yt',
  MX = 'mx',
  FM = 'fm',
  MD = 'md',
  MC = 'mc',
  MN = 'mn',
  ME = 'me',
  MS = 'ms',
  MA = 'ma',
  MZ = 'mz',
  MM = 'mm',
  NA = 'na',
  NR = 'nr',
  NP = 'np',
  NL = 'nl',
  NC = 'nc',
  NZ = 'nz',
  NI = 'ni',
  NE = 'ne',
  NG = 'ng',
  NU = 'nu',
  NF = 'nf',
  MK = 'mk',
  MP = 'mp',
  NO = 'no',
  OM = 'om',
  PK = 'pk',
  PW = 'pw',
  PS = 'ps',
  PA = 'pa',
  PG = 'pg',
  PY = 'py',
  PE = 'pe',
  PH = 'ph',
  PN = 'pn',
  PL = 'pl',
  PT = 'pt',
  PR = 'pr',
  QA = 'qa',
  RE = 're',
  RO = 'ro',
  RU = 'ru',
  RW = 'rw',
  BL = 'bl',
  SH = 'sh',
  KN = 'kn',
  LC = 'lc',
  MF = 'mf',
  PM = 'pm',
  VC = 'vc',
  WS = 'ws',
  SM = 'sm',
  ST = 'st',
  SA = 'sa',
  SN = 'sn',
  RS = 'rs',
  SC = 'sc',
  SL = 'sl',
  SX = 'sx',
  SK = 'sk',
  SI = 'si',
  SB = 'sb',
  SO = 'so',
  ZA = 'za',
  GS = 'gs',
  SS = 'ss',
  ES = 'es',
  LK = 'lk',
  SD = 'sd',
  SR = 'sr',
  SJ = 'sj',
  SE = 'se',
  CH = 'ch',
  SY = 'sy',
  TJ = 'tj',
  TZ = 'tz',
  TL = 'tl',
  TG = 'tg',
  TK = 'tk',
  TO = 'to',
  TT = 'tt',
  TN = 'tn',
  TR = 'tr',
  TM = 'tm',
  TC = 'tc',
  TV = 'tv',
  UG = 'ug',
  UA = 'ua',
  AE = 'ae',
  GB = 'gb',
  US = 'us',
  UM = 'um',
  UY = 'uy',
  UZ = 'uz',
  VU = 'vu',
  VE = 've',
  VG = 'vg',
  VI = 'vi',
  WF = 'wf',
  EH = 'eh',
  YE = 'ye',
  ZM = 'zm',
  ZW = 'zw',
}

/** TODO CurrencyCod 替換掉 shared 裡面的 CurrencyCode (目前別名 SharedCurrencyCode) */
export enum CurrencyCod {
  TWD = 'TWD',
  JPY = 'JPY',
  HKD = 'HKD',
  MYR = 'MYR',
  THB = 'THB',
  VND = 'VND',
  SGD = 'SGD',
  KRW = 'KRW',
  AFN = 'AFN',
  EUR = 'EUR',
  ALL = 'ALL',
  DZD = 'DZD',
  USD = 'USD',
  AOA = 'AOA',
  XCD = 'XCD',
  ARS = 'ARS',
  AMD = 'AMD',
  AWG = 'AWG',
  AUD = 'AUD',
  AZN = 'AZN',
  BSD = 'BSD',
  BHD = 'BHD',
  BDT = 'BDT',
  BBD = 'BBD',
  BYN = 'BYN',
  BZD = 'BZD',
  XOF = 'XOF',
  BMD = 'BMD',
  BTN = 'BTN',
  BOB = 'BOB',
  BAM = 'BAM',
  BWP = 'BWP',
  NOK = 'NOK',
  BRL = 'BRL',
  INR = 'INR',
  BND = 'BND',
  BGN = 'BGN',
  BIF = 'BIF',
  CVE = 'CVE',
  KHR = 'KHR',
  XAF = 'XAF',
  CAD = 'CAD',
  KYD = 'KYD',
  CLP = 'CLP',
  CNY = 'CNY',
  COP = 'COP',
  KMF = 'KMF',
  CDF = 'CDF',
  CKD = 'CKD',
  CRC = 'CRC',
  HRK = 'HRK',
  CUP = 'CUP',
  ANG = 'ANG',
  CZK = 'CZK',
  DKK = 'DKK',
  DJF = 'DJF',
  DOP = 'DOP',
  EGP = 'EGP',
  ERN = 'ERN',
  SZL = 'SZL',
  ETB = 'ETB',
  FJD = 'FJD',
  FKP = 'FKP',
  GEL = 'GEL',
  GHS = 'GHS',
  GIP = 'GIP',
  GMD = 'GMD',
  GTQ = 'GTQ',
  GNF = 'GNF',
  GYD = 'GYD',
  HTG = 'HTG',
  HNL = 'HNL',
  HUF = 'HUF',
  ISK = 'ISK',
  IDR = 'IDR',
  IRR = 'IRR',
  IQD = 'IQD',
  ILS = 'ILS',
  JMD = 'JMD',
  JEP = 'JEP',
  JOD = 'JOD',
  KZT = 'KZT',
  KES = 'KES',
  KPW = 'KPW',
  KWD = 'KWD',
  KGS = 'KGS',
  LAK = 'LAK',
  LBP = 'LBP',
  LSL = 'LSL',
  LRD = 'LRD',
  LYD = 'LYD',
  CHF = 'CHF',
  MOP = 'MOP',
  MGA = 'MGA',
  MWK = 'MWK',
  MVR = 'MVR',
  MRU = 'MRU',
  MUR = 'MUR',
  MXN = 'MXN',
  MDL = 'MDL',
  MNT = 'MNT',
  MAD = 'MAD',
  MZN = 'MZN',
  MMK = 'MMK',
  NAD = 'NAD',
  NPR = 'NPR',
  NZD = 'NZD',
  NIO = 'NIO',
  NGN = 'NGN',
  OMR = 'OMR',
  PKR = 'PKR',
  PAB = 'PAB',
  PGK = 'PGK',
  PYG = 'PYG',
  PEN = 'PEN',
  PHP = 'PHP',
  PLN = 'PLN',
  QAR = 'QAR',
  RON = 'RON',
  RUB = 'RUB',
  RWF = 'RWF',
  SAR = 'SAR',
  RSD = 'RSD',
  SCR = 'SCR',
  SLL = 'SLL',
  SBD = 'SBD',
  SOS = 'SOS',
  ZAR = 'ZAR',
  SSP = 'SSP',
  LKR = 'LKR',
  SDG = 'SDG',
  SRD = 'SRD',
  SEK = 'SEK',
  SYP = 'SYP',
  TJS = 'TJS',
  TZS = 'TZS',
  TND = 'TND',
  TRY = 'TRY',
  TMT = 'TMT',
  UGX = 'UGX',
  UAH = 'UAH',
  AED = 'AED',
  GBP = 'GBP',
  UYU = 'UYU',
  UZS = 'UZS',
  VUV = 'VUV',
  VES = 'VES',
  YER = 'YER',
  ZMW = 'ZMW',
  ZWL = 'ZWL',
  XPF = 'XPF',
  GGP = 'GGP',
  IMP = 'IMP',
  MKD = 'MKD',
  SHP = 'SHP',
  WST = 'WST',
  STN = 'STN',
  TOP = 'TOP',
  TTD = 'TTD',
}

export const defaultPriorityCountries = [
  {
    value: CountryCode.TW,
    countryCallingCode: '+886',
    currency: CurrencyCod.TWD,
  },
  {
    value: CountryCode.HK,
    countryCallingCode: '+852',
    currency: CurrencyCod.HKD,
  },
  {
    value: CountryCode.JP,
    countryCallingCode: '+81',
    currency: CurrencyCod.JPY,
  },
  {
    value: CountryCode.CN,
    countryCallingCode: '+86',
    currency: CurrencyCod.CNY,
  },
  {
    value: CountryCode.MY,
    countryCallingCode: '+60',
    currency: CurrencyCod.MYR,
  },
  {
    value: CountryCode.SG,
    countryCallingCode: '+65',
    currency: CurrencyCod.SGD,
  },
  {
    value: CountryCode.TH,
    countryCallingCode: '+66',
    currency: CurrencyCod.THB,
  },
  {
    value: CountryCode.VN,
    countryCallingCode: '+84',
    currency: CurrencyCod.VND,
  },
  {
    value: CountryCode.KR,
    countryCallingCode: '+82',
    currency: CurrencyCod.KRW,
  },
]

// 根據 ISO 3166-2 產生全部國家
// ref: https://docs.google.com/spreadsheets/d/18JbCi4uvwDplnCOY-UJ5LVPXUXGlnckEqqL9SQcJI7M/edit?gid=2080513620#gid=2080513620
export const allCountries: {
  value: CountryCode
  countryCallingCode: string
  currency: CurrencyCod | '' // 因為南極洲沒有貨幣, 所以使用空字串
}[] = [
  {
    value: CountryCode.TW,
    countryCallingCode: '+886',
    currency: CurrencyCod.TWD,
  },
  {
    value: CountryCode.JP,
    countryCallingCode: '+81',
    currency: CurrencyCod.JPY,
  },
  {
    value: CountryCode.HK,
    countryCallingCode: '+852',
    currency: CurrencyCod.HKD,
  },
  {
    value: CountryCode.MY,
    countryCallingCode: '+60',
    currency: CurrencyCod.MYR,
  },
  {
    value: CountryCode.TH,
    countryCallingCode: '+66',
    currency: CurrencyCod.THB,
  },
  {
    value: CountryCode.VN,
    countryCallingCode: '+84',
    currency: CurrencyCod.VND,
  },
  {
    value: CountryCode.SG,
    countryCallingCode: '+65',
    currency: CurrencyCod.SGD,
  },
  {
    value: CountryCode.KR,
    countryCallingCode: '+82',
    currency: CurrencyCod.KRW,
  },
  {
    value: CountryCode.AF,
    countryCallingCode: '+93',
    currency: CurrencyCod.AFN,
  },
  {
    value: CountryCode.AX,
    countryCallingCode: '+358',
    currency: CurrencyCod.EUR,
  },
  {
    value: CountryCode.AL,
    countryCallingCode: '+355',
    currency: CurrencyCod.ALL,
  },
  {
    value: CountryCode.DZ,
    countryCallingCode: '+213',
    currency: CurrencyCod.DZD,
  },
  {
    value: CountryCode.AS,
    countryCallingCode: '+1684',
    currency: CurrencyCod.USD,
  },
  {
    value: CountryCode.AD,
    countryCallingCode: '+376',
    currency: CurrencyCod.EUR,
  },
  {
    value: CountryCode.AO,
    countryCallingCode: '+244',
    currency: CurrencyCod.AOA,
  },
  {
    value: CountryCode.AI,
    countryCallingCode: '+1264',
    currency: CurrencyCod.XCD,
  },
  { value: CountryCode.AQ, countryCallingCode: '', currency: '' },
  {
    value: CountryCode.AG,
    countryCallingCode: '+1268',
    currency: CurrencyCod.XCD,
  },
  {
    value: CountryCode.AR,
    countryCallingCode: '+54',
    currency: CurrencyCod.ARS,
  },
  {
    value: CountryCode.AM,
    countryCallingCode: '+374',
    currency: CurrencyCod.AMD,
  },
  {
    value: CountryCode.AW,
    countryCallingCode: '+297',
    currency: CurrencyCod.AWG,
  },
  {
    value: CountryCode.AU,
    countryCallingCode: '+61',
    currency: CurrencyCod.AUD,
  },
  {
    value: CountryCode.AT,
    countryCallingCode: '+43',
    currency: CurrencyCod.EUR,
  },
  {
    value: CountryCode.AZ,
    countryCallingCode: '+994',
    currency: CurrencyCod.AZN,
  },
  {
    value: CountryCode.BS,
    countryCallingCode: '+1242',
    currency: CurrencyCod.BSD,
  },
  {
    value: CountryCode.BH,
    countryCallingCode: '+973',
    currency: CurrencyCod.BHD,
  },
  {
    value: CountryCode.BD,
    countryCallingCode: '+880',
    currency: CurrencyCod.BDT,
  },
  {
    value: CountryCode.BB,
    countryCallingCode: '+1246',
    currency: CurrencyCod.BBD,
  },
  {
    value: CountryCode.BY,
    countryCallingCode: '+375',
    currency: CurrencyCod.BYN,
  },
  {
    value: CountryCode.BE,
    countryCallingCode: '+32',
    currency: CurrencyCod.EUR,
  },
  {
    value: CountryCode.BZ,
    countryCallingCode: '+501',
    currency: CurrencyCod.BZD,
  },
  {
    value: CountryCode.BJ,
    countryCallingCode: '+229',
    currency: CurrencyCod.XOF,
  },
  {
    value: CountryCode.BM,
    countryCallingCode: '+1441',
    currency: CurrencyCod.BMD,
  },
  {
    value: CountryCode.BT,
    countryCallingCode: '+975',
    currency: CurrencyCod.BTN,
  },
  {
    value: CountryCode.BO,
    countryCallingCode: '+591',
    currency: CurrencyCod.BOB,
  },
  {
    value: CountryCode.BQ,
    countryCallingCode: '+599',
    currency: CurrencyCod.USD,
  },
  {
    value: CountryCode.BA,
    countryCallingCode: '+387',
    currency: CurrencyCod.BAM,
  },
  {
    value: CountryCode.BW,
    countryCallingCode: '+267',
    currency: CurrencyCod.BWP,
  },
  {
    value: CountryCode.BV,
    countryCallingCode: '',
    currency: CurrencyCod.NOK,
  },
  {
    value: CountryCode.BR,
    countryCallingCode: '+55',
    currency: CurrencyCod.BRL,
  },
  {
    value: CountryCode.IO,
    countryCallingCode: '+246',
    currency: CurrencyCod.INR,
  },
  {
    value: CountryCode.BN,
    countryCallingCode: '+673',
    currency: CurrencyCod.BND,
  },
  {
    value: CountryCode.BG,
    countryCallingCode: '+359',
    currency: CurrencyCod.BGN,
  },
  {
    value: CountryCode.BF,
    countryCallingCode: '+226',
    currency: CurrencyCod.XOF,
  },
  {
    value: CountryCode.BI,
    countryCallingCode: '+257',
    currency: CurrencyCod.BIF,
  },
  {
    value: CountryCode.CV,
    countryCallingCode: '+238',
    currency: CurrencyCod.CVE,
  },
  {
    value: CountryCode.KH,
    countryCallingCode: '+855',
    currency: CurrencyCod.KHR,
  },
  {
    value: CountryCode.CM,
    countryCallingCode: '+237',
    currency: CurrencyCod.XAF,
  },
  {
    value: CountryCode.CA,
    countryCallingCode: '+1',
    currency: CurrencyCod.CAD,
  },
  {
    value: CountryCode.KY,
    countryCallingCode: '+1345',
    currency: CurrencyCod.KYD,
  },
  {
    value: CountryCode.CF,
    countryCallingCode: '+236',
    currency: CurrencyCod.XAF,
  },
  {
    value: CountryCode.TD,
    countryCallingCode: '+235',
    currency: CurrencyCod.XAF,
  },
  {
    value: CountryCode.CL,
    countryCallingCode: '+56',
    currency: CurrencyCod.CLP,
  },
  {
    value: CountryCode.CN,
    countryCallingCode: '+86',
    currency: CurrencyCod.CNY,
  },
  {
    value: CountryCode.CX,
    countryCallingCode: '+61',
    currency: CurrencyCod.AUD,
  },
  {
    value: CountryCode.CC,
    countryCallingCode: '+61',
    currency: CurrencyCod.AUD,
  },
  {
    value: CountryCode.CO,
    countryCallingCode: '+57',
    currency: CurrencyCod.COP,
  },
  {
    value: CountryCode.KM,
    countryCallingCode: '+269',
    currency: CurrencyCod.KMF,
  },
  {
    value: CountryCode.CG,
    countryCallingCode: '+242',
    currency: CurrencyCod.XAF,
  },
  {
    value: CountryCode.CD,
    countryCallingCode: '+243',
    currency: CurrencyCod.CDF,
  },
  {
    value: CountryCode.CK,
    countryCallingCode: '+682',
    currency: CurrencyCod.CKD,
  },
  {
    value: CountryCode.CR,
    countryCallingCode: '+506',
    currency: CurrencyCod.CRC,
  },
  {
    value: CountryCode.CI,
    countryCallingCode: '+225',
    currency: CurrencyCod.XOF,
  },
  {
    value: CountryCode.HR,
    countryCallingCode: '+385',
    currency: CurrencyCod.HRK,
  },
  {
    value: CountryCode.CU,
    countryCallingCode: '+53',
    currency: CurrencyCod.CUP,
  },
  {
    value: CountryCode.CW,
    countryCallingCode: '+599',
    currency: CurrencyCod.ANG,
  },
  {
    value: CountryCode.CY,
    countryCallingCode: '+357',
    currency: CurrencyCod.EUR,
  },
  {
    value: CountryCode.CZ,
    countryCallingCode: '+420',
    currency: CurrencyCod.CZK,
  },
  {
    value: CountryCode.DK,
    countryCallingCode: '+45',
    currency: CurrencyCod.DKK,
  },
  {
    value: CountryCode.DJ,
    countryCallingCode: '+253',
    currency: CurrencyCod.DJF,
  },
  {
    value: CountryCode.DM,
    countryCallingCode: '+1767',
    currency: CurrencyCod.XCD,
  },
  {
    value: CountryCode.DO,
    countryCallingCode: '+1809',
    currency: CurrencyCod.DOP,
  },
  {
    value: CountryCode.EC,
    countryCallingCode: '+593',
    currency: CurrencyCod.USD,
  },
  {
    value: CountryCode.EG,
    countryCallingCode: '+20',
    currency: CurrencyCod.EGP,
  },
  {
    value: CountryCode.SV,
    countryCallingCode: '+503',
    currency: CurrencyCod.USD,
  },
  {
    value: CountryCode.GQ,
    countryCallingCode: '+240',
    currency: CurrencyCod.XAF,
  },
  {
    value: CountryCode.ER,
    countryCallingCode: '+291',
    currency: CurrencyCod.ERN,
  },
  {
    value: CountryCode.EE,
    countryCallingCode: '+372',
    currency: CurrencyCod.EUR,
  },
  {
    value: CountryCode.SZ,
    countryCallingCode: '+268',
    currency: CurrencyCod.SZL,
  },
  {
    value: CountryCode.ET,
    countryCallingCode: '+251',
    currency: CurrencyCod.ETB,
  },
  {
    value: CountryCode.FK,
    countryCallingCode: '+500',
    currency: CurrencyCod.FKP,
  },
  {
    value: CountryCode.FO,
    countryCallingCode: '+298',
    currency: CurrencyCod.DKK,
  },
  {
    value: CountryCode.FJ,
    countryCallingCode: '+679',
    currency: CurrencyCod.FJD,
  },
  {
    value: CountryCode.FI,
    countryCallingCode: '+358',
    currency: CurrencyCod.EUR,
  },
  {
    value: CountryCode.FR,
    countryCallingCode: '+33',
    currency: CurrencyCod.EUR,
  },
  {
    value: CountryCode.GF,
    countryCallingCode: '+594',
    currency: CurrencyCod.EUR,
  },
  {
    value: CountryCode.PF,
    countryCallingCode: '+689',
    currency: CurrencyCod.XPF,
  },
  {
    value: CountryCode.TF,
    countryCallingCode: '',
    currency: CurrencyCod.EUR,
  },
  {
    value: CountryCode.GA,
    countryCallingCode: '+241',
    currency: CurrencyCod.XAF,
  },
  {
    value: CountryCode.GM,
    countryCallingCode: '+220',
    currency: CurrencyCod.GMD,
  },
  {
    value: CountryCode.GE,
    countryCallingCode: '+995',
    currency: CurrencyCod.GEL,
  },
  {
    value: CountryCode.DE,
    countryCallingCode: '+49',
    currency: CurrencyCod.EUR,
  },
  {
    value: CountryCode.GH,
    countryCallingCode: '+233',
    currency: CurrencyCod.GHS,
  },
  {
    value: CountryCode.GI,
    countryCallingCode: '+350',
    currency: CurrencyCod.GIP,
  },
  {
    value: CountryCode.GR,
    countryCallingCode: '+30',
    currency: CurrencyCod.EUR,
  },
  {
    value: CountryCode.GL,
    countryCallingCode: '+299',
    currency: CurrencyCod.DKK,
  },
  {
    value: CountryCode.GD,
    countryCallingCode: '+1473',
    currency: CurrencyCod.XCD,
  },
  {
    value: CountryCode.GP,
    countryCallingCode: '+590',
    currency: CurrencyCod.EUR,
  },
  {
    value: CountryCode.GU,
    countryCallingCode: '+1671',
    currency: CurrencyCod.USD,
  },
  {
    value: CountryCode.GT,
    countryCallingCode: '+502',
    currency: CurrencyCod.GTQ,
  },
  {
    value: CountryCode.GG,
    countryCallingCode: '+44',
    currency: CurrencyCod.GGP,
  },
  {
    value: CountryCode.GN,
    countryCallingCode: '+224',
    currency: CurrencyCod.GNF,
  },
  {
    value: CountryCode.GW,
    countryCallingCode: '+245',
    currency: CurrencyCod.XOF,
  },
  {
    value: CountryCode.GY,
    countryCallingCode: '+592',
    currency: CurrencyCod.GYD,
  },
  {
    value: CountryCode.HT,
    countryCallingCode: '+509',
    currency: CurrencyCod.HTG,
  },
  {
    value: CountryCode.HM,
    countryCallingCode: '',
    currency: CurrencyCod.AUD,
  },
  {
    value: CountryCode.VA,
    countryCallingCode: '+39',
    currency: CurrencyCod.EUR,
  },
  {
    value: CountryCode.HN,
    countryCallingCode: '+504',
    currency: CurrencyCod.HNL,
  },
  {
    value: CountryCode.HU,
    countryCallingCode: '+36',
    currency: CurrencyCod.HUF,
  },
  {
    value: CountryCode.IS,
    countryCallingCode: '+354',
    currency: CurrencyCod.ISK,
  },
  {
    value: CountryCode.IN,
    countryCallingCode: '+91',
    currency: CurrencyCod.INR,
  },
  {
    value: CountryCode.ID,
    countryCallingCode: '+62',
    currency: CurrencyCod.IDR,
  },
  {
    value: CountryCode.IR,
    countryCallingCode: '+98',
    currency: CurrencyCod.IRR,
  },
  {
    value: CountryCode.IQ,
    countryCallingCode: '+964',
    currency: CurrencyCod.IQD,
  },
  {
    value: CountryCode.IE,
    countryCallingCode: '+353',
    currency: CurrencyCod.EUR,
  },
  {
    value: CountryCode.IM,
    countryCallingCode: '+44',
    currency: CurrencyCod.IMP,
  },
  {
    value: CountryCode.IL,
    countryCallingCode: '+972',
    currency: CurrencyCod.ILS,
  },
  {
    value: CountryCode.IT,
    countryCallingCode: '+39',
    currency: CurrencyCod.EUR,
  },
  {
    value: CountryCode.JM,
    countryCallingCode: '+1876',
    currency: CurrencyCod.JMD,
  },
  {
    value: CountryCode.JE,
    countryCallingCode: '+44',
    currency: CurrencyCod.JEP,
  },
  {
    value: CountryCode.JO,
    countryCallingCode: '+962',
    currency: CurrencyCod.JOD,
  },
  {
    value: CountryCode.KZ,
    countryCallingCode: '+7',
    currency: CurrencyCod.KZT,
  },
  {
    value: CountryCode.KE,
    countryCallingCode: '+254',
    currency: CurrencyCod.KES,
  },
  {
    value: CountryCode.KI,
    countryCallingCode: '+686',
    currency: CurrencyCod.AUD,
  },
  {
    value: CountryCode.KP,
    countryCallingCode: '+850',
    currency: CurrencyCod.KPW,
  },
  {
    value: CountryCode.KW,
    countryCallingCode: '+965',
    currency: CurrencyCod.KWD,
  },
  {
    value: CountryCode.KG,
    countryCallingCode: '+996',
    currency: CurrencyCod.KGS,
  },
  {
    value: CountryCode.LA,
    countryCallingCode: '+856',
    currency: CurrencyCod.LAK,
  },
  {
    value: CountryCode.LV,
    countryCallingCode: '+371',
    currency: CurrencyCod.EUR,
  },
  {
    value: CountryCode.LB,
    countryCallingCode: '+961',
    currency: CurrencyCod.LBP,
  },
  {
    value: CountryCode.LS,
    countryCallingCode: '+266',
    currency: CurrencyCod.LSL,
  },
  {
    value: CountryCode.LR,
    countryCallingCode: '+231',
    currency: CurrencyCod.LRD,
  },
  {
    value: CountryCode.LY,
    countryCallingCode: '+218',
    currency: CurrencyCod.LYD,
  },
  {
    value: CountryCode.LI,
    countryCallingCode: '+423',
    currency: CurrencyCod.CHF,
  },
  {
    value: CountryCode.LT,
    countryCallingCode: '+370',
    currency: CurrencyCod.EUR,
  },
  {
    value: CountryCode.LU,
    countryCallingCode: '+352',
    currency: CurrencyCod.EUR,
  },
  {
    value: CountryCode.MO,
    countryCallingCode: '+853',
    currency: CurrencyCod.MOP,
  },
  {
    value: CountryCode.MG,
    countryCallingCode: '+261',
    currency: CurrencyCod.MGA,
  },
  {
    value: CountryCode.MW,
    countryCallingCode: '+265',
    currency: CurrencyCod.MWK,
  },
  {
    value: CountryCode.MV,
    countryCallingCode: '+960',
    currency: CurrencyCod.MVR,
  },
  {
    value: CountryCode.ML,
    countryCallingCode: '+223',
    currency: CurrencyCod.XOF,
  },
  {
    value: CountryCode.MT,
    countryCallingCode: '+356',
    currency: CurrencyCod.EUR,
  },
  {
    value: CountryCode.MH,
    countryCallingCode: '+692',
    currency: CurrencyCod.USD,
  },
  {
    value: CountryCode.MQ,
    countryCallingCode: '+596',
    currency: CurrencyCod.EUR,
  },
  {
    value: CountryCode.MR,
    countryCallingCode: '+222',
    currency: CurrencyCod.MRU,
  },
  {
    value: CountryCode.MU,
    countryCallingCode: '+230',
    currency: CurrencyCod.MUR,
  },
  {
    value: CountryCode.YT,
    countryCallingCode: '+262',
    currency: CurrencyCod.EUR,
  },
  {
    value: CountryCode.MX,
    countryCallingCode: '+52',
    currency: CurrencyCod.MXN,
  },
  {
    value: CountryCode.FM,
    countryCallingCode: '+691',
    currency: CurrencyCod.USD,
  },
  {
    value: CountryCode.MD,
    countryCallingCode: '+373',
    currency: CurrencyCod.MDL,
  },
  {
    value: CountryCode.MC,
    countryCallingCode: '+377',
    currency: CurrencyCod.EUR,
  },
  {
    value: CountryCode.MN,
    countryCallingCode: '+976',
    currency: CurrencyCod.MNT,
  },
  {
    value: CountryCode.ME,
    countryCallingCode: '+382',
    currency: CurrencyCod.EUR,
  },
  {
    value: CountryCode.MS,
    countryCallingCode: '+1664',
    currency: CurrencyCod.XCD,
  },
  {
    value: CountryCode.MA,
    countryCallingCode: '+212',
    currency: CurrencyCod.MAD,
  },
  {
    value: CountryCode.MZ,
    countryCallingCode: '+258',
    currency: CurrencyCod.MZN,
  },
  {
    value: CountryCode.MM,
    countryCallingCode: '+95',
    currency: CurrencyCod.MMK,
  },
  {
    value: CountryCode.NA,
    countryCallingCode: '+264',
    currency: CurrencyCod.NAD,
  },
  {
    value: CountryCode.NR,
    countryCallingCode: '+674',
    currency: CurrencyCod.AUD,
  },
  {
    value: CountryCode.NP,
    countryCallingCode: '+977',
    currency: CurrencyCod.NPR,
  },
  {
    value: CountryCode.NL,
    countryCallingCode: '+31',
    currency: CurrencyCod.EUR,
  },
  {
    value: CountryCode.NC,
    countryCallingCode: '+687',
    currency: CurrencyCod.XPF,
  },
  {
    value: CountryCode.NZ,
    countryCallingCode: '+64',
    currency: CurrencyCod.NZD,
  },
  {
    value: CountryCode.NI,
    countryCallingCode: '+505',
    currency: CurrencyCod.NIO,
  },
  {
    value: CountryCode.NE,
    countryCallingCode: '+227',
    currency: CurrencyCod.XOF,
  },
  {
    value: CountryCode.NG,
    countryCallingCode: '+234',
    currency: CurrencyCod.NGN,
  },
  {
    value: CountryCode.NU,
    countryCallingCode: '+683',
    currency: CurrencyCod.NZD,
  },
  {
    value: CountryCode.NF,
    countryCallingCode: '+672',
    currency: CurrencyCod.AUD,
  },
  {
    value: CountryCode.MK,
    countryCallingCode: '+389',
    currency: CurrencyCod.MKD,
  },
  {
    value: CountryCode.MP,
    countryCallingCode: '+1670',
    currency: CurrencyCod.USD,
  },
  {
    value: CountryCode.NO,
    countryCallingCode: '+47',
    currency: CurrencyCod.NOK,
  },
  {
    value: CountryCode.OM,
    countryCallingCode: '+968',
    currency: CurrencyCod.OMR,
  },
  {
    value: CountryCode.PK,
    countryCallingCode: '+92',
    currency: CurrencyCod.PKR,
  },
  {
    value: CountryCode.PW,
    countryCallingCode: '+680',
    currency: CurrencyCod.USD,
  },
  {
    value: CountryCode.PS,
    countryCallingCode: '+970',
    currency: CurrencyCod.ILS,
  },
  {
    value: CountryCode.PA,
    countryCallingCode: '+507',
    currency: CurrencyCod.PAB,
  },
  {
    value: CountryCode.PG,
    countryCallingCode: '+675',
    currency: CurrencyCod.PGK,
  },
  {
    value: CountryCode.PY,
    countryCallingCode: '+595',
    currency: CurrencyCod.PYG,
  },
  {
    value: CountryCode.PE,
    countryCallingCode: '+51',
    currency: CurrencyCod.PEN,
  },
  {
    value: CountryCode.PH,
    countryCallingCode: '+63',
    currency: CurrencyCod.PHP,
  },
  {
    value: CountryCode.PN,
    countryCallingCode: '+64',
    currency: CurrencyCod.USD,
  },
  {
    value: CountryCode.PL,
    countryCallingCode: '+48',
    currency: CurrencyCod.PLN,
  },
  {
    value: CountryCode.PT,
    countryCallingCode: '+351',
    currency: CurrencyCod.EUR,
  },
  {
    value: CountryCode.PR,
    countryCallingCode: '+1',
    currency: CurrencyCod.USD,
  },
  {
    value: CountryCode.QA,
    countryCallingCode: '+974',
    currency: CurrencyCod.QAR,
  },
  {
    value: CountryCode.RE,
    countryCallingCode: '+262',
    currency: CurrencyCod.EUR,
  },
  {
    value: CountryCode.RO,
    countryCallingCode: '+40',
    currency: CurrencyCod.RON,
  },
  {
    value: CountryCode.RU,
    countryCallingCode: '+7',
    currency: CurrencyCod.RUB,
  },
  {
    value: CountryCode.RW,
    countryCallingCode: '+250',
    currency: CurrencyCod.RWF,
  },
  {
    value: CountryCode.BL,
    countryCallingCode: '+590',
    currency: CurrencyCod.EUR,
  },
  {
    value: CountryCode.SH,
    countryCallingCode: '+290',
    currency: CurrencyCod.SHP,
  },
  {
    value: CountryCode.KN,
    countryCallingCode: '+1869',
    currency: CurrencyCod.XCD,
  },
  {
    value: CountryCode.LC,
    countryCallingCode: '+1758',
    currency: CurrencyCod.XCD,
  },
  {
    value: CountryCode.MF,
    countryCallingCode: '+590',
    currency: CurrencyCod.EUR,
  },
  {
    value: CountryCode.PM,
    countryCallingCode: '+508',
    currency: CurrencyCod.EUR,
  },
  {
    value: CountryCode.VC,
    countryCallingCode: '+1784',
    currency: CurrencyCod.XCD,
  },
  {
    value: CountryCode.WS,
    countryCallingCode: '+685',
    currency: CurrencyCod.WST,
  },
  {
    value: CountryCode.SM,
    countryCallingCode: '+378',
    currency: CurrencyCod.EUR,
  },
  {
    value: CountryCode.ST,
    countryCallingCode: '+239',
    currency: CurrencyCod.STN,
  },
  {
    value: CountryCode.SA,
    countryCallingCode: '+966',
    currency: CurrencyCod.SAR,
  },
  {
    value: CountryCode.SN,
    countryCallingCode: '+221',
    currency: CurrencyCod.XOF,
  },
  {
    value: CountryCode.RS,
    countryCallingCode: '+381',
    currency: CurrencyCod.RSD,
  },
  {
    value: CountryCode.SC,
    countryCallingCode: '+248',
    currency: CurrencyCod.SCR,
  },
  {
    value: CountryCode.SL,
    countryCallingCode: '+232',
    currency: CurrencyCod.SLL,
  },
  {
    value: CountryCode.SX,
    countryCallingCode: '+1721',
    currency: CurrencyCod.ANG,
  },
  {
    value: CountryCode.SK,
    countryCallingCode: '+421',
    currency: CurrencyCod.EUR,
  },
  {
    value: CountryCode.SI,
    countryCallingCode: '+386',
    currency: CurrencyCod.EUR,
  },
  {
    value: CountryCode.SB,
    countryCallingCode: '+677',
    currency: CurrencyCod.SBD,
  },
  {
    value: CountryCode.SO,
    countryCallingCode: '+252',
    currency: CurrencyCod.SOS,
  },
  {
    value: CountryCode.ZA,
    countryCallingCode: '+27',
    currency: CurrencyCod.ZAR,
  },
  {
    value: CountryCode.GS,
    countryCallingCode: '',
    currency: CurrencyCod.GBP,
  },
  {
    value: CountryCode.SS,
    countryCallingCode: '+211',
    currency: CurrencyCod.SSP,
  },
  {
    value: CountryCode.ES,
    countryCallingCode: '+34',
    currency: CurrencyCod.EUR,
  },
  {
    value: CountryCode.LK,
    countryCallingCode: '+94',
    currency: CurrencyCod.LKR,
  },
  {
    value: CountryCode.SD,
    countryCallingCode: '+249',
    currency: CurrencyCod.SDG,
  },
  {
    value: CountryCode.SR,
    countryCallingCode: '+597',
    currency: CurrencyCod.SRD,
  },
  {
    value: CountryCode.SJ,
    countryCallingCode: '+4779',
    currency: CurrencyCod.NOK,
  },
  {
    value: CountryCode.SE,
    countryCallingCode: '+46',
    currency: CurrencyCod.SEK,
  },
  {
    value: CountryCode.CH,
    countryCallingCode: '+41',
    currency: CurrencyCod.CHF,
  },
  {
    value: CountryCode.SY,
    countryCallingCode: '+963',
    currency: CurrencyCod.SYP,
  },
  {
    value: CountryCode.TJ,
    countryCallingCode: '+992',
    currency: CurrencyCod.TJS,
  },
  {
    value: CountryCode.TZ,
    countryCallingCode: '+255',
    currency: CurrencyCod.TZS,
  },
  {
    value: CountryCode.TL,
    countryCallingCode: '+670',
    currency: CurrencyCod.USD,
  },
  {
    value: CountryCode.TG,
    countryCallingCode: '+228',
    currency: CurrencyCod.XOF,
  },
  {
    value: CountryCode.TK,
    countryCallingCode: '+690',
    currency: CurrencyCod.NZD,
  },
  {
    value: CountryCode.TO,
    countryCallingCode: '+676',
    currency: CurrencyCod.TOP,
  },
  {
    value: CountryCode.TT,
    countryCallingCode: '+1868',
    currency: CurrencyCod.TTD,
  },
  {
    value: CountryCode.TN,
    countryCallingCode: '+216',
    currency: CurrencyCod.TND,
  },
  {
    value: CountryCode.TR,
    countryCallingCode: '+90',
    currency: CurrencyCod.TRY,
  },
  {
    value: CountryCode.TM,
    countryCallingCode: '+993',
    currency: CurrencyCod.TMT,
  },
  {
    value: CountryCode.TC,
    countryCallingCode: '+1649',
    currency: CurrencyCod.USD,
  },
  {
    value: CountryCode.TV,
    countryCallingCode: '+688',
    currency: CurrencyCod.AUD,
  },
  {
    value: CountryCode.UG,
    countryCallingCode: '+256',
    currency: CurrencyCod.UGX,
  },
  {
    value: CountryCode.UA,
    countryCallingCode: '+380',
    currency: CurrencyCod.UAH,
  },
  {
    value: CountryCode.AE,
    countryCallingCode: '+971',
    currency: CurrencyCod.AED,
  },
  {
    value: CountryCode.GB,
    countryCallingCode: '+44',
    currency: CurrencyCod.GBP,
  },
  {
    value: CountryCode.US,
    countryCallingCode: '+1',
    currency: CurrencyCod.USD,
  },
  {
    value: CountryCode.UM,
    countryCallingCode: '+1',
    currency: CurrencyCod.USD,
  },
  {
    value: CountryCode.UY,
    countryCallingCode: '+598',
    currency: CurrencyCod.UYU,
  },
  {
    value: CountryCode.UZ,
    countryCallingCode: '+998',
    currency: CurrencyCod.UZS,
  },
  {
    value: CountryCode.VU,
    countryCallingCode: '+678',
    currency: CurrencyCod.VUV,
  },
  {
    value: CountryCode.VE,
    countryCallingCode: '+58',
    currency: CurrencyCod.VES,
  },
  {
    value: CountryCode.VG,
    countryCallingCode: '+1284',
    currency: CurrencyCod.GBP,
  },
  {
    value: CountryCode.VI,
    countryCallingCode: '+1340',
    currency: CurrencyCod.USD,
  },
  {
    value: CountryCode.WF,
    countryCallingCode: '+681',
    currency: CurrencyCod.XPF,
  },
  {
    value: CountryCode.EH,
    countryCallingCode: '+212',
    currency: CurrencyCod.MAD,
  },
  {
    value: CountryCode.YE,
    countryCallingCode: '+967',
    currency: CurrencyCod.YER,
  },
  {
    value: CountryCode.ZM,
    countryCallingCode: '+260',
    currency: CurrencyCod.ZMW,
  },
  {
    value: CountryCode.ZW,
    countryCallingCode: '+263',
    currency: CurrencyCod.ZWL,
  },
]

export const UNLIMITED_COUNTRY_CODE = 'unlimit'

export type AllCountryCode = CountryCode | typeof UNLIMITED_COUNTRY_CODE

export type AllCountryCurrency = (typeof allCountries)[number]['currency']

export const AllCountryCodeParam = createEnumParam([
  UNLIMITED_COUNTRY_CODE,
  ...allCountries.map((country) => country.value),
])

export const COUNTRY_I18N_MAP: Record<AllCountryCode, I18nId> = {
  tw: 'search:TW',
  jp: 'search:JP',
  hk: 'search:HK',
  my: 'search:MY',
  th: 'search:TH',
  vn: 'search:VN',
  sg: 'search:SG',
  kr: 'search:KR',
  af: 'search:AF',
  ax: 'search:AX',
  al: 'search:AL',
  dz: 'search:DZ',
  as: 'search:AS',
  ad: 'search:AD',
  ao: 'search:AO',
  ai: 'search:AI',
  aq: 'search:AQ',
  ag: 'search:AG',
  ar: 'search:AR',
  am: 'search:AM',
  aw: 'search:AW',
  au: 'search:AU',
  at: 'search:AT',
  az: 'search:AZ',
  bs: 'search:BS',
  bh: 'search:BH',
  bd: 'search:BD',
  bb: 'search:BB',
  by: 'search:BY',
  be: 'search:BE',
  bz: 'search:BZ',
  bj: 'search:BJ',
  bm: 'search:BM',
  bt: 'search:BT',
  bo: 'search:BO',
  bq: 'search:BQ',
  ba: 'search:BA',
  bw: 'search:BW',
  bv: 'search:BV',
  br: 'search:BR',
  io: 'search:IO',
  bn: 'search:BN',
  bg: 'search:BG',
  bf: 'search:BF',
  bi: 'search:BI',
  cv: 'search:CV',
  kh: 'search:KH',
  cm: 'search:CM',
  ca: 'search:CA',
  ky: 'search:KY',
  cf: 'search:CF',
  td: 'search:TD',
  cl: 'search:CL',
  cn: 'search:CN',
  cx: 'search:CX',
  cc: 'search:CC',
  co: 'search:CO',
  km: 'search:KM',
  cg: 'search:CG',
  cd: 'search:CD',
  ck: 'search:CK',
  cr: 'search:CR',
  ci: 'search:CI',
  hr: 'search:HR',
  cu: 'search:CU',
  cw: 'search:CW',
  cy: 'search:CY',
  cz: 'search:CZ',
  dk: 'search:DK',
  dj: 'search:DJ',
  dm: 'search:DM',
  do: 'search:DO',
  ec: 'search:EC',
  eg: 'search:EG',
  sv: 'search:SV',
  gq: 'search:GQ',
  er: 'search:ER',
  ee: 'search:EE',
  sz: 'search:SZ',
  et: 'search:ET',
  fk: 'search:FK',
  fo: 'search:FO',
  fj: 'search:FJ',
  fi: 'search:FI',
  fr: 'search:FR',
  gf: 'search:GF',
  pf: 'search:PF',
  tf: 'search:TF',
  ga: 'search:GA',
  gm: 'search:GM',
  ge: 'search:GE',
  de: 'search:DE',
  gh: 'search:GH',
  gi: 'search:GI',
  gr: 'search:GR',
  gl: 'search:GL',
  gd: 'search:GD',
  gp: 'search:GP',
  gu: 'search:GU',
  gt: 'search:GT',
  gg: 'search:GG',
  gn: 'search:GN',
  gw: 'search:GW',
  gy: 'search:GY',
  ht: 'search:HT',
  hm: 'search:HM',
  va: 'search:VA',
  hn: 'search:HN',
  hu: 'search:HU',
  is: 'search:IS',
  in: 'search:IN',
  id: 'search:ID',
  ir: 'search:IR',
  iq: 'search:IQ',
  ie: 'search:IE',
  im: 'search:IM',
  il: 'search:IL',
  it: 'search:IT',
  jm: 'search:JM',
  je: 'search:JE',
  jo: 'search:JO',
  kz: 'search:KZ',
  ke: 'search:KE',
  ki: 'search:KI',
  kp: 'search:KP',
  kw: 'search:KW',
  kg: 'search:KG',
  la: 'search:LA',
  lv: 'search:LV',
  lb: 'search:LB',
  ls: 'search:LS',
  lr: 'search:LR',
  ly: 'search:LY',
  li: 'search:LI',
  lt: 'search:LT',
  lu: 'search:LU',
  mo: 'search:MO',
  mg: 'search:MG',
  mw: 'search:MW',
  mv: 'search:MV',
  ml: 'search:ML',
  mt: 'search:MT',
  mh: 'search:MH',
  mq: 'search:MQ',
  mr: 'search:MR',
  mu: 'search:MU',
  yt: 'search:YT',
  mx: 'search:MX',
  fm: 'search:FM',
  md: 'search:MD',
  mc: 'search:MC',
  mn: 'search:MN',
  me: 'search:ME',
  ms: 'search:MS',
  ma: 'search:MA',
  mz: 'search:MZ',
  mm: 'search:MM',
  na: 'search:NA',
  nr: 'search:NR',
  np: 'search:NP',
  nl: 'search:NL',
  nc: 'search:NC',
  nz: 'search:NZ',
  ni: 'search:NI',
  ne: 'search:NE',
  ng: 'search:NG',
  nu: 'search:NU',
  nf: 'search:NF',
  mk: 'search:MK',
  mp: 'search:MP',
  no: 'search:NO',
  om: 'search:OM',
  pk: 'search:PK',
  pw: 'search:PW',
  ps: 'search:PS',
  pa: 'search:PA',
  pg: 'search:PG',
  py: 'search:PY',
  pe: 'search:PE',
  ph: 'search:PH',
  pn: 'search:PN',
  pl: 'search:PL',
  pt: 'search:PT',
  pr: 'search:PR',
  qa: 'search:QA',
  re: 'search:RE',
  ro: 'search:RO',
  ru: 'search:RU',
  rw: 'search:RW',
  bl: 'search:BL',
  sh: 'search:SH',
  kn: 'search:KN',
  lc: 'search:LC',
  mf: 'search:MF',
  pm: 'search:PM',
  vc: 'search:VC',
  ws: 'search:WS',
  sm: 'search:SM',
  st: 'search:ST',
  sa: 'search:SA',
  sn: 'search:SN',
  rs: 'search:RS',
  sc: 'search:SC',
  sl: 'search:SL',
  sx: 'search:SX',
  sk: 'search:SK',
  si: 'search:SI',
  sb: 'search:SB',
  so: 'search:SO',
  za: 'search:ZA',
  gs: 'search:GS',
  ss: 'search:SS',
  es: 'search:ES',
  lk: 'search:LK',
  sd: 'search:SD',
  sr: 'search:SR',
  sj: 'search:SJ',
  se: 'search:SE',
  ch: 'search:CH',
  sy: 'search:SY',
  tj: 'search:TJ',
  tz: 'search:TZ',
  tl: 'search:TL',
  tg: 'search:TG',
  tk: 'search:TK',
  to: 'search:TO',
  tt: 'search:TT',
  tn: 'search:TN',
  tr: 'search:TR',
  tm: 'search:TM',
  tc: 'search:TC',
  tv: 'search:TV',
  ug: 'search:UG',
  ua: 'search:UA',
  ae: 'search:AE',
  gb: 'search:GB',
  um: 'search:UM',
  us: 'search:US',
  uy: 'search:UY',
  uz: 'search:UZ',
  vu: 'search:VU',
  ve: 'search:VE',
  vg: 'search:VG',
  vi: 'search:VI',
  wf: 'search:WF',
  eh: 'search:EH',
  ye: 'search:YE',
  zm: 'search:ZM',
  zw: 'search:ZW',
  unlimit: 'search:unlimited',
}

export const CURRENCY_I18N_MAP: Record<CurrencyCod, I18nId> = {
  [CurrencyCod.TWD]: 'workspace:currency_TWD',
  [CurrencyCod.JPY]: 'workspace:currency_JPY',
  [CurrencyCod.HKD]: 'workspace:currency_HKD',
  [CurrencyCod.MYR]: 'workspace:currency_MYR',
  [CurrencyCod.THB]: 'workspace:currency_THB',
  [CurrencyCod.VND]: 'workspace:currency_VND',
  [CurrencyCod.SGD]: 'workspace:currency_SGD',
  [CurrencyCod.KRW]: 'workspace:currency_KRW',
  [CurrencyCod.AFN]: 'workspace:currency_AFN',
  [CurrencyCod.EUR]: 'workspace:currency_EUR',
  [CurrencyCod.ALL]: 'workspace:currency_ALL',
  [CurrencyCod.DZD]: 'workspace:currency_DZD',
  [CurrencyCod.USD]: 'workspace:currency_USD',
  [CurrencyCod.AOA]: 'workspace:currency_AOA',
  [CurrencyCod.XCD]: 'workspace:currency_XCD',
  [CurrencyCod.ARS]: 'workspace:currency_ARS',
  [CurrencyCod.AMD]: 'workspace:currency_AMD',
  [CurrencyCod.AWG]: 'workspace:currency_AWG',
  [CurrencyCod.AUD]: 'workspace:currency_AUD',
  [CurrencyCod.AZN]: 'workspace:currency_AZN',
  [CurrencyCod.BSD]: 'workspace:currency_BSD',
  [CurrencyCod.BHD]: 'workspace:currency_BHD',
  [CurrencyCod.BDT]: 'workspace:currency_BDT',
  [CurrencyCod.BBD]: 'workspace:currency_BBD',
  [CurrencyCod.BYN]: 'workspace:currency_BYN',
  [CurrencyCod.BZD]: 'workspace:currency_BZD',
  [CurrencyCod.XOF]: 'workspace:currency_XOF',
  [CurrencyCod.BMD]: 'workspace:currency_BMD',
  [CurrencyCod.BTN]: 'workspace:currency_BTN',
  [CurrencyCod.BOB]: 'workspace:currency_BOB',
  [CurrencyCod.BAM]: 'workspace:currency_BAM',
  [CurrencyCod.BWP]: 'workspace:currency_BWP',
  [CurrencyCod.NOK]: 'workspace:currency_NOK',
  [CurrencyCod.BRL]: 'workspace:currency_BRL',
  [CurrencyCod.INR]: 'workspace:currency_INR',
  [CurrencyCod.BND]: 'workspace:currency_BND',
  [CurrencyCod.BGN]: 'workspace:currency_BGN',
  [CurrencyCod.BIF]: 'workspace:currency_BIF',
  [CurrencyCod.CVE]: 'workspace:currency_CVE',
  [CurrencyCod.KHR]: 'workspace:currency_KHR',
  [CurrencyCod.XAF]: 'workspace:currency_XAF',
  [CurrencyCod.CAD]: 'workspace:currency_CAD',
  [CurrencyCod.KYD]: 'workspace:currency_KYD',
  [CurrencyCod.CLP]: 'workspace:currency_CLP',
  [CurrencyCod.CNY]: 'workspace:currency_CNY',
  [CurrencyCod.COP]: 'workspace:currency_COP',
  [CurrencyCod.KMF]: 'workspace:currency_KMF',
  [CurrencyCod.CDF]: 'workspace:currency_CDF',
  [CurrencyCod.CKD]: 'workspace:currency_CKD',
  [CurrencyCod.CRC]: 'workspace:currency_CRC',
  [CurrencyCod.HRK]: 'workspace:currency_HRK',
  [CurrencyCod.CUP]: 'workspace:currency_CUP',
  [CurrencyCod.ANG]: 'workspace:currency_ANG',
  [CurrencyCod.CZK]: 'workspace:currency_CZK',
  [CurrencyCod.DKK]: 'workspace:currency_DKK',
  [CurrencyCod.DJF]: 'workspace:currency_DJF',
  [CurrencyCod.DOP]: 'workspace:currency_DOP',
  [CurrencyCod.EGP]: 'workspace:currency_EGP',
  [CurrencyCod.ERN]: 'workspace:currency_ERN',
  [CurrencyCod.SZL]: 'workspace:currency_SZL',
  [CurrencyCod.ETB]: 'workspace:currency_ETB',
  [CurrencyCod.FJD]: 'workspace:currency_FJD',
  [CurrencyCod.FKP]: 'workspace:currency_FKP',
  [CurrencyCod.GEL]: 'workspace:currency_GEL',
  [CurrencyCod.GHS]: 'workspace:currency_GHS',
  [CurrencyCod.GIP]: 'workspace:currency_GIP',
  [CurrencyCod.GMD]: 'workspace:currency_GMD',
  [CurrencyCod.GTQ]: 'workspace:currency_GTQ',
  [CurrencyCod.GNF]: 'workspace:currency_GNF',
  [CurrencyCod.GYD]: 'workspace:currency_GYD',
  [CurrencyCod.HTG]: 'workspace:currency_HTG',
  [CurrencyCod.HNL]: 'workspace:currency_HNL',
  [CurrencyCod.HUF]: 'workspace:currency_HUF',
  [CurrencyCod.ISK]: 'workspace:currency_ISK',
  [CurrencyCod.IDR]: 'workspace:currency_IDR',
  [CurrencyCod.IRR]: 'workspace:currency_IRR',
  [CurrencyCod.IQD]: 'workspace:currency_IQD',
  [CurrencyCod.ILS]: 'workspace:currency_ILS',
  [CurrencyCod.JMD]: 'workspace:currency_JMD',
  [CurrencyCod.JEP]: 'workspace:currency_JEP',
  [CurrencyCod.JOD]: 'workspace:currency_JOD',
  [CurrencyCod.KZT]: 'workspace:currency_KZT',
  [CurrencyCod.KES]: 'workspace:currency_KES',
  [CurrencyCod.KPW]: 'workspace:currency_KPW',
  [CurrencyCod.KWD]: 'workspace:currency_KWD',
  [CurrencyCod.KGS]: 'workspace:currency_KGS',
  [CurrencyCod.LAK]: 'workspace:currency_LAK',
  [CurrencyCod.LBP]: 'workspace:currency_LBP',
  [CurrencyCod.LSL]: 'workspace:currency_LSL',
  [CurrencyCod.LRD]: 'workspace:currency_LRD',
  [CurrencyCod.LYD]: 'workspace:currency_LYD',
  [CurrencyCod.CHF]: 'workspace:currency_CHF',
  [CurrencyCod.MOP]: 'workspace:currency_MOP',
  [CurrencyCod.MGA]: 'workspace:currency_MGA',
  [CurrencyCod.MWK]: 'workspace:currency_MWK',
  [CurrencyCod.MVR]: 'workspace:currency_MVR',
  [CurrencyCod.MRU]: 'workspace:currency_MRU',
  [CurrencyCod.MUR]: 'workspace:currency_MUR',
  [CurrencyCod.MXN]: 'workspace:currency_MXN',
  [CurrencyCod.MDL]: 'workspace:currency_MDL',
  [CurrencyCod.MNT]: 'workspace:currency_MNT',
  [CurrencyCod.MAD]: 'workspace:currency_MAD',
  [CurrencyCod.MZN]: 'workspace:currency_MZN',
  [CurrencyCod.MMK]: 'workspace:currency_MMK',
  [CurrencyCod.NAD]: 'workspace:currency_NAD',
  [CurrencyCod.NPR]: 'workspace:currency_NPR',
  [CurrencyCod.NZD]: 'workspace:currency_NZD',
  [CurrencyCod.NIO]: 'workspace:currency_NIO',
  [CurrencyCod.NGN]: 'workspace:currency_NGN',
  [CurrencyCod.OMR]: 'workspace:currency_OMR',
  [CurrencyCod.PKR]: 'workspace:currency_PKR',
  [CurrencyCod.PAB]: 'workspace:currency_PAB',
  [CurrencyCod.PGK]: 'workspace:currency_PGK',
  [CurrencyCod.PYG]: 'workspace:currency_PYG',
  [CurrencyCod.PEN]: 'workspace:currency_PEN',
  [CurrencyCod.PHP]: 'workspace:currency_PHP',
  [CurrencyCod.PLN]: 'workspace:currency_PLN',
  [CurrencyCod.QAR]: 'workspace:currency_QAR',
  [CurrencyCod.RON]: 'workspace:currency_RON',
  [CurrencyCod.RUB]: 'workspace:currency_RUB',
  [CurrencyCod.RWF]: 'workspace:currency_RWF',
  [CurrencyCod.SAR]: 'workspace:currency_SAR',
  [CurrencyCod.RSD]: 'workspace:currency_RSD',
  [CurrencyCod.SCR]: 'workspace:currency_SCR',
  [CurrencyCod.SLL]: 'workspace:currency_SLL',
  [CurrencyCod.SBD]: 'workspace:currency_SBD',
  [CurrencyCod.SOS]: 'workspace:currency_SOS',
  [CurrencyCod.ZAR]: 'workspace:currency_ZAR',
  [CurrencyCod.SSP]: 'workspace:currency_SSP',
  [CurrencyCod.LKR]: 'workspace:currency_LKR',
  [CurrencyCod.SDG]: 'workspace:currency_SDG',
  [CurrencyCod.SRD]: 'workspace:currency_SRD',
  [CurrencyCod.SEK]: 'workspace:currency_SEK',
  [CurrencyCod.SYP]: 'workspace:currency_SYP',
  [CurrencyCod.TJS]: 'workspace:currency_TJS',
  [CurrencyCod.TZS]: 'workspace:currency_TZS',
  [CurrencyCod.TND]: 'workspace:currency_TND',
  [CurrencyCod.TRY]: 'workspace:currency_TRY',
  [CurrencyCod.TMT]: 'workspace:currency_TMT',
  [CurrencyCod.UGX]: 'workspace:currency_UGX',
  [CurrencyCod.UAH]: 'workspace:currency_UAH',
  [CurrencyCod.AED]: 'workspace:currency_AED',
  [CurrencyCod.GBP]: 'workspace:currency_GBP',
  [CurrencyCod.UYU]: 'workspace:currency_UYU',
  [CurrencyCod.UZS]: 'workspace:currency_UZS',
  [CurrencyCod.VUV]: 'workspace:currency_VUV',
  [CurrencyCod.VES]: 'workspace:currency_VES',
  [CurrencyCod.YER]: 'workspace:currency_YER',
  [CurrencyCod.ZMW]: 'workspace:currency_ZMW',
  [CurrencyCod.ZWL]: 'workspace:currency_ZWL',
  [CurrencyCod.XPF]: 'workspace:currency_XPF',
  [CurrencyCod.GGP]: 'workspace:currency_GGP',
  [CurrencyCod.IMP]: 'workspace:currency_IMP',
  [CurrencyCod.MKD]: 'workspace:currency_MKD',
  [CurrencyCod.SHP]: 'workspace:currency_SHP',
  [CurrencyCod.WST]: 'workspace:currency_WST',
  [CurrencyCod.STN]: 'workspace:currency_STN',
  [CurrencyCod.TOP]: 'workspace:currency_TOP',
  [CurrencyCod.TTD]: 'workspace:currency_TTD',
}

export const AllowedDefaultCountryCodes = [
  CountryCode.TW,
  CountryCode.JP,
  CountryCode.HK,
  CountryCode.MY,
  CountryCode.TH,
  CountryCode.VN,
  CountryCode.SG,
  CountryCode.KR,
]

export type AllowedCountryCode = (typeof AllowedDefaultCountryCodes)[number]

export const EnabledCountryCodeList = [
  CountryCode.HK,
  CountryCode.TW,
  CountryCode.JP,
  CountryCode.CN,
  CountryCode.MY,
  CountryCode.SG,
  CountryCode.TH,
  CountryCode.VN,
  CountryCode.KR,
  CountryCode.ID,
  CountryCode.PH,
  CountryCode.IN,
  CountryCode.US,
  CountryCode.CA,
  CountryCode.AU,
]

export const UNKNOWN_COUNTRY_CODE = 'unknown'

const ALL_COUNTRIES = Array.from(allCountries)

export type Country = Item<typeof ALL_COUNTRIES>

export const findCountryByCode = (code: string): Country | undefined => {
  return ALL_COUNTRIES.find((country) => country.value === code)
}

/** api 使用 */
export const countryCodeEnumSchema = z.nativeEnum(CountryCode)

export const unlimitedCountryCodeEnumSchema = countryCodeEnumSchema.or(
  z.literal(UNLIMITED_COUNTRY_CODE),
)
export type UnlimitedCountryCode = z.infer<
  typeof unlimitedCountryCodeEnumSchema
>
/** end */

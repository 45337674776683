import { isNumber } from 'lodash-es'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import AsyncImage from '@/components/common/image/async-image'
import { getThumbnail } from '@/utils/get-thumbnails'

interface PostImageProps {
  kolUuid: string
  thumbnailUrls?: string[]
  alt?: string
  width?: string | number
  height?: string | number
  borderRadius?: string | number
  onClick?: () => void
}

const PostImage: FunctionComponent<PostImageProps> = React.memo(
  ({
    kolUuid,
    thumbnailUrls = [],
    alt = 'post-image',
    width,
    height,
    borderRadius,
    onClick,
  }) => {
    return (
      <PostImageWrapper
        $borderRadius={borderRadius}
        $height={height}
        $width={width}
        onClick={onClick}
      >
        <AsyncImage
          fill
          alt={alt}
          imageUrls={[...thumbnailUrls, getThumbnail(kolUuid)]}
        />
      </PostImageWrapper>
    )
  },
)

export const PostImageWrapper = styled.div<{
  $width?: string | number
  $height?: string | number
  $borderRadius?: string | number
}>`
  cursor: pointer;
  overflow: hidden;
  width: ${({ $width }): string =>
    isNumber($width) ? `${$width}px` : ($width ?? '100%')};
  height: ${({ $height }): string =>
    isNumber($height) ? `${$height}px` : ($height ?? '100%')};
  position: relative;
  img {
    object-fit: cover;
    border-radius: ${({ $borderRadius }): string =>
      isNumber($borderRadius) ? `${$borderRadius}px` : ($borderRadius ?? '0')};
  }
`

PostImage.displayName = 'PostImage'

export default PostImage

import LocalStorageKeys from '@/constants/localstorage-keys'
import useLocalStorageSafely from '@/hooks/use-local-storage-safely'
import SearchIdManager from '@/utils/search-id-manager'
import { searchIdKeySeparator } from '@/utils/search-id-manager/store'

type UseSearchId = () => string | undefined

const getCurrentLocalStorageKey = (): string => {
  const searchIdManager = SearchIdManager.getInstance()
  const currentSessionId = searchIdManager.getSessionId()
  const currentLocalStorageKey = `${LocalStorageKeys.SearchIdMap}${searchIdKeySeparator}${currentSessionId}`

  return currentLocalStorageKey
}

const useSearchId: UseSearchId = () => {
  // 刻意不開放 update fn，不希望元件層有能力做這件事
  const [searchId] = useLocalStorageSafely(getCurrentLocalStorageKey(), '')

  return searchId
}

export { getCurrentLocalStorageKey }
export default useSearchId

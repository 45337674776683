import { z } from 'zod'
import Environment from '@/constants/environment'
import { isClient } from '@/utils/is-client'

const isLocalRunDev = process.env.NODE_ENV === 'development'

/** NOTE:
 * domain 會需要用 window.location.origin 來指定是為了支援白牌，
 * 白牌 domain 對應到的 API URL 就會是白牌的。
 *
 * 其餘預設狀態，我們都 fallback 回 kolr 的 domain。
 */

export const kolrDomain = 'kolr.ai'

const mapSubdomain = (): string => {
  if (process.env.NEXT_PUBLIC_ENVIRONMENT === Environment.Production) {
    return 'app'
  }
  return `app.${process.env.NEXT_PUBLIC_ENVIRONMENT}`
}

export const getKolrAppDomain = (): string => {
  const subdomain = mapSubdomain()

  return `${subdomain}.${kolrDomain}`
}

const mapAppUrl = (): string => {
  if (!isClient()) {
    return `https://${getKolrAppDomain()}`
  }

  return window.location.origin
}

const mapKolrPublicPageAppUrl = (): string => {
  const kolrAppUrl = `https://${getKolrAppDomain()}`

  if (!isClient()) {
    return kolrAppUrl
  }

  const isLocal = window.location.hostname === 'localhost'
  if (isLocal) {
    return window.location.origin
  }

  return kolrAppUrl
}

const mapApiBaseUrl = (): string => {
  if (!isClient()) {
    return `https://${getKolrAppDomain()}/api`
  }

  const isLocal = window.location.hostname === 'localhost'

  if (isLocal) {
    /**
     * For local development
     * @see https://www.notion.so/ikala/Kolr-Frontend-Project-Local-Setup-154fb0834d6e80ff9de4c078aa384cc8
     */
    return (
      process.env.NEXT_PUBLIC_DEVELOPMENT_API_BASE_URL ??
      'http://localhost:8080/gin/api'
    )
  }

  return `${window.location.origin}/api`
}

const envSchema = z.object({
  API_BASE_URL: z.string(),
  APP_URL: z.string(),
  PUBLIC_PAGE_APP_URL: z.string(),
  DEVELOPMENT_MODE: z.boolean(),
  ENVIRONMENT: z.nativeEnum(Environment),
  LANDING_URL: z.string(),
  GOOGLE_ADS_ID: z.string(),
  GOOGLE_ADS_ACCOUNT_CREATED_EVENT_ID: z.string(),
  GOOGLE_ADS_TRIAL_EVENT_ID: z.string(),
  GOOGLE_ADS_PURCHASE_SUBSCRIPTION_EVENT_ID: z.string(),
  STRIPE_PUBLISHABLE_KEY: z.string(),
  K6RSATELLITE_API_BASE_URL: z.string(),
  PUBLIC_K6RSATELLITE_API_BASE_URL: z.string(),
  MSW_ENABLED: z.boolean(),
  NEXT_PUBLIC_GOOGLE_APP_ID: z.string(),
  RECAPTCHA_RENDER_ID: z.string(),
})

const env = envSchema.parse({
  APP_URL: mapAppUrl(),
  API_BASE_URL: mapApiBaseUrl(),
  PUBLIC_PAGE_APP_URL: mapKolrPublicPageAppUrl(),
  DEVELOPMENT_MODE: isLocalRunDev,
  ENVIRONMENT: process.env.NEXT_PUBLIC_ENVIRONMENT,
  LANDING_URL: process.env.NEXT_PUBLIC_LANDING_URL,
  PUBLIC_K6RSATELLITE_API_BASE_URL:
    process.env.NEXT_PUBLIC_PUBLIC_K6RSATELLITE_API_BASE_URL,
  GOOGLE_ADS_ID: process.env.NEXT_PUBLIC_GOOGLE_ADS_ID,
  GOOGLE_ADS_ACCOUNT_CREATED_EVENT_ID:
    process.env.NEXT_PUBLIC_GOOGLE_ADS_ACCOUNT_CREATED_EVENT_ID,
  GOOGLE_ADS_TRIAL_EVENT_ID: process.env.NEXT_PUBLIC_GOOGLE_ADS_TRIAL_EVENT_ID,
  GOOGLE_ADS_PURCHASE_SUBSCRIPTION_EVENT_ID:
    process.env.NEXT_PUBLIC_GOOGLE_ADS_PURCHASE_SUBSCRIPTION_EVENT_ID,
  STRIPE_PUBLISHABLE_KEY: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
  K6RSATELLITE_API_BASE_URL: process.env.NEXT_PUBLIC_K6RSATELLITE_API_BASE_URL,
  MSW_ENABLED: process.env.NEXT_PUBLIC_MSW_ENABLED === '1',
  NEXT_PUBLIC_GOOGLE_APP_ID: process.env.NEXT_PUBLIC_GOOGLE_APP_ID,
  RECAPTCHA_RENDER_ID: process.env.NEXT_PUBLIC_RECAPTCHA_RENDER_ID,
})

export default env

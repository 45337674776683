import Script from 'next/script'
import React, { FunctionComponent } from 'react'
import env from '@/utils/env'
import logger from '@/utils/logger'

const RecaptchaScript: FunctionComponent = () => {
  const renderId = env.RECAPTCHA_RENDER_ID

  return (
    <Script
      async
      src={`https://www.google.com/recaptcha/enterprise.js?render=${renderId}`}
      onError={(): void => {
        logger.error(new Error('recaptcha script load fail: onError'))
      }}
    />
  )
}

export default RecaptchaScript

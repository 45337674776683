import { CurrencyCode as SharedCurrencyCode } from '@buggy/shared'
import { MessageEnvelope } from '@pubnub/react-chat-components'
import { Recipient } from '@/api/user-api'
import {
  AllCountryCode,
  AllCountryCurrency,
  CurrencyCod,
} from '@/constants/country-code'
import {
  CampaignKolContract,
  CampaignKolLaborDeclaration,
  CampaignKolPost,
} from '@/types/campaign-case'
import { Nullable } from '@/types/nullable'
import { Link } from '@/types/schema/kol-schema'
import { UTMInfo } from '@/utils/utm'

interface AuthAssignmentRequestObject {
  /**
   * example:
   * ben.chen@ikala.tv
   */
  email: string
  /**
   * example:
   * admin
   */
  role?: string
}

export interface AuthAssignmentSliceRequestObject {
  roles?: AuthAssignmentRequestObject[]
}

interface GuestAssignmentRequestObject {
  /**
   * example:
   * ben.chen@ikala.tv
   */
  email: string
  /**
   * example:
   * 2
   */
  resourceID: number | 'demo'
  /**
   * example:
   * report
   */
  resourceType?: string
}

export interface GuestAssignmentSliceRequestObject {
  guestAuth?: GuestAssignmentRequestObject[]
}

interface ModelAccountingInfo {
  address?: string
  bankAccount?: string
  bankBranchCode?: string
  bankBranchName?: string
  bankName?: string
  bankSwiftCode?: string
  createdAt?: string
  deletedAt?: string
  fileItems?: ModelFileItem[]
  id?: number
  idcardBackImgs?: ModelFileItem[]
  idcardFrontImgs?: ModelFileItem[]
  idnumber?: string
  insuranceImgs?: ModelFileItem[]
  isCompany?: boolean
  isForeigner?: boolean
  isUnionInsurance?: boolean
  juristicName?: string
  otherImgs?: ModelFileItem[]
  passbookImgs?: ModelFileItem[]
  personInCharge?: string
  phone?: string
  taxIDNumber?: string
  updatedAt?: string
  user?: ModelUser
  userID?: number
  username?: string
}

interface ModelAgentContactInfo {
  agencyEmail?: string
  agencyName?: string
  agencyPhone?: string
  companyAddr?: string
  companyName?: string
  createdAt?: string
  deletedAt?: string
  id?: number
  taxID?: string
  updatedAt?: string
  user?: ModelUser
  userID?: number
}

interface ModelAgentInfo {
  agency?: ModelUser
  agencyEmail?: string
  agencyID?: number
  createdAt?: string
  deletedAt?: string
  id?: number
  kolID?: string
  updatedAt?: string
  /**
   * 此 user 指 kol 對象 可能不見得存在 但kolId則必定有值
   */
  user?: ModelUser
  userID?: number
}

interface ModelAmount {
  /**
   * example:
   * 99900
   */
  actualBillingPrice?: number
  /**
   * example:
   * TWD
   */
  actualPayCurrency?: string
  /**
   * example:
   * 28021
   */
  actualPayPrice?: number
  /**
   * example:
   * 0
   */
  actualReducedPrice?: number
  /**
   * example:
   * 0
   */
  actualSubTotal?: number
  /**
   * example:
   * 0
   */
  actualVatFee?: number
  /**
   * example:
   * JPY
   */
  currency?: string
  /**
   * example:
   * 20200207
   */
  payPrice?: number
  /**
   * example:
   * 0
   */
  reducedPrice?: number
  /**
   * example:
   * 20200215
   */
  subTotal?: number
  /**
   * example:
   * 0.2695621
   */
  toActualPayExchangeRate?: number
  /**
   * example:
   * 0
   */
  vatFee?: number
}

export interface ModelAuthAssignment {
  createdAt?: string
  creatorID?: number
  deletedAt?: string
  email?: string
  id?: number
  role?: string
  updatedAt?: string
  user?: ModelUser
  workspace?: ModelWorkspace
  workspaceID?: number
}

interface ModelBilling {
  /**
   * example:
   * tw
   */
  address: string
  /**
   * example:
   * 20190919
   */
  amount?: number
  /**
   * example:
   * 2077-01-01T00:00:01Z
   */
  checkoutAt?: string
  /**
   * example:
   * IkaLA
   */
  companyName?: string
  /**
   * example:
   * 2077-01-01T00:00:01Z
   */
  completedAt?: string
  couponCode?: string
  createdAt?: string
  /**
   * Follow `ISO 4217`
   * example:
   * PHP
   */
  currency?: string
  deletedAt?: string
  /**
   * example:
   * calvin.huang@ikala.tv
   */
  email: string
  /**
   * example:
   * 1
   */
  exchangeRate?: number
  /**
   * example:
   * 2077-01-01T00:00:01Z
   */
  failedAt?: string
  id?: number
  /**
   * example:
   * Battle Athletes Victory
   */
  name: string
  /**
   * example:
   * +886 9XXXXXXXX
   */
  phone: string
  plan?: ModelPlan
  /**
   * example:
   * JPY
   */
  sourceCurrency?: string
  /**
   * example:
   * requested,failed,paied
   */
  status?: string
  /**
   * example:
   * 0
   */
  tappayStatus?: number
  /**
   * example:
   * 123456789
   */
  taxID?: string
  updatedAt?: string
  user?: ModelUser
  workspaceID?: number
  /**
   * example:
   * 000
   */
  zipCode: string
}

interface ModelBillingToUser {
  createdAt?: string
  deletedAt?: string
  /**
   * example:
   * 2077-01-01T00:00:01Z
   */
  endDate?: string
  id?: number
  note?: string
  /**
   * example:
   * 2077-01-01T00:00:01Z
   */
  startDate?: string
  updatedAt?: string
  userID?: number
  userRole?: ModelUserRole
  workspaceID?: number
}

interface ModelCampaign {
  productIcon?: ModelFileItem
  productName: string
  rewardType: CampaignRewardType
  coworkType: CampaignCoworkType
  brandRecognitionSnap?: BrandRecognitionSnap
  description: string
  campaignKolAmount?: number
  campaignKols?: ModelCampaignKol[]
  createdAt?: string
  deletedAt?: string
  platformInvolved: string[]
  /**
   * example:
   * 2020-09-01T00:00:00Z
   */
  endAt?: string
  id?: number
  minorContractTemplate?: ModelMinorContractTemplate
  minorContractTemplateID?: number
  /**
   * example:
   * 台灣大哥大[好速成雙] KOL圖文合作
   */
  name?: string
  /**
   * example:
   * 1
   */
  ownerID?: number
  /**
   * example:
   * AMOD0629_雪倫
   */
  remark?: string
  /**
   * example:
   * ben.chen@ikala.tv
   */
  representativeEmail?: string
  /**
   * example:
   * Ben
   */
  representativeName?: string
  /**
   * example:
   * 0919123456
   */
  representativePhone?: string
  /**
   * example:
   * 2020-08-01T00:00:00Z
   */
  startAt?: string
  updatedAt?: string
  workspaceID?: number
}

interface KolStatInfo {
  fbAvgEngagementRate3m: number | null
  fbAvgVideoViewRate3m: number | null
  fbFollowerCount: number | null
  followerCount: number | null
  igAvgEngagementRate3m: number | null
  igAvgVideoViewRate3m: number | null
  igFollowerCount: number | null
  level: string
  ytAvgEngagementRate3m: number | null
  ytAvgVideoViewRate3m: number | null
  ytFollowerCount: number | null
}

export interface ModelCampaignKol {
  agencyUser: ModelUser[]
  uuid: string
  /**
   * 是否被代管
   */
  agencyInfo?: ModelAgentInfo
  attachments?: ModelFileItem[]
  campaign?: ModelCampaign
  /**
   * Relations and the key
   */
  campaignID?: number
  createdAt?: string
  deletedAt?: string
  /**
   * example:
   * 2020-08-03T00:00:00Z
   */
  endAt?: string
  id?: number
  /**
   * user v2 format to check verified
   */
  isKolVerified?: boolean
  isRadarStar?: boolean
  /**
   * Columne of data
   */
  isOffline?: boolean
  isVerified?: boolean
  kolID?: string
  kolName?: string
  name: string[]
  /**
   * For data processing
   */
  kolStatistic?: ModelKolLiteObj
  /**
   * 是否被綁定
   */
  kolUser?: ModelUser
  laborDeclaration?: ModelLaborDeclaration
  /**
   * for create campaignKol with template
   */
  minorContractTemplateID?: number
  minorContracts?: ModelMinorContract[]
  payment?: ModelPayment
  paymentStatus?: number
  postLink?: string
  /**
   * example:
   * AMOD0629_雪倫
   */
  remark?: string
  requestPayment?: ModelRequestPayment
  reward?: number
  /**
   * example:
   * 2020-08-03T00:00:00Z
   */
  startAt?: string
  /**
   * kol 實際對應對象的資料
   */
  targetAccountingInfo?: ModelAccountingInfo
  updatedAt?: string
  links?: Link[]
}

export interface ModelContractAcception {
  contractableType: string
  acceptTime?: string
  campaignKol?: ModelCampaignKol
  campaignKolID?: number
  content?: string
  contractFile?: ModelFileItem
  contractFileID?: any[]
  createdAt?: string
  deletedAt?: string
  id: string
  isAcceptable?: boolean
  kolName?: string
  laborDeclaration?: ModelLaborDeclaration
  laborDeclarationID?: number
  mainContract?: ModelMainContract
  mainContractID?: number
  minorContract?: ModelMinorContract
  minorContractID?: number
  targetName?: string
  updatedAt?: string
  user?: ModelUser
  userID?: number
  variables?: string
  contractableId?: number
  title?: string
}

interface ModelCoupon {
  /**
   * example:
   * 春季特惠-95折
   */
  campaignDescription?: string
  /**
   * example:
   * 喜迎鼠年-年初促銷活動
   */
  campaignName?: string
  /**
   * example:
   * 2020Spring
   */
  code?: string
  /**
   * example:
   * 0.05
   */
  discount?: number
  id?: number
  /**
   * example:
   * 0
   */
  type?: number
}

interface ModelCouponRecord {
  coupon?: ModelCoupon
  couponID?: number
  createdAt?: string
  deletedAt?: string
  id?: number
  updatedAt?: string
  user?: ModelUser
  userID?: number
}

interface ModelCrawlerStatus {
  /**
   * example:
   * 2020-09-03T00:00:00Z
   */
  failedAt?: string
  /**
   * example:
   * 9
   */
  finishedAmount?: number
  /**
   * example:
   * 2020-09-03T00:00:00Z
   */
  lastUpdatedAt?: string
  /**
   * example:
   * true
   */
  scanning?: boolean
  /**
   * example:
   * 10
   */
  totalAmount?: number
}

interface ModelDetailInfo {
  companyAddr?: string
  companyName?: string
  companyPhone?: string
  instantMessagings?: ModelUserAdditionalInfo[]
  isEnable?: boolean
  isVerified?: boolean
  linkUnderTheName?: string
  phone?: string
  userName?: string
}

export interface Picture {
  uuid: string
  url: string
  signedUrl: string
}

export interface ModelFileItem {
  url?: string
  signedUrl?: string
  signedURL?: string
  contentLength?: number
  contentType?: string
  createdAt?: string
  deletedAt?: string
  fileName?: string
  fileType?: string
  id?: string
  md5?: string
  updatedAt?: string
  uploadedAt?: string
  zipFileName?: string
}

export interface ModelGuestAssignment {
  accessType?: string
  createdAt?: string
  creatorID?: number
  deletedAt?: string
  email?: string
  id?: number
  resourceID?: number
  /**
   * for display
   * example:
   * report number 3
   */
  resourceName?: string
  resourceType?: string
  updatedAt?: string
  user?: ModelUser
  workspace?: ModelWorkspace
  workspaceID?: number
}

export interface ModelGuestUser {
  availableResources?: ModelResource[]
  email?: string
  user?: ModelUser
}

interface ModelIndustryData {
  /**
   * example:
   * 10.5
   */
  avgCPE?: number
  /**
   * example:
   * 20.23
   */
  avgCPEV?: number
  /**
   * example:
   * 20.23
   */
  avgCPV?: number
  /**
   * example:
   * 30.25
   */
  avgEngagementNum?: number
  /**
   * example:
   * 100
   */
  avgViewNum?: number
  createdAt?: string
  deletedAt?: string
  id?: number
  /**
   * example:
   * 美妝
   */
  industryType?: string
  reportID?: number
  updatedAt?: string
  /**
   * example:
   * false
   */
  userDefined?: boolean
}

export interface ModelInvoice {
  amount?: ModelAmount
  billing?: ModelBilling
  couponCode?: string
  couponRecord?: ModelCouponRecord
  /**
   * example:
   * 2077-01-01T00:00:01Z
   */
  createdAt?: string
  /**
   * example:
   * in_129rjWEjr2o
   */
  id?: string
  /**
   * example:
   * 台北市信義區東興路41號10樓
   */
  ikalaAddress?: string
  /**
   * example:
   * iKala Inc.
   */
  ikalaName?: string
  payContactInfo?: ModelPayContactInfo
  plan?: ModelPlan
}

interface ModelKolLiteObj {
  agentInfo?: ModelAgentInfo
  description?: string
  fbFollowerCount?: number
  followerCount?: number
  gender?: string
  id?: number
  igFollowerCount?: number
  isVerified: boolean
  isRadarStar: boolean
  isVisible?: boolean
  location?: string
  name?: string[]
  customizedTagsNames: string[]
  service?: string
  specialty?: string[]
  ttFollowerCount?: number
  uuid?: string
  ytFollowerCount?: number
}

interface ModelKvObj {
  id?: string
  name?: string
}

interface ModelLaborDeclaration {
  accountingInfo?: ModelAccountingInfo
  campaignKol?: ModelCampaignKol
  campaignKolID?: number
  contractAcception?: ModelContractAcception
  createdAt?: string
  deletedAt?: string
  id?: number
  kolID?: string
  updatedAt?: string
}

interface ModelMainContract {
  agencyID?: number
  agencyUser?: ModelUser
  content?: string
  /**
   * 備註 主約目前連接 v1 kol 因此僅留空欄位 不跨 db relation
   */
  contractAcception?: ModelContractAcception[]
  contractTitle?: string
  createdAt?: string
  creatorUser?: ModelUser
  creatorUserID?: number
  deletedAt?: string
  id?: number
  isDefault?: boolean
  kolID?: string
  kolUser?: ModelUser
  serialID?: string
  targetName?: string
  updatedAt?: string
  version?: number
}

interface ModelMinorContract {
  campaignKol?: ModelCampaignKol
  campaignKolID?: number
  content?: string
  contractAcception?: ModelContractAcception
  contractTitle?: string
  createdAt?: string
  creatorUser?: ModelUser
  creatorUserID?: number
  deletedAt?: string
  id?: number
  isDraft?: boolean
  kolAccept?: boolean
  kolID?: string
  serialID?: string
  updatedAt?: string
  version?: number
}

export interface ModelMinorContractTemplate {
  campaign?: ModelCampaign
  content?: string
  contractAcception?: ModelContractAcception
  contractTitle?: string
  createdAt?: string
  creator?: number
  creatorID?: number
  deletedAt?: string
  id?: number
  updatedAt?: string
  title?: string
}

type ModelNullBool = boolean | null

interface ModelPayContactInfo {
  /**
   * example:
   * tw
   */
  address: string
  /**
   * example:
   * IkaLA
   */
  isCompany?: boolean
  companyName?: string
  /**
   * example:
   * calvin.huang@ikala.tv
   */
  email: string
  /**
   * example:
   * Battle Athletes Victory
   */
  name: string
  /**
   * example:
   * +886 9XXXXXXXX
   */
  phone: string
  /**
   * example:
   * 123456789
   */
  taxID?: string
  /**
   * example:
   * 000
   */
  zipCode: string
}

interface ModelPayment {
  adminID?: number
  campaignKolID?: number
  createdAt?: string
  deletedAt?: string
  id?: number
  updatedAt?: string
}

interface ModelPinnedComment {
  /**
   * example:
   * mH31bJQ3lkk
   */
  commentID?: string
  createdAt?: string
  deletedAt?: string
  id?: number
  platformType?: number
  reportID?: number
  updatedAt?: string
}

interface ModelPlan {
  /**
   * 該幣值 因匯率差而少扣之實扣價格
   * example:
   * 99000
   */
  actualPrice?: number
  /**
   * 該幣值 之牌價, 非固定為twd
   * example:
   * 99900
   */
  billingTWDPrice?: number
  createdAt?: string
  deletedAt?: string
  /**
   * example:
   * 0.95
   */
  discount?: number
  /**
   * Display on Radar or not. Also keep old plan avaliable.
   */
  display?: boolean
  /**
   * Follow `ISO 4217`
   * example:
   * PHP
   */
  displayCurrency?: string
  /**
   * Duration unit: `day`
   * example:
   * 180
   */
  duration?: number
  /**
   * example:
   * 2077-01-01T00:00:01Z
   */
  endDate?: string
  id?: number
  /**
   * 該幣值 折扣前原價
   * example:
   * 118800
   */
  originalTWDPrice?: number
  /**
   * example:
   * 2077-01-01T00:00:01Z
   */
  startDate?: string
  updatedAt?: string
  userRole?: ModelUserRole
  /**
   * example:
   * 0.05
   */
  vat?: number
}

interface ModelPostCrawl {
  crawledID?: string
  createdAt?: string
  deletedAt?: string
  id?: number
  platformType?: number
  reportPost?: ModelReportPost[]
  updatedAt?: string
  url?: string
}

interface ModelReport {
  /**
   * example:
   * 1000
   */
  budget?: number
  /**
   * example:
   * 食品
   */
  category?: string
  commentCrawlerStatus?: ModelCrawlerStatus
  cost?: number
  createdAt?: string
  deletedAt?: string
  /**
   * example:
   * 2020-09-01T00:00:00Z
   */
  endAt?: string
  id?: number
  kolAmount?: number
  kolsInfo?: ModelKvObj[]
  /**
   * example:
   * 台灣大哥大[好速成雙] KOL圖文合作
   */
  name?: string
  owner?: ModelUser
  /**
   * example:
   * 1
   */
  ownerID?: number
  pinnedComments?: ModelPinnedComment[]
  platforms?: ModelKvObj[]
  postCrawlerStatus?: ModelCrawlerStatus
  reportLastUpdatedAt?: string
  reportPosts?: ModelReportPost[]
  /**
   * example:
   * 2020-08-01T00:00:00Z
   */
  startAt?: string
  terms?: ModelTerm[]
  updatedAt?: string
  userDefinedIndustryData?: ModelIndustryData
  valueEstimationWeight?: ModelValueEstimationWeightMap
  workspaceID?: number
}

interface ModelReportPost {
  /**
   * example:
   * 100
   */
  budget?: number
  /**
   * example:
   * 100
   */
  clickCount?: number
  /**
   * example:
   * 2020-09-03T00:00:00Z
   */
  commentFailedAt?: string
  /**
   * example:
   * 2020-09-03T00:00:00Z
   */
  commentFinishedAt?: string
  /**
   * example:
   * 2020-09-03T00:00:00Z
   */
  commentScannedAt?: string
  createdAt?: string
  deletedAt?: string
  /**
   * example:
   * 2020-08-03T00:00:00Z
   */
  endAt?: string
  id?: number
  /**
   * example:
   * 100
   */
  impressionCount?: number
  /**
   * example:
   * 123
   */
  kolID?: string
  kolName?: string
  /**
   * example:
   * {}
   */
  otherMetric?: string
  /**
   * example:
   * 100
   */
  pcu?: number
  platformType?: number
  postCrawl?: ModelPostCrawl
  postCrawlID?: number
  /**
   * example:
   * 2020-09-03T00:00:00Z
   */
  postFailedAt?: string
  /**
   * example:
   * 2020-09-03T00:00:00Z
   */
  postFinishedAt?: string
  /**
   * example:
   * 2020-09-03T00:00:00Z
   */
  postScannedAt?: string
  /**
   * example:
   * image
   */
  postType?: string
  publishTime?: string
  /**
   * example:
   * 100
   */
  reachCount?: number
  report?: ModelReport
  reportID?: number
  /**
   * example:
   * 2020-08-03T00:00:00Z
   */
  startAt?: string
  /**
   * example:
   * 2020-08-03T00:00:00Z
   */
  unavailableAt?: string
  /**
   * example:
   * 404
   */
  unavailableStatusCode?: number
  /**
   * example:
   * FIJLAJOIWWJDIS
   */
  uniqueKey?: string
  updatedAt?: string
  url?: string
  /**
   * example:
   * 100
   */
  viewCount?: number
}

interface ModelRequestPayment {
  adminID?: number
  campaignKolID?: number
  createdAt?: string
  deletedAt?: string
  id?: number
  requestPaymentDate?: string
  updatedAt?: string
  userID?: number
}

export interface ModelResource {
  resourceId?: number
  resourceName?: string
  resourceType?: string
}

interface ModelRoleFunction {
  createdAt?: string
  deletedAt?: string
  id?: number
  name?: string
  type?: string
  updatedAt?: string
  userRoles?: ModelUserRole[]
}

/**
 * @todo 改成 zod 就不用這個
 */
export enum ServiceCategory {
  Competitive = 'competitor',
  Collection = 'collection',
  Report = 'report',
  Hashtag = 'hashtag',
  Directory = 'directory',
  PredictiveReport = 'predictive_report',
}

/**
 * @todo 改成 zod 就不用這個
 */
export enum ServiceName {
  Competitive = 'competitor',
  Collection = 'collection',
  Report = 'report',
  Hashtag = 'hashtag',
  Directory = 'directory',
  PredictiveReport = 'predictive_report',
}

export type BasicService = {
  code: string
  endAt: Nullable<string>
  serviceCategory?: string
  serviceName?: string
  memo: null | object
}

export type CompetitiveService = BasicService & {
  serviceCategory: ServiceCategory.Competitive
  serviceName: ServiceName.Competitive
  memo: {
    competitiveReportLimit: number
    competitiveKeywordLimit: number
  }
}

export type CollectionService = BasicService & {
  serviceCategory: ServiceCategory.Collection
  serviceName: ServiceName.Collection
  memo: {
    collectionCustomizedColumnAmount: number
    collectionLimit: number
    collectionKolLimit: number
  }
}

export type ReportService = BasicService & {
  serviceCategory: ServiceCategory.Report
  serviceName: ServiceName.Report
  memo: {
    reportReportLimit: number
    reportKolLimit: number
  }
}

export type HashtagService = BasicService & {
  serviceCategory: ServiceCategory.Hashtag
  serviceName: ServiceName.Hashtag
  memo: {
    hashtagDetectionLimit?: number
  }
}

export interface DirectoryService extends BasicService {
  serviceCategory: ServiceCategory.Directory
  serviceName: ServiceName.Directory
  memo: {
    directoryKolLimit: number
    directoryCustomizedColumnAmount: number
    kolLabelAmount: number
  }
}

export interface PredictiveReportService extends BasicService {
  serviceCategory: ServiceCategory.PredictiveReport
  serviceName: ServiceName.PredictiveReport
  memo: {
    predictiveReportLimit: number
    predictiveReportCollaborativeItemsLimit: number
  }
}

export type ModelServiceWithEndAt =
  | BasicService
  | CompetitiveService
  | CollectionService
  | ReportService
  | HashtagService
  | DirectoryService
  | PredictiveReportService

export interface ModelSocialAccount {
  createdAt?: string
  deletedAt?: string
  /**
   * example:
   * ben.chen@ikala.tv
   */
  email?: string
  /**
   * example:
   * 2020-09-03T00:00:00Z
   */
  expiredAt?: string
  id?: number
  /**
   * example:
   * 王大明
   */
  name?: string
  oauthInfo?: string
  /**
   * example:
   * 1
   */
  oauthType?: number
  oauthTypeName?: string
  /**
   * example:
   * 3555625801147282
   */
  oauthUserID?: string
  /**
   * example:
   * EAAMHF8sGpW4BAIOA9jOnewosuVSGjsW2AFosc3hpxdrQnclqSD5x
   */
  token?: string
  updatedAt?: string
  userID?: number
}

interface ModelTerm {
  createdAt?: string
  deletedAt?: string
  id?: number
  isInclude?: ModelNullBool
  reportID?: number
  /**
   * example:
   * 很漂亮
   */
  term?: string
  updatedAt?: string
}

interface ModelUser {
  accountingInfo?: ModelAccountingInfo
  recipientInfo?: Recipient
  agencyMainContract?: ModelMainContract
  /**
   * 讓kol本人找到對應經紀人 user.AgencyRelation.Agency
   */
  agencyRelation?: ModelAgentInfo
  agentContactInfo?: ModelAgentContactInfo
  authAssignments?: ModelAuthAssignment[]
  billingToUser?: ModelBillingToUser
  company?: string
  companyAddress?: string
  companyPhone?: string
  contractAcceptions?: ModelContractAcception[]
  createdAt?: string
  currentWorkspaceId?: number
  deletedAt?: string
  detailInfo?: ModelDetailInfo
  /**
   * example:
   * Lerche@mail.com
   */
  email?: string
  group?: string
  guestAssignments?: ModelGuestAssignment[]
  id?: number
  isEnable?: boolean
  isVerified?: boolean
  kolID?: string
  kolMainContract?: ModelMainContract
  /**
   * 讓agency找到他的kol   user.KolRelations.0.KolID
   */
  kolRelations?: ModelAgentInfo[]
  kols?: string[]
  /**
   * example:
   * Asobi Asobase
   */
  name?: string
  password?: string
  phone?: string
  socialAccount?: ModelSocialAccount[]
  targetMainContract?: ModelMainContract
  targetMainContractAcception?: ModelContractAcception
  type?: string
  updatedAt?: string
}

interface ModelUserAdditionalInfo {
  createdAt?: string
  deletedAt?: string
  id?: number
  key?: string
  type?: string
  updatedAt?: string
  user?: ModelUser
  userID?: number
  value?: string
}

interface ModelUserRole {
  code?: string
  createdAt?: string
  deletedAt?: string
  id?: number
  name?: string
  roleFunctions?: ModelRoleFunction[]
  updatedAt?: string
}

interface ModelValueEstimationWeightMap {
  [name: string]: {
    /**
     * example:
     * 10
     */
    commentValue?: number
    /**
     * example:
     * 10
     */
    likeValue?: number
    /**
     * example:
     * 10
     */
    onlineValue?: number
    /**
     * example:
     * 10
     */
    shareValue?: number
    /**
     * example:
     * 10
     */
    viewValue?: number
  }
}

export interface ModelWorkspace {
  company?: string
  availableServices?: ModelServiceWithEndAt[]
  billings?: ModelBillingToUser[]
  createdAt?: string
  /**
   * example:
   * 12
   */
  creatorID?: number
  deletedAt?: string
  guestAssignments?: ModelGuestAssignment[]
  /**
   * fake column for display
   */
  guestUsers?: ModelGuestUser[]
  iconFile?: ModelFileItem
  iconUUID?: any[]
  id?: number
  /**
   * auth related
   */
  memberUsers?: ModelAuthAssignment[]
  /**
   * example:
   * Ben
   */
  name: string
  /**
   * belongings
   */
  report?: ModelReport[]
  updatedAt?: string
  workspaceAuth?: ModelWorkspaceAuth
  workspaceType?: string
  currencyCode: CurrencyCod
  countryCode: SharedCurrencyCode
  industry?: string
  businessSize?: string
  usagePurpose?: string
}

export interface ModelWorkspaceAuth {
  /**
   * example:
   * 2077-01-01T00:00:01Z
   */
  expireAt?: string
  memberQuota: number
}

export interface WorkspaceRequestObject {
  iconUUID?: string
  name: string
  company: string
  industry: string
  businessSize: string
  usagePurpose: string
  purposeDescription?: string
  countryCode: AllCountryCode
  currencyCode: AllCountryCurrency
  isTest: boolean
}

export interface BrandRecognitionSnap {
  name: string
  website: string
  description: string
  iconUUID: string
  iconFile: ModelFileItem
}

export type EditedBrandRecognitionSnap = Omit<BrandRecognitionSnap, 'iconFile'>

enum CampaignCoworkType {
  ArrivalExperience = 'arrival_experience', // 到店體驗
  DeliverGoods = 'deliver_goods', // 寄送產品
  AttendEvent = 'attend_event', // 出席活動
}

enum CampaignRewardType {
  paid = 'paid', // 有酬合作
  free = 'free', // 互惠合作
}

export enum CampaignKolStatus {
  Preparing = 'preparing',
  Inviting = 'inviting',
  Running = 'running',
  Done = 'done',
  Applied = 'applied',
  Rejected = 'rejected',
  Canceled = 'canceled',
}

export enum CampaignKolPaymentStatus {
  Pending = 'pending',
  Processing = 'processing',
  Paid = 'paid',
  NotNeed = 'not-need',
}

export enum CampaignCooperationType {
  FacebookPost = 'facebook_post',
  FacebookVideo = 'facebook_video',
  FacebookLive = 'facebook_live',
  InstagramPost = 'instagram_post',
  InstagramReels = 'instagram_reels',
  InstagramLive = 'instagram_live',
  InstagramStory = 'instagram_story',
  YouTubeVideo = 'youtube_video',
  AdvertisingLicense7Days = 'advertising_license_7d',
  AdvertisingLicense14Days = 'advertising_license_14d',
  AdvertisingLicense30Days = 'advertising_license_30d',
  AdvertisingLicense90Days = 'advertising_license_90d',
}

export type CampaignCooperationPosts = {
  campaignKolPostId: number
  platformPostId: string
}

export type CampaignCooperationDetail = {
  count: number
  reward: number
  type: CampaignCooperationType
  posts: CampaignCooperationPosts[]
}

/**
 * @description
 * "pending"           // 待業主付款
 * "canceled"          // 取消訂單
 * "waiting"           // 業主已付款 <-- 只有這個狀態可以進行"業主"撥款及申訴
 * "holding"           // 申訴中
 * "finance_checking"  // 財務審核
 * "approved"          // 已撥款/待出款
 * "withdraw"          // 已出款給網紅
 * "rejected"          // 待退款
 * "refunded"          // 已退款給業主
 * "api_refund_failed" // API 退款失敗
 */
export enum OrderStatus {
  Pending = 'pending',
  Canceled = 'canceled',
  Waiting = 'waiting',
  Holding = 'holding',
  FinanceChecking = 'finance_checking',
  Approved = 'approved',
  Withdraw = 'withdraw',
  Rejected = 'rejected',
  Refunded = 'refunded',
  ApiRefundFailed = 'api_refund_failed',
}

export enum ApproverType {
  Kol = 'kol',
  Ad = 'ad',
  System = 'system',
}

export interface CampaignKol {
  id: number
  isOffline: boolean
  kolUUID: string
  campaignKolStatus: CampaignKolStatus
  paymentStatus: CampaignKolPaymentStatus
  channelId: string
  kol: ModelCampaignKol
  kolStatInfo: KolStatInfo
  isCanceled?: boolean
  isAccepted?: boolean | null
  attachments: ModelFileItem[]
  campaignKolPosts: CampaignKolPost[]
  campaignKolLaborDeclaration?: CampaignKolLaborDeclaration
  campaignKolContracts: CampaignKolContract[]
  remark: string | null
  reward: number | null
  startAt: string | null
  endAt: string | null
  cooperationDetail: CampaignCooperationDetail[]
  utmInfo?: UTMInfo
  radarRequestPayment?: {
    requestPaymentDate?: string
  }
  status: CampaignKolStatus
  existsProcessingTransaction: boolean
  /**
   * @description finishedAllContract 合約+勞報單是否皆已簽回
   */
  finishedAllContract: boolean
  /**
   * @description orderPaid 已付款
   */
  orderPaid: boolean
  orderRefundedAt?: string
  orderWithdrewAt?: string
  canWatchReport: boolean
  /** @description kolUploadedPost 網紅已上傳任一貼文，不可取消合作 */
  kolUploadedPost: boolean
  /** @description kolUploadedAllPostsAt 網紅已上傳所有貼文的時間，可撥款條件 */
  kolUploadedAllPostsAt: string | null
  /**
   * @description canCreateTransaction 可以付款的判斷依據，包含是不是在交易中
   */
  canCreateTransaction: boolean
  /**
   * @description 合約、勞報單（如果是個人）皆簽回後產生
   */
  orderStatus: Nullable<OrderStatus>
  orderDepositedAt: string | null
  approverType: ApproverType
}

export type UpdateCampaignKolRequest = {
  isAccepted?: boolean | null
  isCanceled?: boolean
  remark?: string
  reward?: number
  /**
   * @description ISO format limit
   * */
  startAt?: string
  /**
   * @description ISO format limit
   * */
  endAt?: string
  attachments?: ModelFileItem[]
  cooperationDetail?: CampaignCooperationDetail[]
}

export type UpdateCampaignKolPaymentRequest = {
  paymentStatus: string
}

export type UpdateCampaignKolContractRequest = {
  contractTitle: string
  content: string
  isDraft: boolean
}

export type Channel = {
  acceptedTime?: string
  id: number
  channelId: string
  follower: number
  campaignKolStatus: CampaignKolStatus
  approverType: ApproverType
  campaignName: string
  kol: {
    id: number
    uuid: string
    name: string[]
    isVerified: boolean
    isRadarStar: boolean
    thumbnailUrl: string
  }
  lastMessage?: MessageEnvelope
  unreadCount: number
}

export type ChannelList = {
  channelInfo: Channel[]
  totalUnreadCount: number
}

export type GetChannelListRequest = {
  workspaceId: number
  campaignId: number
}

type NotificationInfo = {
  notificationType: string
  notificationTimestamp: number
  notificationTime: string
  channelId: string
  campaignId: number
  campaignName: string
  kol: {
    id: number
    uuid: string
    name: string[]
    isVerified: boolean
    thumbnailUrl: string
  }
}

export type Notification = {
  notificationInfo: NotificationInfo[]
  page: number
  perPage: number
  total: number
  totalPage: number
}

export type GetNotificationtRequest = {
  workspaceId: number
  page: number
  perPage: number
}

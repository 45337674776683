import Link from 'next/link'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import LogoHorizontal from '@/components/common/logo-horizontal'
import { SearchKeyword } from '@/components/header/search-keyword'
import { WorkspaceDropdown } from '@/components/header/workspace-dropdown'
import routes from '@/hooks/use-authorization/routes'

export const HeaderWithWorkspace: FunctionComponent = () => {
  return (
    <HeaderWrapper>
      <Container>
        <HeaderItemGroupWrapper>
          <Link href={routes.default.stringifyUrl({})}>
            <LogoHorizontal />
          </Link>
          <SearchKeyword />
        </HeaderItemGroupWrapper>
        <WorkspaceDropdown />
      </Container>
    </HeaderWrapper>
  )
}

const HeaderWrapper = styled.div`
  padding: 4px;
  position: fixed;
  width: 100vw;
  background-color: white;
  z-index: 1000;
  box-shadow: rgb(0 0 0 / 10%) 0 2px 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    padding: 4px 50px;
  }
`

const Container = styled.div`
  width: 1140px;
  padding: 0 20px 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  :last-child {
    display: none;
    @media (min-width: 768px) {
      display: flex;
      align-items: center;
    }
  }

  @media (max-width: 1140px) {
    width: 100%;
  }
`

const HeaderItemGroupWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

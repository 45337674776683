import Environment from '@/constants/environment'
import {
  MOCK_KOL_INFO_PREFIX,
  mockKolInfoList,
} from '@/constants/mock-data/kol-info'

export function getThumbnail(id = ''): string {
  // 如果得到 demo 用假資料的 ID，就回傳對應的 thumbnail
  if (id.includes(MOCK_KOL_INFO_PREFIX)) {
    const mockKolInfo = mockKolInfoList.find(
      (mockKolInfo) => mockKolInfo.id === id,
    )
    if (mockKolInfo) {
      return mockKolInfo.thumbnail
    }
  }

  if (process.env.NEXT_PUBLIC_ENVIRONMENT === Environment.Development) {
    return `https://thumbnail.dev.kolr.ai/${id}`
  }
  if (process.env.NEXT_PUBLIC_ENVIRONMENT === Environment.Ironman) {
    return `https://thumbnail.iro.kolr.ai/${id}`
  }
  if (process.env.NEXT_PUBLIC_ENVIRONMENT === Environment.Staging) {
    return `https://thumbnail.stg.kolr.ai/${id}`
  }

  return `https://thumbnail.kolr.ai/${id}`
}

import { Radio } from 'antd'
import React, { FunctionComponent } from 'react'
import {
  ButtonRadioGroupWrapper,
  FilterGroupWrapper,
  Label,
} from '@/components/search/filters/advanced-filters'
import RateSelectorContent from '@/components/search/filters/common/rate-selector-content'
import useRangeSelector from '@/components/search/filters/hooks/use-range-selector'
import { useIntl } from '@/i18n/hooks/use-intl'
import { Condition } from '@/types/api/search'
import { Potential, POTENTIAL_OPTIONS } from '@/utils/detail/potential'

type GroupFilterKeys = keyof Pick<
  Condition,
  | 'interactiveRateBegin'
  | 'interactiveRateEnd'
  | 'avgPvRateBegin'
  | 'avgPvRateEnd'
  | 'fansUpRateBegin'
  | 'fansUpRateEnd'
>

interface EngagementPotentialFilterGroupProps {
  filterValues: Condition
  onValuesChange: (changedValues: Condition) => void
}

const EngagementPotentialFilterGroup: FunctionComponent<
  EngagementPotentialFilterGroupProps
> = ({ filterValues, onValuesChange }) => {
  const { formatMessage } = useIntl()
  const { viewRate, engagementRate, growthRate } = useRangeSelector()
  const handleValuesChange = (
    fromKey: GroupFilterKeys,
    endKey: GroupFilterKeys,
  ) => {
    return (fromValue: number, endValue: number): void => {
      onValuesChange({ [fromKey]: fromValue, [endKey]: endValue })
    }
  }

  return (
    <FilterGroupWrapper>
      <h4>{formatMessage({ id: 'general:interactive_potential' })}</h4>
      <div>
        <Label>{formatMessage({ id: 'kol:field_potential' })}</Label>
        <ButtonRadioGroupWrapper>
          <Radio.Group
            optionType='button'
            options={POTENTIAL_OPTIONS.map(({ label: id, value }) => ({
              label: formatMessage({ id }),
              value,
            }))}
            value={filterValues?.potential ?? Potential.Any}
            onChange={(v): void =>
              onValuesChange({
                potential:
                  v.target.value === Potential.Any ? undefined : v.target.value,
              })
            }
          />
        </ButtonRadioGroupWrapper>
      </div>
      <div>
        <Label>{engagementRate.buttonLabel}</Label>
        {/* @todo Use components/common/selectors/engagement-rate-select.tsx instead. */}
        <RateSelectorContent
          options={engagementRate.options}
          value={{
            fromValue: filterValues?.interactiveRateBegin,
            endValue: filterValues?.interactiveRateEnd,
          }}
          onValuesChange={handleValuesChange(
            'interactiveRateBegin',
            'interactiveRateEnd',
          )}
        />
      </div>
      <div>
        <Label>{viewRate.buttonLabel}</Label>
        {/* @todo Use components/common/selectors/view-rate-select.tsx instead. */}
        <RateSelectorContent
          options={viewRate.options}
          value={{
            fromValue: filterValues?.avgPvRateBegin,
            endValue: filterValues?.avgPvRateEnd,
          }}
          onValuesChange={handleValuesChange('avgPvRateBegin', 'avgPvRateEnd')}
        />
      </div>
      <div>
        <Label>{growthRate.buttonLabel}</Label>
        {/* @todo Use components/common/selectors/follower-growth-rate-select.tsx instead. */}
        <RateSelectorContent
          negativeInput
          options={growthRate.options}
          value={{
            fromValue: filterValues?.fansUpRateBegin,
            endValue: filterValues?.fansUpRateEnd,
          }}
          onValuesChange={handleValuesChange(
            'fansUpRateBegin',
            'fansUpRateEnd',
          )}
        />
      </div>
    </FilterGroupWrapper>
  )
}
export default EngagementPotentialFilterGroup

import { TabKey } from '@/components/layout/irm/irm-feature-sider-content'
import { TourKey } from '@/store/tour'

export const getTourDataKey = (key: TourKey | 'menu'): string => {
  return `data-tour-${key}`
}

const getTourStepDataKey = (key: TourKey): string => {
  return `${getTourDataKey(key)}-step`
}

export const createTourStepData = (
  key: TourKey,
  step: number,
): Record<string, number> => {
  return { [getTourStepDataKey(key)]: step }
}

export const createMenuTourStepData = (
  value: TabKey,
): Record<string, TabKey> => {
  return { [getTourDataKey('menu')]: value }
}

export const getTourStepSelector = (key: TourKey, step: number): string => {
  return `[${getTourStepDataKey(key)}="${step}"]`
}

export const getMenuTourStepSelector = (value: TabKey): string => {
  return `[${getTourDataKey('menu')}="${value}"]`
}

export const getFinalStepSelector = (): string => {
  return `[${getTourDataKey('final')}]`
}

export const waitForElement = (
  selector: string,
  intervalTime = 360,
): Promise<HTMLElement> => {
  return new Promise<HTMLElement>((resolve) => {
    const interval = setInterval(() => {
      const element = document.querySelector<HTMLElement>(selector)
      if (element) {
        clearInterval(interval)
        clearTimeout(timeout)
        resolve(element)
      }
    }, intervalTime)

    const timeout = setTimeout(() => {
      clearInterval(interval)
    }, 10000)
  })
}

export const closeDrawer = (): void => {
  document.querySelector<HTMLElement>('.ant-drawer-mask')?.click()
}

import { useTheme } from 'styled-components'

type UseGetLabelColor = (checked?: boolean, disabled?: boolean) => string

export const useGetLabelColor = (): UseGetLabelColor => {
  const theme = useTheme()

  const getLabelColor = (checked?: boolean, disabled?: boolean): string => {
    if (!!disabled) {
      return theme.colors.text.placeholder
    }

    return checked ? theme.colors.brand.secondary : theme.colors.text.secondary
  }

  return getLabelColor
}

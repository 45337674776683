import { z } from 'zod'
import ceilNumberSchema from '@/types/schema/ceil-number-schema'
import { followerLevelSchema } from '@/types/schema/follower-level'
import { PlatformType } from '@/types/schema/report-schema'
import { PlatformShortcode } from '@/utils/convert-platform'
import { Potential } from '@/utils/detail/potential'

const competitiveAnalysisKolSchema = z.object({
  kol_uuid: z.string(),
  name: z.string(),
  starred_kol: z.boolean(),
  follower_count: z.number().nullable(),
  order_no: z.number().nullable(),
  platform_type_list: z.nativeEnum(PlatformType).array().optional(),
})
export type CompetitiveAnalysisKol = z.infer<
  typeof competitiveAnalysisKolSchema
>

const pendingKolSchema = z.object({
  uuid: z.string().uuid(),
  name: z.string(),
})
export type PendingKol = z.infer<typeof pendingKolSchema>

export const competitiveAnalysisKolListSchema = z.object({
  kols: competitiveAnalysisKolSchema.array(),
  pending_kols: pendingKolSchema.array().optional(),
})
export type CompetitiveAnalysisKolList = z.infer<
  typeof competitiveAnalysisKolListSchema
>

export const followerLevelPercentageSchema = z.object({
  follower_level: followerLevelSchema,
  percentage: z.number().nullable(),
})

const competitiveAnalysisKolFollowerChartDataSchema = z.object({
  uuid: z.string(),
  name: z.string(),
  follower_level_percentages: followerLevelPercentageSchema.array(),
})
export type CompetitiveAnalysisKolFollowerChartData = z.infer<
  typeof competitiveAnalysisKolFollowerChartDataSchema
>

export const competitiveAnalysisPostSchema = z.object({
  type: z.string(),
  post_id: z.string(),
  url: z.string(),
  thumbnail_url: z.string(),
  title: z.string(),
  content: z.string(),
  crawler_id: z.string(),
  platform_type: z.nativeEnum(PlatformShortcode),
  published_at: z.string().datetime().nullable(),
  is_advertorial: z.boolean(),
  like_count: z.number().nullable(),
  comment_count: z.number().nullable(),
  share_count: z.number().nullable(),
  view_count: z.number().nullable(),
  play_count: z.number().nullish(),
  is_post_collected: z.boolean(),
  ig_post_id: z.string(),
})

export const competitiveAnalysisPostKolSchema = z.object({
  kol_name: z.string(),
  potential: z.nativeEnum(Potential).nullable(),
  customized_tags_names: z.string().array(),
  uuid: z.string(),
  platform: z.object({
    platform: z.nativeEnum(PlatformType).nullable(),
  }),
  is_verified: z.boolean(),
  is_radar_star: z.boolean(),
  is_collection_kol: z.boolean(),
  follower_count: ceilNumberSchema.nullable(),
  follower_count_growth_rate: z.number().nullable(),
  avg_like_count: ceilNumberSchema.nullable(),
  avg_comment_count: ceilNumberSchema.nullable(),
  avg_share_count: ceilNumberSchema.nullable(),
  avg_view_count: ceilNumberSchema.nullable(),
  avg_view_rate: z.number().nullable(),
  avg_engagement_count: ceilNumberSchema.nullable(),
  avg_engagement_rate: z.number().nullable(),
  post_count: ceilNumberSchema.nullable(),
})

export const estimatedCollaborationPricesSchema = z.object({
  follower_level: followerLevelSchema,
  kol_count: z.number().nullable(),
  percentage: z.number().nullable(),
  price: z.string().nullable(),
})

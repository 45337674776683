import isEmpty from 'lodash-es/isEmpty'
import { Trial, useFetchTrialQuery } from '@/api/workspaces-api'
import useIsJapan from '@/hooks/use-is-japan'
import { usePermissions } from '@/hooks/use-permissions'

type UseTrial = () => { trial: Trial[]; canTrial: boolean }

const useTrial: UseTrial = () => {
  const { data } = useFetchTrialQuery()
  const { withFreePermission } = usePermissions()
  const isJapan = useIsJapan()

  return {
    trial: data ?? [],
    canTrial: !isJapan && isEmpty(data) && withFreePermission,
  }
}

export default useTrial

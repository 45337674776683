import { useMemo } from 'react'
import {
  useFetchUserStatusQuery,
  WorkspaceMemberStatus,
  WorkspaceType,
} from '@/api/user-api'
import { CountryCode } from '@/constants/country-code'
import useAgentInfo from '@/hooks/use-agent-info'
import { useAuth } from '@/hooks/use-auth'
import {
  BasicService,
  HashtagService,
  ServiceCategory,
} from '@/types/mock-api-types'
import { KolUserIdentityType } from '@/types/schema/agent-schema'

export type MemberPermission =
  | WorkspaceMemberStatus.Viewer
  | WorkspaceMemberStatus.Editor
  | WorkspaceMemberStatus.Admin

export type Permission =
  | 'report'
  | 'internal'
  | 'competitive'
  | 'predictiveReport'
  | MemberPermission

export interface Permissions {
  withHashtagPermission: boolean
  withReportPermission: boolean
  withInternalPermission: boolean
  withEnterprisePermission: boolean
  withPremiumPermission: boolean
  withFreePermission: boolean
  withKolPermission: boolean
  withGuestPermission: boolean
  withRadarStarPermission: boolean
  withAgencyKolPermission: boolean
  withDeprecatedPremiumPermission: boolean
  withOfflinePremiumPermission: boolean
  withAdvancedPermission: boolean
  withTrialPermission: boolean
  withCompetitivePermission: boolean
  withPredictiveReportPermission: boolean
  withAIEnhancedPermission: boolean
  withViewerPermission: boolean
  withEditorPermission: boolean
  withAdminPermission: boolean
}

// TODO: support all permissions
export function usePermissions(): Permissions {
  const { data: userStatus } = useFetchUserStatusQuery()
  const { isAgent, isKol } = useAuth()
  const { kolUserIdentityType } = useAgentInfo()

  const withHashtagPermission = useMemo<boolean>(() => {
    const isHashtagService = (
      service?: BasicService,
    ): service is HashtagService =>
      service?.serviceCategory === ServiceCategory.Hashtag

    const foundHashtagService = (): HashtagService | undefined =>
      userStatus?.currentWorkspace.availableServices.find(isHashtagService)

    const hashtagService = foundHashtagService()

    if (hashtagService === undefined) {
      return false
    }

    return (hashtagService.memo.hashtagDetectionLimit ?? 0) > 0
  }, [userStatus?.currentWorkspace])

  const withReportPermission = useMemo<boolean>(() => {
    if (!userStatus?.currentWorkspace) {
      return false
    }

    return (
      userStatus.currentWorkspace.availableResources.some(
        (resource) => resource.resourceType === ServiceCategory.Report,
      ) ||
      userStatus.currentWorkspace.availableServices.some(
        (service) => service.serviceCategory === ServiceCategory.Report,
      )
    )
  }, [userStatus?.currentWorkspace])

  const withCompetitivePermission = useMemo<boolean>(
    () =>
      userStatus?.currentWorkspace.availableServices.some(
        (service) => service.serviceCategory === ServiceCategory.Competitive,
      ) ?? false,
    [userStatus?.currentWorkspace],
  )

  const withInternalPermission = useMemo(() => {
    return userStatus?.currentWorkspace.workspaceType === WorkspaceType.Internal
  }, [userStatus?.currentWorkspace.workspaceType])

  const withEnterprisePermission = useMemo(() => {
    return (
      userStatus?.currentWorkspace.workspaceType === WorkspaceType.Enterprise ||
      userStatus?.currentWorkspace.workspaceType === WorkspaceType.Corporate
    )
  }, [userStatus?.currentWorkspace.workspaceType])

  const withPremiumPermission = useMemo(() => {
    return (
      userStatus?.currentWorkspace.workspaceType === WorkspaceType.Premium ||
      userStatus?.currentWorkspace.workspaceType === WorkspaceType.Premium202408
    )
  }, [userStatus?.currentWorkspace.workspaceType])

  const withAdvancedPermission = useMemo(() => {
    return (
      userStatus?.currentWorkspace.workspaceType === WorkspaceType.Advanced ||
      userStatus?.currentWorkspace.workspaceType ===
        WorkspaceType.Advanced202408
    )
  }, [userStatus?.currentWorkspace.workspaceType])

  /**
   * @deprecated
   */
  const withDeprecatedPremiumPermission = useMemo(() => {
    return (
      userStatus?.currentWorkspace.workspaceType === WorkspaceType.Vip ||
      userStatus?.currentWorkspace.workspaceType === WorkspaceType.PaymentVip ||
      userStatus?.currentWorkspace.workspaceType === WorkspaceType.VipTrial
    )
  }, [userStatus?.currentWorkspace.workspaceType])
  /**
   * @deprecated
   */
  const withOfflinePremiumPermission = useMemo(() => {
    return userStatus?.currentWorkspace.workspaceType === WorkspaceType.Vip
  }, [userStatus?.currentWorkspace.workspaceType])

  const withFreePermission = useMemo(() => {
    return userStatus?.currentWorkspace.workspaceType === WorkspaceType.Free
  }, [userStatus?.currentWorkspace.workspaceType])

  const withTrialPermission = useMemo(() => {
    return (
      userStatus?.currentWorkspace.workspaceType === WorkspaceType.Trial ||
      userStatus?.currentWorkspace.workspaceType === WorkspaceType.Trial202408
    )
  }, [userStatus?.currentWorkspace.workspaceType])

  const withPredictiveReportPermission = useMemo(
    () =>
      !!userStatus?.currentWorkspace.availableServices.some(
        (service) =>
          service.serviceCategory === ServiceCategory.PredictiveReport,
      ),
    [userStatus?.currentWorkspace.availableServices],
  )

  const withKolPermission = isAgent || isKol
  const withRadarStarPermission = [
    KolUserIdentityType.RadarStarKOL,
    KolUserIdentityType.RadarStarAgency,
  ].some((type) => type === kolUserIdentityType)
  const withGuestPermission =
    userStatus?.currentWorkspace.memberStatus === WorkspaceMemberStatus.Guest

  const withAgencyKolPermission =
    kolUserIdentityType === KolUserIdentityType.AgencyKOL

  const withAIEnhancedPermission =
    userStatus?.currentWorkspace.countryCode === CountryCode.TW

  const withViewerPermission =
    userStatus?.currentWorkspace.memberStatus === WorkspaceMemberStatus.Viewer
  const withEditorPermission =
    userStatus?.currentWorkspace.memberStatus === WorkspaceMemberStatus.Editor
  const withAdminPermission =
    userStatus?.currentWorkspace.memberStatus === WorkspaceMemberStatus.Admin

  return {
    withInternalPermission,
    withEnterprisePermission,
    withPremiumPermission,
    withHashtagPermission,
    withReportPermission,
    withFreePermission,
    withKolPermission,
    withRadarStarPermission,
    withGuestPermission,
    withAgencyKolPermission,
    withDeprecatedPremiumPermission,
    withOfflinePremiumPermission,
    withAdvancedPermission,
    withTrialPermission,
    withCompetitivePermission,
    withPredictiveReportPermission,
    withAIEnhancedPermission,
    withViewerPermission,
    withEditorPermission,
    withAdminPermission,
  }
}

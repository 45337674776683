import dayjs from 'dayjs'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { GoogleRecaptchaFooter } from '@/components/common/google-recaptcha-footer'
import UnsafeNewTabLink from '@/components/public-page/footer/components/unsafe-new-tab-link'
import { SafeNewTabLink } from '@/components/safe-new-tab-link'
import useBrandName, { DEFAULT_BRAND_NAME } from '@/hooks/use-brand-name'
import { PC_BREAKPOINT, useBreakpoint } from '@/hooks/use-breakpoint'
import { getLocaleRegion, useI18n } from '@/hooks/use-i18n'
import { useIntl } from '@/i18n/hooks/use-intl'
import { StyledFooter } from '@/types/antd/styled-antd'
import env from '@/utils/env'

export const FOOTER_HEIGHT = 222

interface FooterProps {
  displayTaxId?: boolean
  className?: string
}

const Footer: FunctionComponent<FooterProps> = ({
  className,
  displayTaxId = true,
}) => {
  const { formatMessage } = useIntl()
  const brandName = useBrandName()
  const { locale } = useI18n()
  const region = getLocaleRegion(locale)
  const breakpoint = useBreakpoint()

  const isJapan = locale === 'ja'
  const isMobile = breakpoint === 'mobile'

  const getRootUrl = (url: string): string => {
    const urls = [getLocaleRegion(locale), url].filter((_region) => _region)
    return urls.join('/')
  }

  const items = [
    {
      text: 'Kolr',
      url: `${env.LANDING_URL}/${region}`,
    },
    {
      text: formatMessage({ id: 'general:footer_text_terms' }),
      url: `${env.LANDING_URL}/${getRootUrl('terms')}`,
    },
    {
      text: formatMessage({ id: 'general:footer_text_privacy' }),
      url: `${env.LANDING_URL}/${getRootUrl('privacy')}`,
    },
    ...(isJapan
      ? [
          {
            text: formatMessage({
              id: 'general:specified_commercial_transactions_law',
            }),
            url: `${env.LANDING_URL}/${getRootUrl(
              'specified-commercial-transactions-law',
            )}`,
          },
        ]
      : []),
    {
      text: formatMessage({ id: 'general:footer_text_contact' }),
      url: `${env.LANDING_URL}/${getRootUrl('contact')}`,
    },
  ]

  if (brandName !== DEFAULT_BRAND_NAME) {
    return <StyleFooter>Powered by iKala Kolr</StyleFooter>
  }

  return (
    <StyleFooter className={className}>
      <Wrapper>
        <CopyRight>
          {items.map(({ text, url }) => (
            <SafeNewTabLink href={url} key={text}>
              {text}
            </SafeNewTabLink>
          ))}
        </CopyRight>
        <CopyRight>
          {displayTaxId && (
            <>
              <div>
                {formatMessage({ id: 'general:ikala_company_information' })}
              </div>
              <Divider />
              <div>{formatMessage({ id: 'general:ikala_company_tax_id' })}</div>
              <Divider />
            </>
          )}
          <div>
            Copyright © {dayjs().format('YYYY')}{' '}
            <UnsafeNewTabLink href='https://ikala.ai/'>iKala</UnsafeNewTabLink>{' '}
            All Rights Reserved.
          </div>
        </CopyRight>
        {isMobile && <br />}
        <GoogleRecaptchaFooter />
      </Wrapper>
    </StyleFooter>
  )
}

const StyleFooter = styled(StyledFooter)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;

  @media (min-width: ${PC_BREAKPOINT}px) {
    padding: 90px 0 60px 0;
  }
`

const Divider = styled.span.attrs({ children: ' | ' })`
  margin: 0 4px;
  color: ${({ theme }): string => theme.colors.base.grey.grey5};
`

const CopyRight = styled.div`
  color: ${({ theme }): string => theme.colors.text.secondary};

  a {
    line-height: 24px;
    color: ${({ theme }): string => theme.colors.text.secondary};

    &:hover {
      color: ${({ theme }): string => theme.colors.brand.primary};
    }
  }

  &:first-of-type {
    > a:not(:last-child) {
      ::after {
        content: ' | ';
        margin: 0 4px;
        color: ${({ theme }): string => theme.colors.base.grey.grey5};
      }
    }
  }

  div {
    display: inline;
  }

  @media (max-width: 768px) {
    font-size: 15px;
    text-align: center;

    div {
      display: block;
    }
    &:first-child {
      margin-bottom: 24px;
    }
    ${Divider} {
      display: none;
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export default Footer

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'
import { CookieName } from '@/constants/cookie-name'
import LocalStorageKeys from '@/constants/localstorage-keys'
import { kolrDomain } from '@/utils/env'
import { getCookieDomain } from '@/utils/get-cookie-domain'

interface State {
  jwt: string | null
  loginTime: string | null
}

const initialState: State = {
  jwt: null,
  loginTime: null,
}

const meSlice = createSlice({
  name: 'me',
  initialState,
  reducers: {
    cacheJWT: (state, action: PayloadAction<string>) => {
      state.jwt = action.payload
      localStorage.setItem(LocalStorageKeys.Jwt, action.payload)
      Cookies.set(CookieName.UserToken, action.payload, {
        domain: getCookieDomain(window.location.host),
        path: '/',
        secure: true,
      })
    },
    cacheLoginTime: (state, action: PayloadAction<string>) => {
      state.loginTime = action.payload
      localStorage.setItem(LocalStorageKeys.LoginTime, action.payload)
      Cookies.set(CookieName.LoginTime, action.payload, { path: '/' })
    },
    logout: (state) => {
      state.jwt = null
      state.loginTime = null

      /** NOTE:
       * Legacy userToken cookie, which is stored under kolrDomain.
       * */
      Cookies.remove(CookieName.UserToken, {
        domain: kolrDomain,
        path: '/',
      })
      Cookies.remove(CookieName.UserToken, {
        domain: getCookieDomain(window.location.host),
        path: '/',
      })
      Cookies.remove(CookieName.LoginTime, { path: '/' })
      localStorage.removeItem(LocalStorageKeys.Jwt)
      localStorage.removeItem(LocalStorageKeys.LoginTime)
    },
  },
})

export const { cacheJWT, cacheLoginTime, logout } = meSlice.actions
export default meSlice.reducer

import { Icon } from '@buggy/shared'
import { Button, Flex, GetProps, Spin } from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import { compact, debounce, isEmpty } from 'lodash-es'
import { FunctionComponent, useCallback, useMemo, useState } from 'react'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { useCss } from 'react-use'
import styled, { useTheme } from 'styled-components'
import { useFetchFilterResourcesInfiniteQuery } from '@/api/workspace-api/directory-api'
import BasicSelect from '@/components/common/antd/basic-select'
import { NoData } from '@/components/kol/detail/no-data'
import { I18nId } from '@/i18n/config'
import { useIntl } from '@/i18n/hooks/use-intl'
import {
  FilterResource,
  FilterResourceType,
} from '@/types/schema/directory/kol-management'

type OptionType = DefaultOptionType &
  (
    | {
        value: 'all'
        resource?: undefined
      }
    | {
        value: 'loading'
        resource?: undefined
      }
    | {
        value: number
        resource: FilterResource
      }
  )

type SelectProps = GetProps<typeof Select>

export interface ResourcesSelectorProps
  extends Pick<SelectProps, 'maxTagCount' | 'disabled'> {
  value?: FilterResource[] | 'all'
  type: FilterResourceType
  selectAllI18nId?: I18nId
  onChange?: (resource?: ResourcesSelectorProps['value']) => void
}

const ResourcesSelector: FunctionComponent<ResourcesSelectorProps> = ({
  value,
  type,
  maxTagCount,
  disabled,
  selectAllI18nId = 'general:select_all',
  onChange,
}) => {
  const { formatMessage } = useIntl()
  const theme = useTheme()
  const selectAllClassName = useCss({
    borderBottom: `1px solid ${theme.colors.border.divider}`,
  })

  const [debouncedKeyword, setDebouncedKeyword] = useState<string>()
  const [page, setPage] = useState(1)

  const { data, isFetching, isError } = useFetchFilterResourcesInfiniteQuery({
    keyword: debouncedKeyword,
    filter_resource_type: type,
    page,
  })

  const hasNextPage = data ? data.page < data.total_page : false

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    onLoadMore: () => setPage((prev) => prev + 1),
    loading: isFetching,
    disabled: isError,
    hasNextPage,
  })

  const { options, resourceIds } = useMemo(() => {
    if (!data || isEmpty(data.data)) {
      return { options: [], resourceIds: [] }
    }

    return data.data.reduce<{
      options: OptionType[]
      resourceIds: number[]
    }>(
      (acc, resource, currentIndex, array) => {
        acc.options.push({
          value: resource.id,
          label: resource.name,
          resource,
        })
        acc.resourceIds.push(resource.id)

        if (currentIndex === array.length - 1 && hasNextPage) {
          acc.options.push({
            disabled: true,
            value: 'loading',
            label: (
              <Flex
                align='center'
                justify='center'
                ref={sentryRef}
                style={{ width: '100%', height: 30 }}
              >
                <Spin />
              </Flex>
            ),
          })
        }
        return acc
      },
      {
        options: [
          {
            className: selectAllClassName,
            value: 'all',
            label: (
              <Flex align='center' justify='space-between'>
                {formatMessage({ id: selectAllI18nId })}
                {value === 'all' && (
                  <Icon
                    color={theme.colors.brand.primary}
                    fontSize={16}
                    name='check'
                  />
                )}
              </Flex>
            ),
          },
        ],
        resourceIds: [],
      },
    )
  }, [
    data,
    formatMessage,
    hasNextPage,
    selectAllClassName,
    selectAllI18nId,
    sentryRef,
    theme.colors.brand.primary,
    value,
  ])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleOnSearch = useCallback(
    debounce((keyword: string) => {
      setDebouncedKeyword(keyword)
      setPage(1)
    }, 500),
    [],
  )

  return (
    <Select
      allowClear
      disabled={disabled}
      dropdownRender={(menu) => <div ref={rootRef}>{menu}</div>}
      filterOption={false}
      getPopupContainer={(triggerNode): HTMLElement => triggerNode}
      loading={isFetching}
      maxTagCount={value === 'all' ? 0 : maxTagCount}
      maxTagPlaceholder={(omittedValues) => {
        return value === 'all' ? (
          <Flex align='center' gap={7}>
            {formatMessage({ id: selectAllI18nId })}
            <Button
              icon={
                <Icon
                  color={theme.colors.text.tooltip}
                  fontSize={14}
                  name='x'
                />
              }
              type='text'
              onClick={(): void => onChange?.(undefined)}
            />
          </Flex>
        ) : (
          <span>{omittedValues.length}+</span>
        )
      }}
      mode='multiple'
      notFoundContent={
        isFetching ? <Spin style={{ width: '100%' }} /> : <NoData />
      }
      options={options}
      placeholder={formatMessage({ id: 'general:select_placeholder' })}
      value={
        value === 'all' ? resourceIds : value?.map((resource) => resource.id)
      }
      onBlur={(): void => {
        setDebouncedKeyword(undefined)
      }}
      onChange={(newValue: (number | 'all')[], options: OptionType[]): void => {
        if (
          newValue.includes('all') ||
          newValue.length === resourceIds.length
        ) {
          onChange?.('all')
          return
        }
        onChange?.(
          isEmpty(options)
            ? undefined
            : compact(options.map((option) => option.resource)),
        )
      }}
      onSearch={handleOnSearch}
      onSelect={(newValue): void => {
        if (newValue !== 'all') {
          return
        }
        onChange?.(value === 'all' ? undefined : 'all')
      }}
    />
  )
}

const Select = styled(BasicSelect)`
  .ant-select-selector {
    .ant-select-selection-overflow {
      gap: 4px;
    }
  }

  .ant-select-dropdown {
    .ant-select-item {
      color: ${({ theme }): string => theme.colors.text.secondary};
      border-radius: 0;
    }

    .ant-select-item-option-active:not(.ant-select-item-option-disabled),
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      font-weight: 400;
      background: transparent;
    }
  }

  .ant-btn.ant-btn-icon-only:not(.ant-btn-sm):not(.ant-btn-block) {
    font-size: 14px;
    width: 16px;
    height: 16px;
    padding: 0;

    &:not(:disabled) {
      color: ${({ theme }): string => theme.colors.text.tooltip};

      &:hover {
        color: ${({ theme }): string => theme.colors.text.tooltip};
        background: transparent;
      }
    }
  }
`

export default ResourcesSelector

import { SerializedError } from '@reduxjs/toolkit'
import { skipToken } from '@reduxjs/toolkit/query'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { difference, head, isUndefined, omit, pickBy, values } from 'lodash-es'
import { useRouter } from 'next/router'
import queryString from 'query-string'
import { useCallback, useMemo } from 'react'
import {
  createEnumParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'
import { WorkspacesAPITagType } from '@/api/api'
import { useAvailableCountriesListQuery } from '@/api/available-countries-api'
import { useFetchUserStatusQuery } from '@/api/user-api'
import {
  useKolRadarSearchQuery,
  usePostRadarSearchQuery,
} from '@/api/workspace-api/search-api'
import { PlatformParam } from '@/components/hashtag/common/hashtag-filter'
import { FOLLOWER_COUNT_RANGE_RECORD } from '@/components/kol/common/follower-level-selector'
import {
  ANY_KOL_TYPE_CODE,
  EXCLUDED_ALL_KOL_TYPE_CODE,
} from '@/components/search/filters/common/with-official-filter-content'
import { VerifiedKolParam } from '@/components/search/filters/others-filter-group'
import { getCollectionList } from '@/components/search/filters/utils/exclusive-collection-operator'
import {
  AllCountryCodeParam,
  UNLIMITED_COUNTRY_CODE,
} from '@/constants/country-code'
import { FollowerFilter } from '@/constants/search/follower-filter'
import {
  defaultKolSortOption,
  KolSortOption,
} from '@/constants/search/kol-sort-option'
import { PostSortOption } from '@/constants/search/post-sort-option'
import { SearchMode } from '@/constants/search/search-mode'
import { Page } from '@/hooks/use-authorization/constants'
import useIsDefaultSearch from '@/hooks/use-is-default-search'
import { useSelector } from '@/hooks/use-selector'
import useUserMetadata from '@/hooks/use-user-metadata'
import { useIntl } from '@/i18n/hooks/use-intl'
import {
  Condition,
  KolRadarSearchDefaultSearchKeys,
  KolRadarSearchRequestBasicProps,
  KolRadarSearchResponse,
  PostRadarSearchRequest,
  SearchPostQueryParams,
} from '@/types/api/search'
import { Optional } from '@/types/optional'
import UseQueryResult from '@/types/rtk-query'
import { PostRadarSearchResponse } from '@/types/schema/api/radar-search-post'
import { SearchSort } from '@/types/schema/kol-schema'
import {
  ampli,
  type SearchForKolContentProperties,
  type SearchForKolNoResultProperties,
} from '@/utils/ampli'
import { PlatformShortcode } from '@/utils/convert-platform'
import { PotentialParam } from '@/utils/detail/potential'
import { getDefaultCountryCode } from '@/utils/get-default-country-code'
import isExternalOrigin from '@/utils/is-external-origin'
import removeNil from '@/utils/remove-nil'
import SearchIdManager from '@/utils/search-id-manager'
import mapEventPropertyFilterFollowerRange from '@/utils/search/map-event-property-filter-follower-range'
import { getFilterOfficialValues } from '@/utils/tracking/get-filter-official-value'
import searchForKol from '@/utils/tracking/search-for-kol'
import {
  NonNullStringParam,
  NoNullStringArrayParam,
} from '@/utils/use-query-params'

interface SortOption {
  value: NonNullable<SearchSort>
  label: string
  disabled: boolean
  tag?: string
}

const SearchModeParams = createEnumParam(values(SearchMode))

const searchKolDefaultParams = {
  keyword: withDefault(StringParam, undefined),
  keywordFrom: withDefault(StringParam, undefined),
  platform_type: withDefault(PlatformParam, undefined),
  country_code: withDefault(AllCountryCodeParam, undefined),
  verifiedKol: withDefault(VerifiedKolParam, undefined),
  potential: withDefault(PotentialParam, undefined),
  customized_tags_names: withDefault(StringParam, undefined),
  gender: withDefault(StringParam, undefined),
  avgPvRateBegin: withDefault(NumberParam, undefined),
  avgPvRateEnd: withDefault(NumberParam, undefined),
  interactiveRateBegin: withDefault(NumberParam, undefined),
  interactiveRateEnd: withDefault(NumberParam, undefined),
  followerRangeLevel: withDefault(NumberParam, undefined),
  fansUpRateBegin: withDefault(NumberParam, undefined),
  fansUpRateEnd: withDefault(NumberParam, undefined),
  filter_collection: withDefault(StringParam, undefined),
  exclude_kol_collection_folder_ids: withDefault(StringParam, undefined),
  filter_cooperation: withDefault(StringParam, undefined),
  filter_unrecommended: withDefault(StringParam, undefined),
  filter_kol_type: withDefault(StringParam, EXCLUDED_ALL_KOL_TYPE_CODE),
  audienceGenderCode: withDefault(StringParam, undefined),
  audienceGenderRateBegin: withDefault(NumberParam, undefined),
  audienceGenderRateEnd: withDefault(NumberParam, undefined),
  audienceAgeCode: withDefault(StringParam, undefined),
  audienceAgeRateBegin: withDefault(NumberParam, undefined),
  audienceAgeRateEnd: withDefault(NumberParam, undefined),
  audienceGeoCode: withDefault(StringParam, undefined),
  audienceGeoRateBegin: withDefault(NumberParam, undefined),
  audienceGeoRateEnd: withDefault(NumberParam, undefined),
  minQuotationPrice: withDefault(NumberParam, undefined),
  maxQuotationPrice: withDefault(NumberParam, undefined),
  follower_start_from: withDefault(NumberParam, undefined),
  follower_end_to: withDefault(NumberParam, undefined),
  post_start_time: withDefault(StringParam, undefined),
  post_end_time: withDefault(StringParam, undefined),
  sort: withDefault(StringParam, defaultKolSortOption),
  languages: withDefault(NoNullStringArrayParam, undefined),
  anchor: withDefault(StringParam, undefined),
  from: withDefault(StringParam, undefined),
}

const searchPostDefaultParams = {
  keyword: withDefault(StringParam, undefined),
  platform_type: withDefault(PlatformParam, undefined),
  post_type: withDefault(StringParam, undefined),
  customized_tags_names: withDefault(StringParam, undefined),
  follower_start_from: withDefault(NumberParam, undefined),
  follower_end_to: withDefault(NumberParam, undefined),
  post_start_time: withDefault(StringParam, undefined),
  post_end_time: withDefault(StringParam, undefined),
  filter_advertorial: withDefault(StringParam, undefined),
  country_code: withDefault(AllCountryCodeParam, undefined),
  filter_collection: withDefault(StringParam, undefined),
  exclude_kol_collection_folder_ids: withDefault(StringParam, undefined),
  filter_cooperation: withDefault(StringParam, undefined),
  filter_unrecommended: withDefault(StringParam, undefined),
  filter_kol_type: withDefault(StringParam, EXCLUDED_ALL_KOL_TYPE_CODE),
  sort: withDefault(StringParam, PostSortOption.Default),
  after_page: NonNullStringParam,
}

const isSearchPostQueryParams = (
  params: Condition | SearchPostQueryParams,
  mode: SearchMode,
): params is SearchPostQueryParams => {
  return mode === SearchMode.Post || Object(params).hasOwnProperty('page')
}

export const isDefaultSearch = (
  conditions: Condition,
  defaultCountryCode: string,
  mode: SearchMode,
): boolean => {
  const filterConditions = pickBy(conditions, (value) => !!value)

  if (
    Object.keys(filterConditions).length ===
    KolRadarSearchDefaultSearchKeys.length
  ) {
    if (mode === SearchMode.Post) {
      return (
        filterConditions.country_code === defaultCountryCode &&
        filterConditions.sort === PostSortOption.Default &&
        filterConditions.filter_kol_type === EXCLUDED_ALL_KOL_TYPE_CODE
      )
    }

    return (
      filterConditions.country_code === defaultCountryCode &&
      filterConditions.sort === defaultKolSortOption &&
      filterConditions.filter_kol_type === EXCLUDED_ALL_KOL_TYPE_CODE
    )
  }

  return Object.keys(filterConditions).length === 0
}

const omitDefaultValue = <T>(value: T, defaultValue: T): T | undefined => {
  return value === defaultValue ? undefined : value
}

const omitIfGreaterThanOrEqual = <T>(
  value: T,
  defaultValue: T,
): T | undefined => {
  return value >= defaultValue ? undefined : value
}

export const mapKolSearchParams = (
  kolQueryParams: Condition,
  hasAnyKolRadarSearchCache: boolean,
): KolRadarSearchRequestBasicProps => {
  return removeNil({
    ...kolQueryParams,
    // 過濾掉空字串
    keyword: !!kolQueryParams.keyword ? kolQueryParams.keyword : undefined,
    // 搜尋上限超過 1M+，就不帶入
    follower_end_to: omitIfGreaterThanOrEqual(
      kolQueryParams.follower_end_to,
      FOLLOWER_COUNT_RANGE_RECORD.level8.followerStartFrom,
    ),
    filter_kol_type: omitDefaultValue(
      kolQueryParams.filter_kol_type,
      ANY_KOL_TYPE_CODE,
    ),
    country_code: omitDefaultValue(
      kolQueryParams.country_code,
      UNLIMITED_COUNTRY_CODE,
    ),
    anchor: hasAnyKolRadarSearchCache ? kolQueryParams.anchor : undefined,
    languages: kolQueryParams.languages?.join('|'),
  })
}

const mapPostSearchParams = (
  postQueryParams: SearchPostQueryParams,
  hasAnyPostRadarSearchCache: boolean,
): PostRadarSearchRequest['params'] => {
  return removeNil({
    ...postQueryParams,
    // 搜尋上限超過 FollowerFilter.UpperBound+，就不帶入
    follower_end_to: omitIfGreaterThanOrEqual(
      postQueryParams.follower_end_to,
      FollowerFilter.UpperBound,
    ),
    filter_kol_type: omitDefaultValue(
      postQueryParams.filter_kol_type,
      ANY_KOL_TYPE_CODE,
    ),
    country_code: omitDefaultValue(
      postQueryParams.country_code,
      UNLIMITED_COUNTRY_CODE,
    ),
    // 如果沒有快取，就假設是從 URL 進來，從 URL 進來則無視分頁 ref: https://www.notion.so/ikala/Staging-Search-3203896f00ab43e39e385d4a53c10cbb?pvs=4
    after_page: hasAnyPostRadarSearchCache
      ? postQueryParams.after_page
      : undefined,
  })
}

type UseSearch = (disabledDefaultSearch?: boolean) => {
  isAvailableCountry: {
    hasResult: boolean
    isFetching: boolean
    isSuccess: boolean
  }
  handleSearch: {
    (params: Condition): void
    (params: SearchPostQueryParams): void
  }
  handleSearchKol: (params: Condition) => void
  handleSearchPost: (params: SearchPostQueryParams) => void
  kolQueryParams: Condition
  postQueryParams: SearchPostQueryParams
  kolResult: UseQueryResult<KolRadarSearchResponse>
  postResult: {
    data: Optional<PostRadarSearchResponse>
    isFetching: boolean
    error: Optional<FetchBaseQueryError | SerializedError>
  }
  mode: SearchMode
  sortOptions: SortOption[]
}

const useSearch: UseSearch = () => {
  const { formatMessage } = useIntl()
  const router = useRouter()
  const { userMetadata } = useUserMetadata()

  const currentLayout = useSelector((state) => state.search.generalLayout)
  const isOnTourFlow = useSelector((state) => state.tour.tourModalVisible)
  const { data: userStatus } = useFetchUserStatusQuery()
  const { isDefaultSearch: isDefault, setIsDefaultSearch } =
    useIsDefaultSearch()

  const {
    data: availableCountries,
    isFetching: isFetchingAvailableCountries,
    isSuccess: isSuccessFetchAvailableCountries,
  } = useAvailableCountriesListQuery(userStatus ? undefined : skipToken)

  const defaultCountryCode = getDefaultCountryCode(
    userStatus?.currentWorkspace.countryCode,
    userStatus?.currentWorkspace.countryCode,
    userStatus?.currentWorkspace.availableServices,
    true,
  )

  const kolSortOptions = useCallback(
    (keyword: Optional<string>): SortOption[] => {
      return [
        {
          value: KolSortOption.Recommend,
          label: formatMessage({ id: 'search:sorting_option_kolradar' }),
          disabled: false,
          tag: 'recommend',
        },
        {
          value: KolSortOption.FollowerCount,
          label: formatMessage({ id: 'search:sorting_option_fans_descending' }),
          disabled: false,
          tag: 'follower',
        },
        {
          value: KolSortOption.MaxFansUpRate3Month,
          label: formatMessage({
            id: 'search:sorting_option_growth_descending',
          }),
          disabled: false,
          tag: 'fans-up-rate',
        },
        {
          value: KolSortOption.MaxInteractiveRate3Month,
          label: formatMessage({
            id: 'search:sorting_option_engagement_rate_descending',
          }),
          disabled: false,
          tag: 'interactive-rate',
        },
        {
          value: KolSortOption.MaxAvgPvRate3Month,
          label: formatMessage({ id: 'search:filter_view_rate_label' }),
          disabled: false,
          tag: 'pv-rate',
        },
        {
          value: KolSortOption.MatchedPost,
          label: formatMessage({
            id: 'search:sorting_option_keyword_descending',
          }),
          disabled: !keyword,
          tag: 'matched-post',
        },
      ]
    },
    [formatMessage],
  )

  const postSortOptions: SortOption[] = useMemo((): SortOption[] => {
    return [
      {
        value: PostSortOption.Default,
        label: formatMessage({ id: 'search:sorting_option_kolradar' }),
        disabled: false,
      },
      // TODO: 暫時關閉 https://www.notion.so/ikala/Prod-Search-b807357aed3f4545be868262fe4b0c4a?pvs=4
      // {
      //   value: PostSortOption.FollowerCount,
      //   label: formatMessage({
      //     id: 'search:sorting_option_kol_follower_count',
      //   }),
      //   disabled: false,
      // },
      {
        value: PostSortOption.InteractiveCount,
        label: formatMessage({ id: 'kol:post_option_engagement' }),
        disabled: false,
      },

      {
        value: PostSortOption.ViewCount,
        label: formatMessage({ id: 'kol:post_option_view' }),
        disabled: false,
      },

      {
        value: PostSortOption.LikeCount,
        label: formatMessage({ id: 'general:column_title_like_count' }),
        disabled: false,
      },

      {
        value: PostSortOption.CommentCount,
        label: formatMessage({ id: 'general:column_title_comment_count' }),
        disabled: false,
      },

      {
        value: PostSortOption.PublishTime,
        label: formatMessage({ id: 'search:sorting_option_publish_time' }),
        disabled: false,
      },
    ]
  }, [formatMessage])

  const [uiQuery] = useQueryParams({
    mode: withDefault(SearchModeParams, SearchMode.Kol),
  })
  const mode = uiQuery.mode

  const [postQuery] = useQueryParams(searchPostDefaultParams, {
    searchStringToObject: (searchString) => queryString.parse(searchString),
  })

  const [kolQuery] = useQueryParams(searchKolDefaultParams, {
    searchStringToObject: (searchString) =>
      queryString.parse(searchString, { arrayFormat: 'bracket' }),
  })

  const hasAnyPostRadarSearchCache = useSelector(
    (state) => !!state.api.queries.postRadarSearch,
  )
  const hasAnyKolRadarSearchCache = useSelector(
    (state) => !!state.api.queries.kolRadarSearch,
  )
  const hasKolRadarSearchRecord = useSelector(
    (state) =>
      state.api.provided.hasOwnProperty(WorkspacesAPITagType.KolRadarSearch), // 只要有 call 過 radar-search，不管還有沒有元件在訂閱，這個值都會存在
  )
  const isUserLandingSearchPage = useSelector(
    (state) => state.route.initialPathname === Page.Search,
  )

  const kolQueryParams = useMemo(() => {
    return {
      ...kolQuery,
      country_code: kolQuery.country_code ?? defaultCountryCode,
      keywordFrom:
        kolQuery.keywordFrom as SearchForKolNoResultProperties['keywordFrom'],
    }
  }, [defaultCountryCode, kolQuery])

  const postQueryParams = useMemo(() => {
    return {
      ...postQuery,
      country_code: postQuery.country_code ?? defaultCountryCode,
    }
  }, [defaultCountryCode, postQuery])

  const isAvailableCountryResult = useMemo(() => {
    const queryParams =
      mode === SearchMode.Post ? postQueryParams : kolQueryParams

    if (queryParams.country_code === UNLIMITED_COUNTRY_CODE) {
      return true
    }

    return (
      isSuccessFetchAvailableCountries &&
      availableCountries.countries.includes(queryParams.country_code)
    )
  }, [
    availableCountries,
    isSuccessFetchAvailableCountries,
    kolQueryParams,
    mode,
    postQueryParams,
  ])

  const kolSearchParams = mapKolSearchParams(
    kolQueryParams,
    hasAnyKolRadarSearchCache,
  )

  const kolResult = useKolRadarSearchQuery(
    mode === SearchMode.Kol &&
      userStatus?.currentWorkspaceId &&
      router.pathname === Page.Search &&
      isAvailableCountryResult
      ? {
          workspaceId: userStatus.currentWorkspaceId,
          params: kolSearchParams,
          beforeQueryStarts: ({ args }): void => {
            const isDefault =
              isOnTourFlow ||
              isDefaultSearch(kolQueryParams, defaultCountryCode, mode)
            setIsDefaultSearch(isDefault)

            // 看更多的話，不觸發 searchForKol 事件 ref: https://www.notion.so/ikala/Amp-searchId-2932a2c39cf143dd8a4e8a0ad51c4bab?pvs=4
            const hasAnchor = !!args.params.anchor
            if (!hasAnchor) {
              const searchId = SearchIdManager.getInstance().update()
              searchForKol(kolSearchParams, {
                searchId,
                view: currentLayout,
                isDefault,
                aiEnabled: !!userMetadata?.kol_ai_search_mode,
                isExternalLink:
                  isExternalOrigin() && // 防止從 kol-radar 內部頁面，開新頁面至 search 頁（後端 route case）
                  isUserLandingSearchPage && // 防止從 kol-radar 內部頁面，轉導至 search 頁（前端 route case）
                  !hasKolRadarSearchRecord, // 防止從在 search 頁搜尋很多次
              })
            }
          },
          onQueryFulfilled: ({ response }): void => {
            if (response.list.length > 0) {
              ampli.viewSearchResult({
                kolResult: response.list.map((kol) => kol.id),
                from: kolQueryParams.from ?? 'General Search',
                isDefault,
              })
            }
          },
        }
      : skipToken,
  )

  const postSearchParams = mapPostSearchParams(
    postQueryParams,
    hasAnyPostRadarSearchCache,
  )

  const postResult = usePostRadarSearchQuery(
    mode === SearchMode.Post &&
      userStatus?.currentWorkspaceId &&
      isAvailableCountryResult
      ? {
          workspaceId: userStatus.currentWorkspaceId,
          params: postSearchParams,
          beforeQueryStarts: (): void => {
            const isDefault =
              isOnTourFlow ||
              isDefaultSearch(postSearchParams, defaultCountryCode, mode)

            setIsDefaultSearch(isDefault)
            const hasAfterPage = !isUndefined(postQueryParams.after_page)

            if (!hasAfterPage) {
              const searchId = SearchIdManager.getInstance().update()

              const {
                filterCollectionType,
                filterCollectionCount,
                filterCollectionIds,
                filterCollectionNames,
              } = ((): Pick<
                SearchForKolContentProperties,
                | 'filterCollectionType'
                | 'filterCollectionCount'
                | 'filterCollectionIds'
                | 'filterCollectionNames'
              > => {
                if (isUndefined(postSearchParams.filter_collection)) {
                  return {
                    filterCollectionType: undefined,
                    filterCollectionCount: undefined,
                    filterCollectionIds: undefined,
                    filterCollectionNames: undefined,
                  }
                }

                const filterCollectionIds =
                  postSearchParams.exclude_kol_collection_folder_ids ?? ''
                const filterCollectionList =
                  getCollectionList(filterCollectionIds.split(',')) ?? []

                if (filterCollectionList === 'all') {
                  return {
                    filterCollectionType: 'All',
                    filterCollectionCount: undefined,
                    filterCollectionIds: undefined,
                    filterCollectionNames: undefined,
                  }
                }

                return {
                  filterCollectionType: 'Partial',
                  filterCollectionCount: filterCollectionList.length,
                  filterCollectionIds: filterCollectionList.map(
                    (item) => item.id,
                  ),
                  filterCollectionNames: filterCollectionList.map(
                    (item) => item.name,
                  ),
                }
              })()

              ampli.searchForKolContent({
                searchId,
                countryCode: postQueryParams.country_code,
                followerEndTo: postQueryParams.follower_end_to,
                followerStartFrom: postQueryParams.follower_start_from,
                keyword: postQueryParams.keyword,
                platform: postQueryParams.platform_type,
                postEndDate: postQueryParams.post_end_time,
                postStartDate: postQueryParams.post_start_time,
                postType: postQueryParams.post_type,
                filterOfficialAccount: getFilterOfficialValues(
                  postQueryParams.filter_kol_type,
                ),
                view: currentLayout,
                postProperty: postQueryParams.customized_tags_names?.split('|'),
                isDefault,
                isSponsored: !isUndefined(postQueryParams.filter_advertorial)
                  ? postQueryParams.filter_advertorial === 'true'
                  : undefined,
                filterFollowerRange:
                  mapEventPropertyFilterFollowerRange(postQueryParams),
                filterCollectionType,
                filterCollectionCount,
                filterCollectionIds,
                filterCollectionNames,
                filterCollection: !isUndefined(
                  postQueryParams.filter_collection,
                )
                  ? postQueryParams.filter_collection === 'true'
                  : undefined,
              })
            }
          },
        }
      : skipToken,
  )

  const withAvailableSortOption = (
    mode: SearchMode,
    keyword: Optional<string>,
    sort: Optional<string>,
  ): Optional<string> => {
    if (!sort) {
      return sort
    }

    const options =
      mode === SearchMode.Kol ? kolSortOptions(keyword) : postSortOptions
    const defaultOption = head(options)
    const foundOption = options.find((option) => option.value === sort)

    if (foundOption) {
      return foundOption.disabled ? defaultOption?.value : foundOption.value
    }

    return defaultOption?.value
  }

  const withAvailablePlatformOption = (
    mode: SearchMode,
    platform?: PlatformShortcode,
  ): PlatformShortcode | undefined => {
    if (!platform) {
      return undefined
    }

    if (mode === SearchMode.Kol) {
      if (
        ![
          PlatformShortcode.Instagram,
          PlatformShortcode.Facebook,
          PlatformShortcode.YouTube,
          PlatformShortcode.Twitter,
          PlatformShortcode.TikTok,
        ].includes(platform)
      ) {
        return undefined
      }
    } else {
      if (
        ![
          PlatformShortcode.Instagram,
          PlatformShortcode.Facebook,
          PlatformShortcode.YouTube,
        ].includes(platform)
      ) {
        return undefined
      }
    }

    // 程式運行到這邊，代表是受支援的平台
    return platform
  }

  const handleSearchKol = (params: Condition): void => {
    // 只取 query string 的部分
    const queryStringPart = router.asPath.includes('?')
      ? router.asPath.split('?')[1]
      : ''

    const payload = {
      filter_kol_type: omitDefaultValue(
        kolQueryParams.filter_kol_type,
        ANY_KOL_TYPE_CODE,
      ),
      ...queryString.parse(queryStringPart, {
        arrayFormat: 'bracket',
      }),
      ...params,
    }
    const keyword = payload.keyword ?? undefined
    const query = removeNil(
      omit(
        {
          ...payload,
          keyword,
          anchor: params.anchor ?? undefined,
          mode: SearchMode.Kol,
          platform_type: withAvailablePlatformOption(
            SearchMode.Kol,
            payload.platform_type,
          ),
          sort: withAvailableSortOption(SearchMode.Kol, keyword, payload.sort),
        },
        // 砍掉 post 的參數
        difference(
          Object.keys(searchPostDefaultParams),
          Object.keys(searchKolDefaultParams),
        ),
      ),
    )

    void router.push(
      queryString.stringifyUrl(
        {
          url: Page.Search,
          query,
        },
        {
          arrayFormat: 'bracket',
        },
      ),
      undefined,
      {
        scroll: !params.anchor,
      },
    )
  }

  const handleSearchPost = (params: SearchPostQueryParams): void => {
    const payload = {
      filter_kol_type: omitDefaultValue(
        postQueryParams.filter_kol_type,
        ANY_KOL_TYPE_CODE,
      ),
      ...router.query,
      ...params,
    }
    const query = removeNil(
      omit(
        {
          ...payload,
          keyword: payload.keyword ?? undefined,
          after_page: params.after_page,
          mode: SearchMode.Post,
          sort: withAvailableSortOption(
            SearchMode.Post,
            undefined,
            payload.sort,
          ),
          platform_type: withAvailablePlatformOption(
            SearchMode.Post,
            payload.platform_type,
          ),
        },
        // 砍掉 kol 的參數
        difference(
          Object.keys(searchKolDefaultParams),
          Object.keys(searchPostDefaultParams),
        ),
      ),
    )

    const newSearch = isUndefined(params.after_page)
    void router.push(
      queryString.stringifyUrl({
        url: Page.Search,
        query,
      }),
      undefined,
      {
        scroll: newSearch,
      },
    )
  }

  const handleSearch: ReturnType<UseSearch>['handleSearch'] = (
    params: Condition | SearchPostQueryParams,
  ) => {
    if (isSearchPostQueryParams(params, mode)) {
      handleSearchPost(params)
    } else {
      handleSearchKol(params)
    }
  }

  return {
    isAvailableCountry: {
      hasResult: ((): boolean => {
        if (!isAvailableCountryResult) {
          return false
        }

        const [queryParams, result] =
          mode === SearchMode.Post
            ? [postQueryParams, postResult]
            : [kolQueryParams, kolResult]
        const isDefault = isDefaultSearch(
          queryParams,
          queryParams.country_code,
          mode,
        )
        const isResultEmpty = result.isSuccess && result.data?.total === 0

        return !(isDefault && isResultEmpty)
      })(),
      isFetching: isFetchingAvailableCountries,
      isSuccess: isSuccessFetchAvailableCountries,
    },
    handleSearch,
    kolQueryParams,
    postQueryParams,
    kolResult,
    postResult: {
      data: postResult.data,
      isFetching: postResult.isFetching,
      error: postResult.error,
    },
    mode,
    handleSearchKol,
    handleSearchPost,
    sortOptions: useMemo(
      () =>
        mode === SearchMode.Kol
          ? kolSortOptions(kolQueryParams.keyword)
          : postSortOptions,
      [kolQueryParams.keyword, kolSortOptions, mode, postSortOptions],
    ),
  }
}

export default useSearch

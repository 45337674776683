import { z } from 'zod'
import { PaymentStatus } from '@/components/campaign/hooks/use-campaign-kol-payment-status-column'
import { AllowedGender } from '@/components/common/selectors/gender-radio-group'
import { AllCountryCode, countryCodeEnumSchema } from '@/constants/country-code'
import { I18nId } from '@/i18n/config'
import { CampaignKolPaymentStatus } from '@/types/mock-api-types'
import { postSchema } from '@/types/schema/ai-search'
import { listResourceSchema } from '@/types/schema/api/list-resource-schema'
import type { SorterParams } from '@/types/schema/api/sorter-params-schema'
import tokenBasedPaginationSchema, {
  TokenBasedPagination,
} from '@/types/schema/api/token-based-pagination'
import { genderSchema } from '@/types/schema/common/gender-schema'
import { relatedKolSchema } from '@/types/schema/kol-detail-api/related-kol'
import {
  audienceMainAgeSchema,
  audienceMainCountrySchema,
  audienceMainGenderSchema,
} from '@/types/schema/kol/audience-schema'
import { kScoreSchema } from '@/types/schema/kol/k-score-schema'
import { languagesSchema } from '@/types/schema/kol/languages-schema'
import { linkSchema } from '@/types/schema/kol/link-schema'
import potentialSchema from '@/types/schema/kol/potential'
import { quotationsSchema } from '@/types/schema/kol/quotations-schema'
import { PlatformShortcode } from '@/utils/convert-platform'

export const directoryAvailablePlatforms = [
  PlatformShortcode.Instagram,
  PlatformShortcode.Facebook,
  PlatformShortcode.YouTube,
  PlatformShortcode.TikTok,
  PlatformShortcode.Twitter,
] as const

export interface FetchKolManagementRequest
  extends Omit<TokenBasedPagination, 'total' | 'total_page'>,
    SorterParams {
  kol_uuids?: string[]
  platform_types?: PlatformShortcode[]
  customized_tags_names?: string
  country_codes?: AllCountryCode[]
  gender?: AllowedGender
  follower_start_from?: number
  follower_end_to?: number
  verified_kol?: boolean
  engagement_rate_greater_than?: number
  engagement_rate_less_than_or_equal?: number
  view_rate_greater_than?: number
  view_rate_less_than_or_equal?: number
  follow_count_growth_rate_greater_than?: number
  follow_count_growth_rate_less_than_or_equal?: number
  is_unlocked?: boolean
  include_collection_ids?: number[] | 'all'
  include_campaign_ids?: number[] | 'all'
  include_deep_report_ids?: number[] | 'all'
  customized_label_ids?: number[]
  min_collaboration_compensation?: number
  max_collaboration_compensation?: number
  min_quotation_price?: number
  max_quotation_price?: number
}

const fieldTypeSchema = z.enum(['asset-hub', 'collection-folder'])
type FieldType = z.infer<typeof fieldTypeSchema>

const directoryBasicSearchFieldSchema = z.object({
  visible: z.null().describe('免費 / 付費數據'),
  sortable: z.null().describe('Table 可排序'),
  field_type: z.null(),
})

export const directoryCustomizedSearchLeafFieldSchema =
  directoryBasicSearchFieldSchema.extend({
    id: z.number(),
    type: z.string(),
    visible: z.literal(true),
    sortable: z.literal(false),
    field_type: fieldTypeSchema,
  })
export type DirectoryCustomizedSearchLeafField = z.infer<
  typeof directoryCustomizedSearchLeafFieldSchema
>

const resourceKolSchema = z.object({
  follower_count: z.number(),
  is_radar_star: z.boolean(),
  is_verified: z.boolean(),
  name: z.string(),
  uuid: z.string().uuid(),
})
export type ResourceKol = z.infer<typeof resourceKolSchema>
const filterResourceSchema = z.object({
  id: z.number(),
  name: z.string(),
  kols: resourceKolSchema.array(),
})
export type FilterResource = z.infer<typeof filterResourceSchema>

export const contactTypeSchema = z.enum([
  'email',
  'phone',
  'line',
  'qq',
  'we-chat',
  'whatsapp',
])

export type ContactType = z.infer<typeof contactTypeSchema>

const contactInfoSchema = z.object({
  /**@description 非廠商端新增的欄位 directory_contact_id 會是 0, e.g. kol, agent 新增 or 爬蟲過來 */
  directory_contact_id: z.number(),
  type: contactTypeSchema,
  value: z.string(),
  /**@description
   * - api spec: https://www.notion.so/ikala/102fb0834d6e807391b7d19b8a8467fa?pvs=4
   * - feature spec: https://www.notion.so/ikala/2e3c228462004a9c98fcc74d2144c86f?pvs=4#712789d981504e1a84e58418296173ca
   *
   * - directory: ad 新增
   * - user: kol 新增
   * - agency: agency 新增
   * - fb: 爬蟲過來
   * - '': 預設，後端預期應該不會出現
   */
  resource_type: z.enum(['directory', 'user', 'agency', 'fb', '']).optional(),
  notes: z.string().optional(),
})

export type ContactInfo = z.infer<typeof contactInfoSchema>

const footprintResourceStatus = z.enum([
  'new',
  'in-process',
  'update',
  'remove',
  'amend',
  'unlocked',
  'cancel-collaboration',
  'ended',
])

/**
 * @description
 *
 * // general
 * - new ( 新增 )
 * - amend ( 修正 )
 * - remove ( 移除 )
 *
 * // kol
 * - unlocked ( 已解鎖 )
 *
 * // campaign
 * - update ( 更新 )
 * - in-process ( 執行中 )
 * - cancel-collaboration ( 取消合作 )
 * - ended ( 結束 )
 */
export type FootprintResourceStatus = z.infer<typeof footprintResourceStatus>

const footprintResourceType = z.enum([
  'unlock',
  'asset-hub',
  'collection-folder',
  'performance-estimated',
  'campaign',
  'deep-report',
  'competitor',
  'collaboration-detail-basic-info',
  'collaboration-detail-contact-info',
  'collaboration-detail-customized-collaboration-record',
  'collaboration-detail-quotation',
  'collaboration-detail-collaboration-note',
  'collaboration-detail-collaboration-record-scoring',
  'predictive-report-collaborative-item',
  'predictive-report-willingness-to-collaborate',
  'predictive-report',
])

/**
 * @description
 * - unlock ( 解鎖 )
 * - asset-hub ( 資產庫 )
 * - collection-folder ( 收藏夾 )
 * - performance-estimated ( 成效預估 )
 * - campaign ( 商案媒合 )
 * - deep-report ( deep report 洞察報表 )
 * - competitor ( 競品分析 )
 * - collaboration-detail-basic-info ( 合作資訊-基本資訊 )
 * - collaboration-detail-contact-info ( 合作資訊-聯絡資訊 )
 * - collaboration-detail-customized-collaboration-record ( 合作資訊-自定合作紀錄 )
 * - collaboration-detail-quotation ( 合作資訊-詢價 )
 * - collaboration-detail-collaboration-note ( 合作資訊-備註 )
 * - collaboration-detail-collaboration-record-scoring ( 合作資訊-評價 )
 * - predictive-report-collaborative-item ( 效益預測報告-新增/修改合作項目按下確認送出後 )
 * - predictive-report-willingness-to-collaborate ( 效益預測報告-調整合作意願 )
 * - predictive-report ( 該網紅被加入/移除特定效益預測報告 )
 */
export type FootprintResourceType = z.infer<typeof footprintResourceType>

//TODO - 等效益預測報告 merge 後要改用那邊的
const willingnessToCollaborateSchema = z.enum([
  'to_be_confirmed',
  'willing',
  'unwilling',
])

const metaStatus = z
  .nativeEnum(PaymentStatus)
  .or(z.nativeEnum(CampaignKolPaymentStatus))
  .or(willingnessToCollaborateSchema)

/**
 * @description
 * metaStatus 是一個通用的狀態，當 footprintResourceStatus 不夠描述時，會歸類到 metaStatus
 *
 * // 執行中狀態
 * - unpaid ( 尚未付款 )
 * - post_checking ( 貼文確認中 )
 * - post_checked ( 貼文確認中 )
 * - finance_checking ( 撥款審核中 )
 * - approve ( 排程撥款中 )
 * - holding ( 問題回報中 )
 * - reject ( 等候退款 )
 * - pending ( 待處理 )
 * - processing ( 處理中 )
 *
 * // 已完成
 * - paid ( 已匯款 )
 * - refunded ( 已退款 )
 * - not-need ( 無需付款 )
 */
export type MetaStatus = z.infer<typeof metaStatus>

const footprintSchema = z.object({
  id: z.string(),
  user_id: z.number().nullable(),
  user_name: z.string().nullable(),
  created_at: z.string().datetime(),
  footprint_resource_name: z.string().nullable(),
  footprint_resource_status: footprintResourceStatus,
  footprint_resource_type: footprintResourceType,
  footprint_resource_id: z.number().nullable(),
  meta_status: metaStatus.nullable(),
})

export type Footprint = z.infer<typeof footprintSchema>

export const fetchKolFootprintResponseSchema = z.object({
  data: z.object({
    footprints: footprintSchema.array(),
    next_page: z.string().optional(),
    total: z.number(),
  }),
})

export type FetchKolFootprintResponse = z.infer<
  typeof fetchKolFootprintResponseSchema
>

const kolTabFootprintResourceType = z.enum([
  'all',
  'collection-folder',
  'campaign',
  'deep-report',
  'other',
])

export type FetchKolFootprintResourceType = z.infer<
  typeof kolTabFootprintResourceType
>

/** @description query all 的話不帶 */
export interface FetchKolFootprintRequest {
  kolId: string
  footprint_resource_type?: Exclude<FetchKolFootprintResourceType, 'all'>
  next_page?: string
}

const collaborationRecordTypeSchema = z.enum([
  'campaign',
  'deep_report',
  'kol_collection',
])
export type CollaborationRecordType = z.infer<
  typeof collaborationRecordTypeSchema
>

const collaborationRecordSchema = z.object({
  id: z.number(),
  name: z.string(),
  collaboration_record_type: collaborationRecordTypeSchema,
  start_at: z.string().datetime(),
  budget: z.number(),
})

export interface FetchKolManagementCollaborationRecordsRequest {
  kolId: string
  collaboration_record_type: CollaborationRecordType
}

export const fetchKolManagementCollaborationRecordsResponseSchema = z.object({
  data: z.object({
    collaboration_records: collaborationRecordSchema
      .pick({
        id: true,
        name: true,
        collaboration_record_type: true,
      })
      .array(),
  }),
})

export type FetchKolManagementCollaborationRecordsResponse = z.infer<
  typeof fetchKolManagementCollaborationRecordsResponseSchema
>

const platformTypeRelatedBrandsSchema = z
  .object({
    type: z.nativeEnum(PlatformShortcode),
    brands: relatedKolSchema
      .pick({ uuid: true, name: true, mention_count: true })
      .array(),
  })
  .array()

const platformTypeRelatedKolTypePersonsSchema = z
  .object({
    type: z.nativeEnum(PlatformShortcode),
    uuids: z.string().array(),
  })
  .array()

const customizedLabelSchema = z.object({ id: z.number(), name: z.string() })
export type CustomizedLabel = z.infer<typeof customizedLabelSchema>

const directoryCreatingKolSchema = z.object({
  kol_uuid: z.string(),
  names: z.string().array().nonempty(),
  kol_management_status: z.literal('creating'),
})
export type DirectoryCreatingKol = z.infer<typeof directoryCreatingKolSchema>

const platformTypeStatisticsBasicSchema = z
  .object({
    short_engagement_rate_3m: z.number().nullable(),
    short_engagement_rate_6m: z.number().nullable(),
    avg_short_engagement_count_3m: z.number().nullable(),
    avg_short_engagement_count_6m: z.number().nullable(),
    avg_short_like_count_3m: z.number().nullable(),
    avg_short_like_count_6m: z.number().nullable(),
    avg_short_comment_count_3m: z.number().nullable(),
    avg_short_comment_count_6m: z.number().nullable(),
    engagement_rate_3m: z.number().nullable(),
    engagement_rate_6m: z.number().nullable(),
    avg_engagement_count_3m: z.number().nullable(),
    avg_engagement_count_6m: z.number().nullable(),
    avg_share_count_3m: z.number().nullable(),
    avg_share_count_6m: z.number().nullable(),
    avg_like_count_3m: z.number().nullable(),
    avg_like_count_6m: z.number().nullable(),
    avg_comment_count_3m: z.number().nullable(),
    avg_comment_count_6m: z.number().nullable(),
    avg_view_count_3m: z.number().nullable(),
    avg_view_count_6m: z.number().nullable(),
    post_count_3m: z.number().nullable(),
    post_count_6m: z.number().nullable(),
    view_rate_3m: z.number().nullable(),
    view_rate_6m: z.number().nullable(),
    customized_property_statistic_3m: z.string().array(),
    customized_property_statistic_6m: z.string().array(),
    audience_main_country: audienceMainCountrySchema.nullable(),
    audience_main_age: audienceMainAgeSchema.nullable(),
    audience_main_gender: audienceMainGenderSchema.nullable(),
    follow_count: z.number(),
    post_frequency_3m: z.number().nullable(),
    post_frequency_6m: z.number().nullable(),
    sponsor_post_ratio_3m: z.number().nullable(),
    sponsor_post_ratio_6m: z.number().nullable(),
    potential: potentialSchema.nullable(),
    like_comment_ratio_3m: z.number().nullable(),
    like_comment_ratio_6m: z.number().nullable(),
    post_word_of_mouth_volume_3m: z
      .number()
      .nullable()
      .describe('圖文口碑價值（近三個月）'),
    post_word_of_mouth_volume_6m: z
      .number()
      .nullable()
      .describe('圖文口碑價值（近六個月）'),
    video_word_of_mouth_volume_3m: z
      .number()
      .nullable()
      .describe('影音口碑價值（近三個月）'),
    video_word_of_mouth_volume_6m: z
      .number()
      .nullable()
      .describe('影音口碑價值（近六個月）'),
    follow_count_growth_3m: z
      .number()
      .nullable()
      .describe('漲粉數（近三個月）'),
    follow_count_growth_6m: z
      .number()
      .nullable()
      .describe('漲粉數（近六個月）'),
    follow_count_growth_rate_3m: z
      .number()
      .nullable()
      .describe('漲粉率（近三個月）'),
    follow_count_growth_rate_6m: z
      .number()
      .nullable()
      .describe('漲粉率（近六個月）'),
    live_view_count_3m: z
      .number()
      .nullable()
      .describe('直播觀看數（近三個月）'),
    live_view_count_6m: z
      .number()
      .nullable()
      .describe('直播觀看數（近六個月）'),
    live_view_rate_3m: z.number().nullable().describe('直播觀看率（近三個月）'),
    live_view_rate_6m: z.number().nullable().describe('直播觀看率（近六個月）'),
    follower_quality: z.number().nullable().describe('真粉比例'),
  })
  .merge(kScoreSchema)
  .merge(
    z
      .object({
        min_image_estimated_reach_3m: z.number().nullable(),
        max_image_estimated_reach_3m: z.number().nullable(),
        min_image_estimated_reach_6m: z.number().nullable(),
        max_image_estimated_reach_6m: z.number().nullable(),
      })
      .describe('圖文預估觸及數'),
  )
  .merge(
    z
      .object({
        min_video_estimated_reach_3m: z.number().nullable(),
        max_video_estimated_reach_3m: z.number().nullable(),
        min_video_estimated_reach_6m: z.number().nullable(),
        max_video_estimated_reach_6m: z.number().nullable(),
      })
      .describe('影音預估觸及數'),
  )
  .merge(
    z
      .object({
        min_short_estimated_reach_3m: z.number().nullable(),
        max_short_estimated_reach_3m: z.number().nullable(),
        min_short_estimated_reach_6m: z.number().nullable(),
        max_short_estimated_reach_6m: z.number().nullable(),
      })
      .describe('短影音預估觸及數'),
  )

export const directoryKolSchema = z.object({
  kol_management_status: z.literal('created'),
  kol_uuid: z.string(),
  names: z.string().array().nonempty(),
  potential: potentialSchema.nullable(),
  is_verified: z.boolean(),
  is_radar_star: z.boolean(),
  is_lock: z
    .boolean()
    .nullable()
    .describe('Will be null when workspace is not quota base.'),
  brand_asset_hub_customized_fields: directoryCustomizedSearchLeafFieldSchema
    .omit({
      sortable: true,
      visible: true,
    })
    .extend({ value: z.string().nullable() })
    .array(),
  country_code: countryCodeEnumSchema.nullable(),
  gender: genderSchema,
  customized_tags: z.string().array(),
  follow_count: z.number(),
  links: linkSchema.omit({ platform_type: true }).array(),
  birthday: z.string().date().nullable(),
  contact_infos: contactInfoSchema.array(),
  profession: z.string().nullable(),
  quotations: quotationsSchema.nullable(),
  include_collection_count: z.number().min(1).nullable().catch(null),
  include_campaign_count: z.number().min(1).nullable().catch(null),
  include_deep_report_count: z.number().min(1).nullable().catch(null),
  footprint: footprintSchema.nullable(),
  collaboration_record: collaborationRecordSchema.nullable(),
  languages: languagesSchema,
  platform_type_statistics: z
    .discriminatedUnion('type', [
      z
        .object({
          type: z.literal(PlatformShortcode.Facebook),
        })
        .merge(platformTypeStatisticsBasicSchema),
      z
        .object({
          type: z.literal(PlatformShortcode.Twitter),
        })
        .merge(platformTypeStatisticsBasicSchema),
      z
        .object({
          type: z.literal(PlatformShortcode.YouTube),
          avg_short_view_count_3m: z
            .number()
            .nullable()
            .describe('Shorts 平均觀看數（近三個月）'),
          avg_short_view_count_6m: z
            .number()
            .nullable()
            .describe('Shorts 平均觀看數（近六個月）'),
          short_view_rate_3m: z
            .number()
            .nullable()
            .describe('Shorts 觀看率（近三個月）'),
          short_view_rate_6m: z
            .number()
            .nullable()
            .describe('Shorts 觀看率（近六個月）'),
        })
        .merge(platformTypeStatisticsBasicSchema),
      z
        .object({
          type: z.literal(PlatformShortcode.Instagram),
          avg_short_play_count_3m: z
            .number()
            .nullable()
            .describe('Reels 平均觀看數（近三個月）'),
          avg_short_play_count_6m: z
            .number()
            .nullable()
            .describe('Reels 平均觀看數（近六個月）'),
          short_play_rate_3m: z
            .number()
            .nullable()
            .describe('Reels 觀看率（近三個月）'),
          short_play_rate_6m: z
            .number()
            .nullable()
            .describe('Reels 觀看率（近六個月）'),
        })
        .merge(platformTypeStatisticsBasicSchema),
      z
        .object({
          type: z.literal(PlatformShortcode.TikTok),
          avg_short_play_count_3m: z
            .number()
            .nullable()
            .describe('平均觀看數（近三個月）'),
          avg_short_play_count_6m: z
            .number()
            .nullable()
            .describe('平均觀看數（近六個月）'),
          short_play_rate_3m: z
            .number()
            .nullable()
            .describe('觀看率（近三個月）'),
          short_play_rate_6m: z
            .number()
            .nullable()
            .describe('觀看率（近六個月）'),
        })
        .merge(platformTypeStatisticsBasicSchema),
    ])
    .array(),
  platform_type_latest_posts: z
    .object({
      type: z.nativeEnum(PlatformShortcode),
      posts: postSchema.array(),
    })
    .array(),
  platform_type_related_kol_type_brands_3m:
    platformTypeRelatedBrandsSchema.describe('提及品牌（近三個月）'),
  platform_type_related_kol_type_brands_6m:
    platformTypeRelatedBrandsSchema.describe('提及品牌（近六個月）'),
  platform_type_related_kol_type_persons_3m:
    platformTypeRelatedKolTypePersonsSchema.describe('提及網紅（近三個月）'),
  platform_type_related_kol_type_persons_6m:
    platformTypeRelatedKolTypePersonsSchema.describe('提及網紅（近六個月）'),
  platform_type_similar_kols:
    platformTypeRelatedKolTypePersonsSchema.describe('相似網紅'),
  customized_labels: customizedLabelSchema.array(),
})

export type DirectoryKol = z.infer<typeof directoryKolSchema>

export const fetchKolManagementResponseSchema = z.object({
  data: tokenBasedPaginationSchema.extend({
    kols: z
      .discriminatedUnion('kol_management_status', [
        directoryKolSchema,
        directoryCreatingKolSchema,
      ])
      .array(),
    directory_kol_management_total: z.number(),
  }),
})
export type FetchKolManagementResponse = z.infer<
  typeof fetchKolManagementResponseSchema
>

export interface FetchCollaborationRecordScoringRequest {
  kolId: string
}

const scoreSchema = z.object({
  ID: z.number(),
  UpdatedAt: z.string().datetime(),
  UserID: z.number(),
  User: z.object({
    Name: z.string(),
  }),
  ScoreValue: z.number(),
  Comment: z.string(),
})
export type Score = z.infer<typeof scoreSchema>

export const fetchCollaborationRecordScoringSchema = z.array(scoreSchema)
export type FetchCollaborationRecordScoringResponse = z.infer<
  typeof fetchCollaborationRecordScoringSchema
>

export interface DeleteCollaborationRecordScoringRequest {
  id: number
}

export interface AddCollaborationRecordScoringRequest {
  kolId: string
  score: {
    comment: string
    scoreValue: number
  }
}

export interface EditCollaborationRecordScoringRequest {
  id: number
  score: {
    comment: string
    scoreValue: number
  }
}

export interface FetchNoteRequest {
  kolId: string
}

const noteSchema = z.object({
  ID: z.number(),
  UpdatedAt: z.string().datetime(),
  UserID: z.number(),
  User: z.object({
    Name: z.string(),
  }),
  Note: z.string(),
  IsEdited: z.boolean(),
})
export type Note = z.infer<typeof noteSchema>
export const fetchNoteResponseSchema = z.array(noteSchema)
export type FetchNoteResponse = z.infer<typeof fetchNoteResponseSchema>

export interface DeleteNoteRequest {
  id: number
}

export interface NotePayload {
  note: string
}
export interface AddNoteRequest {
  note: NotePayload
  kolId: string
}

export const directorySearchLeafFieldTypeSchema = z.enum([
  'name', // 不能關閉，所以不會顯示在設定中，常駐排序第一個不能拖曳
  'country_code',
  'gender',
  'follower_level',
  'platforms',
  'customized_tag_names',
  'contact_info',
  'profession',
  'birthday',
  'tags',
  'collections',
  'campaigns',
  'reports',
  'collaboration_record',
  'footprint',
  'post_quotations',
  'video_quotations',
  'primary_language',
  'ig_statistics_social_platform_name',
  'fb_statistics_social_platform_name',
  'yt_statistics_social_platform_name',
  'tiktok_statistics_social_platform_name',
  'twitter_statistics_social_platform_name',
  'ig_statistics_k_score_3m',
  'ig_statistics_k_score_6m',
  'fb_statistics_k_score_3m',
  'fb_statistics_k_score_6m',
  'yt_statistics_k_score_3m',
  'yt_statistics_k_score_6m',
  'ig_statistics_reels_engagement_rate_3m',
  'ig_statistics_reels_engagement_rate_6m',
  'ig_statistics_reels_engagement_count_3m',
  'ig_statistics_reels_engagement_count_6m',
  'ig_statistics_reels_avg_like_count_3m',
  'ig_statistics_reels_avg_like_count_6m',
  'ig_statistics_reels_avg_comment_count_3m',
  'ig_statistics_reels_avg_comment_count_6m',
  'yt_statistics_shorts_view_count_3m',
  'yt_statistics_shorts_view_count_6m',
  'yt_statistics_shorts_view_rate_3m',
  'yt_statistics_shorts_view_rate_6m',
  'ig_statistics_engagement_rate_3m',
  'ig_statistics_engagement_rate_6m',
  'fb_statistics_engagement_rate_3m',
  'fb_statistics_engagement_rate_6m',
  'yt_statistics_engagement_rate_3m',
  'yt_statistics_engagement_rate_6m',
  'tiktok_statistics_engagement_rate_3m',
  'tiktok_statistics_engagement_rate_6m',
  'twitter_statistics_engagement_rate_3m',
  'twitter_statistics_engagement_rate_6m',
  'ig_statistics_content_distribution_3m',
  'ig_statistics_content_distribution_6m',
  'fb_statistics_content_distribution_3m',
  'fb_statistics_content_distribution_6m',
  'yt_statistics_content_distribution_3m',
  'yt_statistics_content_distribution_6m',
  'ig_statistics_audience_region',
  'yt_statistics_audience_region',
  'ig_statistics_main_age',
  'fb_statistics_main_age',
  'yt_statistics_main_age',
  'tiktok_statistics_main_age',
  'ig_statistics_main_gender',
  'fb_statistics_main_gender',
  'yt_statistics_main_gender',
  'tiktok_statistics_main_gender',
  'ig_statistics_business_cooperation_ratio_3m',
  'ig_statistics_business_cooperation_ratio_6m',
  'fb_statistics_business_cooperation_ratio_3m',
  'fb_statistics_business_cooperation_ratio_6m',
  'yt_statistics_business_cooperation_ratio_3m',
  'yt_statistics_business_cooperation_ratio_6m',
  'ig_statistics_post_valuation_ratio_3m',
  'ig_statistics_post_valuation_ratio_6m',
  'fb_statistics_post_valuation_ratio_3m',
  'fb_statistics_post_valuation_ratio_6m',
  'yt_statistics_post_valuation_ratio_3m',
  'yt_statistics_post_valuation_ratio_6m',
  'ig_statistics_post_estimated_reach_count_3m',
  'ig_statistics_post_estimated_reach_count_6m',
  'fb_statistics_post_estimated_reach_count_3m',
  'fb_statistics_post_estimated_reach_count_6m',
  'ig_statistics_avg_engagement_count_3m',
  'ig_statistics_avg_engagement_count_6m',
  'fb_statistics_avg_engagement_count_3m',
  'fb_statistics_avg_engagement_count_6m',
  'yt_statistics_avg_engagement_count_3m',
  'yt_statistics_avg_engagement_count_6m',
  'tiktok_statistics_avg_engagement_count_3m',
  'tiktok_statistics_avg_engagement_count_6m',
  'twitter_statistics_avg_engagement_count_3m',
  'twitter_statistics_avg_engagement_count_6m',
  'fb_statistics_avg_share_count_3m',
  'fb_statistics_avg_share_count_6m',
  'ig_statistics_avg_like_count_3m',
  'ig_statistics_avg_like_count_6m',
  'fb_statistics_avg_like_count_3m',
  'fb_statistics_avg_like_count_6m',
  'yt_statistics_avg_like_count_3m',
  'yt_statistics_avg_like_count_6m',
  'tiktok_statistics_avg_like_count_3m',
  'tiktok_statistics_avg_like_count_6m',
  'twitter_statistics_avg_like_count_3m',
  'twitter_statistics_avg_like_count_6m',
  'ig_statistics_avg_comment_count_3m',
  'ig_statistics_avg_comment_count_6m',
  'fb_statistics_avg_comment_count_3m',
  'fb_statistics_avg_comment_count_6m',
  'yt_statistics_avg_comment_count_3m',
  'yt_statistics_avg_comment_count_6m',
  'tiktok_statistics_avg_comment_count_3m',
  'tiktok_statistics_avg_comment_count_6m',
  'twitter_statistics_avg_comment_count_3m',
  'twitter_statistics_avg_comment_count_6m',
  'ig_statistics_avg_view_count_3m',
  'ig_statistics_avg_view_count_6m',
  'fb_statistics_avg_view_count_3m',
  'fb_statistics_avg_view_count_6m',
  'yt_statistics_avg_view_count_3m',
  'yt_statistics_avg_view_count_6m',
  'tiktok_statistics_avg_view_count_3m',
  'tiktok_statistics_avg_view_count_6m',
  'twitter_statistics_avg_view_count_3m',
  'twitter_statistics_avg_view_count_6m',
  'ig_statistics_video_valuation_3m',
  'ig_statistics_video_valuation_6m',
  'fb_statistics_video_valuation_3m',
  'fb_statistics_video_valuation_6m',
  'yt_statistics_video_valuation_3m',
  'yt_statistics_video_valuation_6m',
  'tiktok_statistics_video_valuation_3m',
  'tiktok_statistics_video_valuation_6m',
  'ig_statistics_video_estimated_reach_count_3m',
  'ig_statistics_video_estimated_reach_count_6m',
  'fb_statistics_video_estimated_reach_count_3m',
  'fb_statistics_video_estimated_reach_count_6m',
  'yt_statistics_video_estimated_reach_count_3m',
  'yt_statistics_video_estimated_reach_count_6m',
  'ig_statistics_potential',
  'fb_statistics_potential',
  'yt_statistics_potential',
  'ig_statistics_comment_to_like_rate_3m',
  'ig_statistics_comment_to_like_rate_6m',
  'fb_statistics_comment_to_like_rate_3m',
  'fb_statistics_comment_to_like_rate_6m',
  'yt_statistics_comment_to_like_rate_3m',
  'yt_statistics_comment_to_like_rate_6m',
  'ig_statistics_mention_brand_3m',
  'ig_statistics_mention_brand_6m',
  'ig_statistics_mention_kol_3m',
  'ig_statistics_mention_kol_6m',
  'ig_statistics_latest_posts',
  'fb_statistics_latest_posts',
  'yt_statistics_latest_posts',
  'tiktok_statistics_latest_posts',
  'ig_statistics_post_count_3m',
  'ig_statistics_post_count_6m',
  'fb_statistics_post_count_3m',
  'fb_statistics_post_count_6m',
  'yt_statistics_post_count_3m',
  'yt_statistics_post_count_6m',
  'tiktok_statistics_post_count_3m',
  'tiktok_statistics_post_count_6m',
  'twitter_statistics_post_count_3m',
  'twitter_statistics_post_count_6m',
  'ig_statistics_fans_growth_count_3m',
  'ig_statistics_fans_growth_count_6m',
  'fb_statistics_fans_growth_count_3m',
  'fb_statistics_fans_growth_count_6m',
  'yt_statistics_fans_growth_count_3m',
  'yt_statistics_fans_growth_count_6m',
  'tiktok_statistics_fans_growth_count_3m',
  'tiktok_statistics_fans_growth_count_6m',
  'twitter_statistics_fans_growth_count_3m',
  'twitter_statistics_fans_growth_count_6m',
  'ig_statistics_fans_growth_rate_3m',
  'ig_statistics_fans_growth_rate_6m',
  'fb_statistics_fans_growth_rate_3m',
  'fb_statistics_fans_growth_rate_6m',
  'yt_statistics_fans_growth_rate_3m',
  'yt_statistics_fans_growth_rate_6m',
  'tiktok_statistics_fans_growth_rate_3m',
  'tiktok_statistics_fans_growth_rate_6m',
  'twitter_statistics_fans_growth_rate_3m',
  'twitter_statistics_fans_growth_rate_6m',
  'ig_statistics_post_frequency_3m',
  'ig_statistics_post_frequency_6m',
  'fb_statistics_post_frequency_3m',
  'fb_statistics_post_frequency_6m',
  'yt_statistics_post_frequency_3m',
  'yt_statistics_post_frequency_6m',
  'tiktok_statistics_post_frequency_3m',
  'tiktok_statistics_post_frequency_6m',
  'twitter_statistics_post_frequency_3m',
  'twitter_statistics_post_frequency_6m',
  'fb_statistics_live_view_count_3m',
  'fb_statistics_live_view_count_6m',
  'fb_statistics_live_view_rate_3m',
  'fb_statistics_live_view_rate_6m',
  'ig_statistics_similar_kols',
  'fb_statistics_similar_kols',
  'yt_statistics_similar_kols',
  'ig_statistics_audience_authenticity',
  'yt_statistics_shorts_estimated_reach_count_3m',
  'yt_statistics_shorts_estimated_reach_count_6m',
  'ig_statistics_kol_descriptions',
  'fb_statistics_kol_descriptions',
  'yt_statistics_kol_descriptions',
  'tiktok_statistics_kol_descriptions',
  'ig_statistics_view_rate_3m',
  'ig_statistics_view_rate_6m',
  'fb_statistics_view_rate_3m',
  'fb_statistics_view_rate_6m',
  'yt_statistics_view_rate_3m',
  'yt_statistics_view_rate_6m',
  'tiktok_statistics_view_rate_3m',
  'tiktok_statistics_view_rate_6m',
  'ig_statistics_follower_count',
  'fb_statistics_follower_count',
  'yt_statistics_follower_count',
  'tiktok_statistics_follower_count',
  'twitter_statistics_follower_count',
])
export type DirectorySearchLeafFieldType = z.infer<
  typeof directorySearchLeafFieldTypeSchema
>

export const directorySearchFieldTypeSchema = z.enum([
  'kol_basic',
  'cooperation_info',
])
export type DirectorySearchFieldType = z.infer<
  typeof directorySearchFieldTypeSchema
>

export const directoryPlatformStatisticSearchFieldTypeSchema = z.enum([
  'ig_statistics',
  'fb_statistics',
  'yt_statistics',
  'tiktok_statistics',
  'twitter_statistics',
])
export type DirectoryPlatformStatisticSearchFieldType = z.infer<
  typeof directoryPlatformStatisticSearchFieldTypeSchema
>

export const directoryPlatformStatisticSubFieldTypeSchema = z.enum([
  'ig_statistics_3m',
  'ig_statistics_6m',
  'fb_statistics_3m',
  'fb_statistics_6m',
  'yt_statistics_3m',
  'yt_statistics_6m',
  'tiktok_statistics_3m',
  'tiktok_statistics_6m',
  'twitter_statistics_3m',
  'twitter_statistics_6m',
])
export type DirectoryPlatformStatisticSubFieldType = z.infer<
  typeof directoryPlatformStatisticSubFieldTypeSchema
>

const directoryBuiltinSearchLeafFieldSchema =
  directoryBasicSearchFieldSchema.extend({
    visible: z.boolean(),
    sortable: z.boolean(),
    type: directorySearchLeafFieldTypeSchema,
  })

const directoryCustomizedSearchFieldTypeSchema = z.enum([
  'collection_customized_field',
  'brand_asset_hub_customized_field',
])
export type DirectoryCustomizedSearchFieldType = z.infer<
  typeof directoryCustomizedSearchFieldTypeSchema
>

export const directoryCustomizedSearchFieldSchema =
  directoryBasicSearchFieldSchema.extend({
    type: directoryCustomizedSearchFieldTypeSchema,
    sub_fields: directoryCustomizedSearchLeafFieldSchema.array(),
  })

export type DirectoryCustomizedSearchField = z.infer<
  typeof directoryCustomizedSearchFieldSchema
>

const directoryPlatformStatisticSubFieldSchema =
  directoryBasicSearchFieldSchema.extend({
    type: directoryPlatformStatisticSubFieldTypeSchema,
    sub_fields: directoryBuiltinSearchLeafFieldSchema.array(),
  })
export type DirectoryPlatformStatisticSubField = z.infer<
  typeof directoryPlatformStatisticSubFieldSchema
>

const directorySearchFieldSchema = z.discriminatedUnion('type', [
  directoryBasicSearchFieldSchema.extend({
    type: directorySearchFieldTypeSchema,
    sub_fields: directoryBuiltinSearchLeafFieldSchema.array(),
  }),
  directoryBasicSearchFieldSchema.extend({
    type: directoryPlatformStatisticSearchFieldTypeSchema,
    sub_fields: z
      .discriminatedUnion('type', [
        directoryPlatformStatisticSubFieldSchema,
        directoryBuiltinSearchLeafFieldSchema,
      ])
      .array(),
  }),
  directoryCustomizedSearchFieldSchema,
])
export type DirectorySearchField = z.infer<typeof directorySearchFieldSchema>

const directorySearchLeafFieldSchema = z.discriminatedUnion('field_type', [
  directoryBuiltinSearchLeafFieldSchema,
  directoryCustomizedSearchLeafFieldSchema,
])
export type DirectorySearchLeafField = z.infer<
  typeof directorySearchLeafFieldSchema
>

const directorySearchFieldsSchema = z.object({
  tree_data: directorySearchFieldSchema.array(),
  array: directorySearchLeafFieldSchema.array(),
})
export type DirectorySearchFields = z.infer<typeof directorySearchFieldsSchema>

export const directorySearchFieldsResponseSchema = z.object({
  data: directorySearchFieldsSchema,
})
export type DirectorySearchFieldsResponse = z.infer<
  typeof directorySearchFieldsResponseSchema
>

export interface KolCollectionBasicRequest {
  collectionId: number
}

export interface CreateKolCollectionCustomizedFieldRequest
  extends KolCollectionBasicRequest {
  type: string
}

export interface ChangeKolCollectionCustomizedFieldRequest
  extends CreateKolCollectionCustomizedFieldRequest {
  id: number
}

export interface DeleteKolCollectionCustomizedFieldRequest
  extends KolCollectionBasicRequest {
  id: number
}

export const contactTypeI18nMap: Record<ContactType, I18nId> = {
  email: 'irm:title_email',
  phone: 'consult:field_phone',
  line: 'irm:title_line',
  qq: 'irm:title_qq',
  'we-chat': 'irm:title_wechat',
  whatsapp: 'irm:title_whatsapp',
}

export type UpdateContactInfo = Omit<
  ContactInfo,
  'directory_contact_id' | 'resource_type'
>

export const fetchKolContactInfoResponseSchema = z.object({
  data: z.object({
    contact_infos: contactInfoSchema.array(),
  }),
})

export type FetchKolContactInfoRequest = {
  kolId: string
}

export type FetchKolContactInfoResponse = z.infer<
  typeof fetchKolContactInfoResponseSchema
>

export interface AddKolContactInfoRequest {
  kolId: string
  contactInfo: UpdateContactInfo
}

export interface EditKolContactInfoRequest {
  kolId: string
  contactInfoId: ContactInfo['directory_contact_id']
  contactInfo: UpdateContactInfo
}

export interface DeleteKolContactInfoRequest {
  kolId: string
  contactInfoId: ContactInfo['directory_contact_id']
}

export const filterResourceType = z.enum([
  'deep_report',
  'campaign',
  'collection',
])
export type FilterResourceType = z.infer<typeof filterResourceType>

export interface FetchFilterResourcesRequest {
  keyword?: string
  filter_resource_type: z.infer<typeof filterResourceType>
  page: number
}

export const fetchFilterResourcesResponseSchema = listResourceSchema.extend({
  data: z
    .object({
      filter_resources: filterResourceSchema.array(),
    })
    .transform((data) => data.filter_resources)
    .describe('這樣才能直接用 infiniteConfigSetting'),
})
export type FetchFilterResourcesResponse = z.infer<
  typeof fetchFilterResourcesResponseSchema
>

const basicInfoSchema = z.object({
  birthday: z.string().datetime().or(z.literal('')),
  profession: z.string(),
  address: z.string(),
  customized_labels: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
    }),
  ),
})

export type BasicInfo = z.infer<typeof basicInfoSchema>

const editBasicInfoSchema = basicInfoSchema
  .omit({
    customized_labels: true,
  })
  .extend({
    customized_label_ids: z.array(z.number()),
  })

type EditBasicInfo = z.infer<typeof editBasicInfoSchema>

export const fetchKolBasicInfoResponseSchema = z.object({
  data: z.object({
    basic_info: basicInfoSchema,
  }),
})

export type FetchKolBasicInfoRequest = {
  kolId: string
}

export type FetchKolBasicInfoResponse = z.infer<
  typeof fetchKolBasicInfoResponseSchema
>

export interface EditKolBasicInfoRequest {
  kolId: string
  basicInfo: EditBasicInfo
}

export interface DeleteKolBasicInfoRequest {
  kolId: string
  deleteField: 'birthday' | 'profession' | 'address' | 'customized_label'
}

export const fetchCustomizedLabelsResponseSchema = z.object({
  data: z.object({ customized_labels: customizedLabelSchema.array() }),
})
export type FetchCustomizedLabelsResponse = z.infer<
  typeof fetchCustomizedLabelsResponseSchema
>

export interface EditCustomizedLabelRequest {
  id: number
  customized_label: string
}

export interface FetchCustomizedFieldsRequest {
  kolId: string
  field_type: FieldType
}

const customizedFieldSchema = z.object({
  id: z.number(),
  field_name: z.string(),
  field_type: fieldTypeSchema,
  field_value: z.string().nullish(),
  collection_folder_id: z.number().nullish(),
})
export type CustomizedField = z.infer<typeof customizedFieldSchema>
export const fetchCustomizedFieldsSchema = z.object({
  data: customizedFieldSchema.array(),
})
export type FetchCustomizedFieldsResponse = z.infer<
  typeof fetchCustomizedFieldsSchema
>

export interface UpdateCustomizedFieldValueRequest {
  kolId: string
  payload: CustomizedField
}

const addKolsToDirectoryRequestSchema = z.object({
  add_kol_infos: z
    .discriminatedUnion('status', [
      z.object({
        status: z.literal('created'),
        uuid: z.string(),
      }),
      z.object({
        status: z.literal('need-create'),
        link: z.string(),
      }),
    ])
    .array(),
})
export type AddKolsToDirectoryRequest = z.infer<
  typeof addKolsToDirectoryRequestSchema
>

export const addKolsToDirectoryResponseSchema = z.object({
  data: z
    .object({
      uuid: z.string(),
      status: z.enum(['success', 'failed']),
    })
    .array(),
})
export type AddKolsToDirectoryResponse = z.infer<
  typeof addKolsToDirectoryResponseSchema
>

export const kolManagementStatusSchema = z.discriminatedUnion(
  'kol_management_status',
  [
    z.object({
      kol_management_status: z.enum(['success', 'pending']),
    }),
    z.object({
      kol_management_status: z.literal('failed'),
      failed_count: z.number().min(1),
    }),
  ],
)
export type KolManagementStatus = z.infer<typeof kolManagementStatusSchema>

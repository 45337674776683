import {
  theme as sharedTheme,
  mapHexHoverColor,
  isValidHexString,
} from '@buggy/shared'
import { merge } from 'lodash-es'
import { adjustHue, lighten, saturate } from 'polished'
import { useMemo } from 'react'
import { EnterpriseIdentity, useFetchUserStatusQuery } from '@/api/user-api'
import { useAuth } from '@/hooks/use-auth'

type BrandColorTheme = {
  primary: string
  primaryHover: string
  secondary: string
  secondaryHover: string
  background: string
}

const generateBrandColors = ({
  primaryColor,
  secondaryColor,
  backgroundColor,
}: EnterpriseIdentity): BrandColorTheme => {
  const adjustColor = (
    baseColor: string,
    saturation: number,
    lightness: number,
    hue: number,
  ): string => {
    return saturate(saturation, lighten(lightness, adjustHue(hue, baseColor)))
  }

  const parsedPrimary =
    !!primaryColor && isValidHexString(primaryColor)
      ? primaryColor
      : sharedTheme.colors.kolrBrand.primary

  const parsedSecondary =
    !!secondaryColor && isValidHexString(secondaryColor)
      ? secondaryColor
      : adjustColor(parsedPrimary, 0.15, 0.125, -6)

  return {
    primary: parsedPrimary,
    primaryHover: mapHexHoverColor(parsedPrimary),
    secondary: parsedSecondary,
    secondaryHover: mapHexHoverColor(parsedSecondary),
    background:
      !!backgroundColor && isValidHexString(backgroundColor)
        ? backgroundColor
        : adjustColor(parsedPrimary, 0.23, 0.49, -8),
  }
}

export type MergedTheme = typeof sharedTheme & {
  colors: (typeof sharedTheme)['colors'] & {
    brand: BrandColorTheme
  }
}

const useMergedTheme = (): MergedTheme => {
  const { isAd } = useAuth()
  const { data: userStatus } = useFetchUserStatusQuery()
  const enterpriseIdentity = userStatus?.currentWorkspace?.enterpriseIdentity
  return useMemo(() => {
    if (!enterpriseIdentity || !isAd) {
      return merge({}, sharedTheme, {
        colors: {
          brand: sharedTheme.colors.kolrBrand,
        },
      })
    }

    const { primary, primaryHover, secondary, secondaryHover, background } =
      generateBrandColors(enterpriseIdentity)

    return merge({}, sharedTheme, {
      colors: {
        brand: {
          primary,
          secondary,
          primaryHover,
          secondaryHover,
          background,
        },
      },
      components: {
        Button: {
          colorError: primary,
          colorErrorActive: primary,
          colorErrorBorderHover: primaryHover,
          colorErrorHover: primaryHover,
        },
        Input: {
          activeBorderColor: secondary,
          hoverBorderColor: secondaryHover,
        },
        Radio: {
          colorPrimary: secondary,
          colorPrimaryHover: secondaryHover,
        },
        Checkbox: {
          colorPrimary: secondary,
          colorPrimaryHover: secondaryHover,
        },
        Cascader: {
          colorPrimary: secondary,
          colorPrimaryHover: secondaryHover,
        },
        Tree: {
          colorPrimary: secondary,
          colorPrimaryHover: secondaryHover,
        },
        Select: {
          colorPrimary: secondary,
          colorPrimaryHover: secondaryHover,
          optionSelectedBg: background,
        },
        Slider: {
          colorPrimaryBorder: secondary,
          colorPrimaryBorderHover: secondaryHover,
          handleColor: secondary,
          handleActiveColor: secondaryHover,
        },
      },
      token: {
        colorPrimary: primary,
        colorPrimaryActive: primary,
        colorPrimaryTextActive: primary,
        colorLink: primary,
        colorLinkActive: primary,
        colorPrimaryHover: primaryHover,
        colorPrimaryTextHover: primaryHover,
        colorLinkHover: primaryHover,
      },
    })
  }, [isAd, enterpriseIdentity])
}

export default useMergedTheme

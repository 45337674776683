import { FunctionComponent, ReactNode, useEffect, useState } from 'react'
import initMocks from '@/mocks'
import env from '@/utils/env'

interface MswProviderProps {
  children: ReactNode
}

export const MswProvider: FunctionComponent<MswProviderProps> = ({
  children,
}) => {
  const [isMswReady, setIsMswReady] = useState(false)

  useEffect(() => {
    // eslint-disable-next-line
    const startMockServer = async () => {
      if (!env.MSW_ENABLED) {
        return
      }

      const worker = await initMocks()

      if (!worker) {
        return
      }

      await worker.start({
        onUnhandledRequest(request, print) {
          if (request.url.includes('_next')) {
            return
          }
          print.warning()
        },
      })

      setIsMswReady(true)

      return worker
    }

    const workerPromise = startMockServer()

    return () => {
      workerPromise.then((worker) => {
        if (!worker) {
          return
        }

        worker.stop()
      })
    }
  }, [])

  if (!env.MSW_ENABLED) {
    return children
  }

  return isMswReady ? children : 'mock server worker initialing...'
}

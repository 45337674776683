import { z } from 'zod'
import { partialUserMetadataSchema } from '@/types/schema/user-metadata'

export const userMetadataResponseSchema = z.object({
  data: partialUserMetadataSchema,
})

export type UserMetadataResponse = z.infer<typeof userMetadataResponseSchema>

export const checkPasswordExistResponseSchema = z.object({
  exists: z.boolean(),
})

export type CheckPasswordExistResponse = z.infer<
  typeof checkPasswordExistResponseSchema
>

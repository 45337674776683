import { Icon } from '@buggy/shared'
import { Button, ButtonProps } from 'antd'
import { useRouter } from 'next/router'
import { FunctionComponent, ReactNode } from 'react'
import styled, { useTheme } from 'styled-components'
import { createTourStepData } from '@/components/tour/utils'
import { ClickUpgradePlanProperties, ampli } from '@/utils/ampli'

interface VipButtonProps {
  section?: ClickUpgradePlanProperties['section']
  buttonProps?: Omit<ButtonProps, 'type'>
  onClick?: () => void
  children?: ReactNode
}

const VipButton: FunctionComponent<VipButtonProps> = ({
  section,
  buttonProps,
  onClick,
  children,
}) => {
  const router = useRouter()
  const theme = useTheme()

  const iconSize = ((): number => {
    if (buttonProps?.size === 'large') {
      return 20
    }
    return 16
  })()

  const handleOnClick = (): void => {
    ampli.clickUpgradePlan({ path: router.asPath, section })
  }

  return (
    <StyledButton
      {...buttonProps}
      {...createTourStepData('marketing-trend', 4)}
      {...createTourStepData('predict-competitor', 5)}
      {...createTourStepData('report-performance', 5)}
      icon={
        buttonProps?.icon ? (
          buttonProps.icon
        ) : (
          <Icon
            fill={theme.colors.text.white}
            fontSize={iconSize}
            name='crown-outlined'
          />
        )
      }
      type='primary'
      onClick={onClick ?? handleOnClick}
    >
      {children}
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  background: ${({ theme }): string => theme.colors.plan.premium};

  &:not(:disabled):not(.ant-btn-disabled) {
    &:hover,
    &:focus,
    &:active {
      background: ${({ theme }): string => theme.colors.plan.premium};
    }
  }
`

export default VipButton

import { z } from 'zod'
import { PlatformShortcode } from '@/utils/convert-platform'

const collectedPostSchema = z.object({
  post_collection_post_id: z.number(),
  collected_post_id: z.string(),
})

export const postCollectionSchema = z.object({
  id: z.number(),
  updated_at: z.string().datetime(),
  owner_id: z.number().nullable(),
  owner_name: z.string().nullable(),
  name: z.string(),
  post_amount: z.number(),
  query_collected_posts: collectedPostSchema.array(),
})

export const postCollectionPostSchema = z.object({
  id: z.number(), // Kolr 自己存的 id
  post_id: z.string(), // 各大社群平台存的 id
  ig_post_id: z.string(), // ig 貼文的 original id
  url: z.string(),
  thumbnail_url: z.string(),
  title: z.string(),
  content: z.string(),
  crawler_id: z.string(),
  platform_type: z.nativeEnum(PlatformShortcode),
  published_at: z.string().datetime().nullable(),
  kol_uuid: z.string().uuid(),
  name: z.array(z.string()),
  created_at: z.string().datetime().nullable(),
  is_advertorial: z.boolean(),
  like_count: z.number().nullable(),
  comment_count: z.number().nullable(),
  share_count: z.number().nullable(),
  watch_count: z.number().nullable(), // 和 content api 的命名不同，相當於 viewCount
  engagement_count: z.number().nullable(), // 和 content api 的命名不同，相當於 playCount
  recorded_status: z.union([
    z.literal('recorded'),
    z.literal('updating'),
    z.literal('update-failed'),
  ]),
  customized_tags_names: z.string().array(),
  is_disable_like: z.boolean(),
})

export type PostCollection = z.infer<typeof postCollectionSchema>
export type PostCollectionPost = z.infer<typeof postCollectionPostSchema>
export type CollectedPost = z.infer<typeof collectedPostSchema>

import { Button, notification, Steps } from 'antd'
import { StepProps } from 'antd/es/steps'
import Image from 'next/image'
import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import {
  AuthPage,
  useFetchAuthPagesQuery,
  useSetFacebookAuthTokenMutation,
} from '@/api/oauth-api'
import connectFacebookPageMobileZhHant from '@/assets/images/kol/connect-facebook-page-mobile-zh-hant.png'
import connectFacebookPagePCZhHant from '@/assets/images/kol/connect-facebook-page-pc-zh-hant.png'
import connectedFacebookPagePCZhHant from '@/assets/images/kol/connected-facebook-page-pc-zh-hant.png'
import instagramSettingMobileZhHant from '@/assets/images/kol/ig-setting-mobile-zh-hant.png'
import instagramSettingPCZhHant from '@/assets/images/kol/ig-setting-pc-zh-hant.gif'
import instagramAuthorizationStep1 from '@/assets/images/kol/instagram-authorization-1.png'
import instagramAuthorizationStep2 from '@/assets/images/kol/instagram-authorization-2.png'
import instagramAuthorizationStep3 from '@/assets/images/kol/instagram-authorization-3.png'
import Space from '@/components/common/antd-v4-backport/space'
import { BasicModal } from '@/components/common/antd/basic-modal'
import { SafeNewTabLink } from '@/components/safe-new-tab-link'
import { KOL_AUTH_PARAMS } from '@/constants/meta-auth-request'
import { useBreakpoint } from '@/hooks/use-breakpoint'
import { useIntl } from '@/i18n/hooks/use-intl'
import { ampli } from '@/utils/ampli'

type InstagramBusinessAccountIntegrationModalProps = {
  isVisible: boolean
  onCancel: VoidFunction
}

const InstagramBusinessAccountAuthorizationModal: FunctionComponent<
  InstagramBusinessAccountIntegrationModalProps
> = ({ isVisible, onCancel }) => {
  const breakpoint = useBreakpoint()
  const [currentStep, setCurrentStep] = useState(0)
  const [authenticating, setAuthenticating] = useState(false)
  const [checkingAuthPages, setCheckingAuthPages] = useState(false)
  const [successModalVisible, setSuccessModalVisible] = useState(false)
  const [failureModalVisible, setFailureModalVisible] = useState(false)
  const { formatMessage } = useIntl()
  const [setFacebookAuthToken] = useSetFacebookAuthTokenMutation()
  const { data: fetchAuthPagesResponse, isFetching: fetchAuthPagesRequesting } =
    useFetchAuthPagesQuery()
  const instagramPages = useMemo<AuthPage[]>(() => {
    return (
      fetchAuthPagesResponse?.pageIds.filter(
        (page) => !!page.instagramBusinessAccount,
      ) ?? []
    )
  }, [fetchAuthPagesResponse])
  const steps: StepProps[] = [
    { title: '切換為商業帳號' },
    { title: '連結粉絲專頁' },
    { title: '允許權限' },
  ]

  const handleCloseAll: VoidFunction = () => {
    setSuccessModalVisible(false)
    setFailureModalVisible(false)
    onCancel()
  }

  const handleAuth: VoidFunction = () => {
    setAuthenticating(true)

    ampli.tryGrantSnsPermission({ platform: 'Instagram' })

    window.FB.login(({ authResponse }) => {
      const { accessToken, userID, grantedScopes } = authResponse

      if (accessToken) {
        ampli.authorizeSuccessful({
          platform: 'Instagram',
          permission: grantedScopes?.split(','),
        })

        setFacebookAuthToken({ accessToken, userID })
          .unwrap()
          .then(() => {
            setCheckingAuthPages(true)
            setAuthenticating(false)
          })
          .catch((error) => {
            console.error(error)
            setAuthenticating(false)

            notification.error({
              message: formatMessage({
                id: 'error:edit_kol_data_update_fail',
              }),
              placement: 'bottomRight',
            })
          })
      } else {
        setAuthenticating(false)
        ampli.authorizeFail({ platform: 'Facebook' })
      }
    }, KOL_AUTH_PARAMS)
  }

  useEffect(() => {
    setCurrentStep(0)
  }, [isVisible])

  useEffect(() => {
    if (!checkingAuthPages || fetchAuthPagesRequesting) {
      return
    }

    if (instagramPages.length) {
      setSuccessModalVisible(true)
    } else {
      setFailureModalVisible(true)
    }

    setCheckingAuthPages(false)
  }, [
    checkingAuthPages,
    fetchAuthPagesRequesting,
    instagramPages.length,
    onCancel,
    formatMessage,
  ])

  return (
    <>
      <StyledModal
        centered
        destroyOnClose
        footer={[
          ...(currentStep !== 0
            ? [
                <Button
                  key='back'
                  onClick={(): void => setCurrentStep(currentStep - 1)}
                >
                  回上一步
                </Button>,
              ]
            : []),
          ...(currentStep !== steps.length - 1
            ? [
                <Button
                  key='next'
                  type='primary'
                  onClick={(): void => setCurrentStep(currentStep + 1)}
                >
                  {formatMessage({ id: 'general:next_step_btn' })}
                </Button>,
              ]
            : []),
          ...(currentStep === steps.length - 1
            ? [
                <Button
                  disabled={authenticating || checkingAuthPages}
                  key='auth'
                  type='primary'
                  onClick={(): void => handleAuth()}
                >
                  {authenticating || checkingAuthPages
                    ? '授權中...'
                    : '允許授權'}
                </Button>,
              ]
            : []),
        ]}
        open={isVisible}
        width={900}
        onCancel={onCancel}
      >
        <Title>Instagram 商業帳號授權</Title>
        <StepsWrapper>
          <Steps current={currentStep}>
            {steps.map((stepProps, index) => (
              <Steps.Step key={index} {...stepProps} />
            ))}
          </Steps>
          {currentStep === 0 && (
            <StepContent>
              <p>
                {formatMessage(
                  {
                    id: 'kol:instagram_business_account_authorization_modal_step_1_description',
                  },
                  {
                    link: (text) => (
                      <HyperLink href='https://www.instagram.com/accounts/edit/'>
                        {text}
                      </HyperLink>
                    ),
                  },
                )}
              </p>
              <Space align='center' direction='vertical'>
                {breakpoint === 'mobile' ? (
                  <Image
                    alt='Instagram Business Account'
                    height={541}
                    src={instagramSettingMobileZhHant}
                    width={250}
                  />
                ) : (
                  <Image
                    alt='Instagram Business Account'
                    height={321}
                    src={instagramSettingPCZhHant}
                    width={550}
                  />
                )}
              </Space>
            </StepContent>
          )}
          {currentStep === 1 && (
            <StepContent>
              <p>
                {formatMessage(
                  {
                    id: 'kol:instagram_business_account_authorization_modal_step_2_description',
                  },
                  {
                    link: (text) => (
                      <HyperLink href='https://www.facebook.com/settings?tab=linked_instagram'>
                        {text}
                      </HyperLink>
                    ),
                  },
                )}
              </p>
              {breakpoint === 'mobile' ? (
                <Space align='center' direction='vertical'>
                  <Image
                    alt='Instagram Business Account'
                    height={522}
                    src={connectFacebookPageMobileZhHant}
                    width={250}
                  />
                </Space>
              ) : (
                <Space size={12}>
                  <div>
                    <Image
                      alt='Connect Facebook Fan Page'
                      height={317}
                      src={connectFacebookPagePCZhHant}
                      width={636}
                    />
                  </div>
                  <div>
                    <Image
                      alt='Connected Facebook Fan Page'
                      height={317}
                      src={connectedFacebookPagePCZhHant}
                      width={184}
                    />
                  </div>
                </Space>
              )}
            </StepContent>
          )}
          {currentStep === 2 && (
            <StepContent>
              <p>
                {formatMessage(
                  {
                    id: 'kol:instagram_business_account_authorization_modal_step_3_description',
                  },
                  {
                    br: <br />,
                    link: (text) => (
                      <HyperLink href='https://help.kolr.ai/article/83-cannot-find-instagram-authorization'>
                        {text}
                      </HyperLink>
                    ),
                  },
                )}
              </p>
              <Space
                align='center'
                direction={breakpoint === 'mobile' ? 'vertical' : 'horizontal'}
                size='middle'
              >
                <div>
                  <Image
                    alt='Instagram Authorization Step 1'
                    height={326}
                    src={instagramAuthorizationStep1}
                    width={267}
                  />
                </div>
                <div>
                  <Image
                    alt='Instagram Authorization Step 2'
                    height={327}
                    src={instagramAuthorizationStep2}
                    width={267}
                  />
                </div>
                <div>
                  <Image
                    alt='Instagram Authorization Step 3'
                    height={327}
                    src={instagramAuthorizationStep3}
                    width={267}
                  />
                </div>
              </Space>
            </StepContent>
          )}
        </StepsWrapper>
      </StyledModal>
      <StyledModal
        centered
        footer={
          <Button type='primary' onClick={handleCloseAll}>
            關閉視窗
          </Button>
        }
        open={successModalVisible}
        onCancel={handleCloseAll}
      >
        <Title>Instagram 商業帳號授權成功</Title>
        <StepContent>
          <p>您已完成商業帳號授權成功，請繼續你要新增上線連結的活動。</p>
        </StepContent>
      </StyledModal>
      <StyledModal
        centered
        footer={[
          <Button key='cancel' onClick={handleCloseAll}>
            取消
          </Button>,
          <Button
            key='reset'
            type='primary'
            onClick={(): void => {
              setCurrentStep(0)
              setFailureModalVisible(false)
            }}
          >
            重新設定
          </Button>,
        ]}
        open={failureModalVisible}
        title='Instagram 商業帳號授權失敗'
        onCancel={handleCloseAll}
      >
        <StepContent>
          <p>
            您的帳號權限不足，請查看教學，再重新設定。
            <br />
            <HyperLink href='https://help.kolr.ai/article/84-cannot-connect-instagram-page'>
              無法連結 Instagram 和粉絲專頁怎麼辦？
            </HyperLink>
          </p>
        </StepContent>
      </StyledModal>
    </>
  )
}

const StyledModal = styled(BasicModal)`
  .ant-btn {
    height: auto;
    padding: 12px 20px;
  }
`

const Title = styled.h2`
  color: ${({ theme }): string => theme.colors.text.primary};
  margin: 0;
`

const StepsWrapper = styled.div`
  padding: 25px 0 0;
`

const StepContent = styled.div`
  max-height: 380px;
  overflow: scroll;

  p {
    color: ${({ theme }): string => theme.colors.text.primary};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin: 24px 0;
  }

  ${Space} {
    width: 100%;
  }
`

const HyperLink = styled(SafeNewTabLink)`
  color: ${({ theme }): string => theme.colors.brand.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
`

export default InstagramBusinessAccountAuthorizationModal

import { z } from 'zod'
import { fileItemSchema, updateFileItemSchema } from '@/types/schema/file-item'

const baseAccountingInfoSchema = z.object({
  person_in_charge: z.string(),
  juristic_name: z.string(),
  id_number: z.string(),
  tax_id_number: z.string(),
  address: z.string(),
  phone: z.string(),
  bank_swift_code: z.string(),
  bank_name: z.string(),
  bank_branch_code: z.string(),
  bank_branch_name: z.string(),
  bank_account: z.string(),
  bank_account_name: z.string(),
  is_company: z.boolean().nullable(),
  is_union_insurance: z.boolean().nullable(),
  is_foreigner: z.boolean().nullable(),
})

export const accountingInfoSchema = baseAccountingInfoSchema.extend({
  insurance_imgs: fileItemSchema.array().max(1),
  id_card_front_imgs: fileItemSchema.array().max(1),
  id_card_back_imgs: fileItemSchema.array().max(1),
  passbook_imgs: fileItemSchema.array().max(1),
  other_imgs: fileItemSchema.array(),
})
export type AccountingInfo = z.infer<typeof accountingInfoSchema>

const updateAccountingInfoSchema = baseAccountingInfoSchema.extend({
  insurance_imgs: updateFileItemSchema.array().max(1),
  id_card_front_imgs: updateFileItemSchema.array().max(1),
  id_card_back_imgs: updateFileItemSchema.array().max(1),
  passbook_imgs: updateFileItemSchema.array().max(1),
  other_imgs: updateFileItemSchema.array(),
})
export type UpdateAccountingInfo = z.infer<typeof updateAccountingInfoSchema>

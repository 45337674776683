import { Icon } from '@buggy/shared'
import { isNil } from 'lodash-es'
import React, { FunctionComponent, useRef } from 'react'
import { useHoverDirty } from 'react-use'
import styled from 'styled-components'
import KolNameBadge from '@/components/common/kol-name-badge'
import { KolThumbnail } from '@/components/common/kol-thumbnail'
import SearchSimilarKolsLink from '@/components/search/search-similar-kols-link'
import { useIntl } from '@/i18n/hooks/use-intl'
import { QuickSearchKOL } from '@/types/api/search'
import { PlatformType } from '@/types/schema/report-schema'
import { convertPlatformType } from '@/utils/convert-platform'
import { sortByPlatformTypeScore } from '@/utils/get-main-platforms'

const THUMBNAIL_SIZE = 40
const ROW_GAP = 15
const ROW_PADDING_X = 20
const SIMILAR_KOL_LINK_WIDTH = 62

const getNameWidth = (
  containerWidth: number,
  { isHovering }: { isHovering: boolean },
): number => {
  return (
    containerWidth -
    ROW_PADDING_X * 2 -
    THUMBNAIL_SIZE -
    (isHovering ? ROW_GAP * 2 + SIMILAR_KOL_LINK_WIDTH : ROW_GAP)
  )
}

interface DropdownKOLDetailProps
  extends Pick<QuickSearchKOL, 'similarKolPlatform'> {
  UUID: string
  name: string
  followerCount: number
  isRadarStar?: boolean
  isVerified?: boolean
  platforms: PlatformType[]
  thumbnailUrl?: string
  width?: number
}
export const DropdownKolDetail: FunctionComponent<DropdownKOLDetailProps> = ({
  UUID,
  name,
  similarKolPlatform,
  followerCount,
  isVerified,
  isRadarStar,
  platforms,
  thumbnailUrl,
  width,
}) => {
  const { formatNumber, formatMessage } = useIntl()
  const containerRef = useRef<HTMLDivElement>(null)
  const isHovering = useHoverDirty(containerRef)
  const platformInfo = sortByPlatformTypeScore(
    platforms.map((p) => convertPlatformType(p)),
    'type',
  )

  return (
    <KOLWrapper ref={containerRef}>
      <ThumbnailWrapper>
        {UUID === '' ? (
          <KolThumbnail src={thumbnailUrl ?? ''} />
        ) : (
          <KolThumbnail kolUuid={UUID} />
        )}
      </ThumbnailWrapper>
      <div style={{ flex: 1 }}>
        <KolNameBadge
          badgeWidth={width ? getNameWidth(width, { isHovering }) : undefined}
          isRadarStar={!!isRadarStar}
          isVerified={!!isVerified}
          name={name}
        />
        <SubInfoWrapper>
          {platformInfo.map(
            (platform) =>
              platform?.sharedIconName && (
                <StyledIcon
                  key={platform.code}
                  name={platform.sharedIconName}
                />
              ),
          )}
          <Follower>
            {formatNumber(followerCount)}{' '}
            {formatMessage({ id: 'kol:platform_data_follower' })}
          </Follower>
        </SubInfoWrapper>
      </div>
      {isHovering && !isNil(UUID) && (
        <SearchSimilarKolsLink
          at='Quick Search'
          kolId={UUID}
          similarKolPlatform={similarKolPlatform}
          style='link'
        />
      )}
    </KOLWrapper>
  )
}

const KOLWrapper = styled.div`
  display: flex;
  padding: 16px ${ROW_PADDING_X}px;
  gap: ${ROW_GAP}px;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`
const SubInfoWrapper = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
`

const Follower = styled.div`
  font-size: 12px;
  line-height: 17px;
  color: #909399;
`

const ThumbnailWrapper = styled.div`
  height: ${THUMBNAIL_SIZE}px;
  width: ${THUMBNAIL_SIZE}px;
`

const StyledIcon = styled(Icon)`
  margin-right: 4px;
`

import logger from '@/utils/logger'
import {
  SearchIdMap,
  idSchema,
  searchIdMapSchema,
} from '@/utils/search-id-manager/type'

interface Store {
  get: (sessionId: string) => string | undefined
  getAll: () => SearchIdMap
  getAllSessionId: () => string[]
  update: (sessionId: string, searchId: string) => SearchIdMap
  remove: (sessionIdList: string[]) => void
}

const searchIdKeySeparator = '__' as const

// 儲存至 LocalStorage 時，會將每個 session 的 search id 分別存成獨立的 key value pair，例如：
// radar:search-id__{sessionId_1} = searchId_1
// radar:search-id__{sessionId_2} = searchId_2
// radar:search-id__{sessionId_3} = searchId_3
//
// 載入時，則會將相關 key 合併成一個物件，方便使用，例如：
// {
//   {sessionId_1}: searchId_1,
//   {sessionId_2}: searchId_2,
//   {sessionId_3}: searchId_3,
// }
//
// 這個設計讓每個 session 可以頻繁對自己負責的區塊修改，避免了多個分頁頻繁對同個 key 進行修改而造成的 race-condition 問題
class LocalStorageStore implements Store {
  localStorageBaseKey: string

  constructor(localStorageKey: string, initialSearchIdMap?: SearchIdMap) {
    this.localStorageBaseKey = localStorageKey
    if (initialSearchIdMap) {
      Object.entries(initialSearchIdMap).forEach(([sessionId, searchId]) => {
        localStorage.setItem(this.#getKey(sessionId), JSON.stringify(searchId))
      })
    }
  }

  #getKey(sessionId: string): string {
    return `${this.localStorageBaseKey}${searchIdKeySeparator}${sessionId}`
  }

  getAllSessionId(): string[] {
    return Object.keys(this.getAll())
  }

  get(sessionId: string): string | undefined {
    const searchIdMap = this.getAll()
    const sessionSearchId = searchIdMap[sessionId]

    if (!sessionSearchId) {
      return undefined
    }

    try {
      return idSchema.parse(sessionSearchId)
    } catch (e) {
      logger.error(e)
      this.remove([sessionId])
      return undefined
    }
  }

  getAll(): SearchIdMap {
    const searchIdLocalStorage = Object.entries(localStorage).filter(([key]) =>
      key.startsWith(`${this.localStorageBaseKey}${searchIdKeySeparator}`),
    )

    try {
      const searchIdMap = searchIdLocalStorage.reduce(
        (acc, [key, searchId]) => {
          const sessionId = key.split(searchIdKeySeparator)[1]
          return { ...acc, [sessionId]: JSON.parse(searchId) }
        },
        {},
      )
      return searchIdMapSchema.parse(searchIdMap)
    } catch (e) {
      logger.error(e)
      this.remove(
        searchIdLocalStorage.map(([key]) => key.split(searchIdKeySeparator)[1]),
      )
      return {}
    }
  }

  update(sessionId: string, searchId: string): SearchIdMap {
    localStorage.setItem(this.#getKey(sessionId), JSON.stringify(searchId))
    return this.getAll()
  }

  remove(sessionIdList: string[]): void {
    sessionIdList.forEach((sessionId) => {
      localStorage.removeItem(this.#getKey(sessionId))
    })
  }
}
export type { Store }
export default LocalStorageStore
export { searchIdKeySeparator }

import { Page } from '@/hooks/use-authorization/constants'
import {
  getRegistrationStatus,
  RegistrationStatus,
} from '@/hooks/use-authorization/get-registration-status'
import { UserSchema } from '@/types/schema/user-schema'
import { getUserGroup } from '@/utils/accounting/get-user-group'
import { exhaustiveCheck } from '@/utils/exhaustive-check'

type GetRegistrationStatusUrl = (user: UserSchema) => string

const getRegistrationStatusUrl: GetRegistrationStatusUrl = (user) => {
  const status = getRegistrationStatus(user)
  switch (status) {
    case RegistrationStatus.UntypedKol:
      return `${Page.Register}/kol`
    case RegistrationStatus.Incomplete:
      return `${Page.Register}/${getUserGroup(user.type)}`
    case RegistrationStatus.EmailNotConfirmed:
      return Page.RegisterSuccess
    case RegistrationStatus.Unverified:
      return Page.RegisterSuccess
    case RegistrationStatus.VerifiedAd:
      return Page.CreateWorkspace
    case RegistrationStatus.UnverifiedKol:
      return Page.VerifyAccount
    case RegistrationStatus.Done:
      return Page.Default
    default:
      return exhaustiveCheck(status)
  }
}

export default getRegistrationStatusUrl

import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import AppHeaderJpCountryMobile from '@/components/public-page/header/app-header-jp-country-mobile'
import AppHeaderOtherCountryMobile from '@/components/public-page/header/app-header-other-country-mobile'
import AppHeaderPc from '@/components/public-page/header/app-header-pc'
import { CountryCode } from '@/constants/country-code'
import { getLocaleRegion, useI18n } from '@/hooks/use-i18n'

const AppHeader: FunctionComponent = () => {
  const { locale } = useI18n()
  const country = getLocaleRegion(locale) || CountryCode.TW

  return (
    <HeaderWrapper>
      <Mobile>
        {country === CountryCode.JP ? (
          <AppHeaderJpCountryMobile />
        ) : (
          <AppHeaderOtherCountryMobile />
        )}
      </Mobile>
      <Desktop>
        <AppHeaderPc />
      </Desktop>
    </HeaderWrapper>
  )
}

export default AppHeader

export const HeaderWrapper = styled.div`
  color: ${({ theme }): string => theme.colors.text.white};
  transition: 0.3s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  ul {
    padding-left: 0;

    > li {
      list-style-type: none;
    }
  }
`
const Mobile = styled.div`
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
`
const Desktop = styled.div`
  display: block;

  @media (max-width: 767px) {
    display: none;
  }
`

import { UnknownAction } from 'redux'
import { Epic, StateObservable } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators'
import { AppContext } from '@/api/executor/app-context'
import { userApi } from '@/api/user-api'
import {
  createCampaignKolContracts,
  createCampaignKolContractsFailure,
  createCampaignKolContractsSuccess,
  fetchCampaignContractTemplates,
  fetchCampaignContractTemplatesFailure,
  fetchCampaignContractTemplatesSuccess,
  removeCampaignKols,
  removeCampaignKolsFailure,
  removeCampaignKolsSuccess,
  updateCampaignKolContracts,
  updateCampaignKolContractsFailure,
  updateCampaignKolContractsSuccess,
  updateCampaignKolPayment,
  updateCampaignKolPaymentFailure,
  updateCampaignKolPaymentSuccess,
  updateCampaignKols,
  updateCampaignKolsFailure,
  updateCampaignKolsSuccess,
} from '@/store/campaign'
import { RootState } from '@/store/store'

const removeCampaignKolsEpic: Epic<UnknownAction, UnknownAction, RootState> = (
  action$,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(removeCampaignKols.match),
    switchMap((action) => {
      const workspaceId = userApi.endpoints.fetchUserStatus.select()(
        state$.value,
      )?.data?.currentWorkspaceId

      if (workspaceId) {
        return from(
          AppContext.ApiExecutor.removeCampaignKOLs(
            action.payload.campaignId,
            action.payload.campaignKolIds,
            workspaceId,
          ),
        ).pipe(
          map(() => {
            return removeCampaignKolsSuccess(action.payload.campaignKolIds)
          }),
          catchError(() => {
            return of(removeCampaignKolsFailure())
          }),
        )
      } else {
        return of(removeCampaignKolsFailure())
      }
    }),
  )

const updateCampaignKolsEpic: Epic<UnknownAction, UnknownAction, RootState> = (
  action$,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(updateCampaignKols.match),
    mergeMap((action) => {
      const workspaceId = userApi.endpoints.fetchUserStatus.select()(
        state$.value,
      )?.data?.currentWorkspaceId

      if (!workspaceId) {
        return of(updateCampaignKolsFailure())
      }

      return from(
        AppContext.ApiExecutor.updateCampaignKols(
          action.payload.campaignKol,
          action.payload.campaignKolIds,
          action.payload.campaignId,
          workspaceId,
        ),
      ).pipe(
        map(() => {
          return updateCampaignKolsSuccess(action.payload)
        }),
        catchError(() => {
          return of(updateCampaignKolsFailure())
        }),
      )
    }),
  )

const fetchCampaignContractTemplatesEpic: Epic<
  UnknownAction,
  UnknownAction,
  RootState
> = (action$, state$: StateObservable<RootState>) =>
  action$.pipe(
    filter(fetchCampaignContractTemplates.match),
    switchMap((action) => {
      const workspaceId = userApi.endpoints.fetchUserStatus.select()(
        state$.value,
      )?.data?.currentWorkspaceId

      if (!workspaceId) {
        return of(fetchCampaignContractTemplatesFailure())
      }

      return from(
        AppContext.ApiExecutor.fetchCampaignContractTemplates(
          action.payload.page,
          action.payload.campaignId,
          workspaceId,
        ),
      ).pipe(
        map(({ data }) => {
          return fetchCampaignContractTemplatesSuccess(data)
        }),
        catchError(() => {
          return of(fetchCampaignContractTemplatesFailure())
        }),
      )
    }),
  )

const updateCampaignKolContractsEpic: Epic<
  UnknownAction,
  UnknownAction,
  RootState
> = (action$, state$: StateObservable<RootState>) =>
  action$.pipe(
    filter(updateCampaignKolContracts.match),
    switchMap((action) => {
      const workspaceId = userApi.endpoints.fetchUserStatus.select()(
        state$.value,
      )?.data?.currentWorkspaceId

      if (!workspaceId) {
        return of(updateCampaignKolContractsFailure())
      }

      return from(
        AppContext.ApiExecutor.updateCampaignKolContracts(
          action.payload.campaignKolContract,
          action.payload.campaignKolContractID,
          action.payload.campaignKolId,
          action.payload.campaignId,
          workspaceId,
        ),
      ).pipe(
        map(() => {
          return updateCampaignKolContractsSuccess()
        }),
        catchError(() => {
          return of(updateCampaignKolContractsFailure())
        }),
      )
    }),
  )

const createCampaignKolContractsEpic: Epic<
  UnknownAction,
  UnknownAction,
  RootState
> = (action$, state$: StateObservable<RootState>) =>
  action$.pipe(
    filter(createCampaignKolContracts.match),
    switchMap((action) => {
      const workspaceId = userApi.endpoints.fetchUserStatus.select()(
        state$.value,
      )?.data?.currentWorkspaceId

      if (!workspaceId) {
        return of(createCampaignKolContractsFailure())
      }

      return from(
        AppContext.ApiExecutor.createCampaignKolContracts(
          action.payload.campaignKolContract,
          action.payload.campaignKolIds,
          action.payload.campaignId,
          workspaceId,
        ),
      ).pipe(
        map(() => {
          return createCampaignKolContractsSuccess()
        }),
        catchError(() => {
          return of(createCampaignKolContractsFailure())
        }),
      )
    }),
  )

const updateCampaignKolPaymentEpic: Epic<
  UnknownAction,
  UnknownAction,
  RootState
> = (action$, state$: StateObservable<RootState>) =>
  action$.pipe(
    filter(updateCampaignKolPayment.match),
    mergeMap((action) => {
      const workspaceId = userApi.endpoints.fetchUserStatus.select()(
        state$.value,
      )?.data?.currentWorkspaceId

      if (!workspaceId) {
        return of(updateCampaignKolPaymentFailure())
      }

      return from(
        AppContext.ApiExecutor.updateCampaignKolPayment(
          action.payload.campaignKol,
          action.payload.campaignKolIds,
          action.payload.campaignId,
          workspaceId,
          action.payload.requestPaymentDate,
        ),
      ).pipe(
        map(() => {
          return updateCampaignKolPaymentSuccess()
        }),
        catchError(() => {
          return of(updateCampaignKolPaymentFailure())
        }),
      )
    }),
  )

const CampaignEpics = [
  updateCampaignKolsEpic,
  fetchCampaignContractTemplatesEpic,
  updateCampaignKolContractsEpic,
  createCampaignKolContractsEpic,
  updateCampaignKolPaymentEpic,
  removeCampaignKolsEpic,
]

export default CampaignEpics

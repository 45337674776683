import { Drawer, Spin } from 'antd'
import { keys, sortBy } from 'lodash-es'
import { PubNubProvider } from 'pubnub-react'
import { FunctionComponent, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'
import { useFetchUserStatusQuery } from '@/api/user-api'
import CampaignCaseInvitation from '@/components/campaign/chatroom/campaign-case-invitation'
import CurrentConversationDropdown from '@/components/campaign/chatroom/current-conversation-dropdown'
import MessageCompose from '@/components/campaign/chatroom/message-compose'
import { NoData } from '@/components/kol/detail/no-data'
import { useAuth } from '@/hooks/use-auth'
import { UserType } from '@/hooks/use-authorization/constants'
import { useBreakpoint } from '@/hooks/use-breakpoint'
import { useSelector } from '@/hooks/use-selector'
import {
  Conversation,
  markMessageAsRead,
  setTemporaryConversation,
  switchConversation,
  toggleMessageComposeVisible,
} from '@/store/chatroom'
import { CampaignKolStatus } from '@/types/mock-api-types'
import usePubNubClient from '@/utils/hooks/use-pub-nub-client'

const ChatroomDrawer: FunctionComponent = () => {
  const breakpoint = useBreakpoint()
  const dispatch = useDispatch()
  const { userInfo } = useAuth()
  const { data: userStatus } = useFetchUserStatusQuery()
  const {
    conversationList,
    currentConversationId,
    messageComposeVisible,
    temporaryConversation,
  } = useSelector((state) => state.chatroom)
  const { pubNub: pubNubClient } = usePubNubClient({
    channels: keys(conversationList),
  })
  const { invitingCampaignList, runningCampaignList, appliedCampaignList } =
    useSelector((state) => state.campaignCase)

  const userId = userInfo?.id
  const userType = userInfo?.type
  const currentWorkspaceId = userStatus?.currentWorkspaceId
  const uuid = useMemo((): string | undefined => {
    if (!userId || !userType) {
      return undefined
    }

    if (userType !== UserType.NormalAd) {
      return `campaign_${userType}_user${userId}`
    }

    if (!currentWorkspaceId) {
      return undefined
    }

    return `campaign_workspace${currentWorkspaceId}`
  }, [userId, userType, currentWorkspaceId])

  const currentConversation = useMemo((): Conversation | undefined => {
    if (temporaryConversation) {
      return temporaryConversation
    }
    if (!currentConversationId) {
      return undefined
    }
    const conversation = conversationList[currentConversationId]
    return conversation?.id ? conversation : undefined
  }, [temporaryConversation, currentConversationId, conversationList])

  const list = useMemo(() => {
    return sortBy(
      conversationList,
      (conversation) => conversation.latestMessage?.timetoken ?? 0,
    ).reverse()
  }, [conversationList])

  const requesting =
    invitingCampaignList.apiStatus.requesting ||
    runningCampaignList.apiStatus.requesting ||
    appliedCampaignList.apiStatus.requesting ||
    !pubNubClient
  const success =
    invitingCampaignList.apiStatus.success ||
    appliedCampaignList.apiStatus.success ||
    runningCampaignList.apiStatus.success

  useUpdateEffect(() => {
    if (!requesting && !currentConversation && list.length) {
      dispatch(switchConversation(list[0].id))
    }
  }, [dispatch, list])

  useEffect(() => {
    if (messageComposeVisible && currentConversationId) {
      dispatch(markMessageAsRead(currentConversationId))
    }
  }, [currentConversationId, dispatch, messageComposeVisible])

  return (
    <Drawer
      bodyStyle={{ padding: 0, height: '100%', maxWidth: '100%' }}
      closable={false}
      height='100%'
      open={messageComposeVisible}
      width={breakpoint === 'mobile' ? '100%' : 450}
      onClose={(): void => {
        dispatch(toggleMessageComposeVisible())
        dispatch(setTemporaryConversation(undefined))
      }}
    >
      <Wrapper>
        {requesting && <Spin />}
        {!requesting && success && !currentConversation && <NoData />}
        {pubNubClient &&
          !requesting &&
          success &&
          currentConversation?.campaignCase && (
            <PubNubProvider client={pubNubClient}>
              <CurrentConversationDropdown
                conversationList={list}
                currentConversation={currentConversation}
              />
              <CampaignCaseInvitation
                campaignCase={currentConversation.campaignCase}
              />
              <MessageCompose
                channelId={currentConversation.id}
                disabled={[
                  CampaignKolStatus.Done,
                  CampaignKolStatus.Rejected,
                  CampaignKolStatus.Canceled,
                ].includes(currentConversation.campaignCase.status)}
                participant={{
                  name:
                    currentConversation.campaignCase.campaign
                      .brandRecognitionSnap?.name ?? '',
                  imageUrl:
                    currentConversation.campaignCase.campaign
                      .brandRecognitionSnap?.iconFile?.signedUrl,
                }}
                users={[
                  {
                    id: uuid ?? '',
                    eTag: '',
                    updated: '',
                    custom: {
                      campaignId:
                        currentConversation.campaignCase.campaign.id || 0,
                      campaignName:
                        currentConversation.campaignCase.campaign.name || '',
                      kolName:
                        currentConversation.campaignCase.kolName?.[0] || '',
                      kolUuid: currentConversation.campaignCase.kolUUID || '',
                    },
                  },
                ]}
              />
            </PubNubProvider>
          )}
      </Wrapper>
    </Drawer>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  .ant-spin {
    width: 100%;
  }
`

export default ChatroomDrawer

import { Icon } from '@buggy/shared'
import { FloatButton } from 'antd'
import { sumBy, values } from 'lodash-es'
import Link from 'next/link'
import { FunctionComponent, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import styled, { useTheme } from 'styled-components'
import ChatroomDrawer from '@/components/campaign/chatroom/chatroom-drawer'
import LogoHorizontal from '@/components/common/logo-horizontal'
import HeaderItemGroup from '@/components/header/header-item-group'
import MobileMenu from '@/components/header/mobile-menu'
import useAgentInfo from '@/hooks/use-agent-info'
import routes from '@/hooks/use-authorization/routes'
import useDefaultCampaignCase from '@/hooks/use-default-campaign-case'
import { useSelector } from '@/hooks/use-selector'
import { toggleMessageComposeVisible } from '@/store/chatroom'

const MAX_UNREAD_MESSAGE_COUNT = 99
export const KOL_SELF_LAYOUT_HEADER_HEIGHT = 84

export const Header: FunctionComponent = () => {
  const { agencyKOL } = useAgentInfo()
  const dispatch = useDispatch()
  const theme = useTheme()
  useDefaultCampaignCase()
  const { conversationList } = useSelector((state) => state.chatroom)
  const unreadMessageCount: number | string = useMemo(() => {
    const unreadCount: number = sumBy(
      values(conversationList),
      'unreadMessageCount',
    )

    return unreadCount > MAX_UNREAD_MESSAGE_COUNT
      ? `${MAX_UNREAD_MESSAGE_COUNT}+`
      : unreadCount
  }, [conversationList])

  return (
    <HeaderWrapper>
      <Container>
        <HeaderItemGroupWrapper>
          <Link href={routes.default.stringifyUrl({})}>
            <LogoHorizontal />
          </Link>
        </HeaderItemGroupWrapper>
        <HeaderItemGroup />
        <MobileMenu />
        {!agencyKOL && (
          <>
            <ChatroomButton
              badge={{ count: unreadMessageCount }}
              icon={
                <Icon
                  color={theme.colors.brand.primary}
                  name='message-circle'
                />
              }
              onClick={(): void => {
                dispatch(toggleMessageComposeVisible())
              }}
            />
            <ChatroomDrawer />
          </>
        )}
      </Container>
    </HeaderWrapper>
  )
}

const ChatroomButton = styled(FloatButton)`
  height: 60px;
  width: 60px;
  bottom: 89px;
  right: 20px;

  .ant-float-btn-body .ant-float-btn-content .ant-float-btn-icon {
    font-size: 20px;
  }

  .ant-scroll-number.ant-badge-count {
    transform: translate(-20%, 40%);
  }
`

export const HeaderWrapper = styled.div`
  padding: 4px;
  position: fixed;
  width: 100vw;
  background-color: white;
  z-index: 1000;
  box-shadow: rgb(0 0 0 / 10%) 0 2px 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    padding: 4px 50px;
    height: ${KOL_SELF_LAYOUT_HEADER_HEIGHT}px;
  }
`

const Container = styled.div`
  width: 1140px;
  padding: 0 20px 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1140px) {
    width: 100%;
  }
`

const HeaderItemGroupWrapper = styled.div`
  display: flex;
  align-items: center;
`

import Image from 'next/image'
import React, { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import browserWarningSrc from '@/assets/images/warning/browser.png'
import rwdWarningSrc from '@/assets/images/warning/rwd.png'
import { H2, StyleParagraph } from '@/components/common/typography'
import MessageDropdown from '@/components/header/message-dropdown'
import { SearchInputContainer } from '@/components/header/search-keyword'
import IrmFooter, { FooterWrapper } from '@/components/layout/irm/irm-footer'
import IrmHeader, {
  ActionWrapper,
  HeaderWrapper,
  IRM_HEADER_HEIGHT,
  LogoWrapper,
} from '@/components/layout/irm/irm-header'
import { default as PublicPageFooter } from '@/components/public-page/footer'
import { default as PublicPageHeader } from '@/components/public-page/header'
import { WarningType } from '@/components/warning/use-site-warning'
import { useAuth } from '@/hooks/use-auth'
import { PC_BREAKPOINT } from '@/hooks/use-breakpoint'
import { useIntl } from '@/i18n/hooks/use-intl'
import { ampli } from '@/utils/ampli'

interface WarningProps {
  onSkip?: () => void
  type: WarningType
}

const Warning: FunctionComponent<WarningProps> = ({
  onSkip = (): void => {},
  type,
}) => {
  const { formatMessage } = useIntl()
  const { isLogin } = useAuth()

  return (
    <Wrapper>
      {isLogin ? <IrmHeader isCollapsed /> : <PublicPageHeader />}
      {((): ReactNode => {
        switch (type) {
          case 'rwd':
            return (
              <RWDContentWrapper>
                <ImageWrapper $ratio={335 / 255}>
                  <Image fill alt='unsupported-rwd' src={rwdWarningSrc} />
                </ImageWrapper>
                <RWDTextWrapper>
                  <RWDTitle>
                    {formatMessage({ id: 'general:title_warning_rwd' })}
                  </RWDTitle>
                  <RWDDescription>
                    {formatMessage({ id: 'general:desc_warning_rwd' })}
                  </RWDDescription>
                </RWDTextWrapper>
                <ButtonWrapper>
                  <Button
                    onClick={(): void => {
                      ampli.continueToViewOnMobile()
                      onSkip()
                    }}
                  >
                    {formatMessage({ id: 'general:btn_warning_rwd' })}
                  </Button>
                </ButtonWrapper>
              </RWDContentWrapper>
            )
          case 'browser':
            return (
              <BrowserContentWrapper>
                <ImageWrapper $ratio={253 / 251}>
                  <Image
                    fill
                    alt='unsupported-browser'
                    src={browserWarningSrc}
                  />
                </ImageWrapper>
                <BrowserTextWrapper>
                  <BrowserTitle>
                    {formatMessage({ id: 'general:title_warning_browser' })}
                  </BrowserTitle>
                  <StyleParagraph height={28} size={20}>
                    {formatMessage({ id: 'general:desc_warning_browser' })}
                  </StyleParagraph>
                </BrowserTextWrapper>
              </BrowserContentWrapper>
            )
          default: {
            const _exhaustiveCheck: never = type
            return _exhaustiveCheck
          }
        }
      })()}
      {isLogin ? <IrmFooter isCollapsed /> : <PublicPageFooter />}
    </Wrapper>
  )
}

export default Warning

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  overflow: hidden;

  ${HeaderWrapper} {
    left: 0;
    top: 0;
    width: 100%;

    ${LogoWrapper} {
      display: block;
    }
    ${MessageDropdown},${SearchInputContainer} {
      display: none;
    }
    ${ActionWrapper} {
      a {
        display: none;
      }
    }
  }

  ${FooterWrapper} {
    margin: 0;
    color: ${({ theme }): string => theme.colors.text.secondary};
  }
`

const BaseContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  margin-top: ${IRM_HEADER_HEIGHT}px;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: ${PC_BREAKPOINT}px) {
    flex-direction: row;
    text-align: left;
  }
`

const RWDContentWrapper = styled(BaseContentWrapper)`
  padding: 24px;
`

const BrowserContentWrapper = styled(BaseContentWrapper)`
  padding: 60px 24px 24px 24px;
  gap: 40px;

  @media (min-width: ${PC_BREAKPOINT}px) {
    padding: 0;
    justify-content: center;
  }
`

const RWDTitle = styled(H2)`
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
`

const RWDDescription = styled(StyleParagraph).attrs({ height: 24 })`
  text-align: center;
`

const RWDTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 32px;
`

const BrowserTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;

  @media (min-width: ${PC_BREAKPOINT}px) {
    gap: 20px;
    text-align: left;
  }
`

const BrowserTitle = styled.span`
  font-size: 24px;
  font-weight: 700;
  line-height: 1.4;
  color: ${(props): string => props.theme.colors.brand.primary};

  @media (min-width: ${PC_BREAKPOINT}px) {
    font-size: 30px;
  }
`

const Button = styled.div`
  color: ${({ theme }): string => theme.colors.brand.primary};
  text-decoration: underline;
  cursor: pointer;

  :hover {
    color: ${({ theme }): string => theme.colors.brand.primaryHover};
  }
`
const ImageWrapper = styled.div<{ $ratio: number }>`
  position: relative;
  width: 100%;
  max-width: 500px;
  height: auto;
  aspect-ratio: ${(props): number => props.$ratio};
`

const ButtonWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 32px;
`

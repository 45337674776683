import { FolderOutlined } from '@ant-design/icons'
import React, { FunctionComponent } from 'react'
import Highlighter from 'react-highlight-words'
import styled, { useTheme } from 'styled-components'
import useCustomizedTag from '@/hooks/use-customized-tag'
import { useThemeConstants } from '@/hooks/use-theme-constants'
import { useIntl } from '@/i18n/hooks/use-intl'
import { ampli } from '@/utils/ampli'

interface MatchedCustomizedTagNamesProps {
  keyword: string
  names: string[]
  onClick: (customizedTagName: string) => void
}

const MatchedCustomizedTagNames: FunctionComponent<
  MatchedCustomizedTagNamesProps
> = ({ keyword, names, onClick }) => {
  const { formatMessage } = useIntl()
  const theme = useTheme()
  const { highlightStyle } = useThemeConstants()
  const { getCustomizedTagLocalizedName } = useCustomizedTag()

  return (
    <Box>
      <Header>{formatMessage({ id: 'search:filter_option_kol_type' })}</Header>
      {names.map((name) => {
        const [parentName, subName] = name.split('/')
        const parentLocalizedName = getCustomizedTagLocalizedName(parentName)
        const textToHighlight = subName
          ? `${parentLocalizedName} / ${getCustomizedTagLocalizedName(name)}`
          : parentLocalizedName

        return (
          <TagBox
            key={name}
            onClick={(): void => {
              ampli.clickTheTypeFromQuickSearch({
                type: name,
              })

              onClick(name)
            }}
          >
            <FolderOutlined />
            <Highlighter
              autoEscape
              highlightStyle={{
                ...highlightStyle,
                fontSize: 14,
              }}
              searchWords={[keyword]}
              textToHighlight={textToHighlight}
              unhighlightStyle={{
                fontSize: 14,
                color: theme.colors.text.secondary,
              }}
            />
          </TagBox>
        )
      })}
    </Box>
  )
}

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  cursor: default;
  border-bottom: 1px solid ${({ theme }): string => theme.colors.border.divider};
  background: ${({ theme }): string => theme.colors.background.white};

  > :not(:last-child) {
    margin-bottom: 16px;
  }

  .highlightText {
    color: ${({ theme }): string => theme.colors.brand.secondary};
    background-color: transparent;
  }
`

const Header = styled.div`
  color: ${({ theme }): string => theme.colors.text.secondary};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
`

const TagBox = styled.div`
  display: flex;
  cursor: pointer;

  .anticon {
    font-size: 16px;
    color: ${({ theme }): string => theme.colors.brand.secondary};
    margin-right: 8px;
  }
`

export default MatchedCustomizedTagNames

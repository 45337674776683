import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { StyleParagraph } from '@/components/common/typography'
import SafeNewTabLink from '@/components/safe-new-tab-link'
import { useBreakpoint } from '@/hooks/use-breakpoint'
import { useIntl } from '@/i18n/hooks/use-intl'

const Separator: FunctionComponent = () => {
  return <SeparatorWrapper> | </SeparatorWrapper>
}

export const GoogleRecaptchaFooter: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const breakpoint = useBreakpoint()
  const isDesktop = breakpoint === 'PC'

  return (
    <Wrapper>
      <StyleParagraph height={24} size={14}>
        {formatMessage({ id: 'google:recaptcha_adoption' })}
        {isDesktop ? <Separator /> : <br />}
        <SafeNewTabLink href='https://policies.google.com/privacy'>
          {formatMessage({ id: 'google:privacy' })}
        </SafeNewTabLink>
        <Separator />
        <SafeNewTabLink href='https://policies.google.com/terms'>
          {formatMessage({ id: 'google:terms' })}
        </SafeNewTabLink>
      </StyleParagraph>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  text-align: center;
  * {
    color: ${({ theme }): string => theme.colors.base.grey.grey2};
  }
`

const SeparatorWrapper = styled.span`
  color: ${({ theme }): string => theme.colors.base.grey.grey5};
`

import { Radio, Select, Slider } from 'antd'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import {
  ButtonRadioGroupWrapper,
  FilterGroupWrapper,
  Label,
} from '@/components/search/filters/advanced-filters'
import { useIntl } from '@/i18n/hooks/use-intl'
import { Condition } from '@/types/api/search'
import { PlatformShortcode } from '@/utils/convert-platform'
import useCountry from '@/utils/hooks/use-country'

const AUDIENCE_DEFAULT_CODE = 'any'
const AUDIENCE_MIN_RATE = 0
const AUDIENCE_MAX_RATE = 1

type AudienceQueryRateKey =
  | 'audienceGenderRateBegin'
  | 'audienceAgeRateBegin'
  | 'audienceGeoRateBegin'

interface AudienceSliderProps {
  code?: string
  rateKey: AudienceQueryRateKey
  beginRate?: number
  onValuesChange: (changedValues: Condition) => void
  label: string
}

const AudienceSlider: FunctionComponent<AudienceSliderProps> = ({
  label,
  code = AUDIENCE_DEFAULT_CODE,
  beginRate = AUDIENCE_MIN_RATE,
  onValuesChange,
  rateKey,
}) => {
  const { formatMessage } = useIntl()
  const disabled = code === AUDIENCE_DEFAULT_CODE

  return (
    <SliderWrapper disabled={disabled}>
      <div>
        {disabled ? '' : label}{' '}
        {formatMessage(
          { id: 'search:over_percentage' },
          {
            n: (
              <SliderValue>
                {disabled ? 'N' : (beginRate * 100).toFixed()}
              </SliderValue>
            ),
          },
        )}
      </div>
      <Slider
        disabled={disabled}
        max={1}
        step={0.01}
        tooltip={{
          open: false,
        }}
        value={beginRate}
        onChange={(value: number): void => onValuesChange({ [rateKey]: value })}
      />
    </SliderWrapper>
  )
}

interface AudienceFilterGroupProps {
  filterValues: Condition
  onValuesChange: (changedValues: Condition) => void
}

const AudienceFilterGroup: FunctionComponent<AudienceFilterGroupProps> = ({
  filterValues,
  onValuesChange,
}) => {
  const { formatMessage } = useIntl()
  const country = useCountry()

  const audienceAgeCodes = [
    {
      value: AUDIENCE_DEFAULT_CODE,
      text: formatMessage({ id: 'search:filter_option_percent_any' }),
    },
    { value: '13-17', text: '13-17' },
    { value: '18-24', text: '18-24' },
    { value: '25-34', text: '25-34' },
    { value: '35-44', text: '35-44' },
    { value: '45-64', text: '45-64' },
    { value: '65-', text: '65+' },
  ]

  const audienceGeoCodes = [
    {
      value: AUDIENCE_DEFAULT_CODE,
      text: formatMessage({ id: 'search:filter_option_percent_any' }),
    },
    ...country,
  ]

  const genderCodes: { value: string; text: string }[] = [
    {
      value: AUDIENCE_DEFAULT_CODE,
      text: formatMessage({ id: 'search:filter_option_percent_any' }),
    },
    {
      value: 'male',
      text: formatMessage({ id: 'kol:edit_field_gender_male' }),
    },
    {
      value: 'female',
      text: formatMessage({ id: 'kol:edit_field_gender_female' }),
    },
  ]

  const getCodeText = (
    codes: { value: string; text: string }[],
    code?: string,
  ): string => {
    return codes.find((c) => c.value === code)?.text ?? ''
  }

  const handleCodeChange = (
    codeValue: string,
    codeKey: string,
    rateBeginKey: string,
    rateEndKey: string,
  ): void => {
    if (codeValue === AUDIENCE_DEFAULT_CODE) {
      onValuesChange({
        [codeKey]: undefined,
        [rateBeginKey]: undefined,
        [rateEndKey]: undefined,
      })

      return
    }

    // api end rate 需要帶值，但目前 ui 並沒有可操作的地方，保留舊的行為
    onValuesChange({
      [codeKey]: codeValue,
      [rateEndKey]: AUDIENCE_MAX_RATE,
    })
  }

  return (
    <FilterGroupWrapper>
      <h4>{formatMessage({ id: 'search:audience' })}</h4>
      <div>
        <Label>{formatMessage({ id: 'search:audience_gender' })}</Label>
        <ButtonRadioGroupWrapper>
          <Radio.Group
            optionType='button'
            options={genderCodes.map(({ value, text }) => ({
              label: text,
              value,
            }))}
            value={filterValues?.audienceGenderCode ?? AUDIENCE_DEFAULT_CODE}
            onChange={(v): void =>
              handleCodeChange(
                v.target.value,
                'audienceGenderCode',
                'audienceGenderRateBegin',
                'audienceGenderRateEnd',
              )
            }
          />
        </ButtonRadioGroupWrapper>
        <AudienceSlider
          beginRate={filterValues.audienceGenderRateBegin ?? AUDIENCE_MIN_RATE}
          code={filterValues.audienceGenderCode}
          label={getCodeText(genderCodes, filterValues?.audienceGenderCode)}
          rateKey='audienceGenderRateBegin'
          onValuesChange={onValuesChange}
        />
      </div>
      <div>
        <Label>{formatMessage({ id: 'search:audience_age' })}</Label>
        <RadioGroupWrapper>
          <Radio.Group
            options={audienceAgeCodes.map(({ value, text }) => ({
              label: text,
              value,
            }))}
            value={filterValues?.audienceAgeCode ?? AUDIENCE_DEFAULT_CODE}
            onChange={(e): void =>
              handleCodeChange(
                e.target.value,
                'audienceAgeCode',
                'audienceAgeRateBegin',
                'audienceAgeRateEnd',
              )
            }
          />
        </RadioGroupWrapper>
        <AudienceSlider
          beginRate={filterValues?.audienceAgeRateBegin}
          code={filterValues?.audienceAgeCode}
          label={getCodeText(audienceAgeCodes, filterValues?.audienceAgeCode)}
          rateKey='audienceAgeRateBegin'
          onValuesChange={onValuesChange}
        />
      </div>
      {filterValues.platform_type !== PlatformShortcode.Facebook && (
        <div>
          <Label>{formatMessage({ id: 'search:audience_geo' })}</Label>
          <Select
            showSearch
            filterOption={(input, option): boolean =>
              (option?.props.children?.toString().indexOf(input) ?? -1) >= 0
            }
            style={{ width: '100%' }}
            value={filterValues.audienceGeoCode ?? AUDIENCE_DEFAULT_CODE}
            onChange={(value: string): void =>
              handleCodeChange(
                value,
                'audienceGeoCode',
                'audienceGeoRateBegin',
                'audienceGeoRateEnd',
              )
            }
          >
            {audienceGeoCodes.map((code) => (
              <Select.Option key={code.value} value={code.value}>
                {code.text}
              </Select.Option>
            ))}
          </Select>
          <AudienceSlider
            beginRate={filterValues.audienceGeoRateBegin ?? AUDIENCE_MIN_RATE}
            code={filterValues.audienceGeoCode}
            label={getCodeText(audienceGeoCodes, filterValues?.audienceGeoCode)}
            rateKey='audienceGeoRateBegin'
            onValuesChange={onValuesChange}
          />
        </div>
      )}
    </FilterGroupWrapper>
  )
}

const RadioGroupWrapper = styled.div`
  .ant-radio-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  label {
    font-size: 14px;
    color: ${({ theme }): string => theme.colors.text.secondary};
    margin-bottom: 20px;
    line-height: normal !important;
    height: auto !important;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const SliderValue = styled.span`
  font-size: 14px;
  color: ${({ theme }): string => theme.colors.text.secondary};
  font-weight: 700;
`

interface SliderWrapperProps {
  disabled: boolean
}

const SliderWrapper = styled.div.attrs<SliderWrapperProps>((props) => ({
  ...props,
}))<SliderWrapperProps>`
  height: 54px;
  padding: 8px;
  font-size: 14px;
  color: ${({ disabled, theme }): string =>
    disabled ? theme.colors.base.grey.grey3 : theme.colors.text.secondary};
  background-color: ${(props): string =>
    props.disabled ? '#F5F5F5' : props.theme.colors.brand.background};

  .ant-slider-handle {
    border-color: ${({ theme }): string => theme.colors.brand.primary};
  }

  .ant-slider {
    margin: 8px 0 0;
    padding-bottom: 8px;
  }

  margin-top: 24px;
`

export default AudienceFilterGroup

import { compact, isEmpty } from 'lodash-es'
import Image, { ImageProps } from 'next/image'
import React, { FunctionComponent, useState } from 'react'
import FallbackLogoImage from '@/components/common/image/fallback-logo-image'

interface AsyncImageProps extends Omit<ImageProps, 'src'> {
  imageUrls: (string | undefined)[]
}

const AsyncImage: FunctionComponent<AsyncImageProps> = ({
  imageUrls,
  alt,
  width,
  height,
  fill,
  ...props
}) => {
  const [imgIndex, setImgIndex] = useState(0)
  const imgSrcList = compact(imageUrls)

  if (isEmpty(imgSrcList) || imgSrcList.length < imgIndex + 1) {
    return <FallbackLogoImage height={height} width={width} />
  }

  const sizeProps = fill
    ? { fill, unoptimized: imgSrcList[imgIndex].includes('kolr.ai') }
    : { width: width ?? 200, height: height ?? 200 }

  return (
    <Image
      {...props}
      {...sizeProps}
      alt={alt}
      src={imgSrcList[imgIndex]}
      onError={(): void => {
        setImgIndex((prevIndex) => prevIndex + 1)
      }}
    />
  )
}

export default AsyncImage

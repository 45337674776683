import { notification } from 'antd'
import Link from 'next/link'
import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useLogoutMutation } from '@/api/user-api'
import { Icon } from '@/components/custom-icon'
import { LogoutIcon } from '@/components/header/logout-icon'
import useAgentInfo from '@/hooks/use-agent-info'
import { useAuth } from '@/hooks/use-auth'
import { Page } from '@/hooks/use-authorization/constants'
import routes from '@/hooks/use-authorization/routes'
import { useDispatch } from '@/hooks/use-dispatch'
import { useI18n } from '@/hooks/use-i18n'
import { useIntl } from '@/i18n/hooks/use-intl'
import { logout as logoutAction } from '@/store/me'
import { ampli } from '@/utils/ampli'
import logger from '@/utils/logger'

const MobileMenu: FunctionComponent = () => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const { locale } = useI18n()

  const [isMobileMenuShow, setIsMobileMenuShow] = useState<boolean>(false)
  const { isKol, isAgent } = useAuth()
  const { radarStarAgencyKOL } = useAgentInfo()
  const [logout] = useLogoutMutation()

  const wrapperRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event): void => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsMobileMenuShow(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false)
    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [])

  const onLogout: VoidFunction = () => {
    logout()
      .then(() => {
        ampli.logoutSuccess({ isSingleLogin: false })

        notification.success({
          message: formatMessage({ id: 'general:logout_successfully' }),
          placement: 'bottomRight',
        })

        dispatch(logoutAction())
        routes.login.goto({}, { lang: locale })
      })
      .catch((error) => logger.error(error))
  }

  const handleCloseMenu = (): void => setIsMobileMenuShow(false)

  return (
    <MobileMenuWrapper ref={wrapperRef}>
      <Icon
        style={{ fontSize: '40px' }}
        type='elIconList'
        onClick={(): void => setIsMobileMenuShow(!isMobileMenuShow)}
      />
      {isMobileMenuShow && (
        <MobileMenuContainer>
          {!radarStarAgencyKOL ? (
            <>
              <ItemWrapper>
                <Link href={Page.CaseList} onClick={handleCloseMenu}>
                  <Icon type='caseIcon' />
                  {formatMessage({ id: 'kol:case_list_title' })}
                </Link>
              </ItemWrapper>
              <ItemWrapper>
                <Link href={Page.CampaignCase} onClick={handleCloseMenu}>
                  <Icon className='bullhorn' type='elIconBullhorn' />
                  {formatMessage({ id: 'campaign:kol_my_campaign_title' })}
                </Link>
              </ItemWrapper>
              {isAgent && (
                <ItemWrapper>
                  <Link href={Page.SettingsAgent} onClick={handleCloseMenu}>
                    <Icon type='edit' />
                    {formatMessage({ id: 'general:navbar_kol_management' })}
                  </Link>
                </ItemWrapper>
              )}

              {isKol && (
                <ItemWrapper>
                  <Link
                    href={routes.kolSelf.stringifyUrl({})}
                    onClick={handleCloseMenu}
                  >
                    <Icon type='edit' />
                    {formatMessage({ id: 'general:navbar_my_kol_file' })}
                  </Link>
                </ItemWrapper>
              )}
              <ItemWrapper>
                <Link href={Page.KolSettings} onClick={handleCloseMenu}>
                  <Icon type='setting' />
                  {formatMessage({ id: 'general:navbar_account_text' })}
                </Link>
              </ItemWrapper>
              <ItemWrapper>
                <Link
                  href={routes.kolSettings.stringifyUrl(
                    {},
                    { tab: 'accounting' },
                  )}
                  onClick={handleCloseMenu}
                >
                  <Icon type='dollar' />
                  {formatMessage({ id: 'account:side_accounting_info' })}
                </Link>
              </ItemWrapper>
            </>
          ) : (
            <ItemWrapper>
              <Link
                href={routes.kolSettings.stringifyUrl({})}
                onClick={handleCloseMenu}
              >
                <Icon type='setting' />
                {formatMessage({ id: 'general:navbar_account_text' })}
              </Link>
            </ItemWrapper>
          )}
          <ItemWrapper
            onClick={(): void => {
              setIsMobileMenuShow(false)
              onLogout()
            }}
          >
            <LogoutIcon />
            {formatMessage({ id: 'general:navbar_logout_text' })}
          </ItemWrapper>
        </MobileMenuContainer>
      )}
    </MobileMenuWrapper>
  )
}

export default MobileMenu

const MobileMenuWrapper = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`

const MobileMenuContainer = styled.div`
  position: fixed;
  top: 42px;
  left: 0;
  background: ${({ theme }): string => theme.colors.background.white};
  border-top: 2px solid rgba(0, 0, 0, 0.25);
  border-bottom: 2px solid rgba(0, 0, 0, 0.25);
  width: 100%;
  height: auto;
  margin-top: 5px;
  padding: 30px;
`

const ItemWrapper = styled.div`
  a {
    display: flex;
    align-items: center;
    height: 40px;
    color: ${(props): string => props.theme.colors.base.grey.grey1};

    svg {
      margin-right: 10px;
    }

    .bullhorn {
      font-size: 14px;
    }
  }
`

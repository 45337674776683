import 'antd/dist/reset.css'
import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle<{ fontFamily: string }>`
  html {
    body {
      font-family: ${(props): string => props.fontFamily};
    }
  }
  
  * {
    --ant-color-link: ${({ theme }): string => theme.token.colorLink};
    --ant-color-link-hover: ${({ theme }): string => theme.token.colorLinkHover};
    --ant-color-link-active: ${({ theme }): string => theme.token.colorLinkActive};
    --ant-modal-content-padding: ${({ theme }): string =>
      theme.cssVars.modalContentPadding};
    --ant-modal-footer-margin-top: ${({ theme }): string =>
      theme.cssVars.modalFooterMarginTop};

    white-space: inherit;

    .ant-form-item .ant-form-item-control-input {
      min-height: ${({ theme }): number =>
        theme.components.Form.controlHeight}px;
    }
  }

  div#__next {
    height: 100%;
  }

  .grecaptcha-badge { visibility: hidden; }

  /** tooltip icon 可能需要另外獨立出來一個component 包這個 style  */
  .anticon-info-circle > svg {
    margin: 3px;
  }

  @media (max-width: 767px) {
    .ant-calendar-range {
      width: 100vw;

      .ant-calendar-range-left,
      .ant-calendar-range-right {
        float: none;
        width: 100%;
      }
    }
  }

  .ant-select-dropdown {
    .ant-select-dropdown-menu-item {
      padding-bottom: 10px;
    }
  }

  .ant-popover {
    .ant-popover-arrow {
      display: none;
    }
  }

  .ant-select-tree-dropdown {
    .ant-select-tree {
      max-height: 400px;
      overflow: auto;
    }
  }

  .container {
    width: 1140px;
    padding: 0 20px;
    margin: 0 auto;
    @media (max-width: 1140px) {
      width: 100%;
    }
  }

  .user-dropdown {
    .ant-dropdown-menu-item {
      div {
        width: 150px;
      }
    }
  }

  #__next {
    h1 {
      font-size: 36px;
      font-weight: bold;
      margin: 0 !important;
    }

    h2 {
      font-size: 24px;
      margin-top: 0;
      margin-bottom: 30px;
      font-weight: bold;
    }

    h3 {
      font-size: 20px;
      margin: 0 0 20px 0;
      font-weight: bold;
    }

    .ant-layout,
    .ant-layout-header {
      padding: 0;
      background-color: white;
    }

    // 原本的值是 hidden 但這會阻止 position: sticky 運行
    .ant-layout.ant-layout-has-sider > .ant-layout,
    .ant-layout.ant-layout-has-sider > .ant-layout-content {
      overflow-x: clip;
    }
  }

  .ant-table-thead > tr > th > .ant-table-header-column {
    width: max-content;
  }
  
  body {
    white-space: pre-line;
  }

  // Button
  .ant-btn-default.ant-btn-background-ghost:not(:disabled):not(.ant-btn-disabled):hover {
    background: ${({ theme }): string => theme.components.Button.ghostHoverBg};
    border-color: ${({ theme }): string =>
      theme.components.Button.ghostHoverBorderColor};
  }

  .ant-btn.ant-btn-icon-only:not(.ant-btn-sm) {
    font-size: ${({ theme }): number =>
      theme.components.Button.iconOnlyContentFontSize}px;
    
    .anticon {
      font-size: inherit;  
    }

    &:not(.ant-btn-block) {
      padding: 0 ${({ theme }): number =>
        theme.components.Button.iconOnlyPaddingInline}px;
    }
  }
  
  // Table
  // Remove Table header divider
  .ant-table-thead {
    tr {
      th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
        display: none;
      }
    }
  }

`

// eslint-disable-next-line
const initMocks = async () => {
  if (typeof window === 'undefined') {
    const { server } = await import('./environments/node')
    server.listen()
  } else {
    const { worker } = await import('./environments/browser')
    await worker.start({
      onUnhandledRequest(request, print) {
        if (request.url.includes('_next')) {
          return
        }
        print.warning()
      },
    })
    return worker
  }
}

export default initMocks

import Environment from '@/constants/environment'
import {
  inContextPseudoLanguage,
  sourceLanguage,
  SupportedLanguages,
} from '@/i18n/config'
import env from '@/utils/env'

export interface LanguageDict {
  label: string
  i18nCode: SupportedLanguages
}

const languageArray: [SupportedLanguages, LanguageDict][] = [
  [
    SupportedLanguages.zhTW,
    { label: '繁體中文', i18nCode: SupportedLanguages.zhTW },
  ],
  [
    SupportedLanguages.enUS,
    { label: 'English', i18nCode: SupportedLanguages.enUS },
  ],
  [SupportedLanguages.ja, { label: '日本語', i18nCode: SupportedLanguages.ja }],
]

if (env.ENVIRONMENT !== Environment.Production) {
  languageArray.push([
    inContextPseudoLanguage,
    {
      label: 'Crowdin',
      i18nCode: inContextPseudoLanguage,
    },
  ])
}

const languageMap: Map<SupportedLanguages, LanguageDict> = new Map(
  languageArray,
)

export const isSupportedLanguage = (
  language: unknown,
): language is SupportedLanguages => {
  return languageMap.has(language as SupportedLanguages)
}

export function getLanguage(locale: string): LanguageDict {
  return (
    languageMap.get(locale as SupportedLanguages) ??
    (languageMap.get(sourceLanguage) as LanguageDict)
  )
}

export const languages = Array.from(languageMap, (value) => {
  return { code: value[0], ...value[1] }
})

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { camelizeKeys } from 'humps'
import { decamelizeKeys } from '@/utils/humps'

const FACEBOOK_PAGE_PICTURE_FIELDS = 'picture.width(1200)'
const FACEBOOK_POST_FIELDS =
  'id,permalink_url,message,created_time,attachments{description,type,media_type,title,url,media,target},reactions.type(LIKE).limit(0).summary(total_count).as(like),reactions.type(LOVE).limit(0).summary(total_count).as(love),reactions.type(WOW).limit(0).summary(total_count).as(wow),reactions.type(HAHA).limit(0).summary(total_count).as(haha),reactions.type(SAD).limit(0).summary(total_count).as(sad),reactions.type(ANGRY).limit(0).summary(total_count).as(angry),reactions.type(THANKFUL).limit(0).summary(total_count).as(thankful),comments.filter(stream).limit(0).summary(total_count).as(comments),likes,shares'
const INSTAGRAM_POST_FIELDS =
  'shortcode,caption,id,permalink,like_count,comments,comments_count,insights.metric(shares,plays),media_url,media_type,thumbnail_url,timestamp'

interface Reaction {
  data: any[]
  summary: {
    totalCount: number
  }
}

interface Attachment {
  description: string
  type: string
  mediaType: string
  title: string
  url: string
  media: {
    image: {
      height: number
      src: string
      width: number
    }
  }
  target: {
    id: string
    url: string
  }
}

export interface FacebookPost {
  id: string
  message: string
  createdTime: string
  permalinkUrl: string
  attachments: { data: Attachment[] }
  like: Reaction
  love: Reaction
  wow: Reaction
  haha: Reaction
  sad: Reaction
  angry: Reaction
  thankful: Reaction
  comments: Reaction
  shares?: { count: number }
}

interface Insights {
  description: string
  id: string
  name: string
  period: string
  title: string
  values: { value: number }[]
}

export interface InstagramPost {
  caption: string
  commentsCount: number
  id: string
  shortcode: string
  likeCount: number
  mediaUrl: string
  permalink: string
  timestamp: string
  thumbnailUrl: string
  mediaType: string
  insights?: { data: Insights[] }
}

export enum MetaMediaType {
  Image = 'IMAGE',
  Video = 'VIDEO',
}

interface Cursors {
  before: string
  after: string
}

interface Paging {
  cursors: Cursors
  next?: string
}

type FacebookGraphResponse<T> = {
  data: T[]
  paging?: Paging
}

type FetchFacebookPagePostsResponse = FacebookGraphResponse<FacebookPost>
type FetchInstagramPagePostsResponse = FacebookGraphResponse<InstagramPost>

interface FetchMetaPagePostsRequest {
  accessToken: string
  pageID: string
  after?: string
}

interface FetchMegaPagePictureResponse {
  picture: { data: { url: string } }
}

export const metaApi = createApi({
  reducerPath: 'metaAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://graph.facebook.com/v21.0',
  }),
  endpoints: (builder) => ({
    fetchFacebookPagePicture: builder.query<
      FetchMegaPagePictureResponse,
      FetchMetaPagePostsRequest
    >({
      query: (queryArg) => {
        const { pageID, ...restArgs } = queryArg
        const params = decamelizeKeys({
          fields: FACEBOOK_PAGE_PICTURE_FIELDS,
          ...restArgs,
        })

        return {
          url: `${pageID}`,
          params,
        }
      },
    }),
    fetchFacebookPagePosts: builder.query<
      FetchFacebookPagePostsResponse,
      FetchMetaPagePostsRequest
    >({
      query: (queryArg) => {
        const { pageID, ...restArgs } = queryArg

        const params = decamelizeKeys({
          fields: FACEBOOK_POST_FIELDS,
          ...restArgs,
        })

        return {
          url: `/${pageID}/posts`,
          params,
        }
      },
      transformResponse: (
        response: object[],
      ): FetchFacebookPagePostsResponse => {
        return camelizeKeys(
          response,
        ) as unknown as FetchFacebookPagePostsResponse
      },
    }),
    fetchInstagramPagePosts: builder.query<
      FetchInstagramPagePostsResponse,
      FetchMetaPagePostsRequest
    >({
      query: (queryArg) => {
        const { pageID, ...restArgs } = queryArg

        const params = decamelizeKeys({
          fields: INSTAGRAM_POST_FIELDS,
          ...restArgs,
        })

        return {
          url: `/${pageID}/media`,
          params,
        }
      },
      transformResponse: (
        response: object[],
      ): FetchInstagramPagePostsResponse => {
        return camelizeKeys(
          response,
        ) as unknown as FetchInstagramPagePostsResponse
      },
    }),
  }),
})

export const useFetchFacebookPagePosts =
  metaApi.endpoints.fetchFacebookPagePosts.useQuery

export const useFetchFacebookPagePicture =
  metaApi.endpoints.fetchFacebookPagePicture.useQuery

export const useFetchInstagramPagePosts =
  metaApi.endpoints.fetchInstagramPagePosts.useQuery

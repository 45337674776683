import { Typography } from 'antd'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import RemainingApplyDays from '@/components/campaign/kol/case-list/remaining-apply-days'
import Space from '@/components/common/antd-v4-backport/space'
import AsyncImage from '@/components/common/image/async-image'
import { Icon } from '@/components/custom-icon'
import { getI18nId } from '@/constants/general-tag-code'
import { useBreakpoint } from '@/hooks/use-breakpoint'
import { useIntl } from '@/i18n/hooks/use-intl'
import { Campaign, CampaignPurpose } from '@/store/campaign'
import { convertPlatformShortcode } from '@/utils/convert-platform'

interface CaseCardProps {
  campaign: Campaign
}

const CaseCard: FunctionComponent<CaseCardProps> = ({ campaign }) => {
  const breakpoint = useBreakpoint()
  const { formatMessage, formatNumber, formatDate } = useIntl()

  const isPromotingBrand =
    campaign.campaignPurpose === CampaignPurpose.PromotingBrand

  const imageUrl = isPromotingBrand
    ? campaign.brands[0]?.pictures[0]?.signedUrl
    : campaign.products[0]?.pictures[0]?.signedUrl

  const imageAlt = isPromotingBrand
    ? campaign.brands[0]?.name
    : campaign.products[0]?.name

  return (
    <Wrapper>
      <VStack direction={breakpoint === 'mobile' ? 'horizontal' : 'vertical'}>
        <ImageWrapper>
          <Typography.Paragraph ellipsis={{ rows: 1 }}>
            {campaign.generalTagCodes
              .map((code) => formatMessage({ id: getI18nId(code) }))
              .join('、')}
          </Typography.Paragraph>
          <Image
            alt={imageAlt}
            imageUrls={[
              imageUrl,
              campaign?.brandRecognitionSnap?.iconFile?.signedUrl,
            ]}
          />
        </ImageWrapper>
        <ContentWrapper
          direction='vertical'
          size={breakpoint === 'mobile' ? 5 : 15}
        >
          <Space direction='vertical' size={breakpoint === 'mobile' ? 5 : 3}>
            <BrandName ellipsis={{ rows: 1 }}>
              {campaign.brandRecognitionSnap.name}
            </BrandName>
            <CampaignName ellipsis={{ rows: 2 }}>{campaign.name}</CampaignName>
          </Space>
          <Space direction='vertical' size={4}>
            <BudgetPerPerson size={0}>
              {breakpoint === 'PC' && (
                <Label>
                  {formatMessage({ id: 'kol:case_list_budget_per_person' })}
                </Label>
              )}
              <BudgetPerPersonStartsFrom>
                {formatMessage(
                  { id: 'kol:case_list_budget_per_person_starts_from' },
                  {
                    budgetPerPerson: formatNumber(
                      campaign.cooperation.budgetPerPerson,
                    ),
                  },
                )}
              </BudgetPerPersonStartsFrom>
              {breakpoint === 'PC' && campaign.applyEndTime && (
                <RemainingApplyDays applyEndTime={campaign.applyEndTime} />
              )}
            </BudgetPerPerson>
            <HStack align='center' size={4}>
              {breakpoint === 'PC' && (
                <Label>
                  {formatMessage({ id: 'kol:case_list_platform_involved' })}
                </Label>
              )}
              <PlatformInvolved>
                {campaign.platformInvolved.map((platformShortcode) => {
                  const platform = convertPlatformShortcode(platformShortcode)
                  return (
                    platform.icon && (
                      <Icon key={platformShortcode} type={platform.icon} />
                    )
                  )
                })}
              </PlatformInvolved>
              {breakpoint === 'mobile' && campaign.applyEndTime && (
                <RemainingApplyDays applyEndTime={campaign.applyEndTime} />
              )}
            </HStack>
            {breakpoint === 'PC' && (
              <Label>
                {formatMessage(
                  { id: 'kol:case_list_campaign_duration' },
                  {
                    duration: `${formatDate(campaign.startAt)} - ${formatDate(
                      campaign.endAt,
                    )}`,
                  },
                )}
              </Label>
            )}
          </Space>
        </ContentWrapper>
      </VStack>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: ${({ theme }): string => theme.colors.background.white};
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.102);
  overflow: hidden;
`

const VStack = styled(Space)`
  width: 100%;
`

const ImageWrapper = styled.div`
  position: relative;
  flex: 0 0 auto;
  width: 140px;
  height: 100%;
  aspect-ratio: 1 / 1;

  .ant-typography {
    color: ${({ theme }): string => theme.colors.text.white};
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    background: ${({ theme }): string => theme.colors.text.primary};
    border: 1px solid ${({ theme }): string => theme.colors.text.primary};
    padding: 3px 8px;
    border-radius: 2px 0;
    margin: 0;
  }

  @media (min-width: 768px) {
    aspect-ratio: 1.33;
    width: auto;
    height: 265px;
  }
`

const Image = styled(AsyncImage)`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`

export const BrandName = styled(Typography.Paragraph)`
  color: ${({ theme }): string => theme.colors.text.secondary};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  margin: 0;
`

export const CampaignName = styled(Typography.Paragraph)`
  color: ${({ theme }): string => theme.colors.text.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 140%;
  margin: 0;

  @media (min-width: 768px) {
    font-weight: 500;
    font-size: 18px;
  }
`

const ContentWrapper = styled(Space)`
  justify-content: center;
  min-width: 180px;
  padding: 12px 10px 12px 0;

  div.ant-typography {
    margin: 0;
  }

  @media (min-width: 768px) {
    padding: 16px;
  }
`

const Label = styled.p`
  color: ${({ theme }): string => theme.colors.text.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
`

const BudgetPerPerson = styled(Space)`
  @media (min-width: 768px) {
    order: 2;
    margin: 4px 0 0;
  }
`

const BudgetPerPersonStartsFrom = styled(Label)`
  @media (min-width: 768px) {
    font-weight: 700;
  }
`

export const PlatformInvolved = styled(Space)`
  .anticon {
    font-size: 16px;

    @media (min-width: 768px) {
      font-size: 18px;
    }
  }
`

const HStack = styled(Space)`
  justify-content: space-between;

  @media (min-width: 768px) {
    justify-content: flex-start;
  }
`

export default CaseCard

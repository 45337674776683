import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CountryCode } from '@/constants/country-code'
import { Condition } from '@/types/api/search'
import { StartOnboardingTutorialProperties } from '@/utils/ampli'

export type TourKey =
  | 'search-kol'
  | 'social-performance'
  | 'marketing-trend'
  | 'report-performance'
  | 'predict-competitor'
  | 'world-kol'
  | 'directory'
  | 'kol-collections'
  | 'predictive-report'
  | 'final'
  | 'abort'

type TourStepAction =
  | 'open-preview-post-list'
  | 'tour-final-step'
  | 'fill-keyword'
  | 'trigger-search'
  | 'open-directory'
  | 'open-predictive-report'

export const TOUR_KEY_EVENT_NAME_MAPPING: Record<
  Exclude<TourKey, 'abort' | 'final' | 'world-kol'>,
  StartOnboardingTutorialProperties['onboardingItem']
> = {
  'search-kol': 'Search Influencer',
  'social-performance': 'Influencer Detail',
  'marketing-trend': 'Hashtag',
  'report-performance': 'Deep Report',
  'predict-competitor': 'Competitor Analysis',
  directory: 'Directory',
  'predictive-report': 'Proposal',
  'kol-collections': 'Influencer Collection',
}

export const gerCountryDefaultLang = (
  country: Condition['country_code'],
): string => {
  switch (country) {
    case CountryCode.TW:
      return '團購 AND 優惠'
    case CountryCode.JP:
      return 'グルメ AND レシピ'
    default:
      return 'foodie AND restaurant'
  }
}

interface TourState {
  tourModalVisible: boolean
  selectedTour: TourKey
  tourStepAction?: TourStepAction
}

const initialState: TourState = {
  selectedTour: 'search-kol',
  tourModalVisible: false,
}

const tourSlice = createSlice({
  name: 'tour',
  initialState,
  reducers: {
    setTourModalVisible: (state, action: PayloadAction<boolean>) => {
      state.tourModalVisible = action.payload
    },
    setSelectedTour: (state, action: PayloadAction<TourKey>) => {
      state.selectedTour = action.payload
    },
    setTourStepAction: (
      state,
      action: PayloadAction<TourStepAction | undefined>,
    ) => {
      state.tourStepAction = action.payload
    },
  },
})

export const { setTourModalVisible, setSelectedTour, setTourStepAction } =
  tourSlice.actions

export default tourSlice.reducer

import { PlanType } from '@buggy/shared'
import { CSSProperties } from 'react'
import { useTheme } from 'styled-components'

interface ThemeConstants {
  highlightStyle: CSSProperties
  planTypeColor: Record<PlanType, string>
  planTypeBackgroundColor: Record<PlanType, string>
  chartColors: string[]
}

export const useThemeConstants = (): ThemeConstants => {
  const theme = useTheme()

  const highlightStyle: CSSProperties = {
    color: theme.colors.brand.secondary,
    backgroundColor: 'transparent',
    padding: 0,
  }

  const planTypeColor: Record<PlanType, string> = {
    [PlanType.Free]: theme.colors.plan.advanced,
    [PlanType.Trial]: theme.colors.plan.advanced,
    [PlanType.Premium]: theme.colors.plan.premium,
    [PlanType.Advanced]: theme.colors.plan.advanced,
    [PlanType.Enterprise]: theme.colors.plan.advanced,
    [PlanType.Internal]: theme.colors.plan.advanced,
  }

  const planTypeBackgroundColor: Record<PlanType, string> = {
    [PlanType.Free]: theme.colors.background.grey,
    [PlanType.Trial]: theme.colors.background.grey,
    [PlanType.Premium]: theme.colors.background.green,
    [PlanType.Advanced]: theme.colors.background.blue,
    [PlanType.Enterprise]: theme.colors.background.blue,
    [PlanType.Internal]: theme.colors.background.blue,
  }

  const chartColors = Object.values(theme.colors.chart)

  return {
    highlightStyle,
    planTypeColor,
    planTypeBackgroundColor,
    chartColors,
  }
}

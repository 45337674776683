import { UserType } from '@/hooks/use-authorization/constants'

/** NOTE:
 * referenced from https://gitlab.corp.ikala.tv/kolradar/escanor/-/blob/develop/web/middleware/error_handler.go#L14
 * */
export type APIErrorData = {
  Errors: string[]
}

/** NOTE:
 * referenced from https://gitlab.corp.ikala.tv/kolradar/escanor/-/blob/develop/internal/api/controllers/user/me.go#L129-135
 */
const userTypeOptionPattern = Object.values(UserType).join('|')
const ERROR_USER_TYPE_ALREADY_EXISTS_PATTERN = `user type\\((${userTypeOptionPattern})\\) already exists`
export const ERROR_USER_TYPE_ALREADY_EXISTS_REGEX = new RegExp(
  ERROR_USER_TYPE_ALREADY_EXISTS_PATTERN,
)

/** NOTE:
 * referenced from https://pkg.go.dev/github.com/appleboy/gin-jwt/v2#pkg-variables
 */
export const ERROR_FAILED_AUTHENTICATION = 'incorrect Username or Password'

/** NOTE:
 * referenced from https://gitlab.corp.ikala.tv/kolradar/escanor/-/blob/develop/internal/api/controllers/user/register.go#L464
 */
export const ERROR_USER_ALREADY_EXISTS =
  'the user already completed registration; maybe called the wrong API'

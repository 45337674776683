import { Radio } from 'antd'
import { RadioProps } from 'antd/lib/radio'
import React from 'react'
import styled from 'styled-components'
import { StyleParagraph } from '@/components/common/typography'
import { useGetLabelColor } from '@/utils/hooks/use-get-label-color'

export const RadioButton = styled((props: RadioProps) => {
  const getLabelColor = useGetLabelColor()

  return (
    <Radio {...props}>
      <StyleParagraph color={getLabelColor(props.checked, props.disabled)}>
        {props.children}
      </StyleParagraph>
    </Radio>
  )
})`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`
